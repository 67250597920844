import './answer-finger-on-it.scss';

import { GameState, PlayerAnswer, RoundTypes } from '@house-of-games/common';
import { useEffect, useState } from 'react';

import { Button } from '../../../common/components/buttons/buttons';
import { ImageWithCoordinates } from './image-with-coordinates';

type AnswerComponentProps<T extends RoundTypes.RoundType> = {
    submit: (answer: PlayerAnswer<T>) => void;
    gameState: GameState<T>;
};

type AssociatedRounds = RoundTypes.RoundType.FingerOnIt | RoundTypes.RoundType.WhereIsKazakhstan;

export function AnswerFingerOnIt({ submit, gameState }: AnswerComponentProps<AssociatedRounds>) {
    const [answer, setAnswer] = useState<PlayerAnswer<AssociatedRounds>>(null);
    const [isSending, setIsSending] = useState(false);

    const coordinates = answer
        ? [
              {
                  x: answer.x,
                  y: answer.y,
                  fillColor: 'var(--hog-red-dark)',
                  borderColor: 'var(--hog-primary)',
                  scale: 1.2,
              },
          ]
        : [];

    function onImageClicked(x: number, y: number) {
        setAnswer({ x, y });
    }

    async function onSubmit() {
        setIsSending(true);
        await submit(answer);
        setIsSending(false);
    }

    useEffect(() => {
        setAnswer(null);
    }, [gameState]);

    return (
        <>
            <div className="answer-component answer-finger-on-it">
                <ImageWithCoordinates
                    coordinates={coordinates}
                    imageQuestion={gameState.image}
                    onClick={onImageClicked}
                />
            </div>
            <div className="shelf shelf__bottom">
                <Button label="Submit" disabled={isSending || !answer} onClick={onSubmit} />
            </div>
        </>
    );
}
