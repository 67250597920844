import './play-answer-list.scss';

import { getAnswerValidator, RoundTypes } from '@house-of-games/common';
import { RoundType } from '@house-of-games/common/lib/types/round';
import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { useDynamicTextSize } from '../../game/use-dynamic-text-size';

type AssociatedRounds =
    | SlideProps<RoundTypes.RichListRound>
    | SlideProps<RoundTypes.SizeMattersRound>
    | SlideProps<RoundTypes.ZListRound>;

export function PlayAnswerList({ question, message, players, roundType }: AssociatedRounds) {
    const textSizeClass = useDynamicTextSize([question.question]);
    const [isPlayerAnswersRevealed, setIsPlayerAnswersRevealed] = useState(false);
    const [isPlayerAnswersVisible, setIsPlayerAnswersVisible] = useState(false);
    const [isAnswersRevealed, setIsAnswersRevealed] = useState(false);
    const [isAnswersVisible, setIsAnswersVisible] = useState(false);
    const { question: q, answers } = question;

    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    function handleMessageReceived(message: Message) {
        switch (message.name) {
            case MessageName.reveal:
                if (isPlayerAnswersRevealed) {
                    setIsAnswersRevealed(true);
                    setTimeout(() => {
                        setIsAnswersVisible(true);
                    }, 200);
                } else {
                    setIsPlayerAnswersRevealed(true);
                    setTimeout(() => {
                        setIsPlayerAnswersVisible(true);
                    }, 200);
                }
        }
    }

    function getTransitionDelay(index: number) {
        return `${0.7 + index * 0.1}s`;
    }

    function getPlayersWithValidAnswers() {
        return Object.values(players).filter((p) => {
            if (!p.answer) {
                return false;
            }

            const validator = getAnswerValidator(roundType);
            try {
                validator(p.answer);
                return true;
            } catch (e) {
                return false;
            }
        });
    }

    function renderPlayersWithValidAnswers() {
        const playersWithValidAnswers = getPlayersWithValidAnswers();
        const className = classNames('play-answer-list__answer-item', {
            'play-answer-list__answer-item--visible': isPlayerAnswersVisible,
        });

        if (playersWithValidAnswers.length === 0) {
            return (
                <div key={0} className={className} style={{ transitionDelay: getTransitionDelay(0) }}>
                    No Answers Were Submitted!
                </div>
            );
        }

        return playersWithValidAnswers.map((p, i) => {
            const className = classNames('play-answer-list__answer-item', {
                'play-answer-list__answer-item--visible': isPlayerAnswersVisible,
            });
            return (
                <div key={i} className={className} style={{ transitionDelay: getTransitionDelay(i) }}>{`${p.name}: ${(
                    p.answer as string
                )?.trim()}`}</div>
            );
        });
    }

    function getNumberOfLetters(s: string) {
        const matches = s.match(/[\p{L}0-9]/gu);
        const length = matches ? Array.from(matches).length : 0;
        return length;
    }

    function getFormattedAnswers(): Array<string | ReactElement> {
        const trimmed = answers.map((a) => a.trim());
        switch (roundType) {
            case RoundType.SizeMatters:
                return trimmed
                    .sort((a, b) => getNumberOfLetters(b) - getNumberOfLetters(a))
                    .map((a, i) => {
                        return (
                            <span key={i}>
                                <span>
                                    {a} <span className="answer-length">({getNumberOfLetters(a)})</span>
                                </span>
                            </span>
                        );
                    });
            case RoundType.ZList:
                return trimmed.sort().reverse();
            default:
                return trimmed;
        }
    }

    function renderFormattedAnswers() {
        return getFormattedAnswers().map((a, i) => {
            const className = classNames('play-answer-list__answer-item', {
                'play-answer-list__answer-item--visible': isAnswersVisible,
            });
            return (
                <div key={i} className={className} style={{ transitionDelay: getTransitionDelay(i) }}>
                    {a}
                </div>
            );
        });
    }

    return (
        <div className="play-answer-list slide page">
            {!isPlayerAnswersRevealed && (
                <div className={`play-answer-list__question-container ${textSizeClass}`}>
                    <AnimateExpandContainer className="play-answer-list__question">
                        <TextFlip text={q} offset={300} />
                    </AnimateExpandContainer>
                </div>
            )}
            {isPlayerAnswersRevealed && (
                <>
                    <div className="play-answer-list__answer-container">
                        <AnimateExpandContainer className="play-answer-list__answer play-answer-list__answer--players" />
                        <div className="play-answer-list__answer-items scrollable">
                            {renderPlayersWithValidAnswers()}
                        </div>
                    </div>
                    <div className="play-answer-list__answer-container">
                        {isAnswersRevealed && (
                            <>
                                <AnimateExpandContainer className="play-answer-list__answer play-answer-list__answer--actual" />
                                <div className="play-answer-list__answer-items scrollable">
                                    {renderFormattedAnswers()}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
