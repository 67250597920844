import './create-z-to-a.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';

import { Button } from '../../../common/components/buttons/buttons';
import { TextField } from '../../../common/components/form-fields/text-field';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { RoundProps } from '../create-round-component-map';

const definition = roundDefinitions[RoundTypes.RoundType.ZToA].of;

export function CreateZToA({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: RoundProps<RoundTypes.ZToARound>) {
    const { questions } = round;

    function handleNewQuestion() {
        createNewQuestion({ question: {}, answer: '' });
    }

    function handleClueChanged(questionIndex: number, clueIndex: 0 | 1 | 2, clue: string) {
        const question = questions[questionIndex];
        const newQuestion = { ...question, question: { ...question.question, [clueIndex]: clue } };
        updateQuestion(questionIndex, newQuestion);
    }

    function handleAnswerChanged(questionIndex: number, answer: string) {
        const newQuestion = { ...questions[questionIndex], answer };
        updateQuestion(questionIndex, newQuestion);
    }

    return (
        <div className="create-z-to-a">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-z-to-a-question__container">
                        <div className="create-z-to-a-question__clue-container">
                            <QuestionArrows
                                index={i}
                                numberOfQuestions={questions.length}
                                onClickUpArrow={shiftUp}
                                onClickDownArrow={shiftDown}
                            />
                            {([0, 1, 2] as const).map((clueIndex) => {
                                return (
                                    <div key={clueIndex} className="create-z-to-a-question__clue">
                                        <TextField
                                            label="Clue"
                                            type="text"
                                            maxLength={definition.question.of.length}
                                            isOutline
                                            defaultValue={q.question[clueIndex]}
                                            onChange={(v) => handleClueChanged(i, clueIndex, v)}
                                        />
                                    </div>
                                );
                            })}
                            <QuestionErase index={i} onRemove={removeQuestion} />
                        </div>
                        <div className="create-z-to-a-question__answer">
                            <TextField
                                label="Answer"
                                type="text"
                                maxLength={definition.answer.length}
                                defaultValue={q.answer}
                                onChange={(v) => handleAnswerChanged(i, v)}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
