import './question-area.scss';

import { RoundTypes, UserStorage } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { BasicAudioPlayer } from '../../../common/components/basic-audio-player/basic-audio-player';
import { TextField } from '../../../common/components/form-fields/text-field';
import { Music } from '../../../common/components/icons/music';
import { LoadingSpinner } from '../../../common/components/loading-error/loading-spinner';
import { AudioSelectModal } from '../../../common/components/user-storage/audio-select-modal';
import { ImageSelectModal } from '../../../common/components/user-storage/image-select-modal';
import { useStorageCache } from '../../../common/components/user-storage/use-storage-cache';
import { AppError } from '../../../utils/app-error';
import { FirebaseHelper } from '../../../utils/firebase';
import { ImageSelectComponent } from './image-question-input';
import { QuestionArrows } from './question-arrows';
import { QuestionErase } from './question-erase';
import { QuestionTypeSelector } from './question-type-selector';
import { TextArea } from './text-area';

type QuestionAreaProps = RoundTypes.Question<string> & {
    index: number;
    answerPlaceholder?: string;
    questionPlaceholder?: string;
    maxQuestionLength?: number;
    maxPromptLength?: number;
    maxAnswerLength?: number;
    isArrowsEnabled?: boolean;
    allowedQuestionTypes: Array<RoundTypes.QuestionType>;
    numberOfQuestions: number;
    onChange: (i: number, q: RoundTypes.Question) => void;
    onClickUpArrow?: (i: number) => void;
    onClickDownArrow?: (i: number) => void;
    onRemove?: (i: number) => void;
};

export function QuestionArea({
    question,
    prompt,
    answer,
    index,
    answerPlaceholder,
    questionPlaceholder,
    maxQuestionLength,
    maxPromptLength,
    maxAnswerLength,
    isArrowsEnabled,
    allowedQuestionTypes,
    numberOfQuestions,
    onChange,
    onClickUpArrow,
    onClickDownArrow,
    onRemove,
}: QuestionAreaProps) {
    const audioRef = useRef<HTMLAudioElement>();
    const storageCache = useStorageCache();
    const questionType = UserStorage.getQuestionType(question);
    const [isLoading, setIsLoading] = useState(true);
    const [selectingFileType, setSelectingFileType] = useState<
        RoundTypes.QuestionType.Audio | RoundTypes.QuestionType.Image
    >();

    async function loadQuestion() {
        if (!question || questionType === RoundTypes.QuestionType.Text) {
            return;
        }

        try {
            await storageCache.load(question);
        } catch (e) {
            if (e instanceof AppError && e.code === FirebaseHelper.ErrorCodes.FILE_NOT_FOUND) {
                handleQuestionChange('');
            }
        }

        setIsLoading(false);
    }

    useEffect(() => {
        loadQuestion();
    }, [question]);

    function closeModal() {
        setSelectingFileType(undefined);
    }

    function handleFileSelected(fileKey: string) {
        setSelectingFileType(undefined);
        onChange(index, { question: fileKey, answer });
    }

    function handleQuestionChange(newQuestion: string) {
        onChange(index, { question: newQuestion, prompt, answer });
    }

    function handlePromptChange(newPrompt: string) {
        onChange(index, { question, prompt: newPrompt, answer });
    }

    function handleAnswerChange(newAnswer: string) {
        onChange(index, { question, prompt, answer: newAnswer });
    }

    function handleSwitchToTextQuestion() {
        handleQuestionChange('');
        handlePromptChange('');
    }

    function renderImageQuestion() {
        return (
            <>
                <div className="qa-prompt__container">
                    <TextField
                        maxLength={maxPromptLength}
                        defaultValue={prompt}
                        type="text"
                        label="Prompt (e.g. Name of this person)"
                        onChange={handlePromptChange}
                    />
                </div>
                <div className="qa-image__container">
                    <ImageSelectComponent
                        imageQuestion={question}
                        onClick={() => setSelectingFileType(RoundTypes.QuestionType.Image)}
                    />
                </div>
            </>
        );
    }

    function renderMusicQuestion() {
        return isLoading ? (
            <LoadingSpinner />
        ) : (
            <>
                <div className="qa-prompt__container">
                    <TextField
                        maxLength={maxPromptLength}
                        defaultValue={prompt}
                        type="text"
                        label="Prompt (e.g. Name of this song)"
                        onChange={handlePromptChange}
                    />
                </div>
                <div
                    className="qa-music__container"
                    onClick={() => setSelectingFileType(RoundTypes.QuestionType.Audio)}
                >
                    <Music scale={1.4} />
                </div>
            </>
        );
    }

    function renderTextQuestion() {
        return (
            <TextArea
                maxLength={maxQuestionLength}
                className="qa-question"
                defaultValue={question}
                placeholder={questionPlaceholder || 'Enter your question here...'}
                onChange={handleQuestionChange}
            />
        );
    }

    const containerClassNames = classNames('qa__container readable', {
        'qa__container--music': questionType === RoundTypes.QuestionType.Audio,
        'qa__container--image': questionType === RoundTypes.QuestionType.Image,
    });

    function renderQuestion() {
        switch (questionType) {
            case RoundTypes.QuestionType.Audio:
                return renderMusicQuestion();
            case RoundTypes.QuestionType.Image:
                return renderImageQuestion();
            default:
                return renderTextQuestion();
        }
    }

    function renderModal() {
        switch (selectingFileType) {
            case RoundTypes.QuestionType.Audio:
                return (
                    <AudioSelectModal
                        audioData={undefined}
                        onCloseModal={closeModal}
                        onFileSelected={handleFileSelected}
                    />
                );
            case RoundTypes.QuestionType.Image:
                return <ImageSelectModal onCloseModal={closeModal} onFileSelected={handleFileSelected} />;
            default:
                setSelectingFileType(undefined);
                return <></>;
        }
    }

    return (
        <div className={containerClassNames}>
            {isArrowsEnabled && <QuestionArrows {...{ index, numberOfQuestions, onClickUpArrow, onClickDownArrow }} />}
            {allowedQuestionTypes.length > 1 && (
                <QuestionTypeSelector
                    onSelectText={handleSwitchToTextQuestion}
                    onSelectMusic={() => setSelectingFileType(RoundTypes.QuestionType.Audio)}
                    onSelectImage={() => setSelectingFileType(RoundTypes.QuestionType.Image)}
                    selectedQuestionType={questionType}
                    allowedTypes={allowedQuestionTypes}
                />
            )}
            {renderQuestion()}
            <div className="qa-answer__container">
                <TextField
                    maxLength={maxAnswerLength}
                    defaultValue={answer}
                    type="text"
                    label={answerPlaceholder || 'Answer'}
                    onChange={handleAnswerChange}
                />
            </div>
            {questionType === RoundTypes.QuestionType.Audio && !isLoading && (
                <>
                    <audio ref={audioRef} src={storageCache.cache.current[question]} />
                    <BasicAudioPlayer audioRef={audioRef} className="qa-audio-player" />
                </>
            )}
            {onRemove && <QuestionErase {...{ index, onRemove }} />}
            {selectingFileType && renderModal()}
        </div>
    );
}
