type DeleteTagProps = {
    scale?: number;
    onClick?: () => void;
};

function doNothing() {
    // noop
}

export function DeleteTag({ scale = 1, onClick = doNothing }: DeleteTagProps) {
    return (
        <svg
            onClick={onClick}
            className="icon__delete-tag"
            width={`${24 * scale}`}
            height={`${24 * scale}`}
            viewBox="0 0 24 24"
        >
            <polygon
                points="13.71,9.71 12.29,8.29 10,10.59 7.71,8.29 6.29,9.71 8.59,12 6.29,14.29 7.71,15.71 10,13.41 12.29,15.71 
	13.71,14.29 11.41,12 "
            />
            <path d="M16.41,19H1V5h15.41l7,7L16.41,19z M3,17h12.59l5-5l-5-5H3V17z" />
        </svg>
    );
}
