import './play-dim-sums.scss';

import { RoundTypes } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { useDynamicTextSize } from '../../game/use-dynamic-text-size';
import { calculateAnswer } from './create-dim-sums';
import { MathOperator } from './operator-icons/math-operator';

export function PlayDimSums({ question, message }: SlideProps<RoundTypes.DimSumsRound>) {
    const allText: Array<string> = [];
    Object.values(question.questions).forEach((q) => {
        allText.push(q.question);
        allText.push(`${q.answer}`);
    });
    const textSizeClass = useDynamicTextSize(allText);

    const [isRevealed, setIsRevealed] = useState(false);

    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    function handleMessageReceived(msg: Message) {
        switch (msg.name) {
            case MessageName.reveal:
                setIsRevealed(true);
        }
    }

    const firstTerm = question.questions[question.equation.firstTerm]?.answer;
    const secondTerm = question.questions[question.equation.secondTerm]?.answer;

    return (
        <div className={`play-dim-sums slide page ${textSizeClass}`}>
            <div className="play-dim-sums-questions">
                {Object.values(question.questions).map((q, i) => {
                    const isCorrectAnswer = [question.equation.firstTerm, question.equation.secondTerm].includes(
                        i as 0 | 1 | 2 | 3,
                    );
                    const containerClasses = classNames('play-dim-sums-question', {
                        'play-dim-sums-question--incorrect': isRevealed && !isCorrectAnswer,
                    });
                    return (
                        <AnimateExpandContainer key={i} className={containerClasses}>
                            <TextFlip className="play-dim-sums-question__question" text={q.question} offset={300} />
                            <TextFlip
                                className="play-dim-sums-question__answer"
                                text={q.answer}
                                offsetFlag={!isRevealed}
                            />
                        </AnimateExpandContainer>
                    );
                })}
            </div>
            <div className="play-dim-sums-equation">
                <AnimateExpandContainer className="play-dim-sums-equation__animated-container">
                    <TextFlip text={firstTerm} offsetFlag={!isRevealed} />
                    <TextFlip
                        text={<MathOperator operator={question.equation.operator} scale={2.4} />}
                        offset={300}
                        entranceOnly
                    />
                    <TextFlip text={secondTerm} offsetFlag={!isRevealed} />
                    <TextFlip text={<MathOperator operator="Equals" scale={2.4} />} offset={300} entranceOnly />
                    <TextFlip
                        text={calculateAnswer(question, question.equation.firstTerm, question.equation.secondTerm)}
                        offset={300}
                    />
                </AnimateExpandContainer>
            </div>
        </div>
    );
}
