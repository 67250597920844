export function without<T = unknown>(arr: Array<T>, item: T | Array<T>): Array<T> {
    const itemArray = Array.isArray(item) ? item : [item];
    let newArray = [...arr];

    itemArray.forEach((i) => {
        const index = newArray.indexOf(i);
        if (index !== -1) {
            newArray = [...newArray.slice(0, index), ...newArray.slice(index + 1)];
        }
    });

    return newArray;
}

export function insert<T = unknown>(arr: Array<T>, index: number, item: T): Array<T> {
    return [...arr.slice(0, index), item, ...arr.slice(index)];
}
