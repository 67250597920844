import './image-select-modal.scss';
import 'react-scrubber/lib/scrubber.css';

import { CFRequestTypes, RoundValidators } from '@house-of-games/common';
import imageCompression from 'browser-image-compression';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Scrubber } from 'react-scrubber';

import { useImageLoad } from '../../../modules/rounds/common/use-image-load';
import { AppError } from '../../../utils/app-error';
import { FirebaseHelper } from '../../../utils/firebase';
import { Button } from '../buttons/buttons';
import { TextField } from '../form-fields/text-field';
import { Close } from '../icons/close';
import { ImageNoBorder } from '../icons/image-no-border';
import { LoadingSpinner } from '../loading-error/loading-spinner';
import { useRefState } from '../ref-state/use-ref-state';
import { FileData } from './use-file-drop';
import { useStorageCache } from './use-storage-cache';
import { UserStorageGrid } from './user-storage-grid';

enum ImageSelectStage {
    'Start' = 'Start',
    'UserStorage' = 'UserStorage',
    'CompressAndUpload' = 'CompressAndUpload',
}

type ImageSelectCompressProps = {
    file: File;
    onFileSaved: (fileKey: string) => void;
};

const DEFAULT_COMPRESSION = 0.7;
const INPUT_DEBOUNCE = 700;
const MAX_RESOLUTION: Dimensions = {
    width: 900,
    height: 700,
};

type Dimensions = {
    width: number;
    height: number;
};

function ImageSelectCompress({ file: originalFile, onFileSaved }: ImageSelectCompressProps) {
    const storageCache = useStorageCache();
    const [imageFile, setImageFile] = useState<File>();
    const [processing, setProcessing] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState<string>();

    const originalDimensions = useRef<Dimensions>();

    useEffect(() => {
        const image = new Image();
        const imageSrc = URL.createObjectURL(originalFile);
        image.onload = () => {
            const imageWidth = image.width;
            const imageHeight = image.height;
            const scaleRatioWidth = Math.min(MAX_RESOLUTION.width / imageWidth, 1);
            const scaleRatioHeight = Math.min(MAX_RESOLUTION.height / imageHeight, 1);
            const scaleRatio = Math.min(scaleRatioWidth, scaleRatioHeight);

            const newDimensions = {
                width: imageWidth * scaleRatio,
                height: imageHeight * scaleRatio,
            };

            originalDimensions.current = newDimensions;

            generateNewImage();
        };

        image.src = imageSrc;
    }, []);

    const imageSource = useMemo(() => {
        if (!imageFile) {
            return;
        }

        return URL.createObjectURL(imageFile);
    }, [imageFile]);

    const changeTimeout = useRef<ReturnType<typeof setTimeout>>();
    const [scale, setScale, scaleRef] = useRefState(1);
    const [quality, setQuality, qualityRef] = useRefState(DEFAULT_COMPRESSION);

    function handleResize(scale: number) {
        let toScale = Math.min(1, scale / 100);
        toScale = Math.max(toScale, 0.1);
        setScale(toScale);
        handleChange();
    }

    function handleCompress(scale: number) {
        let toScale = Math.min(1, scale / 100);
        toScale = Math.max(toScale, 0.1);
        setQuality(toScale);
        handleChange();
    }

    function handleChange() {
        clearTimeout(changeTimeout.current);
        changeTimeout.current = setTimeout(async () => {
            generateNewImage();
        }, INPUT_DEBOUNCE);
    }

    async function handleSave() {
        try {
            setUploading(true);
            const fileKey = await FirebaseHelper.uploadFile(imageFile, {
                customMetadata: {
                    originalFileName: originalFile.name,
                },
            });
            storageCache.set(fileKey, URL.createObjectURL(imageFile));
            onFileSaved(fileKey);
        } catch (e) {
            if (e instanceof AppError) {
                setError(e.message);
            } else {
                setError('There was an error trying to upload your file');
            }
        }

        setUploading(false);
    }

    async function generateNewImage() {
        setProcessing(true);

        const newHeight = originalDimensions.current.height * scaleRef.current;
        const newWidth = originalDimensions.current.width * scaleRef.current;
        const maxWidthOrHeight = Math.max(newHeight, newWidth);

        const compressed = await imageCompression(originalFile, {
            alwaysKeepResolution: true,
            maxWidthOrHeight,
            initialQuality: qualityRef.current,
        });

        setProcessing(false);
        setImageFile(compressed);
    }

    return (
        <div className="image-select-compress__container">
            <div className="image-select-modal__header">Compress & Upload</div>
            <div className="image-select-compress__instructions readable">
                Reducing the quality of the image will make the file much smaller and will allow you to upload more
                files overall.
            </div>
            <div className="image-select-compress__image-container">
                {processing && <LoadingSpinner />}
                {imageSource && <img className="image-select-compress__image" src={imageSource} />}
            </div>
            <div className="image-select-compress__controls">
                <div className="image-select-range">
                    <div className="image-select-range__label">Quality</div>
                    <div className="image-select-range__input">
                        <Scrubber
                            value={quality * 100}
                            min={30}
                            max={100}
                            onScrubChange={handleCompress}
                            onScrubEnd={handleCompress}
                        />
                    </div>
                </div>
                <div className="image-select-range">
                    <div className="image-select-range__label">Resolution</div>
                    <div className="image-select-range__input">
                        <Scrubber
                            value={scale * 100}
                            min={10}
                            max={100}
                            onScrubChange={handleResize}
                            onScrubEnd={handleResize}
                        />
                    </div>
                </div>
                <div className="image-select-compress__upload">
                    <div className="image-select-compress__file-size">
                        File Size: {Math.round((imageFile?.size || originalFile.size) / 1024)}KB
                    </div>
                    <Button label="Save & Upload" onClick={handleSave} disabled={uploading || processing} />
                    <div className="image-select-compress__error">{error}</div>
                </div>
            </div>
        </div>
    );
}

type ImageSelectModalProps = {
    onCloseModal: () => void;
    onFileSelected: (urlOrUri: string) => void;
};

export function ImageSelectModal({ onFileSelected, onCloseModal }: ImageSelectModalProps) {
    const [imageSelectStage, setImageSelectStage] = useState(ImageSelectStage.Start);
    const [imageData, setImageData] = useState<File>();
    const [imageUrl, setImageUrl] = useState('');
    const { imageSrc, imageErrored, imageLoaded, imageProps } = useImageLoad(imageUrl);

    function handleSetFileData(fileData: FileData) {
        if (fileData.type === CFRequestTypes.UploadFileType.Image) {
            setImageData(fileData.file);
            setImageSelectStage(ImageSelectStage.CompressAndUpload);
        }
    }

    function handleImageUrlChanged(newVal: string) {
        setImageUrl(newVal);
    }

    function handleClickBack() {
        if (imageSelectStage === ImageSelectStage.UserStorage) {
            setImageSelectStage(ImageSelectStage.Start);
        } else {
            setImageSelectStage(ImageSelectStage.UserStorage);
        }
    }

    function renderImageSelectStart() {
        const imagePreviewClasses = classNames('image-select-imgur__image-container', {
            'image-select-imgur__image-container--loaded': imageLoaded,
        });

        return (
            <div className="image-select-start readable">
                <div className="image-select-imgur">
                    <div className="image-select-modal__header">Image Link</div>
                    <div className="image-select-imgur__body">
                        You can provide a direct link to your image here to avoid uploading it to your House Of Games
                        account and reduce your profile storage usage. It{"'"}s recommended to use{' '}
                        <a
                            href="https://imgur.com"
                            target="_blank"
                            rel="noreferrer"
                            className="image-select-imgur__link"
                        >
                            Imgur
                        </a>{' '}
                        to host your images -{' '}
                        <a
                            href="https://houseofgames.app/faq"
                            target="_blank"
                            rel="noreferrer"
                            className="image-select-imgur__link"
                        >
                            visit our FAQ
                        </a>{' '}
                        to learn how to do this. We promise it{"'"}s quick and easy!
                    </div>
                    <div className={imagePreviewClasses}>
                        <div className="image-select-imgur__no-image">
                            <ImageNoBorder />
                        </div>
                        {imageSrc && !imageErrored && (
                            <img className="image-select-imgur__image" src={imageSrc} {...imageProps} />
                        )}
                    </div>
                    <div className="image-select-imgur__text-input">
                        <TextField
                            maxLength={RoundValidators.imageDefinition.length}
                            label="Image Link"
                            type="text"
                            onChange={handleImageUrlChanged}
                            disableTooltip
                        />
                    </div>
                    <div className="image-select-imgur__select">
                        <Button
                            disabled={!imageUrl || !imageLoaded}
                            onClick={() => onFileSelected(imageUrl)}
                            label="Use Image Link"
                        />
                    </div>
                </div>
                <div className="image-select-midpoint">OR</div>
                <div className="image-select-user-storage-button">
                    <Button
                        secondary
                        onClick={() => setImageSelectStage(ImageSelectStage.UserStorage)}
                        label="Select/Upload A File"
                    />
                </div>
            </div>
        );
    }

    function renderImageSelectUserStorage() {
        return (
            <UserStorageGrid
                allowedFileTypes={[CFRequestTypes.UploadFileType.Image]}
                onFileSelected={onFileSelected}
                onFileUpload={handleSetFileData}
            />
        );
    }

    function renderImageSelectCompress() {
        return <ImageSelectCompress file={imageData} onFileSaved={onFileSelected} />;
    }

    function renderStage() {
        switch (imageSelectStage) {
            case ImageSelectStage.Start:
                return renderImageSelectStart();
            case ImageSelectStage.UserStorage:
                return renderImageSelectUserStorage();
            case ImageSelectStage.CompressAndUpload:
                return renderImageSelectCompress();
        }
    }

    return (
        <div className="image-select-modal__container">
            <div className="image-select-modal">
                {imageSelectStage !== ImageSelectStage.Start && (
                    <div className="image-select-modal__back-button-container" onClick={handleClickBack}>
                        <div className="box-header__back-button" />
                    </div>
                )}
                <div className="image-select-modal__close" onClick={onCloseModal}>
                    <Close scale={0.6} />
                </div>
                <div className="image-select-modal__content scrollable">{renderStage()}</div>
            </div>
        </div>
    );
}
