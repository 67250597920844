import './create-the-answer-isnt.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';

import { Button } from '../../../common/components/buttons/buttons';
import { TextField } from '../../../common/components/form-fields/text-field';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { TextArea } from '../common/text-area';
import { RoundProps } from '../create-round-component-map';

type AssociatedRounds = RoundProps<RoundTypes.TheAnswerIsntRound>;

export function CreateTheAnswerIsnt({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: AssociatedRounds) {
    const { questions } = round;
    const definition = roundDefinitions[round.type].of;

    function handleNewQuestion() {
        createNewQuestion({ fakes: { 0: '', 1: '', 2: '' } });
    }

    function handleQuestionChanged(questionIndex: number, question: string) {
        const newQuestion = { ...questions[questionIndex], question };
        updateQuestion(questionIndex, newQuestion);
    }

    function handleAnswerChanged(questionIndex: number, answer: string) {
        const newQuestion = { ...questions[questionIndex], answer };
        updateQuestion(questionIndex, newQuestion);
    }

    function handleFakeAnswerChanged(questionIndex: number, clueIndex: 0 | 1 | 2, clue: string) {
        const question = questions[questionIndex];
        const newQuestion = { ...question, fakes: { ...question.fakes, [clueIndex]: clue } };
        updateQuestion(questionIndex, newQuestion);
    }

    return (
        <div className="create-the-answer-isnt">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-the-answer-isnt-question__container">
                        <QuestionArrows
                            index={i}
                            numberOfQuestions={questions.length}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                        />
                        <div className="create-the-answer-isnt-question__question-container">
                            <TextArea
                                maxLength={definition.question.length}
                                className="create-the-answer-isnt-question__question"
                                defaultValue={q.question}
                                placeholder="Enter your question here..."
                                onChange={(q) => handleQuestionChanged(i, q)}
                            />
                        </div>
                        <div className="create-the-answer-isnt-question__answers">
                            {([0, 1, 2] as const).map((fakeIndex) => {
                                return (
                                    <div key={fakeIndex} className="create-the-answer-isnt-question__fake">
                                        <TextField
                                            label="Fake Answer"
                                            type="text"
                                            maxLength={definition.fakes.of.length}
                                            isOutline
                                            defaultValue={q.fakes[fakeIndex]}
                                            onChange={(v) => handleFakeAnswerChanged(i, fakeIndex, v)}
                                        />
                                    </div>
                                );
                            })}
                            <div className="create-the-answer-isnt-question__answer">
                                <TextField
                                    label="Answer"
                                    type="text"
                                    maxLength={definition.answer.length}
                                    defaultValue={q.answer}
                                    onChange={(v) => handleAnswerChanged(i, v)}
                                />
                            </div>
                        </div>
                        <QuestionErase index={i} onRemove={removeQuestion} />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
