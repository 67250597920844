import { RoundTypes } from '@house-of-games/common';

import { SlideProps } from '../game/game';
import { PlayAnswerList } from './answer-list/play-answer-list';
import { PlayAnswerSmash } from './answer-smash/play-answer-smash';
import { PlayBackwards } from './backwards/play-backwards';
import { PlayBrokenKaraoke } from './broken-karaoke/play-broken-karaoke';
import { PlayCineNyms } from './cine-nyms/play-cine-nyms';
import { QuestionAnswerSlide } from './common/question-answer';
import { PlayCorrectionCentre } from './correction-centre/play-correction-centre';
import { PlayDimSums } from './dim-sums/play-dim-sums';
import { PlayElephant } from './elephant/play-elephant';
import { PlayFingerOnIt } from './finger-on-it/play-finger-on-it';
import { PlayGamesHouseOf } from './games-house-of/play-games-house-of';
import { PlayHighbrowLowbrow } from './highbrow-lowbrow/play-highbrow-lowbrow';
import { PlayInCode } from './in-code/play-in-code';
import { PlayRhymeTime } from './rhyme-time/play-rhyme-time';
import { PlayTheAnswerIsnt } from './the-answer-isnt/play-the-answer-isnt';
import { PlayTotesEmoji } from './totes-emoji/play-totes-emoji';
import { PlayWhenTheySing } from './when-they-sing/play-when-they-sing';
import { PlayYouCompleteMe } from './you-complete-me/play-you-complete-me';
import { PlayZToA } from './z-to-a/play-z-to-a';

type SlideComponent<T extends RoundTypes.RoundType> = (props: SlideProps<RoundTypes.Round<T>>) => React.ReactElement;

const RoundComponentMap: { [key in RoundTypes.RoundType]: SlideComponent<key> } = {
    [RoundTypes.RoundType.RhymeTime]: PlayRhymeTime,
    [RoundTypes.RoundType.BrokenKaraoke]: PlayBrokenKaraoke,
    [RoundTypes.RoundType.InTheName]: QuestionAnswerSlide,
    [RoundTypes.RoundType.InCode]: PlayInCode,
    [RoundTypes.RoundType.ZToA]: PlayZToA,
    [RoundTypes.RoundType.GamesHouseOf]: PlayGamesHouseOf,
    [RoundTypes.RoundType.Elephant]: PlayElephant,
    [RoundTypes.RoundType.Roonerspisms]: PlayRhymeTime,
    [RoundTypes.RoundType.Backwards]: PlayBackwards,
    [RoundTypes.RoundType.OppositesAttract]: QuestionAnswerSlide,
    [RoundTypes.RoundType.KingJumble]: PlayRhymeTime,
    [RoundTypes.RoundType.TwoInOne]: QuestionAnswerSlide,
    [RoundTypes.RoundType.PastTense]: QuestionAnswerSlide,
    [RoundTypes.RoundType.CorrectionCentre]: PlayCorrectionCentre,
    [RoundTypes.RoundType.MouseOfGames]: QuestionAnswerSlide,
    [RoundTypes.RoundType.TheAnswerIsnt]: PlayTheAnswerIsnt,
    // [RoundTypes.RoundType.InTheQuestion]: () => {},
    [RoundTypes.RoundType.CineNyms]: PlayCineNyms,
    [RoundTypes.RoundType.HighbrowLowbrow]: PlayHighbrowLowbrow,
    [RoundTypes.RoundType.HouseOfGamers]: QuestionAnswerSlide,
    [RoundTypes.RoundType.HoseOfGames]: QuestionAnswerSlide,
    [RoundTypes.RoundType.DistinctlyAverage]: QuestionAnswerSlide,
    [RoundTypes.RoundType.DimSums]: PlayDimSums,
    [RoundTypes.RoundType.WhenTheySing]: PlayWhenTheySing,
    [RoundTypes.RoundType.YouCompleteMe]: PlayYouCompleteMe,
    [RoundTypes.RoundType.YouSpellTerrible]: QuestionAnswerSlide,
    [RoundTypes.RoundType.TotesEmoji]: PlayTotesEmoji,
    [RoundTypes.RoundType.ICompleteYou]: PlayYouCompleteMe,
    [RoundTypes.RoundType.FingerOnIt]: PlayFingerOnIt,
    [RoundTypes.RoundType.WhereIsKazakhstan]: PlayFingerOnIt,
    [RoundTypes.RoundType.RichList]: PlayAnswerList,
    [RoundTypes.RoundType.SizeMatters]: PlayAnswerList,
    [RoundTypes.RoundType.ZList]: PlayAnswerList,
    [RoundTypes.RoundType.TerribleDating]: QuestionAnswerSlide,
    [RoundTypes.RoundType.AnswerSmash]: PlayAnswerSmash,
};

export function getPlayRoundComponent<T extends RoundTypes.RoundType>(type: RoundTypes.RoundType): SlideComponent<T> {
    return RoundComponentMap[type] as unknown as SlideComponent<T>;
}
