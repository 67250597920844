import './noticeboard.scss';

import { useState } from 'react';

import { ConfirmationModal } from '../../common/components/confrimation-modal/confirmation-modal';
// import { ChristmasTree } from '../../common/components/icons/christmas-tree'; // XMAS_THEME
import { FirebaseHelper } from '../../utils/firebase';

const NOTICE_ID = 9;
const NOTICE_DATE = new Date('Dec 19 2023');
const EXPIRATION_DATE = new Date('Jan 16 2024');

function NoticeBoardComponent({ onClose }: { onClose: () => void }) {
    const textElement = (
        <>
            <div className="noticeboard__header">House Of Games just got a new update!</div>
            <div className="noticeboard__item">
                {`
                IMAGES EVERYWHERE!`}
                <br />
                {`That's what this update is all about. You can now add images as questions to lots more
                rounds to really spice things up.
                `}
            </div>
            {/* <br /> */}
            <div className="noticeboard__item">
                {`You can also upload images directly to your House Of Games storage instead of using an image URL, but be aware that this
                will count as part of your 10MB account storage limit. Image URLs are still supported though!`}
            </div>
            <div className="noticeboard__item">
                {`Thank you for all the amazing feedback and helpful suggestions on how to make this even better going
                forwards!`}
            </div>
            <div className="noticeboard__item">{`❤️`}</div>
        </>
    );

    return <ConfirmationModal className="noticeboard" textElement={textElement} onClose={onClose} />;
}

// function NoticeBoardComponent({ onClose }: { onClose: () => void }) {
//     const textElement = (
//         <>
//             <div className="noticeboard__header">House Of Games just got a new update!</div>
//             <div className="noticeboard__item">
//                 {`Christmas is here and snow is falling inside the house of games!`}
//             </div>
//             <div className="noticeboard__item">
//                 {`Unfortunately, snow doesn't always play so well with electronic devices... if you find that
//                 animations are stuttering (or if you just can't stand the snow!) you can disable it
//                 by clicking the`}
//                 <ChristmasTree filled={true} />
//                 {`icon.`}
//             </div>
//             {/* <br /> */}
//             <div className="noticeboard__item">
//                 {`Wishing you all a very Merry Christmas and a Happy New Year!`}
//                 {/* {`Thank you for all the amazing feedback and helpful suggestions on how to make this even better going
//                 forwards!`} */}
//                 <br />
//                 {`❤️`}
//             </div>
//         </>
//     );

//     return <ConfirmationModal className="noticeboard" textElement={textElement} onClose={onClose} />;
// }

export function NoticeBoard() {
    const noticeId = window.localStorage.getItem('NOTICE_ID');
    const isAlreadySeen = parseInt(noticeId) >= NOTICE_ID;

    const userCreationDate = new Date(FirebaseHelper.getAuth().currentUser?.metadata?.creationTime);
    const isAfterDate = userCreationDate > NOTICE_DATE;
    const isExpired = new Date(Date.now()) > EXPIRATION_DATE;
    const [isVisible, setIsVisible] = useState(!isAlreadySeen && !isAfterDate && !isExpired);

    function handleClose() {
        window.localStorage.setItem('NOTICE_ID', `${NOTICE_ID}`);
        setIsVisible(false);
    }

    if (isVisible) {
        return <NoticeBoardComponent onClose={handleClose} />;
    } else {
        return <> </>;
    }
}
