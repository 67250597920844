import { roundDefinitions, RoundTypes } from '@house-of-games/common';
import { useState } from 'react';

import { Button } from '../../../../common/components/buttons/buttons';
import { TextArea, ValidatedChunk } from '../../common/text-area';

const definition = roundDefinitions[RoundTypes.RoundType.BrokenKaraoke].of;

type KaraokeLyricsProps = {
    initialLyrics: string;
    onCancel: () => void;
    onDone: (lyrics: string) => void;
};

export function KaraokeLyrics({ initialLyrics, onCancel, onDone }: KaraokeLyricsProps) {
    const [lyrics, setLyrics] = useState(initialLyrics);
    const [isLyricsValid, setIsLyricsValid] = useState(false);

    function validateWordCount(line: string): [Array<ValidatedChunk>, string] {
        const countStartModifier = line.startsWith(' ') ? -1 : 0;
        const wordCountModifier = !line.endsWith(' ') ? countStartModifier + 1 : countStartModifier;

        const whiteSpaceRegex = new RegExp('[ ]{1,}', 'g');
        const matches = Array.from(line.matchAll(whiteSpaceRegex));
        const wordLimit = definition.lines.of.length;
        const wordCount = wordCountModifier + matches.length;
        if (wordCount <= wordLimit) {
            return [[], line];
        }

        const invalidStartMatch = matches[wordLimit - countStartModifier - 1];
        const invalidStartIndex = invalidStartMatch.index + invalidStartMatch[0].length;
        const validString = line.slice(0, invalidStartIndex);
        const invalidString = line.slice(invalidStartIndex);
        return [[{ string: invalidString, valid: false }], validString];
    }

    function validateLine(line: string) {
        const [invalidWordChunks, restOfLine] = validateWordCount(line);
        const invalidSymbolRegex = new RegExp('[^a-zA-Z \\n]');
        let chunks: Array<ValidatedChunk> = [];
        let trimmedString = restOfLine;

        let done = false;
        while (done === false) {
            if (trimmedString.length === 0) {
                done = true;
                break;
            }

            const match = trimmedString.match(invalidSymbolRegex);
            if (!match) {
                done = true;
                chunks.push({ string: trimmedString, valid: true });
                break;
            }

            const validString = trimmedString.slice(0, match.index);
            const invalidString = trimmedString.slice(match.index, match.index + 1);
            trimmedString = trimmedString.slice(match.index + 1);

            chunks.push({ string: validString, valid: true });
            chunks.push({ string: invalidString, valid: false });
        }

        chunks = chunks.concat(invalidWordChunks);
        return chunks;
    }

    function validateText(lyrics: string) {
        const lines = lyrics.split('\n');
        let validatedChunks: Array<ValidatedChunk> = [];
        lines.forEach((l) => {
            validatedChunks = validatedChunks.concat(validateLine(l));
            validatedChunks.push({ string: '\n', valid: true });
        });

        return validatedChunks;
    }

    function validateLyrics(lyrics: string) {
        const validatedChunks = validateText(lyrics);
        if (lyrics.trim().length > 0 && !validatedChunks.some((c) => !c.valid)) {
            setIsLyricsValid(true);
        } else {
            setIsLyricsValid(false);
        }

        return validatedChunks;
    }

    return (
        <>
            <div className="karaoke-capture-modal__page-lyrics">
                <div className="karaoke-lyrics__text-area">
                    <TextArea
                        defaultValue={lyrics}
                        placeholder="Paste the song lyrics here..."
                        onChange={setLyrics}
                        newLineLimit={2}
                        validator={validateLyrics}
                    />
                </div>
            </div>
            <div className="karaoke-capture-modal__buttons">
                <Button
                    className="karaoke-capture-modal__button karaoke-capture-modal__button--secondary"
                    label="Cancel"
                    onClick={onCancel}
                />
                <Button
                    className="karaoke-capture-modal__button karaoke-capture-modal__button--primary"
                    label="Next"
                    onClick={() => onDone(lyrics)}
                    disabled={!isLyricsValid}
                />
            </div>
        </>
    );
}
