type MusicProps = {
    scale?: number;
};

export function Music({ scale = 1 }: MusicProps) {
    const roundedScale = Math.round(128 * scale);
    return (
        <svg className="icon__music" width={`${roundedScale}`} height={`${roundedScale}`} viewBox="0 0 128 128">
            <path d="M102,24v2v11v42c0,6.63-7.39,12-16.5,12S69,85.63,69,79c0-6.63,7.39-12,16.5-12c4.48,0,8.53,1.3,11.5,3.4V38.47L56,50.53V92c0,6.63-7.39,12-16.5,12S23,98.63,23,92c0-6.63,7.39-12,16.5-12c4.48,0,8.53,1.3,11.5,3.4V52V39L102,24z" />
        </svg>
    );
}
