import './draggable-grid.scss';

import classNames from 'classnames';
import throttle from 'lodash.throttle';
import React, { Component, createRef, useEffect, useRef } from 'react';

import { insert, without } from '../../../utils/array';
import { Bin } from '../icons/bin';

type GridItem = DraggableGridProps['items'][0] & { key: string; offsetX: number; offsetY: number };
type Grid = { [key: string]: GridItem };

type Position = {
    x: number;
    y: number;
};

export type DraggableGridProps = {
    items: Array<{
        key: string;
        isFixed?: boolean;
        isSelectable?: boolean;
        component: () => React.ReactElement;
    }>;
    isFixedOrder?: boolean;
    onChange?: (keys: Array<string>) => void;
    onSelect?: (keys: Array<string>) => void;
    onRemove?: (keys: Array<string>) => void;
};

type DraggableGridState = {
    mouseDownPosition: Position;
    offset: Position;
    dragging: { key: string; index: number; offset: Position };
    mouseOver: number;
    gridObject: Grid;
    isOverDelete: boolean;
    isSelectMode: boolean;
    selected: Array<string>;
};

function doNothing() {
    // noop
}

function hasParentWithMatchingSelector(target: Element, selector: string) {
    return Array.from(document.querySelectorAll(selector)).find((el) => el !== target && el.contains(target));
}

function getGridItemElement(target: Element) {
    if (target.classList.contains('draggable-grid-item')) {
        return target;
    } else {
        return hasParentWithMatchingSelector(target, '.draggable-grid-item');
    }
}

type DraggableGridItemProps = DraggableGridProps['items'][0] & {
    id: string;
    posX: number;
    posY: number;
    isDragging: boolean;
    isDragTarget: boolean;
    isDeleting?: boolean;
    isSelected?: boolean;
    isSelectMode?: boolean;
    select?: (id: string) => void;
    dragOffset: Position;
};

function DraggableGridItem({
    id,
    component: GridComponent,
    isFixed,
    posX,
    posY,
    isDragging,
    isDragTarget,
    isDeleting,
    isSelectable,
    isSelected,
    isSelectMode,
    select = doNothing,
    dragOffset = { x: 0, y: 0 },
}: DraggableGridItemProps) {
    const itemRef = useRef<HTMLDivElement>();
    const classes = classNames(`draggable-grid-item ${id}`, {
        'draggable-grid-item--animate': isDragging && !isDragTarget,
        'draggable-grid-item--dragging': isDragging && isDragTarget,
        'draggable-grid-item--deleting': isDeleting && isDragTarget,
        'draggable-grid-item--selected': isSelected,
        'draggable-grid-item--disabled': isSelectMode && !isSelectable,
    });

    useEffect(() => {
        window.requestAnimationFrame(() => {
            itemRef.current?.style?.setProperty?.('transform', `translate(${posX}px, ${posY}px)`);
        });
    }, [posX, posY]);

    return (
        <div
            id={id}
            ref={itemRef}
            key={id}
            data-drag-key={id}
            data-draggable-fixed={isFixed}
            data-draggable-selectable={isSelectable}
            className={classes}
            style={{
                position: 'absolute',
                left: dragOffset.x,
                top: dragOffset.y,
            }}
            onClick={isSelectable ? () => select(id) : doNothing}
        >
            <GridComponent />
            {(isDragging || isSelectMode) && <div className="draggable-grid-click-overlay" />}
        </div>
    );
}

export class DraggableGrid extends Component<DraggableGridProps, DraggableGridState> {
    gridRef = createRef<HTMLDivElement>();
    deleteRef = createRef<HTMLDivElement>();
    clickStartedTimeout: number;

    state: DraggableGridState = {
        mouseDownPosition: null,
        offset: { x: 0, y: 0 },
        dragging: null,
        mouseOver: null,
        gridObject: null,
        isOverDelete: false,
        isSelectMode: false,
        selected: [],
    };

    generateGrid = (items: DraggableGridProps['items']): Grid => {
        const gridRect = this.gridRef.current.getBoundingClientRect();
        const placeholders = Array.from(this.gridRef.current.getElementsByClassName('draggable-grid-placeholder'));
        return items.reduce((grid, item, i) => {
            const rect = placeholders[i].getBoundingClientRect();
            const offsetX = rect.left - gridRect.left;
            const offsetY = rect.top - gridRect.top;
            return {
                ...grid,
                [item.key]: {
                    ...item,
                    offsetX: offsetX,
                    offsetY: offsetY,
                },
            };
        }, {});
    };

    componentDidMount() {
        window.addEventListener('mousedown', this.handleMouseDown);
        window.addEventListener('mouseup', this.handleMouseUp);
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('resize', this.handleWindowResize);
        const grid = this.generateGrid(this.props.items);
        this.setState({ gridObject: grid });
    }

    componentDidUpdate(prevProps: DraggableGridProps) {
        if (prevProps.items !== this.props.items) {
            const grid = this.generateGrid(this.props.items);
            this.setState({ gridObject: grid });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleMouseDown);
        window.removeEventListener('mouseup', this.handleMouseUp);
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = throttle(
        () => {
            const grid = this.generateGrid(this.props.items);
            this.setState({ gridObject: grid });
        },
        200,
        { trailing: true },
    );

    getTouchGridIndex = (position: Position) => {
        const gridSpacingPx = 16;
        const placeholders = Array.from(this.gridRef.current.getElementsByClassName('draggable-grid-placeholder'));
        const match = placeholders.find((p) => {
            const { left, top, width, height } = p.getBoundingClientRect();
            const isInRow = position.y > top && position.y < top + height + gridSpacingPx;
            const isInColumn = position.x > left && position.x < left + width + gridSpacingPx;
            return isInRow && isInColumn;
        });
        if (match) {
            return parseInt(match.getAttribute('data-drag-index'));
        }
    };

    handleMouseDown = (e: MouseEvent) => {
        const { target } = e;
        if (target instanceof Element) {
            let dragKey: string;
            const draggableElement = getGridItemElement(target);
            if (draggableElement && !draggableElement.getAttribute('data-draggable-fixed')) {
                dragKey = draggableElement.getAttribute('data-drag-key');
            }

            if (dragKey) {
                const dragIndex = Object.keys(this.state.gridObject).findIndex((k) => k === dragKey);
                const { offsetX, offsetY } = this.state.gridObject[dragKey];
                this.clickStartedTimeout = window.setTimeout(() => {
                    this.setState({
                        isSelectMode: false,
                        selected: [],
                        mouseDownPosition: { x: e.clientX, y: e.clientY },
                        dragging: { key: dragKey, index: dragIndex, offset: { x: offsetX, y: offsetY } },
                    });
                }, 200);
            }
        }
    };

    clearSelected = () => {
        this.setState({
            selected: [],
            isSelectMode: false,
        });
        this.props.onSelect?.([]);
    };

    handleMouseUp = (e: MouseEvent) => {
        const { target } = e;
        clearTimeout(this.clickStartedTimeout);
        if (this.state.isOverDelete && this.props.onRemove) {
            if (this.state.dragging?.key) {
                this.props.onRemove([this.state.dragging.key]);
            } else if (this.state.isSelectMode) {
                this.props.onRemove(this.state.selected);
            }
            this.clearSelected();
        } else if (this.state.dragging) {
            const draggableElement = getGridItemElement(target as Element);
            const isSelectable = draggableElement && draggableElement.getAttribute('data-draggable-selectable');

            const { x: originalX, y: originalY } = this.state.mouseDownPosition;
            const absX = Math.abs(Math.abs(originalX) - Math.abs(e.clientX));
            const absY = Math.abs(Math.abs(originalY) - Math.abs(e.clientY));
            const absMovement = Math.max(absX, absY);
            const movementThreshold = 10;

            if (isSelectable && absMovement < movementThreshold) {
                this.setState({
                    isSelectMode: true,
                });
            } else if (this.props.onChange && this.state.dragging) {
                this.props.onChange(Object.keys(this.state.gridObject));
            }
        }

        this.setState({
            mouseDownPosition: null,
            offset: { x: 0, y: 0 },
            dragging: null,
            isOverDelete: false,
        });
    };

    handleMouseMove = throttle(
        (e: MouseEvent) => {
            const { dragging, mouseDownPosition } = this.state;
            if (mouseDownPosition) {
                const x = e.clientX - mouseDownPosition.x;
                const y = e.clientY - mouseDownPosition.y;
                const mouseOver = this.getTouchGridIndex({ x: e.clientX, y: e.clientY }) ?? this.state.mouseOver;
                if (!this.props.isFixedOrder && this.state.mouseOver !== mouseOver) {
                    const gridKeys = Object.keys(this.state.gridObject);
                    const draggableKeys = gridKeys.filter((k) => !this.state.gridObject[k].isFixed);
                    const fixedKeys = gridKeys.filter((k) => this.state.gridObject[k].isFixed);
                    const restGridKeys = without(draggableKeys, dragging.key);
                    const fixedKeysBefore = gridKeys
                        .slice(0, mouseOver)
                        .filter((k) => this.state.gridObject[k].isFixed).length;
                    let newGridKeys = insert(restGridKeys, mouseOver - fixedKeysBefore, dragging.key) as Array<string>;
                    fixedKeys.forEach((k) => {
                        newGridKeys = insert(newGridKeys, gridKeys.indexOf(k), k) as Array<string>;
                    });

                    const newGridItems = newGridKeys.map((k: string) => {
                        return {
                            key: k,
                            component: this.state.gridObject[k].component,
                            isFixed: this.state.gridObject[k].isFixed,
                            isSelectable: this.state.gridObject[k].isSelectable,
                        };
                    });
                    this.setState({ mouseOver, gridObject: this.generateGrid(newGridItems) });
                }
                this.setState({ offset: { x, y } });
            }
        },
        16,
        { trailing: false },
    );

    setIsOverDelete = (bool: boolean) => {
        this.setState({ isOverDelete: bool });
    };

    handleSelect = (id: string) => {
        if (this.state.isSelectMode) {
            const currentSelected = this.state.selected;
            let newSelected;
            if (currentSelected.includes(id)) {
                newSelected = currentSelected.filter((sid) => sid !== id);
            } else {
                newSelected = [...currentSelected, id];
            }
            const isSelectMode = newSelected.length !== 0;
            this.setState({ selected: newSelected, isSelectMode });
            this.props.onSelect?.(newSelected);
        }
    };

    renderItems = () => {
        if (!this.gridRef.current || !this.state.gridObject) {
            return <></>;
        }

        return Object.values(this.state.gridObject).map((gridItem) => {
            const { dragging, offset } = this.state;
            const isDragging = dragging?.key === gridItem.key;
            const { offsetX, offsetY } = gridItem;
            return (
                <DraggableGridItem
                    key={gridItem.key}
                    id={gridItem.key}
                    component={gridItem.component}
                    posX={isDragging ? dragging.offset.x : offsetX}
                    posY={isDragging ? dragging.offset.y : offsetY}
                    isDragging={Boolean(dragging)}
                    isDragTarget={isDragging}
                    isDeleting={this.state.isOverDelete}
                    isFixed={gridItem.isFixed}
                    isSelectable={gridItem.isSelectable}
                    dragOffset={isDragging ? offset : undefined}
                    isSelectMode={this.state.isSelectMode}
                    isSelected={this.state.selected.includes(gridItem.key)}
                    select={this.handleSelect}
                />
            );
        });
    };

    render() {
        return (
            <div ref={this.gridRef} className="draggable-grid">
                {this.props.items.map((x, i) => {
                    return <div key={i} className="draggable-grid-placeholder" data-drag-index={i} />;
                })}
                {this.renderItems()}
                {this.props.onRemove && (this.state.dragging || this.state.isSelectMode) && (
                    <div
                        onMouseEnter={() => this.setIsOverDelete(true)}
                        onMouseLeave={() => this.setIsOverDelete(false)}
                        className="draggable-grid-delete"
                    >
                        <Bin scale={1.5} isOpen={this.state.isOverDelete} />
                    </div>
                )}
            </div>
        );
    }
}
