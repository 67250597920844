type SubtractIconBoldProps = {
    scale?: number;
};

export function SubtractIconBold({ scale = 1 }: SubtractIconBoldProps) {
    return (
        <svg className="icon__subtract-bold" width={`${24 * scale}`} height={`${24 * scale}`} viewBox="0 0 24 24">
            <path d="M0,16V8h24v8H0z" />
        </svg>
    );
}
