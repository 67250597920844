import { GameState, PlayerAnswer, RoundTypes } from '@house-of-games/common';

import { PlayerBuzzer } from '../play/player-buzzer';
import { AnswerFingerOnIt } from './finger-on-it/answer-finger-on-it';
import { AnswerWhenTheySing } from './when-they-sing/answer-when-they-sing';

export type AnswerComponentProps<T extends RoundTypes.RoundType = RoundTypes.RoundType> = {
    buzz: (requestedTimeStamp?: number) => Promise<void>;
    submit: (answer: PlayerAnswer<T>) => Promise<void>;
    gameState: GameState<T>;
    audioElementRef: React.MutableRefObject<HTMLAudioElement>;
    changeAudioSource: (url: string) => Promise<string>;
    disableTextSubmit?: boolean;
};

type AnswerComponent<T extends RoundTypes.RoundType> = (props: AnswerComponentProps<T>) => React.ReactElement;

const answerComponentMap: { [key in RoundTypes.RoundType]?: AnswerComponent<key> } = {
    [RoundTypes.RoundType.FingerOnIt]: AnswerFingerOnIt,
    [RoundTypes.RoundType.WhereIsKazakhstan]: AnswerFingerOnIt,
    [RoundTypes.RoundType.WhenTheySing]: AnswerWhenTheySing,
};

export function getAnswerRoundComponent<T extends RoundTypes.RoundType>(
    type: RoundTypes.RoundType,
): AnswerComponent<T> {
    return (answerComponentMap[type] as AnswerComponent<T>) || PlayerBuzzer;
}
