import './transitional-modal.scss';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Close } from '../icons/close';
type TModalProps = {
    children: React.ReactNode;
    className: string;
    handleClose: () => void;
};

type TMEffect = [string, string, () => void, (cb?: () => void) => void];

export function useTransitionalModal(): TMEffect {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalClosing, setIsModalClosing] = useState<boolean>(false);

    const isModalFocus = isModalOpen && !isModalClosing;
    const contentClassName: TMEffect[0] = classNames('main-content', {
        'main-content--hidden': isModalFocus,
    });
    const modalClassName: TMEffect[1] = classNames('modal readable', {
        'modal--visible': isModalFocus,
    });
    const openModal: TMEffect[2] = () => {
        setIsModalOpen(true);
    };

    const closeModal: TMEffect[3] = (cb) => {
        setIsModalClosing(true);
        setTimeout(() => {
            setIsModalClosing(false);
            setIsModalOpen(false);
            if (cb) {
                cb();
            }
        }, 400);
    };

    return [contentClassName, modalClassName, openModal, closeModal];
}

export function TransitionalModal({ children, className, handleClose }: TModalProps) {
    const [isReMounting, setIsRemounting] = useState(false);

    useEffect(() => {
        // Bit of a bad hack to force a re-render of children
        // to reset state on close/open
        if (className.includes('--visible')) {
            setTimeout(() => {
                setIsRemounting(true);
            });
            setTimeout(() => {
                setIsRemounting(false);
            });
        }
    }, [className]);

    return (
        <div className={className}>
            <div className="modal-close__container" onClick={handleClose}>
                <Close scale={0.6} />
            </div>
            {!isReMounting && children}
        </div>
    );
}
