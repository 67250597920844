import './dropdown.scss';

import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

const singleCharAlphaNumSpc = /^[a-zA-Z0-9 ]$/;

export type DropdownOption<T> = {
    value: T;
    label: string;
    disabled: boolean;
};

type DropdownProps<T> = {
    defaultOption: DropdownOption<T>;
    options: Array<DropdownOption<T>>;
    className: string;
    onChange: (i: DropdownOption<T>) => void;
};

export function Dropdown<T extends string | number>({ defaultOption, options, className, onChange }: DropdownProps<T>) {
    const focussedRef = useRef<HTMLLIElement>(null);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [focussedOption, setFocussedOption] = useState<DropdownOption<T>>(defaultOption);
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const searchTimeout = useRef<number>();
    const dropdownRef = useRef<HTMLDivElement>();
    const enabledOptions = options.filter((o) => !o.disabled);
    const isDropdownDisabled = enabledOptions.length === 0;

    const handleKeyPress = useCallback(
        (e: KeyboardEvent) => {
            if (!isOpen) {
                return;
            }

            e.stopPropagation();

            if (e.key === 'Enter') {
                if (enabledOptions.includes(focussedOption)) {
                    handleChange(focussedOption);
                }
            } else if (e.key === 'ArrowDown') {
                const nextIndex = Math.min(
                    enabledOptions.findIndex((o) => o === focussedOption) + 1,
                    enabledOptions.length - 1,
                );
                setFocussedOption(enabledOptions[nextIndex]);
            } else if (e.key === 'ArrowUp') {
                const prevIndex = Math.max(enabledOptions.findIndex((o) => o === focussedOption) - 1, 0);
                setFocussedOption(enabledOptions[prevIndex]);
            } else if (singleCharAlphaNumSpc.test(e.key)) {
                handleSearchFilter(e.key);
            }
        },
        [isOpen, focussedOption, selectedOption, searchText],
    );

    function handleSearchFilter(char: string) {
        const newFilter = `${searchText}${char}`;
        const filteredOptions = options.filter((o) => o.label.toLowerCase().startsWith(newFilter.toLowerCase()));
        if (filteredOptions.length > 0) {
            setFocussedOption(filteredOptions[0]);
            setSearchText(newFilter);
        }
        clearTimeout(searchTimeout.current);
        searchTimeout.current = window.setTimeout(() => {
            setSearchText('');
        }, 1000);
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        focussedRef.current?.scrollIntoView();
    }, [focussedOption]);

    function handleClick(e: MouseEvent) {
        try {
            if (!dropdownRef.current.contains(e.target as Node)) {
                closeModal();
            }
        } catch (e) {
            // Do Nothing
        }
    }

    useEffect(() => {
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    function handleChange(option: DropdownOption<T>) {
        setSelectedOption(option);
        closeModal();
        onChange(option);
    }

    function closeModal() {
        setFocussedOption(null);
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    function toggleModal() {
        !isOpen && !isDropdownDisabled ? openModal() : closeModal();
    }

    const dropdownClassNames = classNames('dropdown', {
        [className]: className,
        'dropdown--disabled': isDropdownDisabled,
    });

    return (
        <div ref={dropdownRef} className={dropdownClassNames}>
            <div className="dropdown__selected" onClick={toggleModal}>
                {isDropdownDisabled ? 'No Options Available' : selectedOption?.label}
            </div>
            {isOpen && (
                <ul className="dropdown__list scrollable">
                    {options.map((opt) => {
                        const isFocussed = opt === focussedOption;
                        const itemClassName = classNames('dropdown__list-item', {
                            'dropdown__list-item--disabled': opt.disabled,
                            'dropdown__list-item--focussed': isFocussed,
                        });
                        return (
                            <li
                                key={opt.value}
                                ref={isFocussed ? focussedRef : undefined}
                                className={itemClassName}
                                onClick={() => handleChange(opt)}
                            >
                                {opt.label}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}
