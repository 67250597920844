import { RoundTypes } from '@house-of-games/common';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../../../../common/components/buttons/buttons';

type Question = RoundTypes.BrokenKaraokeRound['questions'][0];

type KaraokeCaptureProps = {
    wordLines: Array<Array<string>>;
    onCaptureEnd: (lines: Question['lines']) => void;
    onBack: () => void;
};

export function KaraokeCapture({ wordLines, onBack, onCaptureEnd }: KaraokeCaptureProps) {
    const [currentWord, setCurrentWord] = useState<[number, number]>([0, 0]);
    const generatedLines = useRef<Question['lines']>({ 0: [] });
    const [isRecording, setIsRecording] = useState(false);
    const lastTimePressed = useRef<number>(null);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    function moveToNextWord() {
        const [currentLineIndex, currentWordIndex] = currentWord;
        const nextWordOnLine = wordLines[currentLineIndex][currentWordIndex + 1];
        if (nextWordOnLine) {
            setCurrentWord([currentLineIndex, currentWordIndex + 1]);
            return;
        }

        if (currentLineIndex === wordLines.length - 1) {
            onCaptureEnd(generatedLines.current);
            return;
        }

        let nextLineIndex = currentLineIndex + 1;
        while (wordLines[nextLineIndex].length === 0) {
            generatedLines.current[nextLineIndex] = [];
            nextLineIndex += 1;
        }

        generatedLines.current[nextLineIndex] = [];
        setCurrentWord([nextLineIndex, 0]);
        return;
    }

    function handleKeyPress(e: KeyboardEvent) {
        if (!['Enter', ' '].includes(e.key)) {
            return;
        }

        const currentTimeMs = Date.now();
        const delayMs = isRecording ? currentTimeMs - lastTimePressed.current : 0;
        const [currentLineIndex, currentWordIndex] = currentWord;
        const currentWordString = wordLines[currentLineIndex][currentWordIndex];
        generatedLines.current[currentLineIndex].push({ character: currentWordString[0], delayMs });
        lastTimePressed.current = currentTimeMs;

        if (!isRecording) {
            setIsRecording(true);
        }

        moveToNextWord();
    }

    function renderLines() {
        return wordLines.map((line, lineIndex) => {
            if (line.length === 0) {
                return <div className="karaoke-capture__line-spacer" />;
            }
            return (
                <div key={lineIndex} className="karaoke-capture__line">
                    {line.map((word, wordIndex) => {
                        const [currentLineIndex, currentWordIndex] = currentWord;
                        const className = classNames('karaoke-capture__word', {
                            'karaoke-capture__word--active':
                                currentLineIndex === lineIndex && currentWordIndex === wordIndex,
                        });
                        return (
                            <span key={`w-${word}-${wordIndex}`} className={className}>
                                {word}
                            </span>
                        );
                    })}
                </div>
            );
        });
    }

    return (
        <>
            <div className="karaoke-capture-modal__page-capture">
                <div className="karaoke-capture__container">{renderLines()}</div>
            </div>
            <div className="karaoke-capture-modal__buttons">
                <Button
                    className="karaoke-capture-modal__button karaoke-capture-modal__button--secondary"
                    label="Back"
                    onClick={onBack}
                />
            </div>
        </>
    );
}
