import { GameTypes } from '@house-of-games/common';
import { onAuthStateChanged, User } from 'firebase/auth';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
import { LoadingSpinner } from '../../common/components/loading-error/loading-spinner';
import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { LoggedOutRoutes } from '../../common/constants/routes';
import { isMobile } from '../../utils/device';
import { FirebaseHelper } from '../../utils/firebase';
import { requestHandler } from '../../utils/request-handler';
import { AppRouter } from '../app-router/app-router';
import { Homepage } from '../homepage/homepage';
import { Login } from '../login/login';
import { Play } from '../play/play';
import { VerifyEmail } from '../verify-email/verify-email';
import { ProfileContext } from './auth-context';

enum AuthState {
    'LoggedIn' = 'LoggedIn',
    'LoggingIn' = 'LoggingIn',
    'LoggedOut' = 'LoggedOut',
    'Verify' = 'Verify',
}

type UserData = {
    like: Array<string>;
    dislike: Array<string>;
};

function Corners() {
    return (
        <>
            <div className="app-bg">
                <div className="app-bg-box top left" />
                <div className="app-bg-box top right" />
                <div className="app-bg-box bottom left" />
                <div className="app-bg-box bottom right" />
            </div>
        </>
    );
}

export function AuthRouter() {
    const [authState, setAuthState] = useState<AuthState>();
    const [profile, setProfile, profileRef] = useRefState<GameTypes.PrivateProfile>(null);
    const unsubscribe = useRef<Array<() => void>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const shouldRenderCorners = !Boolean(location.pathname.match(/\/(control)/));

    function mergeGameWithProfile(savedProfile: GameTypes.PrivateProfile, games: Array<GameTypes.PrivateGame>) {
        const newProfile = { ...savedProfile };
        newProfile.games = games;
        setProfile(newProfile);
    }

    function mergeLikesWithProfile(user: UserData) {
        const newProfile = { ...profileRef.current };
        newProfile.like = [...(user?.like || [])];
        newProfile.dislike = [...(user?.dislike || [])];
        setProfile(newProfile);
    }

    useEffect(() => {
        FirebaseHelper.initializeApp();
        const auth = FirebaseHelper.getAuth();
        onAuthStateChanged(auth, async (user) => {
            unsubscribe.current = unsubscribe.current?.filter((fn) => {
                fn();
                return false;
            });
            if (user) {
                console.log(auth.currentUser);
                if (!user.emailVerified) {
                    setIsLoading(false);
                    setAuthState(AuthState.Verify);
                } else {
                    setAuthState(AuthState.LoggingIn);
                    setIsLoading(true);
                    handleLoggedIn(user);
                }
            } else {
                setIsLoading(false);
                setAuthState(AuthState.LoggedOut);
            }
        });
    }, []);

    useEffect(() => {
        FirebaseHelper.setProfile(profile);
    }, [profile]);

    async function handleLoggedIn(user: User) {
        await requestHandler.getToken();
        const profileRes = await requestHandler.getPrivateProfile();
        setProfile(profileRes);
        setIsLoading(false);
        setAuthState(AuthState.LoggedIn);
        const firestore = FirebaseHelper.getFirestore();
        const gamesQuery = query(collection(firestore, 'games'), where('owner', '==', user.uid));
        const gamesSub = onSnapshot(gamesQuery, (snapshot) => {
            const games: Array<GameTypes.PrivateGame> = [];
            snapshot.forEach((doc) => {
                games.push(doc.data() as GameTypes.PrivateGame);
            });
            mergeGameWithProfile(
                profileRes,
                games.sort((a, b) => b.timestamp - a.timestamp),
            );
        });
        unsubscribe.current.push(gamesSub);

        const profileSub = onSnapshot(doc(firestore, 'users', user.uid), (snapshot) => {
            const user = snapshot.data();
            if (user) {
                mergeLikesWithProfile(user as UserData);
            }
        });
        unsubscribe.current.push(profileSub);
    }

    function handleRefreshVerified() {
        FirebaseHelper.getAuth()
            .currentUser?.reload()
            .then(async () => {
                const auth = FirebaseHelper.getAuth();
                if (auth.currentUser?.emailVerified) {
                    setIsLoading(true);
                    handleLoggedIn(auth.currentUser);
                }
            })
            .catch(console.error);
    }

    async function handleChangeName(name: string) {
        await FirebaseHelper.updateProfile(name);
        setProfile({ ...profile, displayName: name });
    }

    function renderAuthRoute() {
        if (isMobile() || authState === AuthState.LoggedOut) {
            return (
                <Routes>
                    <Route path={`${LoggedOutRoutes.Play}`} element={<Play />}>
                        <Route path=":sessionId" element={<Play />} />
                    </Route>
                    <Route path={`${LoggedOutRoutes.LogIn}`} element={<Login />} />
                    <Route
                        path={`${LoggedOutRoutes.Home}`}
                        element={<Homepage loggedInUser={profile?.displayName} />}
                    />
                    <Route path="*" element={<Navigate to={LoggedOutRoutes.Home} replace />} />
                </Routes>
            );
        } else if (authState === AuthState.LoggedIn) {
            return (
                <ProfileContext.Provider value={profile}>
                    <AppRouter onChangeName={handleChangeName} />
                </ProfileContext.Provider>
            );
        } else if (authState === AuthState.Verify) {
            return (
                <VerifyEmail
                    email={FirebaseHelper.getAuth().currentUser?.email}
                    handleRefreshVerified={handleRefreshVerified}
                />
            );
        } else {
            return <></>;
        }
    }

    return (
        <>
            {shouldRenderCorners && <Corners />}
            {isLoading && <LoadingSpinner />}
            {renderAuthRoute()}
            {authState === AuthState.Verify && (
                <Button
                    className="logout"
                    style={{ position: 'absolute', right: '0.5em', top: '0.5em' }}
                    onClick={() => FirebaseHelper.getAuth().signOut()}
                    label="Logout"
                />
            )}
        </>
    );
}
