import { MathOperator, Round, RoundType } from '../types/round';
import { fromStringEnum } from '../utils/array-utils';

export enum FieldType {
    'boolean' = 'boolean',
    'string' = 'string',
    'number' = 'number',
    'array' = 'array',
    'object' = 'object',
    'objectarray' = 'objectarray',
}

export interface BooleanField {
    type: FieldType.boolean;
}

export interface StringField {
    type: FieldType.string;
    length: number;
    allowWhiteSpace?: boolean;
}

export interface NumberField {
    type: FieldType.number;
    length: number;
    maxValue?: number;
    minValue?: number;
}

export type SimpleField = StringField | NumberField | BooleanField;

interface ArrayField {
    length: number;
    type: FieldType.array | FieldType.objectarray;
    of: Field;
}

interface ObjectField {
    type: FieldType.object;
    of: { [key: string]: Field };
}

interface OneOfField {
    oneOf: Array<unknown>;
}

export type Field = SimpleField | ArrayField | ObjectField | OneOfField;

export const shortStringDefinition = {
    length: 55,
    type: FieldType.string,
} as const;

export const mediumStringDefinition = {
    length: 80,
    type: FieldType.string,
} as const;

export const longStringDefinition = {
    length: 200,
    type: FieldType.string,
} as const;

export const imageDefinition = {
    length: 2000,
    type: FieldType.string,
} as const;

const defaultFields = {
    type: FieldType.object,
    of: {
        question: longStringDefinition,
        answer: shortStringDefinition,
        category: shortStringDefinition,
        prompt: shortStringDefinition,
    },
} as const;

const shortQuestionFields = {
    type: FieldType.object,
    of: {
        question: shortStringDefinition,
        answer: shortStringDefinition,
        category: shortStringDefinition,
    },
} as const;

const brokenKaraokeFields = {
    type: FieldType.object,
    of: {
        song: shortStringDefinition,
        artist: shortStringDefinition,
        year: {
            length: 4,
            type: FieldType.string,
        },
        lines: {
            length: 14,
            type: FieldType.objectarray,
            of: {
                length: 14,
                type: FieldType.array,
                of: {
                    type: FieldType.object,
                    of: {
                        character: {
                            length: 1,
                            type: FieldType.string,
                        },
                        delayMs: {
                            length: 4,
                            type: FieldType.number,
                        },
                    },
                },
            },
        },
    },
} as const;

const dimSumsFields = {
    type: FieldType.object,
    of: {
        questions: {
            type: FieldType.objectarray,
            length: 4,
            of: {
                type: FieldType.object,
                of: {
                    question: mediumStringDefinition,
                    answer: {
                        type: FieldType.number,
                        length: 5,
                        minValue: -9999,
                        maxValue: 9999,
                    },
                },
            },
        },
        equation: {
            type: FieldType.object,
            of: {
                firstTerm: {
                    type: FieldType.number,
                    length: 1,
                    minValue: 0,
                    maxValue: 3,
                },
                secondTerm: {
                    type: FieldType.number,
                    length: 1,
                    minValue: 0,
                    maxValue: 3,
                },
                operator: {
                    oneOf: fromStringEnum(MathOperator),
                },
            },
        },
    },
} as const;

const zToAFields = {
    type: FieldType.object,
    of: {
        answer: shortStringDefinition,
        question: {
            length: 3,
            type: FieldType.objectarray,
            of: shortStringDefinition,
        },
    },
} as const;

const twoQuestionsFields = {
    type: FieldType.objectarray,
    length: 2,
    of: {
        type: FieldType.object,
        of: {
            question: longStringDefinition,
            answer: shortStringDefinition,
            prompt: shortStringDefinition,
        },
    },
} as const;

const answerListFields = {
    type: FieldType.object,
    of: {
        question: longStringDefinition,
        answers: {
            type: FieldType.array,
            length: 55,
            of: { ...shortStringDefinition, allowWhiteSpace: true },
        },
    },
} as const;

const cineNymsFields = {
    type: FieldType.object,
    of: {
        question: longStringDefinition,
        originalQuote: longStringDefinition,
        answer: shortStringDefinition,
    },
} as const;

const totesEmojiFields = {
    type: FieldType.object,
    of: {
        category: shortStringDefinition,
        author: shortStringDefinition,
        question: {
            length: 80, // Double character length because emojis use more than one each
            type: FieldType.string,
        },
        answer: shortStringDefinition,
    },
} as const;

const theAnswerIsntFields = {
    type: FieldType.object,
    of: {
        question: longStringDefinition,
        fakes: {
            type: FieldType.objectarray,
            length: 3,
            of: shortStringDefinition,
        },
        answer: shortStringDefinition,
    },
} as const;

const highbrowLowbrowFields = {
    type: FieldType.object,
    of: {
        highbrow: longStringDefinition,
        lowbrow: longStringDefinition,
        answer: shortStringDefinition,
    },
} as const;

const answerSmashFields = {
    type: FieldType.object,
    of: {
        category: shortStringDefinition,
        question: longStringDefinition,
        image: imageDefinition,
        answer: shortStringDefinition,
    },
} as const;

const correctionCentreFields = {
    type: FieldType.object,
    of: {
        question: longStringDefinition,
        answer: longStringDefinition,
    },
} as const;

export const fingerOnItAnswer = {
    type: FieldType.object,
    of: {
        x: {
            type: FieldType.number,
            length: 5,
        },
        y: {
            type: FieldType.number,
            length: 5,
        },
    },
} as const;

const fingerOnItFields = {
    type: FieldType.object,
    of: {
        question: {
            length: 80,
            type: FieldType.string,
        },
        image: imageDefinition,
        answer: fingerOnItAnswer,
    },
} as const;

const whenTheySingFields = {
    type: FieldType.object,
    of: {
        question: shortStringDefinition,
        title: shortStringDefinition,
        answer: {
            maxValue: 60,
            length: 5,
            type: FieldType.number,
        },
        fadeOut: {
            maxValue: 60,
            length: 5,
            type: FieldType.number,
        },
    },
} as const;

function definitionsMap<T extends { [key in RoundType]: Field }>(t: T) {
    return t;
}

export const roundDefinitions = definitionsMap({
    [RoundType.BrokenKaraoke]: brokenKaraokeFields,
    [RoundType.InCode]: shortQuestionFields,
    [RoundType.ZToA]: zToAFields,
    [RoundType.DimSums]: dimSumsFields,
    [RoundType.Roonerspisms]: twoQuestionsFields,
    [RoundType.KingJumble]: twoQuestionsFields,
    [RoundType.RhymeTime]: twoQuestionsFields,
    [RoundType.CineNyms]: cineNymsFields,
    [RoundType.OppositesAttract]: shortQuestionFields,
    [RoundType.TwoInOne]: shortQuestionFields,
    [RoundType.CorrectionCentre]: correctionCentreFields,
    [RoundType.HighbrowLowbrow]: highbrowLowbrowFields,
    [RoundType.AnswerSmash]: answerSmashFields,
    [RoundType.TotesEmoji]: totesEmojiFields,
    [RoundType.TheAnswerIsnt]: theAnswerIsntFields,
    [RoundType.FingerOnIt]: fingerOnItFields,
    [RoundType.WhereIsKazakhstan]: fingerOnItFields,
    [RoundType.WhenTheySing]: whenTheySingFields,
    [RoundType.InTheName]: defaultFields,
    [RoundType.GamesHouseOf]: defaultFields,
    [RoundType.Elephant]: defaultFields,
    [RoundType.Backwards]: defaultFields,
    [RoundType.PastTense]: defaultFields,
    [RoundType.MouseOfGames]: defaultFields,
    [RoundType.HouseOfGamers]: defaultFields,
    [RoundType.HoseOfGames]: defaultFields,
    [RoundType.DistinctlyAverage]: defaultFields,
    [RoundType.YouCompleteMe]: defaultFields,
    [RoundType.YouSpellTerrible]: defaultFields,
    [RoundType.ICompleteYou]: defaultFields,
    [RoundType.TerribleDating]: defaultFields,
    [RoundType.RichList]: answerListFields,
    [RoundType.SizeMatters]: answerListFields,
    [RoundType.ZList]: answerListFields,
});

type Validator = (question: Round['questions'][number]) => void;
function createValidator(roundType: RoundType): Validator {
    return (q) => validateField(roundType, roundDefinitions[roundType], q);
}

export const roundValidators: { [key in RoundType]: Validator } = {
    [RoundType.BrokenKaraoke]: createValidator(RoundType.BrokenKaraoke),
    [RoundType.InCode]: createValidator(RoundType.InCode),
    [RoundType.DimSums]: createValidator(RoundType.DimSums),
    [RoundType.ZToA]: createValidator(RoundType.ZToA),
    [RoundType.Roonerspisms]: createValidator(RoundType.Roonerspisms),
    [RoundType.KingJumble]: createValidator(RoundType.KingJumble),
    [RoundType.CineNyms]: createValidator(RoundType.CineNyms),
    [RoundType.OppositesAttract]: createValidator(RoundType.OppositesAttract),
    [RoundType.TwoInOne]: createValidator(RoundType.TwoInOne),
    [RoundType.CorrectionCentre]: createValidator(RoundType.CorrectionCentre),
    [RoundType.HighbrowLowbrow]: createValidator(RoundType.HighbrowLowbrow),
    [RoundType.AnswerSmash]: createValidator(RoundType.AnswerSmash),
    [RoundType.TotesEmoji]: createValidator(RoundType.TotesEmoji),
    [RoundType.TheAnswerIsnt]: createValidator(RoundType.TheAnswerIsnt),
    [RoundType.FingerOnIt]: createValidator(RoundType.FingerOnIt),
    [RoundType.WhereIsKazakhstan]: createValidator(RoundType.WhereIsKazakhstan),
    [RoundType.RichList]: createValidator(RoundType.RichList),
    [RoundType.SizeMatters]: createValidator(RoundType.SizeMatters),
    [RoundType.ZList]: createValidator(RoundType.ZList),
    [RoundType.WhenTheySing]: createValidator(RoundType.WhenTheySing),

    // Default Rounds
    [RoundType.RhymeTime]: createValidator(RoundType.RhymeTime),
    [RoundType.InTheName]: createValidator(RoundType.InTheName),
    [RoundType.GamesHouseOf]: createValidator(RoundType.GamesHouseOf),
    [RoundType.Elephant]: createValidator(RoundType.Elephant),
    [RoundType.Backwards]: createValidator(RoundType.Backwards),
    [RoundType.PastTense]: createValidator(RoundType.PastTense),
    [RoundType.MouseOfGames]: createValidator(RoundType.MouseOfGames),
    [RoundType.HouseOfGamers]: createValidator(RoundType.HouseOfGamers),
    [RoundType.HoseOfGames]: createValidator(RoundType.HoseOfGames),
    [RoundType.DistinctlyAverage]: createValidator(RoundType.DistinctlyAverage),
    [RoundType.YouCompleteMe]: createValidator(RoundType.YouCompleteMe),
    [RoundType.YouSpellTerrible]: createValidator(RoundType.YouSpellTerrible),
    [RoundType.ICompleteYou]: createValidator(RoundType.ICompleteYou),
    [RoundType.TerribleDating]: createValidator(RoundType.TerribleDating),
};

export function validateField(key: string, field: Field, value: unknown) {
    if ('oneOf' in field) {
        validateOneOfField(key, field, value);
        return;
    }

    switch (field.type) {
        case FieldType.boolean:
        case FieldType.number:
        case FieldType.string:
            validateSimlpeField(key, field, value);
            break;
        case FieldType.array:
        case FieldType.objectarray:
            validateArrayField(key, field, value);
            break;
        case FieldType.object:
            validateObjectField(key, field, value);
            break;
    }
}

function validateStringField(key: string, field: StringField, value: string) {
    const tooLongMessage = `Key "${key}" cannot be more than ${field.length} characters`;
    if (field.allowWhiteSpace) {
        if (value.length > field.length + 2) {
            throw tooLongMessage;
        }

        if (value.trim().length > field.length) {
            throw tooLongMessage;
        }
    } else if (value.length > field.length) {
        throw tooLongMessage;
    }
}

function validateNumberField(key: string, field: NumberField, value: number) {
    if (field.maxValue !== undefined && value > field.maxValue) {
        throw `Key "${key}" cannot be larger than ${field.maxValue}`;
    }

    if (field.minValue !== undefined && value < field.minValue) {
        throw `Key "${key}" cannot be smaller than ${field.minValue}`;
    }

    const modifiedValue = value.toString();
    if (modifiedValue.length > field.length) {
        throw `Key "${key}" cannot be more than ${field.length} characters`;
    }
}

function validateSimlpeField(key: string, field: SimpleField, value: unknown) {
    if (typeof value === 'boolean' && field.type === FieldType.boolean) {
        return;
    } else if (typeof value === 'number' && field.type === FieldType.number) {
        validateNumberField(key, field, value);
    } else if (typeof value === 'string' && field.type === FieldType.string) {
        validateStringField(key, field, value);
    } else {
        throw `Unexpected value received for "${key}": "${value}"`;
    }
}

function checkIsValidObjectArray(value: unknown) {
    if (Array.isArray(value)) {
        return false;
    }

    if (typeof value === 'object') {
        return Object.keys(value as object).every((k) => {
            return !isNaN(parseInt(k, 10));
        });
    }

    return false;
}

function validateArrayField(key: string, field: ArrayField, value: unknown) {
    const isArray = field.type === FieldType.array;
    const isObjectArray = field.type === FieldType.objectarray;
    const isInvalidArray = isArray && !Array.isArray(value);
    const isInvalidObjectArray = isObjectArray && !checkIsValidObjectArray(value);

    if (isInvalidArray || isInvalidObjectArray) {
        throw `Unexpected value received for "${key}": "${value}"`;
    }

    let isWithinLength: boolean;
    let arrayToValidate: Array<unknown>;
    if (isObjectArray) {
        isWithinLength = Object.keys(value as object).every((k) => parseInt(k, 10) < field.length);
        arrayToValidate = Object.values(value as object);
    } else {
        isWithinLength = (value as Array<unknown>).length <= field.length;
        arrayToValidate = value as Array<unknown>;
    }

    if (!isWithinLength) {
        throw `Key "${key}" cannot contain more than ${field.length} items`;
    }

    arrayToValidate.forEach((v) => {
        validateField(key, field.of, v);
    });
}

function validateObjectField(key: string, field: ObjectField, value: unknown) {
    if (typeof value !== 'object') {
        throw `Unexpected value received for "${key}": "${value}"`;
    }

    const item = value as object;
    const fieldKeys = Object.keys(field.of);
    const itemKeys = Object.keys(item);
    const extraFields = itemKeys.filter((k) => !fieldKeys.includes(k));
    if (extraFields.length > 0) {
        const keysString = fieldKeys.map((k) => `"${k}"`).join(', ');
        throw `Unexpected values received. Expected ${keysString} but received "${extraFields}"`;
    }

    Object.entries(item).forEach(([nextKey, nextValue]) => {
        validateField(nextKey, field.of[nextKey], nextValue);
    });
}

function validateOneOfField(key: string, field: OneOfField, value: unknown) {
    if (!field.oneOf.includes(value)) {
        throw `Unexpected values received. Expected to be one of: [${field.oneOf}] but received "${value}"`;
    }
}
