import deepmerge from 'deepmerge';

import { RoundTypes } from '..';
import { PrivateGame } from '../types/game';
import { RoundType } from '../types/round';
import { AppError } from './app-error';

export type MatchType = string | number | boolean;

type Path = {
    absolute: string;
    gameId: string;
    roundType: RoundType;
    questionNumber: string;
};

export type Match<T extends MatchType> = {
    paths: Array<Path>;
    value: T;
};

export type CrawlResult<T extends MatchType> = Record<string, Match<T>>;

// function brokenKaraokeCrawler(round: BrokenKaraokeRound, matcher: (test: string) => boolean) {
//     const matches: Array<Match> = [];
//     round.questions.forEach((q, i) => {
//         const { lines, ...testEntries } = q;
//         Object.entries(testEntries).forEach(([key, value]) => {
//             if (matcher(value)) {
//                 matches.push({ path: `${RoundType.BrokenKaraoke}}.questions.${i}.key`, value: value });
//             }
//         });
//     });
// }

const twoQuestionRound = <const>[RoundType.Roonerspisms, RoundType.KingJumble, RoundType.RhymeTime];

function twoQuestionCrawler<T extends MatchType>(
    round: RoundTypes.Round<typeof twoQuestionRound[number]>,
    pathPrefix: string,
    matcher: (test: MatchType) => test is T,
): CrawlResult<T> {
    const matches: CrawlResult<T> = {};
    Object.entries(round.questions).forEach(([questionNumber, twoQuestionObject]) => {
        Object.entries(twoQuestionObject).forEach(([firstOrSecondIndex, question]) => {
            Object.entries(question).forEach(([key, value]) => {
                if (value === null || value === undefined) {
                    return;
                }

                const absolute = `${pathPrefix}.${round.type}.questions.${questionNumber}.${firstOrSecondIndex}.${key}`;
                if (!['string', 'number'].includes(typeof value)) {
                    console.warn('Unexpected type when crawling at: ', absolute);
                    return;
                }

                if (!matcher(value)) {
                    return;
                }

                const [gameId] = pathPrefix.split('.');
                const path: Path = {
                    absolute,
                    gameId,
                    roundType: round.type,
                    questionNumber: questionNumber,
                };

                const valueString = `${value}`;
                if (matches[valueString]) {
                    matches[valueString].paths.push(path);
                    return;
                }

                matches[valueString] = {
                    paths: [path],
                    value,
                };
            });
        });
    });

    return matches;
}

function questionArrayCrawler<T extends MatchType>(
    round: RoundTypes.Round,
    pathPrefix: string,
    matcher: (test: MatchType) => test is T,
): CrawlResult<T> {
    const matches: CrawlResult<T> = {};
    round.questions.forEach((question, questionNumber) => {
        Object.keys(question).forEach((key) => {
            const value = question[key as keyof typeof question];

            if (value === null || value === undefined) {
                return;
            }

            if (typeof value !== 'string' && typeof value !== 'number') {
                return;
            }

            const absolute = `${pathPrefix}.${round.type}.questions.${questionNumber}.${key}`;

            if (!matcher(value)) {
                return;
            }

            const [gameId] = pathPrefix.split('.');
            const path: Path = {
                absolute,
                gameId,
                roundType: round.type,
                questionNumber: `${questionNumber}`,
            };

            const valueString = `${value}`;
            if (matches[valueString]) {
                matches[valueString].paths.push(path);
                return;
            }

            matches[valueString] = {
                paths: [path],
                value,
            };
        });
    });

    return matches;
}

export function gameCrawler<T extends MatchType>(
    game: Pick<PrivateGame, 'id' | 'rounds'>,
    matcher: (test: MatchType) => test is T,
): CrawlResult<T> {
    if (!game || !game.rounds || !game.id) {
        const err = new AppError({
            code: 'GAME_CRAWL_MISSING_DATA',
            message: 'GameCrawler was called with missing data',
        });
        err.log();
        return {};
    }

    const pathPrefix = `${game.id}.rounds`;
    let matches: CrawlResult<T> = {};
    Object.values(game.rounds).forEach((round) => {
        if (twoQuestionRound.includes(round.type as typeof twoQuestionRound[number])) {
            const twoQuestionMatches = twoQuestionCrawler(
                round as RoundTypes.Round<typeof twoQuestionRound[number]>,
                pathPrefix,
                matcher,
            );
            matches = deepmerge(matches, twoQuestionMatches);
            return;
        }

        const questionArrayMatches = questionArrayCrawler(round, pathPrefix, matcher);
        matches = deepmerge(matches, questionArrayMatches);
        return;
    });

    return matches;
}

export function accessPropertyWithDotNotation<T = unknown>(
    obj: Record<string, any>,
    accessor: string,
    overwrite?: any,
): T {
    const keys = accessor.split('.');
    return keys.reduce((a, b, i) => {
        if (!a) {
            return;
        }

        const next = (a as any)[b];
        if (i === keys.length - 1 && overwrite !== undefined) {
            (a as any)[b] = overwrite;
            return next;
        }

        if (!next) {
            return undefined;
        }

        return next;
    }, obj) as T;
}
