import { RoundType } from '../types/round';
import { Field, fingerOnItAnswer, shortStringDefinition, validateField } from './round-validators';

type AnswerValidator = (answer: unknown) => void;

const answerDefinitions: { [key in RoundType]?: Field } = {
    [RoundType.FingerOnIt]: fingerOnItAnswer,
    [RoundType.WhereIsKazakhstan]: fingerOnItAnswer,
};

function createValidator(roundType: RoundType | undefined): AnswerValidator {
    const key = roundType || 'Title Slide';
    const definition = getAnswerDefinition(roundType);
    return (answer) => validateField(key, definition, answer);
}

const answerValidators: { [key in RoundType]?: AnswerValidator } = {
    [RoundType.FingerOnIt]: createValidator(RoundType.FingerOnIt),
    [RoundType.WhereIsKazakhstan]: createValidator(RoundType.WhereIsKazakhstan),
};

export function getAnswerDefinition(roundType: RoundType | undefined): Field {
    return answerDefinitions[roundType!] || shortStringDefinition;
}

export function getAnswerValidator(roundType: RoundType | undefined): AnswerValidator {
    return answerValidators[roundType!] || createValidator(roundType);
}
