type ImageNoBorderProps = {
    scale?: number;
};

export function ImageNoBorder({ scale = 1 }: ImageNoBorderProps) {
    const roundedScale = Math.round(24 * scale);
    return (
        <svg className="icon__image-no-border" width={`${roundedScale}`} height={`${roundedScale}`} viewBox="0 0 24 24">
            <path d="M22.9,20.2H1.1l6.8-9.5l3.4,2.7l3.4-5.5L22.9,20.2z M3.8,9.3c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7S1.1,5,1.1,6.5C1.1,8.1,2.3,9.3,3.8,9.3z" />
        </svg>
    );
}
