type AddProps = {
    scale?: number;
};

export function Add({ scale = 1 }: AddProps) {
    return (
        <svg className="icon__add" width={`${24 * scale}`} height={`${24 * scale}`} viewBox="0 0 24 24">
            <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" />
        </svg>
    );
}
