import './animate-expand-container.scss';

import classNames from 'classnames';
import React from 'react';

import { useAnimate } from '../../../modules/rounds/useAnimate';

export function AnimateExpandContainer({ children, className }: React.PropsWithChildren<{ className?: string }>) {
    const classes = useAnimate('animate-expand');
    const containerClasses = classNames('animate-expand-container', {
        [className]: className,
    });

    return (
        <div className={containerClasses}>
            {children}
            <div className={classes} />
        </div>
    );
}
