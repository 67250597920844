import './user-storage-grid.scss';

import { CFRequestTypes, GameCrawler, GameTypes, RoundTypes, UserStorage } from '@house-of-games/common';
import classNames from 'classnames';
import deepmerge from 'deepmerge';
import { FullMetadata, getMetadata, StorageReference } from 'firebase/storage';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useProfileContext } from '../../../modules/auth-router/auth-context';
import { FirebaseHelper } from '../../../utils/firebase';
import { roundLabels } from '../../round-maps/round-labels';
import { Button } from '../buttons/buttons';
import { Upload } from '../icons/upload';
import { LoadingSpinner } from '../loading-error/loading-spinner';
import { FileData, useFileDrop } from './use-file-drop';
import { useStorageCache } from './use-storage-cache';

const tenMegabytesInBytes = 10485760;
const localStorageKey = 'storageMetadata';

type StorageItemProps = {
    fileKey: string;
    fileMetadata: FullMetadata;
    matches: GameCrawler.Match<string>;
    onDelete: (fileKey: string) => void;
    onSelect?: (fileKey: string) => void;
};

type RoundQuestionsRecord = { [key in RoundTypes.RoundType]?: Array<string> };
type GameInfo = { name: string; rounds: RoundQuestionsRecord };
type GamesRecord = Record<GameTypes.GameId, GameInfo>;

function StorageItem({ fileKey, fileMetadata, matches, onDelete, onSelect }: StorageItemProps) {
    const itemType = UserStorage.getFileTypeFromUri(fileKey);
    const profile = useProfileContext();
    const storageCache = useStorageCache();
    const [isLoading, setIsLoading] = useState<boolean>(!storageCache.cache.current[fileKey]);

    const gamesRecord = useMemo<GamesRecord>(() => {
        const partialRecord: GamesRecord = {};
        matches?.paths.forEach((path) => {
            const { gameId, roundType, questionNumber } = path;
            const currentGameRecord = partialRecord[gameId];
            if (currentGameRecord) {
                if (currentGameRecord.rounds[roundType]) {
                    currentGameRecord.rounds[roundType].push(questionNumber);
                    return;
                }

                currentGameRecord.rounds[roundType] = [questionNumber];
                return;
            }

            const game = profile.games.find((g) => g.id === gameId);
            partialRecord[gameId] = { name: game.name, rounds: { [roundType]: [questionNumber] } };
        });

        return partialRecord;
    }, []);

    useEffect(() => {
        if (!isLoading) {
            return;
        }

        storageCache
            .load(fileKey)
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                // noop
            });
    }, []);

    function getRoundInfo(gameId: GameTypes.GameId) {
        const roundInfo: Array<React.ReactElement> = [];
        const roundRecords = gamesRecord[gameId].rounds;
        Object.keys(roundRecords).forEach((roundType: string) => {
            const castRoundType = roundType as RoundTypes.RoundType;
            roundRecords[castRoundType].forEach((questionIndex) => {
                const questionNumber = Number(questionIndex) + 1;
                roundInfo.push(
                    <div key={`${roundLabels[castRoundType]}-${questionIndex}`} className="storage-item__info-round">
                        {`${roundLabels[castRoundType]} (Q${questionNumber})`}
                    </div>,
                );
            });
        });

        return roundInfo;
    }
    function renderGamesInfo() {
        const gameKeys = Object.keys(gamesRecord);
        if (matches === undefined || gameKeys.length === 0) {
            return (
                <div className="storage-item__info-container storage-item__info-container--unused">
                    <div className="storage-item__info-game">This File Is Unused</div>
                </div>
            );
        }

        return (
            <div className="storage-item__info-container">
                {gameKeys.map((gameId) => {
                    return (
                        <div key={gameId} className="storage-item__info-game">
                            <div className="storage-item__info-game-id">{gamesRecord[gameId].name}</div>
                            <div className="storage-item__info-rounds">{getRoundInfo(gameId)}</div>
                        </div>
                    );
                })}
            </div>
        );
    }

    function getFileName() {
        const { nickName, originalFileName } = fileMetadata.customMetadata || {};
        if (nickName) {
            return nickName;
        }

        if (!originalFileName) {
            return 'N/A';
        }

        if (originalFileName.length > 30) {
            return `${originalFileName.substring(0, 29)}...`;
        }

        return originalFileName;
    }

    const className = classNames('storage-item', {
        'storage-item--unused': matches === undefined,
    });

    return (
        <div className={className}>
            <div className="storage-item__file-name">{getFileName()}</div>
            {itemType === CFRequestTypes.UploadFileType.Audio ? (
                <audio src={storageCache.cache.current[fileKey]} controls />
            ) : (
                <img className="storage-item__image" src={storageCache.cache.current[fileKey]} />
            )}

            <div className="storage-item__space-used">
                Size - {FirebaseHelper.createStorageString(fileMetadata.size)}
            </div>
            {renderGamesInfo()}
            <div className="storage-item__buttons">
                <Button className="storage-item__button-delete" label="Delete File" onClick={() => onDelete(fileKey)} />
                {onSelect && (
                    <Button
                        className="storage-item__button-select"
                        label="Select File"
                        onClick={() => onSelect(fileKey)}
                    />
                )}
            </div>
        </div>
    );
}

type UserStorageGridProps = {
    initialFileTypeFilter?: CFRequestTypes.UploadFileType;
    allowedFileTypes: Array<CFRequestTypes.UploadFileType>;
    onFileUpload?: (fileData: FileData) => void;
    onFileSelected?: (fileKey: string) => void;
};

export function UserStorageGrid({
    allowedFileTypes,
    initialFileTypeFilter,
    onFileUpload,
    onFileSelected,
}: UserStorageGridProps) {
    const profile = useProfileContext();
    const showFilterTabs = allowedFileTypes.length > 1;
    const [fileTypeFilter, setFileTypeFilter] = useState<CFRequestTypes.UploadFileType>(
        initialFileTypeFilter || showFilterTabs ? CFRequestTypes.UploadFileType.Audio : allowedFileTypes[0],
    );
    const storageCache = useStorageCache();
    const [storage, setStorage] = useState<Record<string, FullMetadata>>({});
    const [storageUsedBytes, setStorageUsedBytes] = useState<number>();
    const [isLoading, setIsLoading] = useState(true);
    const [fileMatches, setFileMatches] = useState<GameCrawler.CrawlResult<string>>();
    const uploadInputRef = useRef<HTMLInputElement>();
    const { isDragging, isDraggingInvalid, isFileProcessing, fileDataRef, dropEventListeners, onUserFileSelected } =
        useFileDrop(allowedFileTypes, () => onFileUpload(fileDataRef.current), uploadInputRef);

    const acceptMap: { [key in CFRequestTypes.UploadFileType]: string } = {
        [CFRequestTypes.UploadFileType.Image]: 'image/*',
        [CFRequestTypes.UploadFileType.Audio]: 'audio/*',
    };

    useEffect(() => {
        const storedFileListPromise = FirebaseHelper.listAllFiles();
        const metadataString = localStorage.getItem(localStorageKey);
        let localStorageData: Record<string, FullMetadata> = {};

        if (metadataString) {
            try {
                localStorageData = JSON.parse(metadataString);
            } catch {
                localStorage.removeItem(localStorageKey);
            }
        }

        const filteredStorageData: Record<string, FullMetadata> = {};

        storedFileListPromise.then(async (res: Array<StorageReference>) => {
            const unknownFiles: Array<StorageReference> = [];
            res.forEach((item) => {
                const uri = UserStorage.buildUriFromFullPath(item.fullPath);

                if (localStorageData[uri]) {
                    filteredStorageData[uri] = localStorageData[uri];
                    return;
                }

                unknownFiles.push(item);
            });

            const fileMetadataPromises: Array<Promise<FullMetadata>> = unknownFiles.map((ref) => {
                return getMetadata(ref);
            });

            const results = await Promise.allSettled(fileMetadataPromises);
            results.forEach((res) => {
                if (res.status === 'rejected') {
                    return;
                }

                const uri = UserStorage.buildUriFromFullPath(res.value.fullPath);
                filteredStorageData[uri] = res.value;
            });

            let storageUsedBytesAcc = 0;
            Object.values(filteredStorageData).forEach((meta) => {
                storageUsedBytesAcc += meta.size;
            });

            let allFiles: GameCrawler.CrawlResult<string> = {};
            profile.games.forEach((game) => {
                const gameMatches = GameCrawler.gameCrawler(game, UserStorage.userStorageMatcher);
                allFiles = deepmerge(allFiles, gameMatches);
            });

            localStorage.setItem(localStorageKey, JSON.stringify(filteredStorageData));
            setStorageUsedBytes(storageUsedBytesAcc);
            setStorage(filteredStorageData);
            setFileMatches(allFiles);
            setIsLoading(false);
        });
    }, []);

    function handleDeleteFile(fileKey: string) {
        const newStorage = { ...storage };
        delete newStorage[fileKey];
        let storageUsedBytesAcc = 0;
        Object.values(newStorage).forEach((meta) => {
            storageUsedBytesAcc += meta.size;
        });

        localStorage.setItem(localStorageKey, JSON.stringify(newStorage));
        setStorageUsedBytes(storageUsedBytesAcc);
        setStorage(newStorage);

        try {
            FirebaseHelper.deleteFile(fileKey);
            storageCache.delete(fileKey);
        } catch {
            localStorage.setItem(localStorageKey, JSON.stringify(storage));
            setStorageUsedBytes(storageUsedBytes);
            setStorage(storage);
        }
    }

    function handleClickUpload() {
        uploadInputRef.current.click();
    }

    function filterOnlyAudio(fileName: string) {
        return !fileName.includes(UserStorage.filePathMap[CFRequestTypes.UploadFileType.Image]);
    }

    function filterOnlyImages(fileName: string) {
        return fileName.includes(UserStorage.filePathMap[CFRequestTypes.UploadFileType.Image]);
    }

    function renderStorageItems() {
        const items = Object.entries(storage);
        const fileFilter = fileTypeFilter === CFRequestTypes.UploadFileType.Audio ? filterOnlyAudio : filterOnlyImages;
        const filteredItems = items.filter(([key]) => fileFilter(key));

        if (filteredItems.length === 0 && !onFileUpload) {
            return (
                <div className="storage-items__no-items">
                    You have not uploaded any {fileTypeFilter.toLowerCase()} files yet. Some rounds will allow you to
                    upload files for questions. These files will be displayed here.
                </div>
            );
        }

        const dragDropClassNames = classNames('storage-item storage-item--upload', {
            'storage-item--dragging': isDragging,
        });

        return (
            <div className="storage-items__container">
                {onFileUpload && (
                    <div className={dragDropClassNames} {...dropEventListeners} onClick={handleClickUpload}>
                        {isFileProcessing ? (
                            <LoadingSpinner />
                        ) : (
                            <>
                                <input
                                    ref={uploadInputRef}
                                    type="file"
                                    accept={allowedFileTypes.map((f) => acceptMap[f]).join(',')}
                                    className="storage-item__file-input"
                                    onChange={onUserFileSelected}
                                />
                                <Upload scale={5} isInvalid={isDraggingInvalid} />
                            </>
                        )}
                    </div>
                )}
                {filteredItems.map(([key, metadata]) => (
                    <StorageItem
                        key={key}
                        fileKey={key}
                        fileMetadata={metadata}
                        matches={fileMatches[key]}
                        onDelete={handleDeleteFile}
                        onSelect={onFileSelected}
                    />
                ))}
            </div>
        );
    }

    function renderFileTypeFilter() {
        if (!showFilterTabs) {
            return;
        }

        const tabBaseClass = 'storage-type-filter__tab';
        return (
            <div className="storage-type-filter__container">
                <div
                    className={classNames(tabBaseClass, {
                        [`${tabBaseClass}--selected`]: fileTypeFilter === CFRequestTypes.UploadFileType.Audio,
                    })}
                    onClick={() => setFileTypeFilter(CFRequestTypes.UploadFileType.Audio)}
                >
                    <div className="storage-type-filter__text">Audio</div>
                </div>
                <div
                    className={classNames(tabBaseClass, {
                        [`${tabBaseClass}--selected`]: fileTypeFilter === CFRequestTypes.UploadFileType.Image,
                    })}
                    onClick={() => setFileTypeFilter(CFRequestTypes.UploadFileType.Image)}
                >
                    <div className="storage-type-filter__text">Images</div>
                </div>
            </div>
        );
    }

    const usedStoragePercent = Math.min((storageUsedBytes / tenMegabytesInBytes) * 100, 100);
    return (
        <div className="storage-grid">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="storage-availability__container">
                        <div className="storage-availability__outline">
                            <div
                                className="storage-availability__fill"
                                style={{ right: `${100 - usedStoragePercent}%` }}
                            />
                            <div className="storage-availability__text">
                                {FirebaseHelper.createStorageString(storageUsedBytes)} /{' '}
                                {FirebaseHelper.createStorageString(tenMegabytesInBytes)}
                            </div>
                        </div>
                    </div>
                    {renderFileTypeFilter()}
                    {renderStorageItems()}
                </>
            )}
        </div>
    );
}
