import './player-buzzer.scss';

import { getAnswerDefinition, getAnswerValidator, RoundValidators } from '@house-of-games/common';
import classNames from 'classnames';
import { useRef, useState } from 'react';

import { TextField } from '../../common/components/form-fields/text-field';
import { Buzzer as BuzzerIcon } from '../../common/components/icons/buzzer';
import { Send } from '../../common/components/icons/send';
import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { isMobile } from '../../utils/device';
import { AnswerComponentProps } from '../rounds/answer-round-component-map';

function checkIsStringField(field: RoundValidators.Field): field is RoundValidators.StringField {
    return 'type' in field && [RoundValidators.FieldType.string, RoundValidators.FieldType.number].includes(field.type);
}

export function PlayerBuzzer({ buzz, disableTextSubmit, gameState, submit }: AnswerComponentProps) {
    const [isPressed, setIsPressed] = useState(false);
    const [answer, setAnswer, answerRef] = useRefState('');
    const [isSendingAnswer, setIsSendingAnswer] = useState(false);
    const answerFieldRef = useRef<TextField>();
    let isAnswerSendable = false;
    const definition = getAnswerDefinition(gameState?.roundType);
    const validator = getAnswerValidator(gameState?.roundType);

    if (!isSendingAnswer && Boolean(answer)) {
        try {
            validator(answer);
            isAnswerSendable = true;
        } catch (e) {}
    }

    const handleAnswerChanged = (v: string) => {
        setAnswer(v);
    };

    const handleSendAnswer = async () => {
        if (!isAnswerSendable) {
            return;
        }

        setIsPressed(true);
        setTimeout(() => {
            setIsPressed(false);
        }, 100);

        try {
            setIsSendingAnswer(true);
            await submit(answerRef.current);
            answerFieldRef.current.reset();
            answerFieldRef.current.blur();
            setAnswer('');
        } catch (e) {}

        setIsSendingAnswer(false);
    };

    const handleBuzz = () => {
        setIsPressed(true);
        setTimeout(() => {
            setIsPressed(false);
        }, 100);
        window.navigator?.vibrate?.(300);
        buzz();
    };

    const listeners = isMobile() ? { onTouchStart: handleBuzz } : { onMouseDown: handleBuzz };

    const sendIconClassNames = classNames('send-icon__container', {
        'send-icon__container--disabled': !isAnswerSendable,
        'send-icon__container--sending': isSendingAnswer,
    });

    const buzzerClasses = classNames('buzzer', {
        'buzzer--pressed': isPressed,
    });

    function renderTextField() {
        if (!disableTextSubmit && checkIsStringField(definition)) {
            return (
                <>
                    <div className="player-buzzer answer-field__container">
                        <TextField
                            className="answer-field"
                            type="text"
                            label="Answer"
                            isOutline={true}
                            ref={answerFieldRef}
                            onChange={handleAnswerChanged}
                            onEnterPress={handleSendAnswer}
                            maxLength={definition.length}
                        />
                        <div className={sendIconClassNames} onClick={handleSendAnswer}>
                            <Send />
                        </div>
                    </div>
                    <div className="answer-field__instructions">{`Not using voice chat? If you win the buzzer race you can type and send your answer here!`}</div>
                </>
            );
        }
    }

    return (
        <>
            <div className="answer-component answer-component__player-buzzer">
                <div className={buzzerClasses} {...listeners}>
                    <BuzzerIcon />
                </div>
            </div>
            <div className="shelf shelf__bottom answer-component__player-buzzer-answer">{renderTextField()}</div>
        </>
    );
}
