import './basic-audio-player.scss';
import 'react-scrubber/lib/scrubber.css';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Scrubber } from 'react-scrubber';

import { PlayPause } from '../icons/play-pause';

type BasicAudioPlayerProps = {
    audioRef: React.MutableRefObject<HTMLAudioElement>;
    className?: string;
    disabled?: boolean;
    onClickPause?: () => void;
    onClickPlay?: () => void;
    onPlay?: () => void;
    onPause?: () => void;
};

export function BasicAudioPlayer({
    audioRef,
    className,
    disabled,
    onClickPlay,
    onClickPause,
    onPlay,
    onPause,
}: BasicAudioPlayerProps) {
    const [scrubPosition, setScrubPosition] = useState<number>();
    const [audioPosition, setAudioPosition] = useState<number>(0);
    const [audioDuration, setAudioDuration] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState(false);

    function attachListeners() {
        audioRef.current?.addEventListener('canplaythrough', handleAudioLoaded);
        audioRef.current?.addEventListener('ended', handleAudioEnded);
        audioRef.current?.addEventListener('timeupdate', handlePositionChanged);
        audioRef.current?.addEventListener('play', handleStateChangePlay);
        audioRef.current?.addEventListener('pause', handleStateChangePause);
    }

    function removeListeners() {
        audioRef.current?.removeEventListener('canplaythrough', handleAudioLoaded);
        audioRef.current?.removeEventListener('ended', handleAudioEnded);
        audioRef.current?.removeEventListener('timeupdate', handlePositionChanged);
        audioRef.current?.removeEventListener('play', handleStateChangePlay);
        audioRef.current?.removeEventListener('pause', handleStateChangePause);
    }

    useEffect(() => {
        if (audioRef.current) {
            attachListeners();
            return () => removeListeners();
        }
    }, [audioRef.current]);

    function handleTogglePlay() {
        if (!isPlaying) {
            onClickPlay?.();
            audioRef.current?.play();
            setIsPlaying(true);
            return;
        }

        onClickPause?.();
        audioRef.current?.pause();
        setIsPlaying(false);
    }

    function handlePositionChanged() {
        setAudioPosition(audioRef.current.currentTime);
    }

    function handleStateChangePlay() {
        setIsPlaying(true);
        onPlay?.();
    }

    function handleStateChangePause() {
        setIsPlaying(false);
        onPause?.();
    }

    function handleAudioEnded() {
        setIsPlaying(false);
    }

    function handleAudioLoaded() {
        setAudioDuration(audioRef.current.duration);
    }

    function handleScrubStart(val: number) {
        setScrubPosition(val);
    }

    function handleScrubEnd(val: number) {
        audioRef.current.currentTime = val;
        setAudioPosition(val);
        setScrubPosition(undefined);
    }

    function handleScrubChange(val: number) {
        setScrubPosition(val);
    }

    function getTimeString(totalSeconds: number) {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        const secondsString = `0${seconds}`.substr(-2);

        return `${minutes}:${secondsString}`;
    }

    const currentPosition = scrubPosition ?? audioPosition;
    const containerClassName = classNames('basic-audio-player__container', {
        'basic-audio-player__container--disabled': disabled,
        [className]: Boolean(className),
    });

    return (
        <div className={containerClassName}>
            <div className="basic-audio-player__play-container" onClick={handleTogglePlay}>
                <PlayPause isPlaying={isPlaying} />
            </div>
            <div className="basic-audio-player__scrubber-container">
                <div className="basic-audio-player__scrub-time">{getTimeString(currentPosition)}</div>
                <Scrubber
                    min={0}
                    max={audioDuration}
                    value={currentPosition}
                    onScrubStart={handleScrubStart}
                    onScrubEnd={handleScrubEnd}
                    onScrubChange={handleScrubChange}
                />
                <div className="basic-audio-player__scrub-time">{getTimeString(audioDuration)}</div>
            </div>
        </div>
    );
}
