import './question-erase.scss';

import { Erase } from '../../../common/components/icons/erase';

type QuestionEraseProps = {
    index: number;
    onRemove: (i: number) => void;
};

export function QuestionErase({ index, onRemove }: QuestionEraseProps) {
    return (
        <div className="question-erase__container" onClick={() => onRemove(index)}>
            <Erase />
        </div>
    );
}
