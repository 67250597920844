import { RateState } from '../types/cloud-functions-request';

export function calculateNewVoteCount(
    isAlreadyLiked: boolean,
    isAlreadyDisliked: boolean,
    currentCount: number,
    state: RateState,
) {
    if (!RateState[state]) {
        return currentCount;
    }

    let count = currentCount || 0;

    if (state === RateState.Disliked) {
        if (isAlreadyLiked) {
            count -= 2;
        } else if (!isAlreadyDisliked) {
            count -= 1;
        }
    } else if (state === RateState.Liked) {
        if (isAlreadyDisliked) {
            count += 2;
        } else if (!isAlreadyLiked) {
            count += 1;
        }
    } else {
        if (isAlreadyLiked) {
            count -= 1;
        } else if (isAlreadyDisliked) {
            count += 1;
        }
    }

    return count;
}
