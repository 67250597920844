import './create-correction-centre.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';

import { Button } from '../../../common/components/buttons/buttons';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { TextArea } from '../common/text-area';
import { RoundProps } from '../create-round-component-map';

const definition = roundDefinitions[RoundTypes.RoundType.CorrectionCentre].of;

export function CreateCorrectionCentre({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: RoundProps<RoundTypes.CorrectionCentreRound>) {
    const { questions } = round;

    function handleNewQuestion() {
        createNewQuestion({});
    }

    function handleQuestionChanged(quesitonIndex: number, question: string) {
        updateQuestion(quesitonIndex, { question });
    }

    function handleAnswerChanged(quesitonIndex: number, answer: string) {
        updateQuestion(quesitonIndex, { answer });
    }

    return (
        <div className="create-correction-centre">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-correction-centre-question__container">
                        <QuestionArrows
                            index={i}
                            numberOfQuestions={questions.length}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                        />
                        <div className="create-correction-centre-question__question-container">
                            <TextArea
                                className="create-correction-centre-question__question-field"
                                defaultValue={q.question}
                                maxLength={definition.question.length}
                                placeholder="Enter your question here..."
                                onChange={(question) => handleQuestionChanged(i, question)}
                            />
                        </div>
                        <div className="create-correction-centre-question__answer-container">
                            <TextArea
                                className="create-correction-centre-question__answer-field"
                                defaultValue={q.answer}
                                maxLength={definition.answer.length}
                                placeholder="Enter your answer here..."
                                onChange={(answer) => handleAnswerChanged(i, answer)}
                            />
                        </div>
                        <QuestionErase index={i} onRemove={removeQuestion} />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
