type AddIconBoldProps = {
    scale?: number;
};

export function AddIconBold({ scale = 1 }: AddIconBoldProps) {
    return (
        <svg className="icon__add-bold" width={`${24 * scale}`} height={`${24 * scale}`} viewBox="0 0 24 24">
            <path d="M24,16h-8v8H8v-8H0V8h8V0h8v8h8V16z" />
        </svg>
    );
}
