import './faq.scss';

import classNames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { AppRoute } from '../../common/constants/routes';

type FaqItem = {
    label: string;
    body: JSX.Element;
};

const howDoesItWork: FaqItem = {
    label: 'How Does It Work?',
    body: (
        <>
            <h3 className="faq-subheader">Creating A Game</h3>
            <div className="faq-text">
                {`
                When you create a new game it will appear on the home screen. Click on the game,
                and then select "New Round" to create a new round, or click on a specific round to
                add or edit the questions. Your account will be created with an "Examples" game which
                provides one or two example questions for a handlful of rounds.
            `}
            </div>
            <h3 className="faq-subheader">Playing A Game</h3>
            <div className="faq-text">
                {`
                In order to play the game you will need to make sure everybody can see the screen.
                See the "Setting Up The Screen" section for more information about this.
            `}
                <br />
                <br />
                {`
                Once you have created your game and it's time to settle in and play, click
                the "Play" button on the game creation page. This will take you to the title
                screen and open up a control panel which you will use to control the screen and
                manage the scoreboard.
            `}
                <br />
                <br />
                {`
                On the title screen, you should see a link that looks something like
                "houseofgames.app/play/XXXX". Players should go to this link on their
                mobile phone/tablet devices and join the game. As players join, you will see
                their names start to appear on the scoreboard on the control panel. They will
                use their phone to buzz in when they know the answer, or submit their answer
                for non-buzzer based rounds.
            `}
                <br />
                <br />
                {`
                When everybody has joined the game, you can use the left/right arrows to progress
                through the game. Players will be able to buzz in when they know the answer, and
                you will be able to clear the buzzer if they get the answer wrong or show the answer
                if they get it right. Once a player has buzzed in, they will no longer be allowed to
                buzz again for that question (although you can clear this by clicking the buzzer icon
                next to their name on the scoreboard). Scores are NOT automatically updated, so you will
                need to remember to update the scoreboard after each question!
            `}
                <br />
                <br />
                <video className="faq-video" controls src={`${process.env.PUBLIC_URL}/videos/how-it-works.mp4`} />
            </div>
        </>
    ),
};

const howManyPeople = {
    label: 'How Many People Can Play?',
    body: (
        <>
            <div className="faq-text">
                {`
                I have personally tested the app with 12 players and did not have any issues, but
                I have received feeback from other users to say that they have played with 100+
                people and not had any issues.
            `}
            </div>
        </>
    ),
};

const addImages = {
    label: 'How Do I Add Images To Supported Rounds?',
    body: (
        <>
            <div className="faq-text">
                {`
                To add images to questions you must provide a url to the image. This URL can be
                taken straight from an image search by right-clicking and selecting "Copy Image Address",
                but I would recommend using 
            `}
                <a href="https://imgur.com" target="_blank" className="faq-link" rel="noreferrer">
                    imgur
                </a>
                {`
                instead.
            `}
                <br />
                <br />
                {`
                On Imgur you can create an acount and upload pictures for free. Once you have
                signed up, create a new post. Give your post a title and upload as many
                images as you like. When an image is uploaded, right-click and select "Copy Image Address".
                Paste this address into the Image Link field on House Of Games and the image should
                load automatically.
            `}
                <br />
                <br />
                <video className="faq-video" controls src={`${process.env.PUBLIC_URL}/videos/image-url.mp4`} />
            </div>
        </>
    ),
};

const screenSetup = {
    label: 'Setting Up The Screen',
    body: (
        <>
            <h3 className="faq-subheader">Playing In The Same Room</h3>
            <div className="faq-text">
                {`
                If you are playing in the same room, you will need to connect a laptop to a
                big screen so that everybody can see the questions. Typically, you can do this
                using an HDMI cable, or wirelessly using Apple Airplay, Chromecast, or other
                wireless screen mirroring options.
            `}
                <br />
                <br />
                {`
                You will need to make sure that the players can see the game on the big screen,
                and you can access the control panel on the laptop screen. If you are seeing the
                same thing on both the laptop screen and the big screen, you will need to disable
                screen mirroring. This just means that both screens can be used individually
                as opposed to showing the same thing as each other. Disabling screen mirorring is
                different for Windows vs Mac but very simple to do on both.
            `}
                <br />
                <br />
                {`
                On Windows, connect the laptop to the second screen, right click on the desktop and select
                "Display Settings", then scroll down to "Multiple Displays" and select the "Extend" option.
            `}
                <br />
                <br />
                {`
                On Mac, connect the laptop to the second screen, open up System Preferences, select "Displays"
                and then "Display "Settings". In the "Use as" dropdown select "Stop Mirroring".
            `}
                <br />
                <br />
                {`
                If these methods do not work for you it could be because you using a different version. A
                quick search for "how to disable display mirroring" might be useful in this scenario.
            `}
            </div>
            <h3 className="faq-subheader">Playing Online</h3>
            <div className="faq-text">
                {`
                Video calling applications like Zoom or Microsoft Teams have a screen share feature which
                you can use to share the game screen with the players. You will have the option to share
                the entire desktop or just a specific window, so you should select and share the game
                window only. It is important that players cannot see the control panel as this will
                contain the answers to questions. If you're playing online it's still recommended to have a
                second display to allow you to see the question screen at the same time as the control panel. 
            `}
            </div>
        </>
    ),
};

export function Faq() {
    const isWindows = window.navigator.platform.includes('Win');
    const modifierKey = isWindows ? 'Ctrl' : 'Cmd';
    const copyRounds = {
        label: 'Can I Copy Rounds From One Game To Another?',
        body: (
            <>
                <div className="faq-text">
                    {`
                    Yes! If you long-click on a round it will enter "selection mode". Clicking on
                    other rounds will add them to your current selection. You can then use ${modifierKey} + C
                    to copy and ${modifierKey} + V to paste them into a different game.
                `}
                </div>
                <br />
                <br />
                <video className="faq-video" controls src={`${process.env.PUBLIC_URL}/videos/copy-rounds.mp4`} />
            </>
        ),
    };

    const faqItems: Array<FaqItem> = [howDoesItWork, howManyPeople, copyRounds, addImages, screenSetup];

    const [selectedItem, setSelectedItem] = useState<number>();

    function handleClickExpand(index: number) {
        if (selectedItem === index) {
            setSelectedItem(undefined);
        } else {
            setSelectedItem(index);
        }
    }

    return (
        <div className="faq page">
            <div className="header">
                <div className="box-header__container">
                    <Link draggable={false} to={AppRoute.Home}>
                        <div className="box-header__back-button-container">
                            <div className="box-header__back-button" />
                        </div>
                    </Link>
                    <h1 className="box-header">Frequently Asked Questions</h1>
                </div>
            </div>
            <div className="faq-list readable">
                {faqItems.map((item, index) => {
                    const className = classNames('faq-item', {
                        'faq-item--selected': index === selectedItem,
                    });
                    return (
                        <div className={className} key={item.label}>
                            <h2 className="faq-label" onClick={() => handleClickExpand(index)}>
                                {item.label}
                            </h2>
                            <div className="faq-body">{item.body}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
