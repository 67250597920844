import './buzzer-test.scss';

import { GameTypes } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

type BuzzerTestProps = {
    players: { [key: string]: GameTypes.Player };
};

export function BuzzerTest({ players }: BuzzerTestProps) {
    const initialPlayerState = useRef(players);
    const [buzzedPlayers, setBuzzedPlayers] = useState<Array<string>>([]);

    useEffect(() => {
        const newBuzzedPlayers = [...buzzedPlayers];
        Object.keys(players).forEach((playerId) => {
            if (buzzedPlayers.includes(playerId)) {
                return;
            }

            const oldBuzz = initialPlayerState.current[playerId]?.buzzer || 0;
            const newBuzz = players[playerId].buzzer || 0;
            if (newBuzz > oldBuzz) {
                newBuzzedPlayers.push(playerId);
            }
        });
        setBuzzedPlayers(newBuzzedPlayers);
    }, [players]);

    return (
        <div className="buzzer-test__container">
            <div className="buzzer-test__title box-header">Buzz To Ready Up!</div>
            <div className="buzzer-test__player-names-container">
                <div className="buzzer-test__player-names">
                    {Object.values(players).length <= 0 ? (
                        <div className="buzzer-test__player-name buzzer-test__empty-placeholder">
                            Waiting for players to join...
                        </div>
                    ) : (
                        Object.values(players)
                            .sort((a, b) => a?.name?.localeCompare(b?.name))
                            .map((player) => {
                                const playerNameClasses = classNames('buzzer-test__player-name', {
                                    'buzzer-test__player-name--ready': buzzedPlayers.includes(player.id),
                                });
                                return (
                                    <div key={player.id} className={playerNameClasses}>
                                        <span>{player.name}</span>
                                    </div>
                                );
                            })
                    )}
                </div>
            </div>
        </div>
    );
}
