import './music-question.scss';

import { useEffect, useMemo, useRef } from 'react';
import { ReactFitty } from 'react-fitty';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { Music } from '../../../common/components/icons/music';
import { TextFlip } from '../../../common/components/text-flip/text-flip';

type MusicQuestionProps = {
    answer?: string;
};

export function MusicQuestion({ answer }: MusicQuestionProps) {
    const visibilityTimeout = useRef<number>();
    const musicIconRef = useRef<HTMLDivElement>();

    useEffect(() => {
        visibilityTimeout.current = window.setTimeout(() => {
            if (musicIconRef.current) {
                musicIconRef.current?.classList.add('music-icon--visible');
            }
        }, 800);

        return () => {
            clearTimeout(visibilityTimeout.current);
        };
    }, []);

    function renderInnerComponent() {
        if (answer) {
            return (
                <div className="music-question__answer">
                    <ReactFitty className="fitty-container" wrapText minSize={48}>
                        {answer}
                    </ReactFitty>
                </div>
            );
        }

        return (
            <div ref={musicIconRef} className="music-icon">
                <Music scale={3} />
            </div>
        );
    }

    const innerComponent = useMemo(renderInnerComponent, [answer]);
    return (
        <AnimateExpandContainer className="music-question">
            <TextFlip text={innerComponent} />
        </AnimateExpandContainer>
    );
}
