import './audio-select-modal.scss';

import { CFRequestTypes } from '@house-of-games/common';
import { useState } from 'react';

import { Close } from '../icons/close';
import { Mp3Trim } from './mp3-trim';
import { AudioData, FileData } from './use-file-drop';
import { UserStorageGrid } from './user-storage-grid';

type AudioSelectModalProps = {
    audioData: AudioData;
    onCloseModal: () => void;
    onFileSelected: (fileKey: string) => void;
};

export function AudioSelectModal({ audioData: initialAudioData, onCloseModal, onFileSelected }: AudioSelectModalProps) {
    const [audioData, setAudioData] = useState(initialAudioData);

    function handleFileSelected(fileKey: string) {
        onFileSelected(fileKey);
    }

    if (initialAudioData) {
        return (
            <Mp3Trim
                audioBuffer={audioData.audioBuffer}
                audioContext={audioData.audioContext}
                file={audioData.file}
                onFileSaved={handleFileSelected}
                onCancel={onCloseModal}
            />
        );
    }

    if (audioData) {
        return (
            <Mp3Trim
                audioBuffer={audioData.audioBuffer}
                audioContext={audioData.audioContext}
                file={audioData.file}
                onFileSaved={handleFileSelected}
                onCancel={() => setAudioData(undefined)}
            />
        );
    }

    function handleSetAudioData(audioData: AudioData) {
        setAudioData(audioData);
    }

    function handleSetFileData(fileData: FileData) {
        if (fileData.type === CFRequestTypes.UploadFileType.Audio) {
            handleSetAudioData(fileData);
        }
    }

    return (
        <div className="audio-select-modal__container">
            <div className="audio-select-modal">
                <div className="audio-select-modal__close" onClick={onCloseModal}>
                    <Close scale={0.6} />
                </div>
                <UserStorageGrid
                    allowedFileTypes={[CFRequestTypes.UploadFileType.Audio]}
                    onFileSelected={handleFileSelected}
                    onFileUpload={handleSetFileData}
                />
            </div>
        </div>
    );
}
