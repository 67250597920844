import './audio-notification.scss';

import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Close } from '../../../common/components/icons/close';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const popSound = require('./pop.mp3');

export function AudioNotification() {
    const dismissed = window.localStorage.getItem('audio-notification');
    const audioRef1 = useRef<HTMLAudioElement>();
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        audioRef1.current.volume = 0.1;
        audioRef1.current.play();
        timeoutRef.current = setTimeout(() => {
            setIsVisible(true);
        }, 1300);
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    function handleClose() {
        window.localStorage.setItem('audio-notification', 'dismissed');
        setIsVisible(false);
    }

    const classes = classNames('audio-notification', {
        'audio-notification--visible': isVisible,
    });

    return (
        <>
            {!dismissed && (
                <div className={classes}>
                    <div className="audio-notification__close" onClick={handleClose}>
                        <Close scale={0.5} />
                    </div>
                    Pause!
                    <br />
                    {`Each letter in this round will make a pop noise when shown on-screen. Some web browsers block audio
                    on cetain websites by default so you might need to make sure the sound is enabled. If you see a
                    "muted" icon in the addres bar you can click on it and allow sound on this site.`}
                </div>
            )}
            <audio id="audio-pop-1" src={popSound} ref={audioRef1} />
        </>
    );
}
