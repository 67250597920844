enum UserStorage {
    'SPACE_LIMIT_REACHED' = 'SPACE_LIMIT_REACHED',
    'UPLOAD_ERROR' = 'UPLOAD_ERROR',
    'INVALID_STORAGE_PATH' = 'INVALID_STORAGE_PATH',
    'FILE_NOT_FOUND' = 'FILE_NOT_FOUND',
}

export const ErrorCode = {
    UserStorage,
};
