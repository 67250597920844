import './image-with-coordinates.scss';

import classNames from 'classnames';
import React from 'react';

import { Picture } from '../../../common/components/icons/picture';
import { useImageLoad } from '../common/use-image-load';
import { CoordinateMarker, CoordinateMarkerProps, CoordinateMarkerType } from './coordinate-marker';

export type ImageWithCoordinatesProps = {
    className?: string;
    coordinates: Array<
        Omit<CoordinateMarkerProps, 'style' | 'type'> & {
            type?: CoordinateMarkerType;
            key?: string;
            x: number;
            y: number;
        }
    >;
    imageQuestion?: string;
    onClick?: (x: number, y: number) => void;
};

export function ImageWithCoordinates({ className, coordinates, imageQuestion, onClick }: ImageWithCoordinatesProps) {
    const classes = classNames('image-with-coordinates', {
        [className]: className,
    });

    const { imageSrc, imageProps, imageErrored, imageLoaded } = useImageLoad(imageQuestion);

    function handleImageClick(e: React.MouseEvent<HTMLImageElement>) {
        e.preventDefault();
        if (imageLoaded) {
            const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
            const relativeX = e.clientX - left;
            const relativeY = e.clientY - top;
            const xPercent = parseFloat(((relativeX / width) * 100).toFixed(2));
            const yPercent = parseFloat(((relativeY / height) * 100).toFixed(2));
            onClick?.(xPercent, yPercent);
        }
    }

    return (
        <div className={classes}>
            <div className="image-with-coordinates__image-container">
                {imageSrc && !imageErrored ? (
                    <>
                        <img
                            {...imageProps}
                            className="image-with-coordinates__image"
                            src={imageSrc}
                            draggable={false}
                            onClick={handleImageClick}
                        />
                        <div
                            className="image-with-coordinates__coordinates"
                            style={onClick ? { pointerEvents: 'none' } : {}}
                        >
                            {coordinates.map((c, i) => {
                                return (
                                    <CoordinateMarker
                                        key={i}
                                        type={c.type}
                                        hoverText={c.hoverText}
                                        fillColor={c.fillColor}
                                        borderColor={c.borderColor}
                                        style={{
                                            position: 'absolute',
                                            left: `${c.x}%`,
                                            top: `${c.y}%`,
                                        }}
                                        className={c.className}
                                        scale={c.scale}
                                    />
                                );
                            })}
                        </div>
                    </>
                ) : (
                    <div className="image-with-coordinates__placeholder">
                        <Picture scale={2} />
                    </div>
                )}
            </div>
        </div>
    );
}
