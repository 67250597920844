import { RoundTypes } from '@house-of-games/common';

export const roundLabels: { [key in RoundTypes.RoundType]: string } = {
    // Buzzer Rounds
    [RoundTypes.RoundType.RhymeTime]: 'Rhyme Time',
    [RoundTypes.RoundType.BrokenKaraoke]: 'Broken Karaoke',
    [RoundTypes.RoundType.InTheName]: "It's All In The Name",
    [RoundTypes.RoundType.InCode]: 'This Round Is In Code',
    [RoundTypes.RoundType.ZToA]: 'Z To A',
    [RoundTypes.RoundType.GamesHouseOf]: 'Games House Of',
    [RoundTypes.RoundType.Elephant]: 'Elephant In The Room',
    [RoundTypes.RoundType.Roonerspisms]: 'Roonerspisms',
    [RoundTypes.RoundType.Backwards]: 'The Backwards Round',
    [RoundTypes.RoundType.OppositesAttract]: 'Opposites Attract',
    [RoundTypes.RoundType.KingJumble]: 'King Of The Jumble',
    [RoundTypes.RoundType.TwoInOne]: 'Two Clues In One',
    [RoundTypes.RoundType.PastTense]: 'A Blast From The Past Tense',
    // [RoundTypes.RoundType.InternetHistory]: 'InternetHistory',
    // [RoundTypes.RoundType.PopArt]: 'PopArt',                     Fun but longer
    // [RoundTypes.RoundType.FeelIt]: 'FeelIt',                     Not Doable Online
    // [RoundTypes.RoundType.TooComplicated]: 'TooComplicated',

    // Individual Games
    [RoundTypes.RoundType.CorrectionCentre]: 'Correction Centre',
    [RoundTypes.RoundType.MouseOfGames]: 'Mouse Of Games',
    // [RoundTypes.RoundType.InTheQuestion]: '',
    [RoundTypes.RoundType.TheAnswerIsnt]: "And The Answer Isn't",
    [RoundTypes.RoundType.CineNyms]: 'Cine-Nyms',
    [RoundTypes.RoundType.HighbrowLowbrow]: 'Highbrow Lowbrow',
    // [RoundTypes.RoundType.PenUltimate]: 'PenUltimate',
    // [RoundTypes.RoundType.WritersDayOff]: 'WritersDayOff',
    [RoundTypes.RoundType.HouseOfGamers]: 'House Of Gamers',
    [RoundTypes.RoundType.HoseOfGames]: 'Hose Of Games',

    // Pairs Games
    [RoundTypes.RoundType.DistinctlyAverage]: 'Distinctly Average',
    // [RoundTypes.RoundType.BuildYourOwn]: 'BuildYourOwn',         Tricky for this scenario
    [RoundTypes.RoundType.DimSums]: 'Dim Sums',
    // [RoundTypes.RoundType.ChronIllogical]: 'ChronIllogical',
    [RoundTypes.RoundType.YouCompleteMe]: 'You Complete Me',
    // [RoundTypes.RoundType.AllInTheDetails]: 'AllInTheDetails',
    // [RoundTypes.RoundType.VennFamous]: 'VennFamous',
    [RoundTypes.RoundType.YouSpellTerrible]: 'You Spell Terrible',
    [RoundTypes.RoundType.TotesEmoji]: 'Totes Emoji',
    // [RoundTypes.RoundType.TheTwoWrongies]: 'TheTwoWrongies',
    [RoundTypes.RoundType.ICompleteYou]: 'I Complete You',
    // [RoundTypes.RoundType.HausDerSpiele]: 'HausDerSpiele',       Fun but tricky
    [RoundTypes.RoundType.ZList]: 'The Z-List',

    // "Random" Games
    // [RoundTypes.RoundType.IsItMe]: 'IsItMe',
    // [RoundTypes.RoundType.NiceRound]: 'NiceRound',               Tricky
    // [RoundTypes.RoundType.WhatsTheQuestion]: 'WhatsTheQuestion', Tricky
    // [RoundTypes.RoundType.LuckyDip]: 'LuckyDip',
    [RoundTypes.RoundType.WhenTheySing]: "Win When They're Singing",
    // [RoundTypes.RoundType.NotSoNiceRound]: 'NotSoNiceRound',
    // [RoundTypes.RoundType.WhoGoesFirst]: 'WhoGoesFirst',

    // Tablet Games
    [RoundTypes.RoundType.FingerOnIt]: 'Put Your Finger On It',
    [RoundTypes.RoundType.WhereIsKazakhstan]: 'Where Is Kazakhstan?',
    [RoundTypes.RoundType.RichList]: 'The Rich List',
    [RoundTypes.RoundType.SizeMatters]: 'Size Matters',
    [RoundTypes.RoundType.TerribleDating]: "I'm Terrible At Dating",
    // [RoundTypes.RoundType.OutplayOsman]: 'OutplayOsman',         Not Possible Online

    // Final Round
    [RoundTypes.RoundType.AnswerSmash]: 'Answer Smash',
};
