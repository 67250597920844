import './scoreboard-answer.scss';

import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { Tooltip } from '../../../common/components/tooltip/tooltip';

type ScoreboardAnswerProps = {
    answer?: string;
    isAnswerSelected?: boolean;
    onClick?: (e: React.MouseEvent, answer: string) => void;
};

function doNothing() {
    // noop
}

export function ScoreboardAnswer({ answer, isAnswerSelected, onClick = doNothing }: ScoreboardAnswerProps) {
    const textElementRef = useRef<HTMLDivElement>();
    const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);

    function checkIsOverflowing() {
        return textElementRef.current && textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    }

    useEffect(() => {
        if (checkIsOverflowing()) {
            setIsTooltipDisabled(false);
        } else {
            setIsTooltipDisabled(true);
        }
    }, [answer]);

    const classes = classNames('scoreboard-answer-container', {
        'scoreboard-answer-container--selected': isAnswerSelected,
    });

    return (
        <Tooltip text={isTooltipDisabled ? undefined : answer} delayMs={30}>
            <div className={classes} onClick={(e) => onClick(e, answer)}>
                {answer ? (
                    <div ref={textElementRef} className="scoreboard-answer">
                        {answer}
                    </div>
                ) : (
                    <div className="scoreboard-answer-placeholder">Answer</div>
                )}
            </div>
        </Tooltip>
    );
}
