import './profile.scss';

import { dataValidators } from '@house-of-games/common';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
import { TextField } from '../../common/components/form-fields/text-field';
import { AppRoute } from '../../common/constants/routes';
import { useProfileContext } from '../auth-router/auth-context';

type ProfileProps = {
    onChangeName: (name: string) => Promise<void>;
};

export function Profile({ onChangeName }: ProfileProps) {
    const profile = useProfileContext();
    const [newName, setNewName] = useState(profile.displayName);

    return (
        <div className="profile page">
            <div className="header">
                <div className="box-header__container">
                    <Link draggable={false} to={AppRoute.Home}>
                        <div className="box-header__back-button-container">
                            <div className="box-header__back-button" />
                        </div>
                    </Link>
                    <h1 className="box-header">My Profile</h1>
                </div>
            </div>
            <div className="form__container">
                <div className="form">
                    <TextField
                        label="Name"
                        type="text"
                        maxLength={dataValidators.displayName.max}
                        validator={dataValidators.displayName.validate}
                        errorMessage={dataValidators.displayName.errorMessage}
                        isOutline
                        defaultValue={profile.displayName}
                        onChange={(n: string) => setNewName(n)}
                    />
                    <Button
                        label="Save"
                        disabled={newName === profile.displayName}
                        onClick={() => onChangeName(newName)}
                    />
                </div>
            </div>
        </div>
    );
}
