import { GameId, PrivateGame, SessionId } from './game';
import { GameState } from './game-state';
import { PlayerAnswer } from './player-answers';
import { Round, RoundType } from './round';

export type SignUpPayload = {
    email: string;
    name: string;
    password: string;
};

export type CreateGamePayload = {
    name: string;
    cpId?: GameId;
};

export type RequestSessionIdPayload = {
    gameId: GameId;
    preferredSessionId?: SessionId;
};

export type RequestSessionIdResponse = {
    gameId: GameId;
    sessionId: SessionId;
};

export type RenameGamePayload = {
    gameId: GameId;
    name: string;
};

export type GetGamePayload = {
    gameId: GameId;
};

export type SubmitRoundsPayload = {
    gameId: GameId;
    rounds: Array<Round>;
};

export type MoveRoundsPayload = {
    gameId: GameId;
    rounds: PrivateGame['rounds'];
};

export type DeleteRoundsPayload = {
    gameId: GameId;
    roundTypes: Array<RoundType>;
};

export type DeleteGamesPayload = {
    gameIds: Array<GameId>;
};

export type ClearPlayerPayload = {
    gameId: GameId;
    playerId: string;
};

export type ClearPlayersPayload = {
    gameId: GameId;
};

export type JoinGamePayload = {
    sessionId: GameId;
    uid: string;
    name: string;
};

export type BuzzPayload = {
    gameId: GameId;
    uid: string;
    timeStamp?: number;
};

export type AnswerPayload = {
    gameId: GameId;
    uid: string;
    roundType: RoundType;
    answer: PlayerAnswer;
};

export type SearchPayload = {
    searchTerm: string;
};

export enum RateState {
    'Liked' = 'Liked',
    'Disliked' = 'Disliked',
    'Neutral' = 'Neutral',
}

export type RatePayload = {
    publicUid: GameId;
    state: RateState;
};

export type MakePublicPayload = {
    gameId: GameId;
    isPublic: boolean;
};

export type GameStatePayload = {
    gameId: GameId;
    gameState: GameState;
};

export enum UploadFileType {
    'Audio' = 'Audio',
    'Image' = 'Image',
}

export type UploadTokenRequest = {
    type: UploadFileType;
};

export type UploadTokenResponse = {
    token: string;
    availableStorageBytes: number;
    filePath: string;
    fileName: string;
    pathSegments: {
        userId: string;
        fileType?: string;
        fileName: string;
    };
};

export type CreateFileUrlsRequest = {
    gameId: string;
};

export type CreateFileUrlsResponse = Record<string, string>;

export type VerifyCopyStorageRequest = {
    cpId: GameId;
};

export type VerifyCopyStorageResponse = {
    availableStorageBytes: number;
    requiredStorageBytes: number;
};
