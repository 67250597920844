import './create-answer-list.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';
import { FormEvent } from 'react';
import ExpandingTextArea from 'react-expanding-textarea';

import { Button } from '../../../common/components/buttons/buttons';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { TextArea } from '../common/text-area';
import { RoundProps } from '../create-round-component-map';

type AssociatedRounds =
    | RoundProps<RoundTypes.RichListRound>
    | RoundProps<RoundTypes.SizeMattersRound>
    | RoundProps<RoundTypes.ZListRound>;

export function CreateAnswerList({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: AssociatedRounds) {
    const { questions } = round;
    const definition = roundDefinitions[round.type].of;

    function handleNewQuestion() {
        createNewQuestion({ question: '', answers: [] });
    }

    function handleQuestionChange(index: number, question: string) {
        updateQuestion(index, { ...questions[index], question });
    }

    function handleAnswerChange(index: number, e: FormEvent<HTMLTextAreaElement>) {
        const text = e.currentTarget.value;

        if (text.match(/\r?\n/g) || text.match(/  /g)) {
            e.currentTarget.value = text.replace(/\r?\n/g, '').replace(/  /g, ' ');
            return;
        }

        const answerList = text.split(',');
        const last = answerList[answerList.length - 1].trim();
        const secondLast = answerList[answerList.length - 2]?.trim();
        const isTwoEmptyAnswersAtEnd = last === '' && secondLast === '';

        if (isTwoEmptyAnswersAtEnd) {
            const correctedAnswerList = answerList.slice(0, -1);
            e.currentTarget.value = createAnswerString(correctedAnswerList);
        }

        const isAnyAnswerInvalid = checkAnyAnswerInvalid(answerList);
        const isTooManyAnswers = answerList.length > definition.answers.length;
        if (isAnyAnswerInvalid || isTooManyAnswers) {
            const lastText = createAnswerString(questions[index].answers);
            e.currentTarget.value = lastText;
            return;
        }

        updateQuestion(index, { ...questions[index], answers: answerList });
    }

    function createAnswerString(answers: Array<string>) {
        return answers.join(',');
    }

    function checkAnyAnswerInvalid(answerList: Array<string>) {
        return answerList.some((a) => a.trim().length > definition.answers.of.length);
    }

    return (
        <div className="create-answer-list">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-answer-list-question__container">
                        <QuestionArrows
                            index={i}
                            numberOfQuestions={questions.length}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                        />
                        <div className="create-answer-list-question create-answer-list__text-area">
                            <TextArea
                                maxLength={definition.question.length}
                                defaultValue={q.question}
                                onChange={(question) => handleQuestionChange(i, question)}
                                placeholder="Enter your question here..."
                            />
                        </div>
                        <div className="create-answer-list-answer create-answer-list__text-area">
                            <div className="text-area__container">
                                <ExpandingTextArea
                                    className="text-area"
                                    value={createAnswerString(q.answers)}
                                    onChange={(e) => handleAnswerChange(i, e)}
                                />
                                <div className="text-area__counter">
                                    {q.answers.length}/{definition.answers.length}
                                </div>
                            </div>
                        </div>
                        <QuestionErase index={i} onRemove={removeQuestion} />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
