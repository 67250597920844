import { GameTypes, RoundTypes } from '@house-of-games/common';

import { PreviewDefinition, previewStringMap } from '../../common/round-maps/question-preview-string';
import { Scores } from './control';

export enum SlideType {
    Title = 'Title',
    BuzzerTest = 'BuzzerTest',
    NewRound = 'NewRound',
    NewCategory = 'NewCategory',
    Question = 'Question',
    Scores = 'Scores',
}

type TitleSlide = {
    slideType: SlideType.Title;
};

type BuzzerTestSlide = {
    slideType: SlideType.BuzzerTest;
};

type NewRoundSlide = {
    slideType: SlideType.NewRound;
    roundType: RoundTypes.RoundType;
    roundNumber: number;
    totalRounds: number;
    totalQuestions: number;
};

type NewCategorySlide = {
    slideType: SlideType.NewCategory;
    category: string;
};

type QuestionSlide = {
    slideType: SlideType.Question;
    roundType: RoundTypes.RoundType;
    question: RoundTypes.Round['questions'][number];
    previewString: Array<PreviewDefinition>;
    isBuzzerEnabled: boolean;
    questionNumber: number;
    totalQuestions: number;
    roundNumber: number;
    totalRounds: number;
};

type ScoresSlide = {
    slideType: SlideType.Scores;
    scores: Scores;
};

export type Slide = TitleSlide | BuzzerTestSlide | NewRoundSlide | NewCategorySlide | QuestionSlide | ScoresSlide;

function shouldUseActiveBuzzer(roundType: RoundTypes.RoundType) {
    return (
        roundType &&
        ![
            RoundTypes.RoundType.WhereIsKazakhstan,
            RoundTypes.RoundType.FingerOnIt,
            RoundTypes.RoundType.WhenTheySing,
        ].includes(roundType)
    );
}

export function generateSlides(rounds: GameTypes.PrivateGame['rounds'] = {}) {
    const slides: Array<Slide> = [{ slideType: SlideType.Title }, { slideType: SlideType.BuzzerTest }];

    const orderedRounds = Object.values(rounds).sort((a, b) => a.position - b.position);
    const totalRounds = orderedRounds.length;
    orderedRounds.forEach((round, i) => {
        const totalQuestions = round.questions.length;
        const roundNumber = i + 1;
        slides.push({
            slideType: SlideType.NewRound,
            roundType: round.type,
            roundNumber,
            totalRounds,
            totalQuestions,
        });

        let category: string;
        round.questions.forEach((q, j) => {
            const questionNumber = j + 1;
            if ('category' in q && q.category !== category) {
                category = q.category;
                slides.push({
                    slideType: SlideType.NewCategory,
                    category: category,
                });
            }

            slides.push({
                slideType: SlideType.Question,
                roundType: round.type,
                question: q,
                previewString: previewStringMap[round.type](q as any),
                isBuzzerEnabled: shouldUseActiveBuzzer(round.type),
                questionNumber,
                totalQuestions,
                roundNumber,
                totalRounds,
            });
        });
    });
    return slides;
}
