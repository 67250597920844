import { MutableRefObject, useEffect } from 'react';

const TEN_MINUTES_IN_MS = 1000 * 60 * 10;

export function useAutoSave(saveFunction: () => void, diffRef: MutableRefObject<boolean>) {
    useEffect(() => {
        const saveInterval = setInterval(() => {
            if (diffRef.current) {
                saveFunction();
            }
        }, TEN_MINUTES_IN_MS);

        return () => {
            clearInterval(saveInterval);
        };
    }, []);
}
