type BuzzerProps = {
    scale?: number;
};

export function Buzzer({ scale = 1 }: BuzzerProps) {
    return (
        <svg className="icon__buzzer" width={`${24 * scale}`} height={`${24 * scale}`} viewBox="0 0 24 24">
            <path d="M2.99,10.6l-0.86-0.52C3.27,8.2,4.9,6.69,6.86,5.71L7.3,6.61C5.52,7.5,4.03,8.88,2.99,10.6z M8.21,8.43L7.77,7.53c-1.61,0.81-2.95,2.05-3.88,3.6l0.86,0.52C5.57,10.26,6.77,9.15,8.21,8.43z M16.23,7.53l-0.45,0.89c1.44,0.72,2.64,1.83,3.47,3.22l0.86-0.52C19.19,9.58,17.84,8.34,16.23,7.53z M17.14,5.71L16.7,6.61C18.48,7.5,19.97,8.88,21,10.6l0.86-0.52C20.73,8.2,19.1,6.69,17.14,5.71z M19,16c0,0.12-0.01,0.24-0.02,0.36c-0.46,0.17-0.96,0.33-1.48,0.47v2.51l-0.39,0.09C15.48,19.81,13.76,20,12,20s-3.48-0.19-5.11-0.57L6.5,19.33v-2.51c-0.52-0.14-1.02-0.29-1.48-0.47C5.01,16.24,5,16.12,5,16c0-3.87,3.13-7,7-7S19,12.13,19,16z M16.5,17.04c-1.38,0.28-2.89,0.46-4.5,0.46s-3.12-0.17-4.5-0.46v1.49c2.89,0.61,6.11,0.61,9,0V17.04z" />
        </svg>
    );
}
