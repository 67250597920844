import { createContext, MutableRefObject, useContext } from 'react';

type StorageCache = MutableRefObject<Record<string, string>>;

// type StorageCacheContextType = [StorageCache, (key: string, value: string) => void, (keyToRemove: string) => void];
type StorageCacheContextType = {
    cache: StorageCache;
    set: (key: string, value: string) => void;
    delete: (keyToRemove: string) => void;
    load: (key: string) => Promise<string | undefined>;
};

const noop = () => {
    // noop
};

export const StorageCacheContext = createContext<StorageCacheContextType>({
    cache: { current: {} } as StorageCache,
    set: noop,
    delete: noop,
    load: async () => undefined,
});
export const useStorageCache = () => useContext(StorageCacheContext);
