import { GameTypes, RoundTypes } from '@house-of-games/common';
import { createContext, useContext } from 'react';

export type ClipboardState = {
    gameId: GameTypes.GameId;
    rounds: Array<RoundTypes.RoundType>;
};

export const ClipboardContext = createContext<ClipboardState>(null);
export const useClipboardContext = () => useContext(ClipboardContext);
