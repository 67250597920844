import './join.scss';

import { dataValidators, GameTypes } from '@house-of-games/common';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
import { TextField } from '../../common/components/form-fields/text-field';
import { useLoadingError } from '../../common/components/loading-error/loading-error';
import { LoadingSpinner } from '../../common/components/loading-error/loading-spinner';
import { Logo } from '../../common/components/logo/logo';
import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { Toast } from '../../common/components/toast/toast';
import { AppRoute } from '../../common/constants/routes';

type JoinProps = {
    isLoggedIn: boolean;
    initialSessionId: GameTypes.SessionId;
    handleJoinGame: (sessionId: GameTypes.SessionId, name: string) => Promise<void>;
};

export function Join({ isLoggedIn, initialSessionId, handleJoinGame }: JoinProps) {
    const nameFieldRef = useRef<TextField>();
    const sessionIdFieldRef = useRef<TextField>();

    const [name, setName, nameRef] = useRefState<string>(null);
    const [isNameValid, setIsNameValid] = useState(false);

    const [sessionId, setSessionId, sessionIdRef] = useRefState<GameTypes.SessionId>(initialSessionId);
    // const [gameId, setGameId, gameIdRef] = useRefState<string>(initialGameId);
    const [isSessionIdValid, setIsSessionIdValid] = useState(dataValidators.sessionId.validate(sessionId));

    const [isLoading, error, wrapper] = useLoadingError(false);

    const wrappedJoinGame = wrapper(() => handleJoinGame(sessionIdRef.current, nameRef.current));

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [isNameValid, isSessionIdValid]);

    const handleKeyUp = (e: KeyboardEvent) => {
        const shouldJoin = isNameValid && isSessionIdValid;
        if (e.key === 'Enter' && shouldJoin) {
            wrappedJoinGame();
        }
    };

    function handleNameChanged(name: string) {
        setIsNameValid(nameFieldRef.current.state.isValid);
        setName(name);
    }

    function handleSessionIdChanged(newSessionId: string) {
        setIsSessionIdValid(sessionIdFieldRef.current.state.isValid);
        setSessionId(newSessionId);
    }

    return (
        <>
            {isLoggedIn && (
                <Link draggable={false} to={AppRoute.Home} className="join__back-button">
                    <div className="box-header__back-button-container">
                        <div className="box-header__back-button" />
                    </div>
                </Link>
            )}
            <div className="join__logo">
                <Logo />
            </div>
            <div className="join__form">
                <div className="join__field">
                    <TextField
                        defaultValue={name}
                        ref={nameFieldRef}
                        label="Name"
                        type="text"
                        maxLength={dataValidators.displayName.max}
                        validator={dataValidators.displayName.validate}
                        errorMessage={dataValidators.displayName.errorMessage}
                        onChange={handleNameChanged}
                    />
                </div>
                <div className="join__field join__game-code">
                    <TextField
                        defaultValue={sessionId}
                        ref={sessionIdFieldRef}
                        label="Game Code"
                        type="text"
                        errorMessage={dataValidators.sessionId.errorMessage}
                        maxLength={dataValidators.sessionId.max}
                        validator={dataValidators.sessionId.validate}
                        onChange={handleSessionIdChanged}
                    />
                </div>
                <Button
                    className="join__button"
                    label="Join"
                    disabled={!isNameValid || !isSessionIdValid}
                    onClick={wrappedJoinGame}
                />
                {isLoading && <LoadingSpinner />}
                <Toast s={error?.message} />
            </div>
        </>
    );
}
