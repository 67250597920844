import './create-round.scss';

import { GameCrawler, GameTypes, RoundTypes, UserStorage } from '@house-of-games/common';
import { RichListRound, RoundType } from '@house-of-games/common/lib/types/round';
import classNames from 'classnames';
import deepEqual from 'deep-equal';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
import { Help } from '../../common/components/icons/help';
import { useLoadingError } from '../../common/components/loading-error/loading-error';
import { LoadingSpinner } from '../../common/components/loading-error/loading-spinner';
import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { Toast } from '../../common/components/toast/toast';
import { useStorageCache } from '../../common/components/user-storage/use-storage-cache';
import { AppRoute } from '../../common/constants/routes';
import { roundCreationInfo } from '../../common/round-maps/round-creation-info';
import { roundDescriptions } from '../../common/round-maps/round-descriptions';
import { roundLabels } from '../../common/round-maps/round-labels';
import { roundTips } from '../../common/round-maps/round-tips';
import { roundsLowerCaseMap } from '../../common/round-maps/rounds-lower-case-map';
import { without } from '../../utils/array';
import { FirebaseHelper } from '../../utils/firebase';
import { requestHandler } from '../../utils/request-handler';
import { usePrompt } from '../../utils/router-prompt-temp';
import { getCreateRoundComponent } from './create-round-component-map';
import { useAutoSave } from './useAutoSave';

type CreateRoundProps = {
    rounds: GameTypes.PrivateGame['rounds'];
};

type RouteParams = { gameId: GameTypes.GameId; roundType: RoundTypes.RoundType };

export function CreateRound<RoundType extends RoundTypes.RoundType>({ rounds }: CreateRoundProps) {
    const storageCache = useStorageCache();
    type AnyRound = RoundTypes.Round<RoundType>;
    const { gameId, roundType: roundPath } = useParams<RouteParams>();
    const savedRound = useMemo<AnyRound>(() => {
        const roundKey = roundsLowerCaseMap[roundPath.toLowerCase()];
        const matchedRound = rounds[roundKey];
        if (matchedRound && matchedRound.type === RoundTypes.RoundType[roundKey]) {
            return matchedRound as AnyRound;
        }
    }, [rounds]);

    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
    const [round, setRound] = useState(cloneDeep(savedRound));
    const [shouldAutoSave, setShouldAutoSave] = useState(false);
    const [isUnsavedChanges, setIsUnsavedChanges, isUnsavedChangesRef] = useRefState(false);
    useAutoSave(handleSave, isUnsavedChangesRef);
    const [isLoading, error, wrapper] = useLoadingError(false);
    const uniqueKey = useRef(Date.now());
    const objectPath = `${gameId}.rounds.${round.type}`;

    useEffect(() => {
        if (shouldAutoSave) {
            setShouldAutoSave(false);
            handleSave();
        }
    }, [shouldAutoSave]);

    useEffect(() => {
        setIsUnsavedChanges(checkIsRoundChanged(round));
    }, [round, rounds]);

    usePrompt('Are you sure you want to leave with unsaved changes?', isUnsavedChanges);

    if (!savedRound) {
        return <Navigate to={`${AppRoute.Create}/${gameId}`} replace />;
    }

    function shuffleKey() {
        uniqueKey.current = Date.now();
    }

    function preSave(): AnyRound {
        if ([RoundType.RichList, RoundType.SizeMatters, RoundType.ZList].includes(round.type)) {
            const newQuestions = (round as RichListRound).questions.map((q) => {
                return {
                    ...q,
                    answers: q.answers.filter((a) => Boolean(a?.trim())),
                };
            });
            const newRound = { ...round, questions: newQuestions };
            setRound(newRound);
            return newRound;
        }

        return round;
    }

    async function handleSave() {
        try {
            const newRound = preSave();
            if (checkIsRoundChanged(newRound)) {
                await requestHandler.submitRounds(gameId, [newRound]);
            }
        } catch (e: any) {
            console.error(e);
            const message = e?.nativeError || e.message;
            if (e?.httpStatusCode === 424 && message) {
                throw new Error(message);
            }

            throw new Error('An error occurred while saving the round');
        }
    }

    function handleCreateNewQuestion(emptyQuestion: AnyRound['questions'][0]) {
        const lastQuestion = round.questions[round.questions.length - 1];
        if (lastQuestion && 'category' in lastQuestion) {
            (emptyQuestion as typeof lastQuestion).category = lastQuestion.category;
        }

        setRound({
            ...round,
            questions: [...round.questions, emptyQuestion],
        });
    }

    async function handleRemoveQuestion(i: number) {
        shuffleKey();
        const newQuestions = [...round.questions];
        const deletedQuestion = newQuestions.splice(i, 1);
        const newRound = { ...round, questions: newQuestions };
        const fileMatches = GameCrawler.gameCrawler(
            { id: gameId, rounds: { [round.type]: { questions: deletedQuestion } } },
            UserStorage.userStorageMatcher,
        );
        const fileUris = Object.keys(fileMatches);
        if (fileUris.length > 0) {
            try {
                const deletedFiles = await FirebaseHelper.checkAndDeleteFiles(fileUris, [
                    `${gameId}.rounds.${round.type}.questions.${i}`,
                ]);
                deletedFiles.forEach((f) => {
                    storageCache.delete(f);
                });
            } catch {
                // noop
            }
        }
        setRound(newRound);
        if (fileUris.length > 0) {
            setShouldAutoSave(true);
        }
    }

    async function handleUpdateQuestion(i: number, q: AnyRound['questions'][0]) {
        const newQuestions = [...round.questions];
        const newQuestion = { ...newQuestions[i], ...q };
        newQuestions[i] = newQuestion;
        const newRound = { ...round, questions: newQuestions };

        const oldFiles = GameCrawler.gameCrawler(
            { id: gameId, rounds: { [round.type]: { type: round.type, questions: [round.questions[i]] } } },
            UserStorage.userStorageMatcher,
        );
        const oldFileUris = Object.keys(oldFiles);
        const newFiles = GameCrawler.gameCrawler(
            { id: gameId, rounds: { [round.type]: { type: round.type, questions: [newQuestion] } } },
            UserStorage.userStorageMatcher,
        );
        const newFileUris = Object.keys(newFiles);

        const removedFiles = without(oldFileUris, newFileUris);
        if (removedFiles.length > 0) {
            try {
                const deletedFiles = await FirebaseHelper.checkAndDeleteFiles(removedFiles, [
                    `${gameId}.rounds.${round.type}.questions.${i}`,
                ]);
                deletedFiles.forEach((f) => {
                    storageCache.delete(f);
                });
            } catch {
                // noop
            }
        }

        setRound(newRound);

        const addedFiles = without(newFileUris, oldFileUris);
        if (addedFiles.length > 0 || removedFiles.length > 0) {
            setShouldAutoSave(true);
        }
    }

    function handleShiftUp(i: number) {
        shuffleKey();
        const newQuestions = [...round.questions];
        newQuestions[i] = round.questions[i - 1];
        newQuestions[i - 1] = round.questions[i];
        setRound({ ...round, questions: newQuestions });
    }

    function handleShiftDown(i: number) {
        shuffleKey();
        const newQuestions = [...round.questions];
        newQuestions[i] = round.questions[i + 1];
        newQuestions[i + 1] = round.questions[i];
        setRound({ ...round, questions: newQuestions });
    }

    function checkIsRoundChanged(newRound: AnyRound) {
        try {
            // Stringify removes keys with `undefined` value so { x: undefined } can equal {}
            const savedSimple = JSON.parse(JSON.stringify(savedRound));
            const newSimple = JSON.parse(JSON.stringify(newRound));
            return !deepEqual(savedSimple, newSimple);
        } catch (e) {
            return !deepEqual(savedRound, newRound);
        }
    }

    const desscriptionClasses = classNames('create-round__description', {
        'create-round__description--visible': isDescriptionVisible,
    });

    const RoundComponent = getCreateRoundComponent<RoundType>(round.type);
    return (
        <div className="create-round page">
            <div className="header">
                <div className="box-header__container">
                    <Link draggable={false} to={`${AppRoute.Create}/${gameId}`}>
                        <div className="box-header__back-button-container">
                            <div className="box-header__back-button" />
                        </div>
                    </Link>
                    <div
                        className="create-round__help"
                        onMouseEnter={() => setIsDescriptionVisible(true)}
                        onMouseLeave={() => setIsDescriptionVisible(false)}
                    >
                        <Help />
                    </div>
                    <h1 className="box-header">{roundLabels[round.type]}</h1>
                    <div className={desscriptionClasses}>
                        {roundDescriptions[round.type]}
                        {roundCreationInfo[round.type] && (
                            <>
                                <br />
                                <br />
                                {roundCreationInfo[round.type]}
                            </>
                        )}
                    </div>
                    <div className="create-round-save-button__container">
                        <Button label="Save" disabled={!isUnsavedChanges} onClick={wrapper(handleSave)} />
                    </div>
                </div>
            </div>
            {roundTips[round.type] && <div className="box-header__tip-container">{roundTips[round.type]}</div>}
            <div className="content scrollable">
                {
                    <RoundComponent
                        objectPath={objectPath}
                        createNewQuestion={handleCreateNewQuestion}
                        removeQuestion={handleRemoveQuestion}
                        updateQuestion={handleUpdateQuestion}
                        shiftUp={handleShiftUp}
                        shiftDown={handleShiftDown}
                        round={round}
                        uniqueKey={uniqueKey.current}
                    />
                }
            </div>
            {isLoading && <LoadingSpinner />}
            {<Toast s={error && error.message} />}
        </div>
    );
}
