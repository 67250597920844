import './answer-when-they-sing.scss';

import { RoundTypes } from '@house-of-games/common';
import { useEffect, useRef } from 'react';

import { Toast } from '../../../common/components/toast/toast';
import { PlayerBuzzer } from '../../play/player-buzzer';
import { AnswerComponentProps } from '../answer-round-component-map';

export function AnswerWhenTheySing({ buzz, gameState, audioElementRef, changeAudioSource }: AnswerComponentProps) {
    const isClientSideAudioRef = useRef(false);
    const startTimeRef = useRef<number>();
    const fadeOutRef = useRef<number>();
    const audioFileUrl = useRef<string>();
    const isAudioLoadedRef = useRef(false);
    const isAudioTriggeredRef = useRef(false);
    const volumeIntervalRef = useRef<number>();
    const volumeIntervalCountRef = useRef<number>(0);
    const muteIntervalRef = useRef<number>();
    const isVolumeChangeBlocked = useRef(false);

    useEffect(() => {
        return () => {
            audioElementRef.current?.pause();
        };
    }, []);

    useEffect(() => {
        if (gameState.roundType !== RoundTypes.RoundType.WhenTheySing || !gameState.clientSideAudio) {
            isClientSideAudioRef.current = false;
            startTimeRef.current = undefined;
            fadeOutRef.current = undefined;
            audioFileUrl.current = undefined;
            audioElementRef.current.pause();
            return;
        }

        isClientSideAudioRef.current = gameState.clientSideAudio;
        fadeOutRef.current = gameState.fadeOut;

        if (gameState.fileUrl !== audioFileUrl.current) {
            isAudioLoadedRef.current = false;
            audioFileUrl.current = gameState.fileUrl;
            changeAudioSource(gameState.fileUrl).then((url) => {
                if (url !== audioFileUrl.current || !isClientSideAudioRef.current) {
                    return;
                }

                isAudioLoadedRef.current = true;
                if (isAudioTriggeredRef.current) {
                    handlePlay();
                }
            });
        }

        if (gameState.startTime && startTimeRef.current !== gameState.startTime) {
            startTimeRef.current = gameState.startTime;
            if (audioElementRef.current.playbackRate !== 0) {
                audioElementRef.current.pause();
                audioElementRef.current.currentTime = 0;
            }

            isAudioTriggeredRef.current = true;
            if (isAudioLoadedRef.current) {
                handlePlay();
            }
            return;
        }

        if (!gameState.startTime) {
            startTimeRef.current = undefined;
            isAudioTriggeredRef.current = false;
            if (audioElementRef.current) {
                audioElementRef.current.pause();
                audioElementRef.current.currentTime = 0;
            }
            return;
        }
    }, [gameState]);

    function handlePlay() {
        audioElementRef.current.muted = false;
        audioElementRef.current.play();
        audioElementRef.current.volume = 1;
        triggerVolumeInterval();
    }

    function triggerVolumeInterval() {
        if (isVolumeChangeBlocked.current) {
            triggerMuteFallback();
            return;
        }

        clearInterval(volumeIntervalRef.current);
        volumeIntervalCountRef.current = 0;
        volumeIntervalRef.current = window.setInterval(() => {
            if (!audioElementRef.current) {
                clearInterval(volumeIntervalRef.current);
                return;
            }

            if (audioElementRef.current.currentTime < fadeOutRef.current) {
                return;
            }

            if (volumeIntervalCountRef.current > 0 && audioElementRef.current.volume === 1) {
                isVolumeChangeBlocked.current = true;
                clearInterval(volumeIntervalRef.current);
                triggerMuteFallback();
                return;
            }

            const easeValue = Math.floor((audioElementRef.current.volume * 100) / 40) / 100;
            const newVolume = Math.max(audioElementRef.current.volume - (0.05 + easeValue), 0);
            if (newVolume === 0) {
                clearInterval(volumeIntervalRef.current);
            }

            volumeIntervalCountRef.current += 1;
            audioElementRef.current.volume = newVolume;
        }, 120);
    }

    function triggerMuteFallback() {
        const fadeOutDurationSeconds = 2;
        clearInterval(muteIntervalRef.current);
        muteIntervalRef.current = window.setInterval(() => {
            if (!audioElementRef.current) {
                clearInterval(muteIntervalRef.current);
                return;
            }

            if (audioElementRef.current.currentTime > fadeOutRef.current + fadeOutDurationSeconds * 0.95) {
                audioElementRef.current.muted = true;
                clearInterval(muteIntervalRef.current);
            }
        }, 120);
    }

    async function handleBuzz() {
        if (
            !isClientSideAudioRef.current ||
            !startTimeRef.current ||
            gameState.roundType !== RoundTypes.RoundType.WhenTheySing
        ) {
            return buzz();
        }

        if (audioElementRef.current?.currentTime < 1) {
            return;
        }

        const currentTimeMs = Math.round(audioElementRef.current.currentTime * 1000);
        const playerGuess = startTimeRef.current + currentTimeMs;
        return buzz(playerGuess);
    }

    return (
        <>
            <PlayerBuzzer
                buzz={handleBuzz}
                gameState={gameState}
                submit={() => Promise.resolve()}
                disableTextSubmit
                audioElementRef={audioElementRef}
                changeAudioSource={changeAudioSource}
            />
            <Toast s="Turn Your Sound Up For This Round!" className="answer-when-they-sing__sound-toast" />
        </>
    );
}
