import './image-question-input.scss';

import classNames from 'classnames';

import { ImageNoBorder } from '../../../common/components/icons/image-no-border';
import { Upload } from '../../../common/components/icons/upload';
import { useImageLoad } from './use-image-load';

type ImageSelectComponentProps = {
    imageQuestion: string;
    onClick: () => void;
};

export function ImageSelectComponent({ imageQuestion, onClick }: ImageSelectComponentProps) {
    const { imageErrored, imageLoaded, imageSrc, imageProps } = useImageLoad(imageQuestion);

    const classes = classNames('image-select__container', {
        'image-select__container--loaded': imageLoaded,
    });

    return (
        <div className={classes} onClick={onClick}>
            {imageSrc ? (
                <>
                    <div className="image-select__image-container">
                        {!imageLoaded && (
                            <div className="image-select__no-image">
                                <ImageNoBorder />
                            </div>
                        )}
                        {imageSrc && !imageErrored && (
                            <img {...imageProps} className="image-select__image" src={imageSrc} />
                        )}
                    </div>
                    <div className="image-select__edit-container">
                        <Upload />
                    </div>
                </>
            ) : (
                <div className="image-select__no-image">
                    <ImageNoBorder />
                </div>
            )}
        </div>
    );
}

// type ImageQuestionInputProps = {
//     imageUrl: string;
//     prompt?: string;
//     answerPlaceholder?: string;
//     maxQuestionLength?: number;
//     maxPromptLength?: number;
//     maxAnswerLength?: number;
//     onQuestionChanged: (newQuestion: string) => void;
//     onPromptChanged: (newPrompt: string) => void;
//     onAnswerChanged: (newAnswer: string) => void;
// };

// export function ImageQuestionInput({
//     question,
//     prompt,
//     answer,
//     index,
//     answerPlaceholder,
//     questionPlaceholder,
//     maxQuestionLength,
//     maxPromptLength,
//     maxAnswerLength,
//     onQuestionChanged,
//     onPromptChanged,
//     onAnswerChanged,
// }: ImageQuestionInputProps) {

//     return (
//         <ImageSelectComponent imageUrl={question} onClick={() => {}} />
//     );
// }
