import './question-answer.scss';

import { RoundTypes, UserStorage } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { useDynamicTextSize } from '../../game/use-dynamic-text-size';
import { UseMusicQuestionBuzzerReturnType } from '../../game/use-music-question-buzzer';
import { ImageQuestion } from './image-question';
import { MusicQuestion } from './music-question';

type QuestionAnswerComponentProps = Pick<RoundTypes.Question, 'question'> & {
    answer?: React.ReactNode;
    category?: string;
    className?: string;
    musicQuestionHelpers: UseMusicQuestionBuzzerReturnType;
};

export function QuestionAnswerComponent({
    question,
    answer,
    category,
    className,
    musicQuestionHelpers,
}: QuestionAnswerComponentProps) {
    const allText: Array<string> = [question];
    const textSizeClass = useDynamicTextSize(allText);

    const classes = classNames(`question-answer ${textSizeClass}`, {
        [className]: Boolean(className),
    });

    useEffect(() => {
        musicQuestionHelpers.preloadQuestions([question]).then(() => {
            musicQuestionHelpers.questionChanged(question);
        });
    }, []);

    function renderQuestion() {
        const questionType = UserStorage.getQuestionType(question);
        if (questionType === RoundTypes.QuestionType.Audio) {
            return <MusicQuestion />;
        }

        if (questionType === RoundTypes.QuestionType.Image) {
            return (
                <div className="question-answer__image">
                    <ImageQuestion question={question} />
                </div>
            );
        }

        return (
            <AnimateExpandContainer className="question-answer__question readable">
                <TextFlip className="question-answer__question-text" text={question} offset={300} />
            </AnimateExpandContainer>
        );
    }

    return (
        <div className={classes}>
            {category && (
                <div className="question-answer__category">
                    <div className="box-header">{category}</div>
                </div>
            )}
            {renderQuestion()}
            <div className="question-answer__answer-container">
                {answer && (
                    <AnimateExpandContainer className="question-answer__answer">
                        <TextFlip className="question-answer__answer-text" text={answer} offset={300} />
                    </AnimateExpandContainer>
                )}
            </div>
        </div>
    );
}

type AssociatedRounds =
    | SlideProps<RoundTypes.InTheNameRound>
    | SlideProps<RoundTypes.ElephantRound>
    | SlideProps<RoundTypes.OppositesAttractRound>
    | SlideProps<RoundTypes.TwoInOneRound>
    | SlideProps<RoundTypes.PastTenseRound>
    | SlideProps<RoundTypes.MouseOfGamesRound>
    | SlideProps<RoundTypes.HouseOfGamersRound>
    | SlideProps<RoundTypes.HoseOfGamesRound>
    | SlideProps<RoundTypes.DistinctlyAverageRound>
    | SlideProps<RoundTypes.YouSpellTerribleRound>
    | SlideProps<RoundTypes.TerribleDatingRound>;

export function QuestionAnswerSlide({ question, message, musicQuestionHelpers }: AssociatedRounds) {
    const [isRevealed, setIsRevealed] = useState(false);
    const category = useMemo(() => {
        if (question && 'category' in question) {
            return question.category;
        }
    }, []);

    useEffect(() => {
        switch (message.name) {
            case MessageName.reveal:
                setIsRevealed(true);
                break;
        }
    }, [message]);

    return (
        <QuestionAnswerComponent
            category={category}
            question={question.question}
            answer={isRevealed && question.answer}
            musicQuestionHelpers={musicQuestionHelpers}
        />
    );
}
