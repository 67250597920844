import './play-highbrow-lowbrow.scss';

import { RoundTypes } from '@house-of-games/common';
import { useEffect, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { useDynamicTextSize } from '../../game/use-dynamic-text-size';

export function PlayHighbrowLowbrow({ question, message }: SlideProps<RoundTypes.HighbrowLowbrowRound>) {
    const [isLowbrowVisible, setIsLowbrowVisible] = useState(false);
    const [isAnswerVisible, setIsAnswerVisible] = useState(false);
    const textSizeClass = useDynamicTextSize([question.highbrow, question.lowbrow]);

    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    function handleMessageReceived(msg: Message) {
        switch (msg.name) {
            case MessageName.reveal:
                if (isLowbrowVisible) {
                    setIsAnswerVisible(true);
                } else {
                    setIsLowbrowVisible(true);
                }
        }
    }

    return (
        <div className={`play-highbrow-lowbrow ${textSizeClass}`}>
            <div className="play-highbrow-lowbrow__question-container">
                <AnimateExpandContainer className="play-highbrow-lowbrow__question-animated-container">
                    <TextFlip className="play-highbrow-lowbrow__question" text={question.highbrow} offset={300} />
                </AnimateExpandContainer>
            </div>
            <div className="play-highbrow-lowbrow__answer-container">
                {isAnswerVisible && (
                    <AnimateExpandContainer className="play-highbrow-lowbrow__answer-animated-container">
                        <TextFlip className="play-highbrow-lowbrow__answer" text={question.answer} offset={300} />
                    </AnimateExpandContainer>
                )}
            </div>
            <div className="play-highbrow-lowbrow__question-container">
                {isLowbrowVisible && (
                    <AnimateExpandContainer className="play-highbrow-lowbrow__question-animated-container">
                        <TextFlip className="play-highbrow-lowbrow__question" text={question.lowbrow} offset={300} />
                    </AnimateExpandContainer>
                )}
            </div>
        </div>
    );
}
