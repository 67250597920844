import { RoundTypes, UserStorage } from '@house-of-games/common';
import { MutableRefObject, useEffect, useMemo, useRef } from 'react';

import { useStorageCache } from '../../common/components/user-storage/use-storage-cache';
import { Message, MessageName } from '../../common/constants/message';

export type UseMusicQuestionBuzzerReturnType = {
    audioElementRef: MutableRefObject<HTMLAudioElement>;
    preloadQuestions: (questions: Array<string>) => Promise<void>;
    questionChanged: (question: string) => boolean;
    setMessageHandler: (handler: (msg: Message) => void) => void;
    handleSlideChanged: () => void;
};

export function useMusicQuestionBuzzer(
    message: Message,
    onQuestionChanged: (isMusicQuestion: boolean) => void,
    audioElementRef: MutableRefObject<HTMLAudioElement>,
): UseMusicQuestionBuzzerReturnType {
    const storageCache = useStorageCache();
    const messageReceivedHandler = useRef<(msg: Message) => void>(defaultMessageReceivedHandler);
    const wasPlayingPreBuzz = useRef(false);

    useEffect(() => {
        messageReceivedHandler.current(message);
    }, [message]);

    function handlePreloadQuestions(questions: Array<string>) {
        const audioDownloadPromises = [];
        for (const question of questions) {
            const questionType = UserStorage.getQuestionType(question);
            if (questionType === RoundTypes.QuestionType.Audio) {
                audioDownloadPromises.push(storageCache.load(question));
            }
        }

        return Promise.all(audioDownloadPromises).then(() => {
            // noop
        });
    }

    function handleQuestionChanged(question: string): boolean {
        const questionType = UserStorage.getQuestionType(question);
        if (questionType !== RoundTypes.QuestionType.Audio) {
            onQuestionChanged(false);
            return false;
        }

        const audioSourceUrl = storageCache.cache.current[question];
        if (audioSourceUrl) {
            audioElementRef.current.src = audioSourceUrl;
            onQuestionChanged(true);
        }

        return true;
    }

    function handleSlideChanged() {
        audioElementRef.current.pause();
        if (audioElementRef.current.currentSrc) {
            audioElementRef.current.removeAttribute('currentSrc');
        }

        if (audioElementRef.current.src) {
            audioElementRef.current.removeAttribute('src');
        }

        messageReceivedHandler.current = defaultMessageReceivedHandler;
        wasPlayingPreBuzz.current = false;
        if (audioElementRef.current) {
            audioElementRef.current.muted = false;
            audioElementRef.current.volume = 1;
        }
    }

    function defaultMessageReceivedHandler(message: Message) {
        if (!audioElementRef.current.src) {
            return;
        }

        switch (message.name) {
            case MessageName.play:
                try {
                    audioElementRef.current?.play();
                } catch (e) {
                    console.warn('Caught Error while Triggering Play: ', e);
                }
                break;
            case MessageName.pause:
                audioElementRef.current?.pause();
                break;
            case MessageName.buzzed:
                if (audioElementRef.current) {
                    wasPlayingPreBuzz.current = !audioElementRef.current.paused;
                    audioElementRef.current.pause();
                }
                break;
            case MessageName.clear:
                if (wasPlayingPreBuzz.current) {
                    wasPlayingPreBuzz.current = false;
                    try {
                        audioElementRef.current?.play();
                    } catch (e) {
                        console.warn('Caught Error while Triggering Play: ', e);
                    }
                }
                break;
        }
    }

    function setMessageHandler(handler: (msg: Message) => void) {
        messageReceivedHandler.current = handler;
    }

    return useMemo(
        () => ({
            audioElementRef: audioElementRef,
            preloadQuestions: handlePreloadQuestions,
            questionChanged: handleQuestionChanged,
            setMessageHandler: setMessageHandler,
            handleSlideChanged: handleSlideChanged,
        }),
        [],
    );
}
