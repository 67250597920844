export enum RoundType {
    // Buzzer Rounds
    'RhymeTime' = 'RhymeTime',
    'BrokenKaraoke' = 'BrokenKaraoke',
    'InTheName' = 'InTheName',
    'InCode' = 'InCode',
    'ZToA' = 'ZToA',
    'GamesHouseOf' = 'GamesHouseOf',
    'Elephant' = 'Elephant',
    'Roonerspisms' = 'Roonerspisms',
    'Backwards' = 'Backwards',
    'OppositesAttract' = 'OppositesAttract',
    'KingJumble' = 'KingJumble',
    'TwoInOne' = 'TwoInOne',
    'PastTense' = 'PastTense',
    // 'InternetHistory' = 'InternetHistory',
    // 'PopArt' = 'PopArt',                     Fun but longer
    // 'FeelIt' = 'FeelIt',                     Not Doable Online
    // 'TooComplicated' = 'TooComplicated',

    // Individual Games
    'CorrectionCentre' = 'CorrectionCentre',
    'MouseOfGames' = 'MouseOfGames',
    // 'InTheQuestion' = 'InTheQuestion',
    'TheAnswerIsnt' = 'TheAnswerIsnt',
    'CineNyms' = 'CineNyms',
    'HighbrowLowbrow' = 'HighbrowLowbrow',
    // 'PenUltimate' = 'PenUltimate',
    // 'WritersDayOff' = 'WritersDayOff',
    'HouseOfGamers' = 'HouseOfGamers',
    'HoseOfGames' = 'HoseOfGames',

    // Pairs Games
    'DistinctlyAverage' = 'DistinctlyAverage',
    // 'BuildYourOwn' = 'BuildYourOwn',         Tricky for this scenario
    'DimSums' = 'DimSums',
    // 'ChronIllogical' = 'ChronIllogical',
    'YouCompleteMe' = 'YouCompleteMe',
    // 'AllInTheDetails' = 'AllInTheDetails',
    // 'VennFamous' = 'VennFamous',
    'YouSpellTerrible' = 'YouSpellTerrible',
    'TotesEmoji' = 'TotesEmoji',
    // 'TheTwoWrongies' = 'TheTwoWrongies',
    'ICompleteYou' = 'ICompleteYou',
    // 'HausDerSpiele' = 'HausDerSpiele',       Fun but tricky
    'ZList' = 'ZList',

    // "Random" Games
    // 'IsItMe' = 'IsItMe',
    // 'NiceRound' = 'NiceRound',               Tricky
    // 'WhatsTheQuestion' = 'WhatsTheQuestion', Tricky
    // 'LuckyDip' = 'LuckyDip',
    'WhenTheySing' = 'WhenTheySing', // Fun but requires audio storage
    // 'NotSoNiceRound' = 'NotSoNiceRound',
    // 'WhoGoesFirst' = 'WhoGoesFirst',

    // Tablet Games
    'FingerOnIt' = 'FingerOnIt',
    'WhereIsKazakhstan' = 'WhereIsKazakhstan',
    'RichList' = 'RichList',
    'SizeMatters' = 'SizeMatters',
    'TerribleDating' = 'TerribleDating',
    // 'OutplayOsman' = 'OutplayOsman',         Not Possible Online

    // Final Round
    'AnswerSmash' = 'AnswerSmash',
}

export enum QuestionType {
    'Text' = 'Text',
    'Audio' = 'Audio',
    'Image' = 'Image',
}

export type Question<T = string> = {
    question?: T;
    answer?: string;
    prompt?: string;
};

export interface BaseRound<category extends RoundType> {
    type: category;
    position: number;
    questions: Array<unknown>;
    isBuzzerRound: boolean;
}

export interface RhymeTimeRound extends BaseRound<RoundType.RhymeTime> {
    questions: Array<{ 0: Question; 1: Question }>;
}

export enum MathOperator {
    'Add' = 'Add',
    'Subtract' = 'Subtract',
    'Multiply' = 'Multiply',
    'Divide' = 'Divide',
}

type DimSumsEquationPart = keyof DimSumsRound['questions'][number]['questions'];
export interface DimSumsRound extends BaseRound<RoundType.DimSums> {
    questions: Array<{
        questions: {
            0?: {
                question?: string;
                answer?: number;
            };
            1?: {
                question?: string;
                answer?: number;
            };
            2?: {
                question?: string;
                answer?: number;
            };
            3?: {
                question?: string;
                answer?: number;
            };
        };
        equation: {
            firstTerm?: DimSumsEquationPart;
            operator?: MathOperator;
            secondTerm?: DimSumsEquationPart;
        };
    }>;
}

export type BrokenKaraokeCharacter = {
    character?: string;
    delayMs?: number;
};

export interface BrokenKaraokeRound extends BaseRound<RoundType.BrokenKaraoke> {
    questions: Array<{
        song?: string;
        artist?: string;
        year?: string;
        lines: {
            [lineNumber: number]: Array<BrokenKaraokeCharacter>;
        };
    }>;
}

export interface WhenTheySingRound extends BaseRound<RoundType.WhenTheySing> {
    questions: Array<{
        question?: string;
        answer?: number;
        fadeOut?: number;
        title?: string;
    }>;
}

export interface InTheNameRound extends BaseRound<RoundType.InTheName> {
    questions: Array<Question & { category?: string }>;
}

export interface InCodeRound extends BaseRound<RoundType.InCode> {
    questions: Array<Question & { category?: string }>;
}

export interface ZToARound extends BaseRound<RoundType.ZToA> {
    questions: Array<Question<{ 0?: string; 1?: string; 2?: string }>>;
}

export interface GamesHouseOfRound extends BaseRound<RoundType.GamesHouseOf> {
    questions: Array<Question>;
}

export interface ElephantRound extends BaseRound<RoundType.Elephant> {
    questions: Array<Question & { category?: string }>;
}

export interface RoonerspismsRound extends BaseRound<RoundType.Roonerspisms> {
    questions: Array<{ 0: Question; 1: Question }>;
}

export interface BackwardsRound extends BaseRound<RoundType.Backwards> {
    questions: Array<Question>;
}

export interface OppositesAttractRound extends BaseRound<RoundType.OppositesAttract> {
    questions: Array<Question & { category?: string }>;
}

export interface KingJumbleRound extends BaseRound<RoundType.KingJumble> {
    questions: Array<{ 0: Question; 1: Question }>;
}

export interface TwoInOneRound extends BaseRound<RoundType.TwoInOne> {
    questions: Array<Question & { category?: string }>;
}

export interface PastTenseRound extends BaseRound<RoundType.PastTense> {
    questions: Array<Question>;
}

export interface CorrectionCentreRound extends BaseRound<RoundType.CorrectionCentre> {
    questions: Array<Question>;
}

export interface MouseOfGamesRound extends BaseRound<RoundType.MouseOfGames> {
    questions: Array<Question>;
}

export interface TheAnswerIsntRound extends BaseRound<RoundType.TheAnswerIsnt> {
    questions: Array<{
        question?: string;
        fakes: { 0: string; 1: string; 2: string };
        answer?: string;
    }>;
}

// export interface InTheQuestionRound extends BaseRound<RoundType.InTheQuestion> {
//     questions: Array<Question & { category?: string }>;
// }

export interface CineNymsRound extends BaseRound<RoundType.CineNyms> {
    questions: Array<Question & { originalQuote?: string }>;
}

export interface HighbrowLowbrowRound extends BaseRound<RoundType.HighbrowLowbrow> {
    questions: Array<{ highbrow?: string; lowbrow?: string; answer?: string }>;
}

export interface HouseOfGamersRound extends BaseRound<RoundType.HouseOfGamers> {
    questions: Array<Question>;
}

export interface HoseOfGamesRound extends BaseRound<RoundType.HoseOfGames> {
    questions: Array<Question>;
}

export interface DistinctlyAverageRound extends BaseRound<RoundType.DistinctlyAverage> {
    questions: Array<Question>;
}

// export interface DimSumsOption {
//     clue?: string;
//     value?: number;
//     correct?: boolean;
// }

// export enum DimSumsOperator {
//     'Add' = 'Add',
//     'Subtract' = 'Subtract',
//     'Multiply' = 'Multiply',
//     'Divide' = 'Divide',
// }

// export interface DimSumsRound extends BaseRound<RoundType.DimSums> {
//     questions: Array<{
//         options: [DimSumsOption, DimSumsOption, DimSumsOption, DimSumsOption];
//         operator: DimSumsOperator;
//     }>;
// }

export interface YouCompleteMeRound extends BaseRound<RoundType.YouCompleteMe> {
    questions: Array<Question>;
}

export interface YouSpellTerribleRound extends BaseRound<RoundType.YouSpellTerrible> {
    questions: Array<Question>;
}

export interface TotesEmojiRound extends BaseRound<RoundType.TotesEmoji> {
    questions: Array<Question & { category?: string; author?: string }>;
}

export interface ICompleteYouRound extends BaseRound<RoundType.ICompleteYou> {
    questions: Array<Question>;
}

export interface FingerOnItRound extends BaseRound<RoundType.FingerOnIt> {
    questions: Array<{
        question?: string;
        image?: string;
        answer?: {
            x: number;
            y: number;
        };
    }>;
}

export interface WhereIsKazakhstanRound extends BaseRound<RoundType.WhereIsKazakhstan> {
    questions: Array<{
        question?: string;
        image?: string;
        answer?: {
            x: number;
            y: number;
        };
    }>;
}

export interface RichListRound extends BaseRound<RoundType.RichList> {
    questions: Array<{
        question?: string;
        answers: Array<string>;
    }>;
}

export interface SizeMattersRound extends BaseRound<RoundType.SizeMatters> {
    questions: Array<{
        question?: string;
        answers: Array<string>;
    }>;
}

export interface ZListRound extends BaseRound<RoundType.ZList> {
    questions: Array<{
        question?: string;
        answers: Array<string>;
    }>;
}

export interface TerribleDatingRound extends BaseRound<RoundType.TerribleDating> {
    questions: Array<Question>;
}

export interface AnswerSmashRound extends BaseRound<RoundType.AnswerSmash> {
    questions: Array<Question & { category?: string; image?: string }>;
}

type RoundsMap = {
    [RoundType.RhymeTime]: RhymeTimeRound;
    [RoundType.BrokenKaraoke]: BrokenKaraokeRound;
    [RoundType.InTheName]: InTheNameRound;
    [RoundType.InCode]: InCodeRound;
    [RoundType.ZToA]: ZToARound;
    [RoundType.GamesHouseOf]: GamesHouseOfRound;
    [RoundType.Elephant]: ElephantRound;
    [RoundType.Roonerspisms]: RoonerspismsRound;
    [RoundType.Backwards]: BackwardsRound;
    [RoundType.OppositesAttract]: OppositesAttractRound;
    [RoundType.KingJumble]: KingJumbleRound;
    [RoundType.TwoInOne]: TwoInOneRound;
    [RoundType.PastTense]: PastTenseRound;
    [RoundType.CorrectionCentre]: CorrectionCentreRound;
    [RoundType.MouseOfGames]: MouseOfGamesRound;
    // [RoundType.InTheQuestion]: InTheQuestionRound;
    [RoundType.TheAnswerIsnt]: TheAnswerIsntRound;
    [RoundType.CineNyms]: CineNymsRound;
    [RoundType.HighbrowLowbrow]: HighbrowLowbrowRound;
    [RoundType.HouseOfGamers]: HouseOfGamersRound;
    [RoundType.HoseOfGames]: HoseOfGamesRound;
    [RoundType.DistinctlyAverage]: DistinctlyAverageRound;
    [RoundType.DimSums]: DimSumsRound;
    [RoundType.YouCompleteMe]: YouCompleteMeRound;
    [RoundType.YouSpellTerrible]: YouSpellTerribleRound;
    [RoundType.TotesEmoji]: TotesEmojiRound;
    [RoundType.ICompleteYou]: ICompleteYouRound;
    [RoundType.FingerOnIt]: FingerOnItRound;
    [RoundType.WhereIsKazakhstan]: WhereIsKazakhstanRound;
    [RoundType.RichList]: RichListRound;
    [RoundType.SizeMatters]: SizeMattersRound;
    [RoundType.ZList]: ZListRound;
    [RoundType.TerribleDating]: TerribleDatingRound;
    [RoundType.AnswerSmash]: AnswerSmashRound;
    [RoundType.WhenTheySing]: WhenTheySingRound;
};

export type Round<T extends keyof RoundsMap = RoundType> = RoundsMap[T];
