import './dynamic-text-size.scss';

// const short_string = 40;
// const medium_string = 80;
const long_string = 200;
const assumed_paragraph = 40;

// const size_xl = 4;
// const size_l = 3.5;
// const size_m = 3;
// const size_s = 2.5;
// const size_xs = 2;

export function useDynamicTextSize(textArr: Array<string>): string {
    function getSizeFromCharacterNumber(numberOfCharacters: number) {
        if (numberOfCharacters >= long_string * 3) {
            return 's';
        } else if (numberOfCharacters >= long_string * 2) {
            return 'm';
        } else if (numberOfCharacters >= long_string) {
            return 'l';
        } else {
            return 'xl';
        }
    }

    function getNumberOfCharacters() {
        let numberOfCharacters = textArr.reduce((acc: number, cur: string) => {
            return (acc += cur?.length || 0);
        }, 0);

        numberOfCharacters += (textArr.length - 1) * assumed_paragraph;
        return numberOfCharacters;
    }

    const numberOfCharacters = getNumberOfCharacters();
    const textSize = getSizeFromCharacterNumber(numberOfCharacters);
    return `dynamic-fs-${textSize}`;
}
