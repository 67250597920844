export enum AppRoute {
    'Home' = '/',
    'Create' = '/create',
    'Control' = '/control',
    'Game' = '/game',
    'Login' = '/login',
    'Play' = '/play',
    'Verify' = '/verify',
    'Search' = '/search',
    'Storage' = '/storage',
    'Faq' = '/faq',
    'Profile' = '/profile',
}

export enum LoggedOutRoutes {
    'Home' = '/',
    'LogIn' = '/log-in',
    'Play' = '/play',
}
