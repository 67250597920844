import './image-question.scss';

import { UserStorage } from '@house-of-games/common';
import { useEffect, useMemo, useRef } from 'react';
import { ReactFitty } from 'react-fitty';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { useStorageCache } from '../../../common/components/user-storage/use-storage-cache';

type ImageQuestionProps = {
    question: string;
    answer?: string;
};

export function ImageQuestion({ question, answer }: ImageQuestionProps) {
    const storageCache = useStorageCache();
    const visibilityTimeout = useRef<number>();
    const imageRef = useRef<HTMLImageElement>();

    useEffect(() => {
        visibilityTimeout.current = window.setTimeout(() => {
            if (imageRef.current) {
                imageRef.current?.classList.add('image-question__image--visible');
            }
        }, 800);

        return () => {
            clearTimeout(visibilityTimeout.current);
        };
    }, []);

    function renderInnerComponent() {
        if (answer) {
            return (
                <div className="image-question__answer">
                    <ReactFitty className="fitty-container" wrapText minSize={48}>
                        {answer}
                    </ReactFitty>
                </div>
            );
        }

        const imageSrc = UserStorage.checkIsValidUri(question) ? storageCache.cache.current[question] : question;
        return <img ref={imageRef} className="image-question__image" src={imageSrc} />;
    }

    const innerComponent = useMemo(renderInnerComponent, [answer]);
    return (
        <AnimateExpandContainer className="image-question">
            <TextFlip text={innerComponent} />
        </AnimateExpandContainer>
    );
}
