import './create-when-they-sing.scss';

import { CFRequestTypes, roundDefinitions, RoundTypes } from '@house-of-games/common';
import { WhenTheySingRound } from '@house-of-games/common/lib/types/round';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { BasicAudioPlayer } from '../../../common/components/basic-audio-player/basic-audio-player';
import { Button } from '../../../common/components/buttons/buttons';
import { TextField } from '../../../common/components/form-fields/text-field';
import { Music } from '../../../common/components/icons/music';
import { Trim } from '../../../common/components/icons/trim';
import { Upload } from '../../../common/components/icons/upload';
import { LoadingSpinner } from '../../../common/components/loading-error/loading-spinner';
import { AudioSelectModal } from '../../../common/components/user-storage/audio-select-modal';
import { AudioData, useFileDrop } from '../../../common/components/user-storage/use-file-drop';
import { useStorageCache } from '../../../common/components/user-storage/use-storage-cache';
import { AppError } from '../../../utils/app-error';
import { FirebaseHelper } from '../../../utils/firebase';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { RoundProps } from '../create-round-component-map';

type WhenTheySingQuestionProps = {
    path: string;
    question?: string;
    answer?: number;
    fadeOut?: number;
    title?: string;
    updateQuestion: (question: WhenTheySingRound['questions'][number]) => void;
};

const definition = roundDefinitions[RoundTypes.RoundType.WhenTheySing].of;

function WhenTheySingQuestion({ question, answer, fadeOut, title, updateQuestion }: WhenTheySingQuestionProps) {
    const storageCache = useStorageCache();
    const [isQuestionLoaded, setIsQuestionLoaded] = useState(false);
    const [isFileSelecting, setIsFileSelecting] = useState(false);
    const isPreviewRef = useRef(false);
    const [showTimer, setShowTimer] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(0);
    const audioRef = useRef<HTMLAudioElement>();
    const volumeIntervalRef = useRef<number>();
    const positionIntervalRef = useRef<number>();
    const overwriteFadeOut = useRef<{ value: string }>({ value: undefined });
    const overwriteAnswer = useRef<{ value: string }>({ value: undefined });
    const { isDragging, isDraggingInvalid, isFileProcessing, fileDataRef, dropEventListeners } = useFileDrop(
        [CFRequestTypes.UploadFileType.Audio],
        () => setIsFileSelecting(true),
    );

    function handleQuestionChanged(newQ: string) {
        const newQuestion = { question: newQ, answer, fadeOut, title };
        updateQuestion(newQuestion);
    }

    function handleTitleChanged(newTitle: string) {
        const newQuestion = { question, answer, fadeOut, title: newTitle };
        updateQuestion(newQuestion);
    }

    function handleFadeOutChanged(newFadeOut: string) {
        const value = parseFloat(newFadeOut);
        const newQuestion = { question, answer, title, fadeOut: isNaN(value) ? undefined : value };
        updateQuestion(newQuestion);
    }

    function handleAnswerChanged(newAnswer: string) {
        const value = parseFloat(newAnswer);
        const newQuestion = { question, fadeOut, title, answer: isNaN(value) ? undefined : value };
        updateQuestion(newQuestion);
    }

    function handleTrimFadeOut() {
        const fadeOutPosition = currentPosition.toFixed(2);
        overwriteFadeOut.current = { value: fadeOutPosition };
        handleFadeOutChanged(fadeOutPosition);
    }

    function handleTrimAnswer() {
        const answerPosition = currentPosition.toFixed(2);
        overwriteAnswer.current = { value: answerPosition };
        handleAnswerChanged(answerPosition);
    }

    async function loadQuestion() {
        if (!question) {
            return;
        }

        try {
            await storageCache.load(question);
        } catch (e) {
            if (e instanceof AppError && e.code === FirebaseHelper.ErrorCodes.FILE_NOT_FOUND) {
                handleQuestionChanged('');
            }
        }

        setIsQuestionLoaded(true);
    }

    useEffect(() => {
        loadQuestion();
    }, [question]);

    function handleFileSaved(fileKey: string) {
        setCurrentPosition(0);
        setIsQuestionLoaded(true);
        handleQuestionChanged(fileKey);
        setIsFileSelecting(false);
    }

    function handleAudioEnded() {
        setShowTimer(false);
        clearInterval(positionIntervalRef.current);
    }

    function handleClickAudioPlayerPlay() {
        isPreviewRef.current = false;
    }

    function handleAduioPlayerPlay() {
        setShowTimer(true);
        triggerPositionInterval();
        audioRef.current.volume = 1;
    }

    function handlePreview() {
        isPreviewRef.current = true;
        setShowTimer(false);
        triggerPlayAudio(0);
    }

    function triggerPlayAudio(startPosition?: number) {
        triggerPositionInterval();
        if (audioRef.current) {
            if (startPosition !== undefined) {
                audioRef.current.currentTime = startPosition;
            }
            audioRef.current.volume = 1;
            audioRef.current.play();
        }
    }

    function triggerPositionInterval() {
        clearInterval(positionIntervalRef.current);
        positionIntervalRef.current = window.setInterval(() => {
            if (!audioRef?.current || audioRef.current?.paused) {
                clearInterval(positionIntervalRef.current);
            }
            setCurrentPosition(audioRef?.current.currentTime);
        }, 77);
    }

    function handlePositionChanged() {
        if (
            isPreviewRef.current &&
            !Boolean(volumeIntervalRef.current) &&
            audioRef.current?.volume !== 0 &&
            audioRef.current?.currentTime >= fadeOut
        ) {
            volumeIntervalRef.current = window.setInterval(() => {
                if (!audioRef.current) {
                    clearInterval(volumeIntervalRef.current);
                    volumeIntervalRef.current = undefined;
                    return;
                }

                const easeValue = Math.floor((audioRef.current.volume * 100) / 40) / 100;
                const newVolume = Math.max(audioRef.current.volume - (0.05 + easeValue), 0);
                if (newVolume === 0) {
                    clearInterval(volumeIntervalRef.current);
                    volumeIntervalRef.current = undefined;
                }
                audioRef.current.volume = newVolume;
            }, 120);
        }
    }

    function renderIcon() {
        if (isFileProcessing) {
            return <LoadingSpinner />;
        }

        if (!question || isDragging) {
            return <Upload scale={7} isInvalid={isDraggingInvalid} />;
        }

        if (!isQuestionLoaded) {
            return <LoadingSpinner />;
        }

        const text = (answer ? Math.min(currentPosition, answer) : currentPosition).toFixed(2);

        const iconClassName = classNames('music-icon', {
            'music-icon--hidden': showTimer,
        });
        const textClassName = classNames('music-text', {
            'music-text--hidden': !showTimer,
            'music-text--answer': answer && currentPosition >= answer,
        });

        return (
            <div className="when-they-sing-question__music">
                <div className={iconClassName}>
                    <Music scale={1.5} />
                </div>
                <div className={textClassName}>{text}s</div>
            </div>
        );
    }

    const iconClassName = classNames('when-they-sing-question__icon', {
        'when-they-sing-question__icon--dragging': isDragging,
    });

    function getDefaultNumberText(n: number): string {
        if (n !== undefined) {
            return `${n}`;
        }
    }

    return (
        <div className="when-they-sing-question__container">
            {isFileSelecting && (
                <AudioSelectModal
                    audioData={fileDataRef.current as AudioData}
                    onCloseModal={() => setIsFileSelecting(false)}
                    onFileSelected={handleFileSaved}
                />
            )}
            {isQuestionLoaded && (
                <audio
                    ref={audioRef}
                    src={storageCache.cache.current[question]}
                    onTimeUpdate={handlePositionChanged}
                    onEnded={handleAudioEnded}
                />
            )}
            <div className="when-they-sing-question__input-title">
                <TextField
                    className="textfield__title"
                    label="Title/Artist"
                    type="text"
                    isOutline
                    maxLength={definition.title.length}
                    defaultValue={title}
                    onChange={handleTitleChanged}
                />
            </div>
            <div className="when-they-sing-question">
                <div className={iconClassName}>
                    <div
                        className="when-they-sing-question__icon-container"
                        onClick={() => setIsFileSelecting(true)}
                        {...dropEventListeners}
                    >
                        {renderIcon()}
                    </div>
                    <BasicAudioPlayer
                        audioRef={audioRef}
                        disabled={!question}
                        onPlay={handleAduioPlayerPlay}
                        onClickPlay={handleClickAudioPlayerPlay}
                    />
                </div>
                <div className="when-they-sing-question__inputs">
                    <div className="when-they-sing-question__input-fade-out">
                        <div className="when-they-sing-question__trim-fade-out" onClick={handleTrimFadeOut}>
                            <Trim scale={1.3} />
                        </div>
                        <TextField
                            className="textfield__fade-out"
                            label="Fade Out (seconds)"
                            type="number"
                            isOutline
                            maxLength={5}
                            minValue={0}
                            maxValue={Math.max(
                                (answer ?? (audioRef.current?.duration || definition.fadeOut.maxValue)) - 1,
                                0,
                            )}
                            defaultValue={getDefaultNumberText(fadeOut)}
                            onChange={handleFadeOutChanged}
                            overwrite={overwriteFadeOut.current}
                        />
                    </div>
                    <div className="when-they-sing-question__input-answer">
                        <div className="when-they-sing-question__trim-answer" onClick={handleTrimAnswer}>
                            <Trim scale={1.3} />
                        </div>
                        <TextField
                            className="textfield__answer"
                            label="Answer (seconds)"
                            type="number"
                            maxLength={5}
                            minValue={fadeOut ? fadeOut + 1 : 1}
                            maxValue={audioRef.current?.duration || definition.answer.maxValue}
                            isOutline
                            defaultValue={getDefaultNumberText(answer)}
                            onChange={handleAnswerChanged}
                            overwrite={overwriteAnswer.current}
                        />
                    </div>
                    <div className="when-they-sing-question__button-container">
                        <Button label="Preview With Fade Out" disabled={!question} onClick={handlePreview} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function CreateWhenTheySing({
    objectPath,
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: RoundProps<RoundTypes.WhenTheySingRound>) {
    const { questions } = round;
    const basePath = `${objectPath}.questions`;

    function handleNewQuestion() {
        createNewQuestion({});
    }

    return (
        <div className="create-when-they-sing">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-when-they-sing-question__container">
                        <QuestionArrows
                            index={i}
                            numberOfQuestions={questions.length}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                        />
                        <WhenTheySingQuestion
                            path={`${basePath}.${i}.question`}
                            question={q.question}
                            answer={q.answer}
                            fadeOut={q.fadeOut}
                            title={q.title}
                            updateQuestion={(q) => updateQuestion(i, q)}
                        />
                        <QuestionErase index={i} onRemove={removeQuestion} />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
