import { useCallback, useEffect, useState } from 'react';

type ErrorWithCode = Error & {
    code?: string;
};

type LoadingResult = {
    didTry: boolean;
    didError: boolean;
};

type OptionalArgsFn = (() => any) | ((...args: Array<any>) => any);
type LoadingErrorEffect = [
    boolean,
    ErrorWithCode,
    (cb: OptionalArgsFn) => (...args: Array<any>) => Promise<LoadingResult>,
];

export function useLoadingError(initialLoadingState: boolean): LoadingErrorEffect {
    const [isLoading, setIsLoading] = useState<boolean>(initialLoadingState);
    const [error, setError] = useState<ErrorWithCode>(undefined);
    const [isMounted, setIsMounted] = useState<boolean>(true);

    useEffect(() => {
        return () => setIsMounted(false);
    }, []);

    const asyncFunctionHandler = useCallback(
        (asyncCall: OptionalArgsFn) => {
            return async function <T>(...extraArgs: Array<T>) {
                const loadingResult = {
                    didTry: false,
                    didError: false,
                };

                if (!isLoading) {
                    loadingResult.didTry = true;
                    setIsLoading(true);
                    setError(undefined);
                    try {
                        await asyncCall(...extraArgs);
                    } catch (e) {
                        loadingResult.didError = true;
                        if (isMounted) {
                            setError(e as ErrorWithCode);
                            setIsLoading(false);
                        }
                    }
                    if (isMounted) {
                        setIsLoading(false);
                    }
                }

                return loadingResult;
            };
        },
        [isLoading, isMounted],
    );

    return [isLoading, error, asyncFunctionHandler];
}

type LoadingErrorProps = {
    isLoading: boolean;
    error: string;
};

export function LoadingError({ isLoading, error }: LoadingErrorProps) {
    //   const initialLoadingState = useRef(isLoading).current;

    return (
        <div className="loading-error__container">
            {isLoading && <div className="loading" />}
            {error && <div className="loading-error__error">{error}</div>}
        </div>
    );
}
