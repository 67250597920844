type PlayPauseProps = {
    scale?: number;
    isPlaying?: boolean;
    color?: string;
};

function roundPixels(size: number, scale: number) {
    return `${Math.round(size * scale)}px`;
}

export function PlayPause({ scale = 1, isPlaying, color = 'black' }: PlayPauseProps) {
    const roundedScale = Math.round(24 * scale);
    const weight = roundPixels(4, scale);

    const path = isPlaying ? (
        <div
            style={{
                width: `${roundPixels(roundedScale, 0.5)}`,
                height: `${roundPixels(roundedScale, 0.8)}`,
                borderLeft: `${weight} solid ${color}`,
                borderRight: `${weight} solid ${color}`,
            }}
        />
    ) : (
        <div
            style={{
                borderTop: `${roundPixels(roundedScale, 0.4)} solid transparent`,
                borderBottom: `${roundPixels(roundedScale, 0.4)} solid transparent`,
                borderLeft: `${roundPixels(roundedScale, 0.4)} solid ${color}`,
                transform: 'translate(20%)',
            }}
        />
    );

    return (
        <div
            className="icon icon__play-pause"
            style={{
                width: roundedScale,
                height: roundedScale,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {path}
        </div>
    );
}
