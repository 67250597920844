type AppErrorObject = {
    code: string;
    message: string;
    nativeError?: unknown;
};

export class AppError {
    private static readonly defaultCode: string = 'UNKNOWN_ERROR';
    private static readonly defaultMessage: string = 'An unknown error occurred.';
    public readonly code: string;
    public readonly message: string;
    public readonly nativeError: unknown;

    public static convertUnknownError(
        e: any = {},
        code: string = this.defaultCode,
        message: string = this.defaultMessage,
    ): AppErrorObject {
        const errorObject: AppErrorObject = {
            code: e.code || code,
            message: e.message || message,
            nativeError: e,
        };

        return errorObject;
    }

    constructor({ code, message, nativeError }: AppErrorObject) {
        this.code = code;
        this.message = message;
        this.nativeError = nativeError;
    }

    public log = () => {
        console.error({ code: this.code, message: this.message, nativeError: this.nativeError });
        return this;
    };
}
