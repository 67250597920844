export const characterSets = {
    unambiguous: '123456789ABCDEFGHJKLMNPQRSTUVWXYZ',
    alphaNum: '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
};

export const randomString = (length: number, chars = characterSets.alphaNum) => {
    let result = '';
    for (let i = length; i > 0; i -= 1) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};
