import './create-dim-sums.scss';

import { ArrayUtils, roundDefinitions, RoundTypes } from '@house-of-games/common';
import deepmerge from 'deepmerge';

import { Button } from '../../../common/components/buttons/buttons';
import { Dropdown } from '../../../common/components/form-fields/dropdown';
import { TextField } from '../../../common/components/form-fields/text-field';
import { without } from '../../../utils/array';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { RoundProps } from '../create-round-component-map';
import { MathOperator } from './operator-icons/math-operator';

type EquationTerm = 0 | 1 | 2 | 3;
type DimSumsQuestion = RoundTypes.DimSumsRound['questions'][number];
type DimSumsSubQuestion = DimSumsQuestion['questions'][EquationTerm];
const numberToLetterMap = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
} as const;

const definition = roundDefinitions[RoundTypes.RoundType.DimSums].of;

const mathOperatorArray = ArrayUtils.fromStringEnum(RoundTypes.MathOperator);
export function calculateAnswer(question: DimSumsQuestion, firstTerm: EquationTerm, secondTerm: EquationTerm) {
    const firstAnswer = question.questions[firstTerm]?.answer;
    const secondAnswer = question.questions[secondTerm]?.answer;
    const firstFloat = parseFloat(`${firstAnswer}`);
    const secondFloat = parseFloat(`${secondAnswer}`);
    if (isNaN(firstFloat) || isNaN(secondFloat)) {
        return;
    }

    let answerValue: number;
    switch (question.equation.operator) {
        case RoundTypes.MathOperator.Add:
            answerValue = firstFloat + secondFloat;
            break;
        case RoundTypes.MathOperator.Subtract:
            answerValue = firstFloat - secondFloat;
            break;
        case RoundTypes.MathOperator.Multiply:
            answerValue = firstFloat * secondFloat;
            break;
        case RoundTypes.MathOperator.Divide:
            answerValue = firstFloat / secondFloat;
            break;
    }

    return Number(answerValue.toFixed(4));
}

type SubQuestionProps = {
    index: EquationTerm;
    currentQuestionValue: string;
    currentAnswerValue: string;
    isSelectedAsAnswer: boolean;
    updateQuestion: (newQuestion: DimSumsSubQuestion) => void;
};

function SubQuestion({
    index,
    currentQuestionValue,
    currentAnswerValue,
    isSelectedAsAnswer,
    updateQuestion,
}: SubQuestionProps) {
    const questionLetter = numberToLetterMap[index];

    function handleQuestionChanged(question: string) {
        updateQuestion({ question });
    }

    function handleAnswerChanged(answer: string) {
        updateQuestion({ answer: parseFloat(answer) });
    }

    return (
        <div className="create-dim-sums-sub-question">
            <TextField
                className="sub-question"
                defaultValue={currentQuestionValue}
                label={`Question ${questionLetter}`}
                type="text"
                isOutline
                theme={{ border: 'var(--hog-primary)' }}
                onChange={handleQuestionChanged}
                maxLength={definition.questions.of.of.question.length}
            />
            <TextField
                className="sub-answer"
                defaultValue={currentAnswerValue}
                label={`Answer ${questionLetter}`}
                type="number"
                onChange={handleAnswerChanged}
                isOutline={!isSelectedAsAnswer}
                maxLength={definition.questions.of.of.answer.length}
                maxValue={definition.questions.of.of.answer.maxValue}
                minValue={definition.questions.of.of.answer.minValue}
            />
        </div>
    );
}

type QuestionProps = {
    question: DimSumsQuestion;
    updateQuestion: (newQuestion: Partial<DimSumsQuestion>) => void;
};

function Question({ question, updateQuestion }: QuestionProps) {
    function handleSubQuestionChanged(subQuestionIndex: 0 | 1 | 2 | 3, subQuestion: DimSumsSubQuestion) {
        const newSubQuestion = { ...question.questions[subQuestionIndex], ...subQuestion };
        updateQuestion({ questions: { [subQuestionIndex]: newSubQuestion } });
    }

    function handleFirstTermChanged(firstTerm: DimSumsQuestion['equation']['firstTerm']) {
        if (firstTerm === question.equation.secondTerm) {
            return;
        }

        updateQuestion({ equation: { firstTerm } });
    }

    function handleSecondTermChanged(secondTerm: DimSumsQuestion['equation']['secondTerm']) {
        if (secondTerm === question.equation.firstTerm) {
            return;
        }

        updateQuestion({ equation: { secondTerm } });
    }

    function handleEquationOperatorChanged(operator: RoundTypes.MathOperator) {
        updateQuestion({ equation: { operator } });
    }

    const subQuestionIndexes = [0, 1, 2, 3] as const;
    function calculateSelectedTerm(term: EquationTerm, otherTerm: EquationTerm) {
        if (term !== otherTerm) {
            return term;
        }

        if (term !== undefined) {
            return term;
        }

        const possibleTerms = without(subQuestionIndexes as [0, 1, 2, 3], question.equation.secondTerm);
        const defaultedTerm = possibleTerms[0];
        return defaultedTerm;
    }

    const secondTerm = calculateSelectedTerm(question.equation.secondTerm, question.equation.firstTerm);
    const firstTerm = calculateSelectedTerm(question.equation.firstTerm, secondTerm);
    const operatorMap: { [key in RoundTypes.MathOperator]: string } = {
        [RoundTypes.MathOperator.Add]: '+',
        [RoundTypes.MathOperator.Subtract]: '−',
        [RoundTypes.MathOperator.Multiply]: '×',
        [RoundTypes.MathOperator.Divide]: '÷',
    };

    const answerNumber = calculateAnswer(question, firstTerm, secondTerm);
    const answerString = answerNumber === undefined ? '__' : answerNumber;

    return (
        <div className="create-dim-sums-question">
            <div className="create-dim-sums-sub-questions">
                {subQuestionIndexes.map((i) => {
                    const subQuestion = question.questions[i];
                    const isSelectedAsAnswer = [question.equation.firstTerm, question.equation.secondTerm].includes(i);
                    return (
                        <SubQuestion
                            index={i}
                            key={i}
                            isSelectedAsAnswer={isSelectedAsAnswer}
                            currentQuestionValue={subQuestion?.question}
                            currentAnswerValue={`${subQuestion?.answer}`}
                            updateQuestion={(subQuestion: DimSumsSubQuestion) =>
                                handleSubQuestionChanged(i, subQuestion)
                            }
                        />
                    );
                })}
            </div>
            <div className="create-dim-sums-equation">
                <Dropdown<EquationTerm>
                    className="create-dim-sums-equation__dropdown-term"
                    options={Object.values(numberToLetterMap).map((char, i) => {
                        return {
                            value: i as EquationTerm,
                            label: char,
                            disabled: i === secondTerm,
                        };
                    })}
                    defaultOption={{
                        value: firstTerm,
                        label: numberToLetterMap[firstTerm],
                        disabled: false,
                    }}
                    onChange={(opt) => handleFirstTermChanged(opt.value)}
                />
                <Dropdown<RoundTypes.MathOperator>
                    className="create-dim-sums-equation__dropdown-operator"
                    options={mathOperatorArray.map((operator) => {
                        return {
                            value: operator as RoundTypes.MathOperator,
                            label: operatorMap[operator],
                            disabled: false,
                        };
                    })}
                    defaultOption={{
                        value: question.equation.operator,
                        label: operatorMap[question.equation.operator],
                        disabled: false,
                    }}
                    onChange={(opt) => handleEquationOperatorChanged(opt.value)}
                />
                <Dropdown<EquationTerm>
                    className="create-dim-sums-equation__dropdown-term"
                    options={Object.values(numberToLetterMap).map((char, i) => {
                        return {
                            value: i as EquationTerm,
                            label: char,
                            disabled: i === firstTerm,
                        };
                    })}
                    defaultOption={{
                        value: secondTerm,
                        label: numberToLetterMap[secondTerm],
                        disabled: false,
                    }}
                    onChange={(opt) => handleSecondTermChanged(opt.value)}
                />
                <div className="create-dim-sums-equation__equals">
                    <MathOperator operator="Equals" scale={2} />
                </div>
                <div className="create-dim-sums-equation__answer">{answerString}</div>
            </div>
        </div>
    );
}

export function CreateDimSums({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: RoundProps<RoundTypes.DimSumsRound>) {
    const { questions } = round;

    function handleNewQuestion() {
        createNewQuestion({
            questions: {},
            equation: { firstTerm: 0, secondTerm: 1, operator: RoundTypes.MathOperator.Add },
        });
    }

    function deepMergeAndUpdate(questionIndex: number, newQuestion: Partial<typeof questions[number]>) {
        const currentQuestion = round.questions[questionIndex];
        const mergedQuestion = deepmerge(currentQuestion, newQuestion);
        updateQuestion(questionIndex, mergedQuestion);
    }

    return (
        <div className="create-dim-sums">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-dim-sums-question__container">
                        <QuestionArrows
                            index={i}
                            numberOfQuestions={questions.length}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                        />
                        <Question question={q} updateQuestion={(newQ) => deepMergeAndUpdate(i, newQ)} />
                        <QuestionErase index={i} onRemove={removeQuestion} />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
