import './play-cine-nyms.scss';

import { RoundTypes } from '@house-of-games/common';
import { useEffect, useState } from 'react';

import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { QuestionAnswerComponent } from '../common/question-answer';

export function PlayCineNyms({ question, message, musicQuestionHelpers }: SlideProps<RoundTypes.CineNymsRound>) {
    const [isRevealed, setIsRevealed] = useState(false);

    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    function handleMessageReceived(msg: Message) {
        switch (msg.name) {
            case MessageName.reveal:
                setIsRevealed(true);
        }
    }

    return (
        <div className="play-cine-nyms slide page">
            <QuestionAnswerComponent
                question={isRevealed ? question.originalQuote : question.question}
                answer={isRevealed && question.answer}
                musicQuestionHelpers={musicQuestionHelpers}
            />
        </div>
    );
}
