import classNames from 'classnames';
import { useEffect, useState } from 'react';

export function useAnimate(className: string, additionalClassNames?: string) {
    const [rendered, setIsRendered] = useState(false);
    useEffect(() => {
        const animateTimeout = setTimeout(() => {
            setIsRendered(true);
        }, 100);

        return () => {
            clearTimeout(animateTimeout);
        };
    }, []);

    const newClasses = classNames(className, {
        [`${className}--animate`]: rendered,
        [additionalClassNames]: additionalClassNames,
    });

    return newClasses;
}
