import { CFRequestTypes } from '@house-of-games/common';
import React, { useRef, useState } from 'react';

import { decodeAudioData } from '../../../utils/mp3-trim';

export type AudioData = {
    type: CFRequestTypes.UploadFileType.Audio;
    file: File;
    audioBuffer: AudioBuffer;
    audioContext: AudioContext;
};

export type ImageData = {
    type: CFRequestTypes.UploadFileType.Image;
    file: File;
};

export type FileData = AudioData | ImageData;

type UseFileDropEvents = {
    onDragLeave: (e: React.DragEvent<HTMLDivElement>) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
    onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
};

type UseFileDropReturnType = {
    isDragging: boolean;
    isDraggingInvalid: boolean;
    isFileProcessing: boolean;
    fileDataRef: React.MutableRefObject<FileData>;
    dropEventListeners: UseFileDropEvents;
    onUserFileSelected: React.ReactEventHandler<HTMLInputElement>;
};

const fileTypeRegexMap: { [key in CFRequestTypes.UploadFileType]: RegExp } = {
    [CFRequestTypes.UploadFileType.Audio]: /^audio\/.+/,
    [CFRequestTypes.UploadFileType.Image]: /^image\/.+/,
};

export function useFileDrop(
    allowedFileTypes: Array<CFRequestTypes.UploadFileType>,
    onFileDrop: () => void,
    inputRef?: React.MutableRefObject<HTMLInputElement>,
): UseFileDropReturnType {
    const [isDragging, setIsDragging] = useState(false);
    const [isDraggingInvalid, setIsDraggingInvalid] = useState(false);
    const [isFileProcessing, setIsFileProcessing] = useState(false);
    const fileDataRef = useRef<FileData>();

    function handleDragLeave() {
        setIsDragging(false);
        setIsDraggingInvalid(false);
    }

    function matchFileType(fileType: string): CFRequestTypes.UploadFileType {
        const match = Object.entries(fileTypeRegexMap).find(([_fileType, regex]) => {
            return regex.test(fileType);
        });

        if (!match) {
            return;
        }

        const matchedType = match[0] as keyof typeof fileTypeRegexMap;
        if (!allowedFileTypes.includes(matchedType)) {
            return;
        }

        return matchedType;
    }

    function getFileType(dataTransfer: DataTransfer): string {
        let draggedFileType: string;
        // first check if we are using 'items' or 'files'
        if (dataTransfer.items.length === 1) {
            draggedFileType = Array.from(dataTransfer.items)[0].type;
        } else if (dataTransfer.files.length === 1) {
            draggedFileType = Array.from(dataTransfer.files)[0].type;
        }

        return draggedFileType;
    }

    function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        const fileType = getFileType(e.dataTransfer);
        const matchedFileType = matchFileType(fileType);
        const isDraggingValid = Boolean(matchedFileType);
        if (!isDraggingValid) {
            setIsDraggingInvalid(true);
            return;
        }

        setIsDragging(true);
    }

    async function processFile(fileType: CFRequestTypes.UploadFileType, file: File) {
        switch (fileType) {
            case CFRequestTypes.UploadFileType.Image:
                fileDataRef.current = {
                    type: fileType,
                    file,
                };
                onFileDrop();
                fileDataRef.current = undefined;
                break;
            default: {
                setIsFileProcessing(true);
                try {
                    const [audioBuffer, audioContext] = await decodeAudioData(file);
                    fileDataRef.current = {
                        type: fileType,
                        file,
                        audioBuffer,
                        audioContext,
                    };
                    setIsFileProcessing(false);
                    onFileDrop();
                    fileDataRef.current = undefined;
                } catch (e) {
                    setIsFileProcessing(false);
                }
            }
        }
    }

    function handleDrop(e: React.DragEvent<HTMLDivElement>) {
        const fileType = getFileType(e.dataTransfer);
        const matchedFileType = matchFileType(fileType);
        const isDropValid = Boolean(matchedFileType);
        e.preventDefault();
        setIsDragging(false);
        setIsDraggingInvalid(false);

        if (!isDropValid) {
            return;
        }

        let file: File;
        if (e.dataTransfer.items.length === 1) {
            file = e.dataTransfer.items[0].getAsFile();
        } else {
            file = e.dataTransfer.files[0];
        }

        processFile(matchedFileType, file);
    }

    function handleUserFileSelected() {
        if (!inputRef?.current) {
            return;
        }

        const files = inputRef.current.files;
        if (files.length > 1) {
            return;
        }

        const selectedFileType = files[0].type;
        const matchedFileType = matchFileType(selectedFileType);
        const isFileValid = Boolean(matchedFileType);

        if (!isFileValid) {
            return;
        }

        processFile(matchedFileType, files[0]);
    }

    return {
        isDragging,
        isDraggingInvalid,
        isFileProcessing,
        fileDataRef,
        dropEventListeners: {
            onDragLeave: handleDragLeave,
            onDragOver: handleDragOver,
            onDrop: handleDrop,
        },
        onUserFileSelected: handleUserFileSelected,
    };
}
