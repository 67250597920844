import './logo.scss';

import { useEffect, useRef, useState } from 'react';

import Logo64 from '../../../images/hog-logo--64.png';
import Logo128 from '../../../images/hog-logo--128.png';
import Logo256 from '../../../images/hog-logo--256.png';
import Logo512 from '../../../images/hog-logo--512.png';
import Logo1024 from '../../../images/hog-logo--1024.png';

export function Logo() {
    const containerRef = useRef<HTMLDivElement>();
    const imageSrc = useRef<string>(null);
    const [size, setSize] = useState(0);

    function setNewSize() {
        const { width } = containerRef.current.getBoundingClientRect();
        setSize(width);
        return width;
    }

    function getAppripriateImageSrc(width: number) {
        if (width < 70) {
            return Logo64;
        } else if (width < 140) {
            return Logo128;
        } else if (width < 290) {
            return Logo256;
        } else if (width < 600) {
            return Logo512;
        } else {
            return Logo1024;
        }
    }

    useEffect(() => {
        const width = setNewSize();
        imageSrc.current = getAppripriateImageSrc(width);
        window.addEventListener('resize', setNewSize);
        return () => {
            window.removeEventListener('resize', setNewSize);
        };
    }, []);

    function inPixels(n: number) {
        return `${n}px`;
    }

    const fontSize = inPixels(size / 10);
    const bottomOffset = inPixels(size / 10);
    const sizePx = inPixels(size);

    return (
        <div ref={containerRef} className="logo__container">
            <img className="logo" src={imageSrc.current} alt="Logo" style={{ width: sizePx, height: sizePx }} />
            <div className="logo__text" style={{ fontSize, bottom: bottomOffset }}>
                HOUSE of GAMES
            </div>
        </div>
    );
}
