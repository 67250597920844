import { RoundTypes, UserStorage } from '@house-of-games/common';
import { useEffect, useState } from 'react';

import { useStorageCache } from '../../../common/components/user-storage/use-storage-cache';

type UseImageLoadReturnType = {
    imageErrored: boolean;
    imageLoaded: boolean;
    imageSrc: string;
    imageProps: { onLoad: () => void; onError: () => void };
};

export function useImageLoad(imageQuestion: string): UseImageLoadReturnType {
    const storageCache = useStorageCache();
    const [imageErrored, setImageErrored] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState<string>();

    useEffect(() => {
        setImageErrored(false);
        setImageLoaded(false);
        if (!imageQuestion) {
            return;
        }

        const type = UserStorage.getQuestionType(imageQuestion);
        if (type !== RoundTypes.QuestionType.Image) {
            setImageErrored(true);
            return;
        }

        if (UserStorage.checkIsValidUri(imageQuestion)) {
            storageCache.load(imageQuestion).then((res) => {
                setImageSrc(res);
            });
        } else {
            setImageSrc(imageQuestion);
        }
    }, [imageQuestion]);

    function handleImageErrored() {
        setImageErrored(true);
    }

    function handleImageLoaded() {
        setImageLoaded(true);
    }

    return {
        imageErrored,
        imageLoaded,
        imageSrc,
        imageProps: {
            onLoad: handleImageLoaded,
            onError: handleImageErrored,
        },
    };
}
