import { CFRequestTypes } from '..';
import { UploadFileType } from '../types/cloud-functions-request';
import { QuestionType } from '../types/round';
import { AppError } from './app-error';
import { ErrorCode } from './error-codes';
import { MatchType } from './game-crawler';

export const USER_STORAGE_PREFIX = 'userstorage://';
// Case insensitive regex in-case questions are auto-capitalised
const userStorageRegex = /^userstorage:\/\//i;
export const filePathMap: { [key in UploadFileType]: string } = {
    [UploadFileType.Audio]: 'audio',
    [UploadFileType.Image]: 'images',
};

// Group 1 checks for string starting with http:// or https://
// Group 2 checks for any number of non-space characers followed by "." followed
// by 2-10 non puntuation characters, followed by a "/"
const urlRegex = /(^(http:\/\/|https:\/\/)|^([^ .]+\.[\p{L}]{2,10}[\/]))/i;
const imageRegex = /.(jpe?g|png)$/i;

// export type FilePath = {
//     owner: string;
//     gameId: string;
//     fileName: string;
//     path: string;
// };

function checkIsValidStorageBucketUrl(url: URL) {
    return url.pathname.includes('house-of-games-nm.appspot.com');
}

export function checkIsHttpUrl(str: string) {
    return urlRegex.test(str);
}

export function getQuestionType(question: string): QuestionType {
    const isValidUri = checkIsValidUri(question);
    if (!isValidUri) {
        // support for externally hosted images
        if (checkIsHttpUrl(question)) {
            try {
                const url = new URL(question);

                const isStorageBuckedUrl = checkIsValidStorageBucketUrl(url);
                if (isStorageBuckedUrl) {
                    return url.pathname.includes(filePathMap[QuestionType.Image])
                        ? QuestionType.Image
                        : QuestionType.Audio;
                }

                if (imageRegex.test(url.pathname)) {
                    return QuestionType.Image;
                }
            } catch {
                return QuestionType.Text;
            }
        }

        return QuestionType.Text;
    }

    const isImage = question.includes(filePathMap[UploadFileType.Image]);
    if (isImage) {
        return QuestionType.Image;
    }

    return QuestionType.Audio;
}

export function checkIsValidUri(uri: string): boolean {
    if (!userStorageRegex.test(uri)) {
        return false;
    }

    const parts = uri.split(userStorageRegex);
    if (parts.length !== 2) {
        return false;
    }

    return true;
}

export function buildUriFromFullPath(path: string) {
    const pathSegments = path.split('/');
    const [_userId, ...rest] = pathSegments;
    const uri = `${USER_STORAGE_PREFIX}${rest.join('/')}`;
    return uri;
}

export function getFileTypeFromUri(uri: string) {
    const isValid = checkIsValidUri(uri);
    if (!isValid) {
        throw new AppError({
            code: ErrorCode.UserStorage.INVALID_STORAGE_PATH,
            message: 'Invalid Storage Path',
        });
    }

    if (uri.includes(filePathMap[CFRequestTypes.UploadFileType.Image])) {
        return CFRequestTypes.UploadFileType.Image;
    }

    return CFRequestTypes.UploadFileType.Audio;
}

export function getFilePathFromUri(uri: string, owner: string): string {
    const isValid = checkIsValidUri(uri);
    if (!isValid) {
        throw new AppError({
            code: ErrorCode.UserStorage.INVALID_STORAGE_PATH,
            message: 'Invalid Storage Path',
        });
    }

    const parts = uri.split(userStorageRegex);
    const storagePath = parts[1];
    const filePath = `${owner}/${storagePath}`;
    return filePath;
}

export function userStorageMatcher(s: MatchType): s is string {
    return typeof s === 'string' && userStorageRegex.test(s);
}
