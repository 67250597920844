import './text-area.scss';

import classNames from 'classnames';
import { FormEvent, useEffect, useRef, useState } from 'react';
import ExpandingTextArea from 'react-expanding-textarea';

export type ValidatedChunk = {
    string: string;
    valid: boolean;
};

type TextAreaProps = {
    maxLength?: number;
    defaultValue?: string;
    placeholder: string;
    className?: string;
    validator?: (text: string) => Array<ValidatedChunk>;
    onChange: (text: string) => void;
    newLineLimit?: number;
};

export function TextArea({
    maxLength,
    defaultValue,
    placeholder,
    className,
    onChange,
    validator,
    newLineLimit = 0,
}: TextAreaProps) {
    const validatedTextRef = useRef<HTMLDivElement>();
    const [validatedChunks, setValidatedChunks] = useState<Array<ValidatedChunk | undefined>>();
    const lastTextRef = useRef(defaultValue || '');

    useEffect(() => {
        if (defaultValue && validator) {
            const chunks = validator(defaultValue);
            if (chunks) {
                setValidatedChunks(chunks);
            }
        }
    }, []);

    function handleTextInputChange(e: FormEvent<HTMLTextAreaElement>) {
        const newLineRegex = new RegExp(`\r?\n{${newLineLimit},}`, 'g');
        const replaceString = '\n'.repeat(newLineLimit);
        const text = e.currentTarget.value.replace(newLineRegex, replaceString);
        const lastText = lastTextRef.current;
        if (text === lastText || text.length > maxLength) {
            e.currentTarget.value = text;
        } else {
            handleTextChange(text);
        }
    }

    function handleTextChange(text: string) {
        lastTextRef.current = text;
        const chunks = validator?.(text);
        if (chunks) {
            setValidatedChunks(chunks);
        }
        onChange(text);
    }

    const classes = classNames('text-area', {
        [className]: className,
    });

    function createValidatedTextElements() {
        return (
            <div className="validated-overlay">
                {validatedChunks.map((chunk, i) => {
                    const className = classNames('validated-chunk', {
                        'validated-chunk__invalid': !chunk.valid,
                        'validated-chunk__valid': chunk.valid,
                    });
                    return (
                        <span className={className} key={i}>
                            {chunk.string}
                        </span>
                    );
                })}
            </div>
        );
    }

    return (
        <div className="text-area__container">
            <ExpandingTextArea
                className={classes}
                defaultValue={defaultValue}
                placeholder={placeholder}
                onChange={handleTextInputChange}
            />
            {validatedChunks && <div ref={validatedTextRef}>{createValidatedTextElements()}</div>}
            {maxLength && (
                <div className="text-area__counter">{`${lastTextRef.current?.length || 0}/${maxLength}`}</div>
            )}
        </div>
    );
}
