type CarriageReturnProps = {
    scale?: number;
};

export function CarriageReturn({ scale = 1 }: CarriageReturnProps) {
    return (
        <svg className="icon__carriage-return" width={`${24 * scale}`} height={`${24 * scale}`} viewBox="0 0 24 24">
            <path d="M24,6v12h-6H9v5l-9-8l9-8v5h9V6H24z" />
        </svg>
    );
}

/*
</svg>/<svg className="icon__carriage-return" width={`${24 * scale}`} height={`${24 * scale}`} viewBox="0 0 24 24">
    <path d="M23,4v14h-6H9v3l-8-6.3l8-6.3V12h8V6H23z" />
</svg>
*/
