import './homepage.scss';

import { Link } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
import { Logo } from '../../common/components/logo/logo';
import { LoggedOutRoutes } from '../../common/constants/routes';
import CreateBlast from '../../images/create-blast.png';
import RoundTitle from '../../images/round-title.png';
import ZToA from '../../images/z-to-a.png';

type HomepageProps = {
    loggedInUser?: string;
};

export function Homepage({ loggedInUser }: HomepageProps) {
    return (
        <div className="homepage scrollable">
            <Logo />
            {loggedInUser && (
                <div className="logged-in-info-container">
                    <span className="logged-in-info-title">Hi {loggedInUser}!</span>
                    <span className="logged-in-info-text">
                        Creating & editing is not currently supported on mobile devices. Please log in to a laptop or
                        desktop if you want to make changes to your games.
                    </span>
                </div>
            )}
            <div className="action-buttons">
                <Link draggable={false} to={`${LoggedOutRoutes.Play}`}>
                    <Button className="action-button action-button__join-game" label="Join Game" />
                </Link>
                {!loggedInUser && (
                    <>
                        <Link draggable={false} to={`${LoggedOutRoutes.LogIn}`}>
                            <Button className="action-button action-button__log-in" label="Log In" />
                        </Link>
                        <Link draggable={false} to={`${LoggedOutRoutes.LogIn}?sign-up`}>
                            <Button className="action-button action-button__sign-up" label="Sign Up" />
                        </Link>
                    </>
                )}
            </div>
            <div className="summary">
                {`An unofficial fan game based on the BBC quiz show "Richard Osman's House Of Games"`}
            </div>
            <div className="screenshot__container">
                <div className="screenshot">
                    <img className="screenshot__image" src={CreateBlast} alt="create-game-blast" />
                    <div className="screenshot__text">Create your own questions</div>
                </div>
                <div className="screenshot">
                    <img className="screenshot__image" src={RoundTitle} alt="round-title" />
                    <div className="screenshot__text">Featuring rounds from the TV show</div>
                </div>
                <div className="screenshot">
                    <img className="screenshot__image" src={ZToA} alt="z-to-a" />
                    <div className="screenshot__text">Interactive experience where friends can buzz in</div>
                </div>
            </div>
        </div>
    );
}
