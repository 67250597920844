import { CFRequestTypes } from '@house-of-games/common';
import { Link } from 'react-router-dom';

import { UserStorageGrid } from '../../common/components/user-storage/user-storage-grid';
import { AppRoute } from '../../common/constants/routes';

export function Storage() {
    return (
        <div className="storage page">
            <div className="header">
                <div className="box-header__container">
                    <Link draggable={false} to={AppRoute.Home}>
                        <div className="box-header__back-button-container">
                            <div className="box-header__back-button" />
                        </div>
                    </Link>
                    <h1 className="box-header">Stored Files</h1>
                </div>
            </div>
            <UserStorageGrid
                allowedFileTypes={[CFRequestTypes.UploadFileType.Audio, CFRequestTypes.UploadFileType.Image]}
            />
        </div>
    );
}
