import './question-arrows.scss';

import classNames from 'classnames';

type QuestionArrowsProps = {
    index: number;
    numberOfQuestions: number;
    onClickUpArrow: (i: number) => void;
    onClickDownArrow: (i: number) => void;
};

export function QuestionArrows({ index, numberOfQuestions, onClickUpArrow, onClickDownArrow }: QuestionArrowsProps) {
    const upArrowClassNames = classNames('question-arrows question-arrows__up', {
        'question-arrows--disabled': index === 0,
    });

    const downArrowClassNames = classNames('question-arrows question-arrows__down', {
        'question-arrows--disabled': index === numberOfQuestions - 1,
    });

    return (
        <div className="question-arrows__container">
            <div className={upArrowClassNames} onClick={() => onClickUpArrow(index)}></div>
            <div className={downArrowClassNames} onClick={() => onClickDownArrow(index)}></div>
        </div>
    );
}
