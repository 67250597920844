import './buttons.scss';

import classNames from 'classnames';
import React, { Component } from 'react';

type ButtonProps = {
    className?: string;
    label: string;
    onClick?: () => void;
    disabled?: boolean;
    secondary?: boolean;
    style?: React.CSSProperties;
};

type ButtonState = {
    mouseDown: boolean;
    mouseOver: boolean;
};

function doNothing() {
    // noop
}

export class Button extends Component<ButtonProps, ButtonState> {
    state: ButtonState = {
        mouseDown: false,
        mouseOver: false,
    };

    componentDidMount() {
        window.addEventListener('mouseup', this.handleMouseUp);
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseDown = () => {
        this.setState({ mouseDown: true });
    };

    handleMouseUp = () => {
        this.setState({ mouseDown: false });
    };

    handleMouseEnter = () => {
        this.setState({ mouseOver: true });
    };

    handleMouseLeave = () => {
        this.setState({ mouseOver: false });
    };

    render() {
        const { className, label, onClick = doNothing, disabled, secondary, style } = this.props;

        const { mouseDown, mouseOver } = this.state;
        const buttonClasses = classNames('button', {
            'button--clicked': mouseDown && mouseOver,
            'button--disabled': disabled,
            'button--secondary': secondary,
            [className]: className,
        });

        return (
            <div
                className={buttonClasses}
                style={style}
                onClick={onClick}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {label}
            </div>
        );
    }
}
