import lamejs from 'lamejs';

function float32ToInt16(float32: Float32Array) {
    let i = 0;
    const dataAsInt16Array = new Int16Array(float32.length);

    while (i < float32.length) {
        dataAsInt16Array[i] = convert(float32[i++]);
    }

    function convert(n: number) {
        const v = n < 0 ? n * 32768 : n * 32767; // convert in range [-32768, 32767]
        return Math.max(-32768, Math.min(32768, v)); // clamp
    }

    return dataAsInt16Array;
}

function encodeMp3(buffer: AudioBuffer) {
    const mp3Data = [];

    const channels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const kbps = 128;

    const channelData = [];

    for (let i = 0; i < channels; i++) {
        channelData.push(float32ToInt16(buffer.getChannelData(i)));
    }

    const encoder = new lamejs.Mp3Encoder(channels, sampleRate, kbps);
    let mp3Tmp = encoder.encodeBuffer(...channelData);

    mp3Data.push(mp3Tmp);
    mp3Tmp = encoder.flush();
    mp3Data.push(mp3Tmp);

    return new Blob(mp3Data, {
        type: 'audio/mp3',
    });
}

export async function decodeAudioData(src: File): Promise<[AudioBuffer, AudioContext]> {
    const arrayBuffer = await new Response(src).arrayBuffer();
    const audioContext = new AudioContext();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    return [audioBuffer, audioContext];
}

export function trim(audioBuffer: AudioBuffer, audioContext: AudioContext, start: number, end: number) {
    const channels = audioBuffer.numberOfChannels;
    const rate = audioBuffer.sampleRate;

    const startOffset = rate * start;
    const endOffset = rate * end;
    const frameCount = endOffset - startOffset;

    const newAudioBuffer = audioContext.createBuffer(channels, endOffset - startOffset, rate);

    const tempArray = new Float32Array(frameCount);
    const offset = 0;

    for (let channel = 0; channel < channels; channel++) {
        audioBuffer.copyFromChannel(tempArray, channel, startOffset);
        newAudioBuffer.copyToChannel(tempArray, channel, offset);
    }

    return encodeMp3(newAudioBuffer);
}
