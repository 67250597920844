import { RoundTypes } from '@house-of-games/common';

export const roundCreationInfo: { [key in RoundTypes.RoundType]?: string } = {
    // Buzzer Rounds
    [RoundTypes.RoundType.BrokenKaraoke]:
        'The top field represents the letter that will be shown, and the bottom field represents the time at which it should appear relative to the previous character (in milliseconds)',
    [RoundTypes.RoundType.InTheName]:
        'If a contestant has the name "John Smith", one possible answer could be "Son", because those letters are a partial anagram of the name.',
    [RoundTypes.RoundType.GamesHouseOf]:
        'The answers should be typed normally, and they will be sorted alphabetically when shown in game.',
    [RoundTypes.RoundType.Backwards]:
        'Questions and answers should be typed normally, and they will be reversed when shown in game.',
    // [RoundTypes.RoundType.InternetHistory]: 'InternetHistory',
    // [RoundTypes.RoundType.PopArt]: 'PopArt',                     Fun but longer
    // [RoundTypes.RoundType.FeelIt]: 'FeelIt',                     Not Doable Online
    // [RoundTypes.RoundType.TooComplicated]: 'TooComplicated',

    // Individual Games
    // [RoundTypes.RoundType.InTheQuestion]:
    //     'The players are given a category and a statement describing something from that category. Part of the statement is in capitals, and is an anagram for the answer.',
    // [RoundTypes.RoundType.TheAnswerIsnt]: 'TheAnswerIsnt',       Tricky for this scenario
    // [RoundTypes.RoundType.HighbrowLowbrow]:
    //     'The players are given two questions with the same answer, one of which is significantly harder than the other. They are shown the hard question first, follwed by the easier question. If they get the correct answer having seen only the hard question they are awarded 2 points, but if they change their answer after seeing the easier question they will only be awarded one point.',
    // [RoundTypes.RoundType.PenUltimate]: 'PenUltimate',
    // [RoundTypes.RoundType.WritersDayOff]: 'WritersDayOff',

    // Pairs Games
    // [RoundTypes.RoundType.BuildYourOwn]: 'BuildYourOwn',         Tricky for this scenario
    // [RoundTypes.RoundType.DimSums]:
    //     'The players are in pairs for this round (although this round also works well as individuals). The players are given four questions, each of which has a numerical answer. The players are also given a sum (e.g. ? x ? = 18). The players must choose which two questions should be used to fill in the sum correctly.',
    // [RoundTypes.RoundType.ChronIllogical]: 'ChronIllogical',
    [RoundTypes.RoundType.YouCompleteMe]:
        'Although the game is originally designed for pairs, groups of any number work well. The number of words in the answer should match the number of people in the group, and each word will be revealed one at a time.',
    // [RoundTypes.RoundType.AllInTheDetails]: 'AllInTheDetails',
    // [RoundTypes.RoundType.VennFamous]: 'VennFamous',
    // [RoundTypes.RoundType.TotesEmoji]: 'TotesEmoji',              Fun but tricky
    // [RoundTypes.RoundType.TheTwoWrongies]: 'TheTwoWrongies',
    [RoundTypes.RoundType.ICompleteYou]:
        'Although the game is originally designed for pairs, groups of any number work well. The number of words in the answer should match the number of people in the group, and each word will be revealed one at a time.',
    // [RoundTypes.RoundType.HausDerSpiele]: 'HausDerSpiele',       Fun but tricky
    // [RoundTypes.RoundType.ZList]: 'ZList',

    // "Random" Games
    // [RoundTypes.RoundType.IsItMe]: 'IsItMe',
    // [RoundTypes.RoundType.NiceRound]: 'NiceRound',               Tricky
    // [RoundTypes.RoundType.WhatsTheQuestion]: 'WhatsTheQuestion', Tricky
    // [RoundTypes.RoundType.LuckyDip]: 'LuckyDip',
    // [RoundTypes.RoundType.WhenTheySing]: 'Drag and drop an mp3 file into the music box. Audio clips can be a maximum of 30 seconds and you will have the option to trim them if they are longer.',
    // [RoundTypes.RoundType.NotSoNiceRound]: 'NotSoNiceRound',
    // [RoundTypes.RoundType.WhoGoesFirst]: 'WhoGoesFirst',

    // Tablet Games
    // [RoundTypes.RoundType.FingerOnIt]: 'FingerOnIt',
    // [RoundTypes.RoundType.WhereIsKazakhstan]: 'WhereIsKazakhstan',   A bit more work needed
    // [RoundTypes.RoundType.RichList]:
    //     'The players are given a question, statement, or category that has multiple answers. Players must write down a correct answer, but will only receive a point if the same answer was not given by any other player.',
    // [RoundTypes.RoundType.SizeMatters]:
    //     'The players are given a question, statement, or category that has multiple answers. Players must write down a correct answer with the most letters. The correct answer with the most letters gets a point, but a bonus point is awarded if it is the longest possible answer.',
    // [RoundTypes.RoundType.OutplayOsman]: 'OutplayOsman',         Not Possible Online

    // Final Round
    [RoundTypes.RoundType.AnswerSmash]:
        'Image urls can be obtained by right clicking on a picture online and selecting "Copy image address" or "Copy link". You can also upload images to Google Drive and use the shareable link option.',
};
