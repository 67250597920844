type PencilProps = {
    scale?: number;
};

export function Pencil({ scale = 1 }: PencilProps) {
    const roundedScale = Math.round(24 * scale);
    return (
        <svg className="icon__pencil" width={`${roundedScale}`} height={`${roundedScale}`} viewBox="0 0 24 24">
            <path d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z" />
        </svg>
    );
}
