import './create-in-the-name.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';

import { Button } from '../../../common/components/buttons/buttons';
import { TextField } from '../../../common/components/form-fields/text-field';
import { QuestionArea } from '../common/question-area';
import { RoundProps } from '../create-round-component-map';

const nameFieldLabel: { [key in RoundTypes.RoundType]?: string } = {
    [RoundTypes.RoundType.InTheName]: 'Name',
    [RoundTypes.RoundType.Elephant]: 'Word (e.g. "Ant")',
};

export function CreateInTheName({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: RoundProps<RoundTypes.InTheNameRound> | RoundProps<RoundTypes.ElephantRound>) {
    const { questions } = round;
    const definition = roundDefinitions[round.type].of;

    function handleNewQuestion() {
        createNewQuestion({});
    }

    function handleNameChanged(questionIndex: number, name: string) {
        const newQuestion = { ...questions[questionIndex], category: name };
        updateQuestion(questionIndex, newQuestion);
    }

    return (
        <div className="create-in-the-name">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-in-the-name-question__container">
                        <div className="create-in-the-name__name">
                            <TextField
                                label={nameFieldLabel[round.type]}
                                type="text"
                                maxLength={definition.category.length}
                                isOutline
                                defaultValue={q.category}
                                onChange={(v) => handleNameChanged(i, v)}
                            />
                        </div>
                        <QuestionArea
                            index={i}
                            {...q}
                            allowedQuestionTypes={[RoundTypes.QuestionType.Text]}
                            maxPromptLength={definition.prompt.length}
                            maxQuestionLength={definition.question.length}
                            maxAnswerLength={definition.answer.length}
                            isArrowsEnabled={true}
                            numberOfQuestions={questions.length}
                            onChange={updateQuestion}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                            onRemove={removeQuestion}
                        />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
