import './play-correction-centre.scss';

import { RoundTypes } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { useDynamicTextSize } from '../../game/use-dynamic-text-size';

export function PlayCorrectionCentre({ question, message }: SlideProps<RoundTypes.CorrectionCentreRound>) {
    const textSizeClass = useDynamicTextSize([question.question]);
    const [isRevealed, setIsRevealed] = useState(false);

    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    function handleMessageReceived(msg: Message) {
        switch (msg.name) {
            case MessageName.reveal:
                setIsRevealed(true);
        }
    }

    const textClasses = classNames(`play-correction-centre__text ${textSizeClass}`, {
        'play-correction-centre__text--answer': isRevealed,
    });

    return (
        <div className="play-correction-centre slide page">
            <AnimateExpandContainer className={textClasses}>
                <TextFlip text={isRevealed ? question.answer : question.question} offset={300} />
            </AnimateExpandContainer>
        </div>
    );
}
