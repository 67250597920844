import { RoundTypes } from '@house-of-games/common';

import { CreateAnswerList } from './answer-list/create-answer-list';
import { CreateAnswerSmash } from './answer-smash/create-answer-smash';
import { CreateBrokenKaraoke } from './broken-karaoke/create-broken-karaoke';
import { CreateCineNyms } from './cine-nyms/create-cine-nyms';
import { CreateCorrectionCentre } from './correction-centre/create-correction-centre';
import { CreateDimSums } from './dim-sums/create-dim-sums';
import { CreateFingerOnIt } from './finger-on-it/create-finger-on-it';
import { CreateGamesHouseOf } from './games-house-of/create-games-house-of';
import { CreateHighbrowLowbrow } from './highbrow-lowbrow/create-highbrow-lowbrow';
import { CreateInCode } from './in-code/create-in-code';
import { CreateInTheName } from './in-the-name/create-in-the-name';
import { CreateOppositesAttract } from './opposites-attract/create-opposites-attract';
import { CreateRhymeTime } from './rhyme-time/create-rhyme-time';
import { CreateTheAnswerIsnt } from './the-answer-isnt/create-the-answer-isnt';
import { CreateTotesEmoji } from './totes-emoji/create-totes-emoji';
import { CreateWhenTheySing } from './when-they-sing/create-when-they-sing';
import { CreateZToA } from './z-to-a/create-z-to-a';

export type RoundProps<T extends RoundTypes.Round> = {
    objectPath: string;
    createNewQuestion: (emptyQuestion: T['questions'][0]) => void;
    removeQuestion: (index: number) => void;
    updateQuestion: (index: number, question: T['questions'][0]) => void;
    shiftUp: (index: number) => void;
    shiftDown: (index: number) => void;
    round: T;
    uniqueKey: number;
};

type RoundComponent<T extends RoundTypes.RoundType> = (props: RoundProps<RoundTypes.Round<T>>) => React.ReactElement;

const RoundComponentMap: { [key in RoundTypes.RoundType]: RoundComponent<key> } = {
    [RoundTypes.RoundType.RhymeTime]: CreateRhymeTime,
    [RoundTypes.RoundType.BrokenKaraoke]: CreateBrokenKaraoke,
    [RoundTypes.RoundType.InTheName]: CreateInTheName,
    [RoundTypes.RoundType.InCode]: CreateInCode,
    [RoundTypes.RoundType.ZToA]: CreateZToA,
    [RoundTypes.RoundType.GamesHouseOf]: CreateGamesHouseOf,
    [RoundTypes.RoundType.Elephant]: CreateInTheName,
    [RoundTypes.RoundType.Roonerspisms]: CreateRhymeTime,
    [RoundTypes.RoundType.Backwards]: CreateGamesHouseOf,
    [RoundTypes.RoundType.OppositesAttract]: CreateOppositesAttract,
    [RoundTypes.RoundType.KingJumble]: CreateRhymeTime,
    [RoundTypes.RoundType.TwoInOne]: CreateOppositesAttract,
    [RoundTypes.RoundType.PastTense]: CreateGamesHouseOf,
    [RoundTypes.RoundType.CorrectionCentre]: CreateCorrectionCentre,
    [RoundTypes.RoundType.MouseOfGames]: CreateGamesHouseOf,
    [RoundTypes.RoundType.TheAnswerIsnt]: CreateTheAnswerIsnt,
    // [RoundTypes.RoundType.InTheQuestion]: () => {},
    [RoundTypes.RoundType.CineNyms]: CreateCineNyms,
    [RoundTypes.RoundType.HighbrowLowbrow]: CreateHighbrowLowbrow,
    [RoundTypes.RoundType.HouseOfGamers]: CreateGamesHouseOf,
    [RoundTypes.RoundType.HoseOfGames]: CreateGamesHouseOf,
    [RoundTypes.RoundType.DistinctlyAverage]: CreateGamesHouseOf,
    [RoundTypes.RoundType.DimSums]: CreateDimSums,
    [RoundTypes.RoundType.WhenTheySing]: CreateWhenTheySing,
    [RoundTypes.RoundType.YouCompleteMe]: CreateGamesHouseOf,
    [RoundTypes.RoundType.YouSpellTerrible]: CreateGamesHouseOf,
    [RoundTypes.RoundType.TotesEmoji]: CreateTotesEmoji,
    [RoundTypes.RoundType.ICompleteYou]: CreateGamesHouseOf,
    [RoundTypes.RoundType.FingerOnIt]: CreateFingerOnIt,
    [RoundTypes.RoundType.WhereIsKazakhstan]: CreateFingerOnIt,
    [RoundTypes.RoundType.RichList]: CreateAnswerList,
    [RoundTypes.RoundType.SizeMatters]: CreateAnswerList,
    [RoundTypes.RoundType.ZList]: CreateAnswerList,
    [RoundTypes.RoundType.TerribleDating]: CreateGamesHouseOf,
    [RoundTypes.RoundType.AnswerSmash]: CreateAnswerSmash,
};

export function getCreateRoundComponent<T extends RoundTypes.RoundType>(type: RoundTypes.RoundType): RoundComponent<T> {
    return RoundComponentMap[type] as unknown as RoundComponent<T>;
}
