import { CoordinateMarkerType } from './coordinate-marker';

enum Color {
    'Red' = 'Red',
    'Orange' = 'Orange',
    'Yellow' = 'Yellow',
    'Green' = 'Green',
    'Teal' = 'Teal',
    'Blue' = 'Blue',
    'Purple' = 'Purple',
    'Pink' = 'Pink',
}

const pairs: { [key in Color]: { dark: string; light: string } } = {
    [Color.Red]: {
        dark: '#711A14',
        light: '#FE6C5E',
    },
    [Color.Orange]: {
        dark: '#713414',
        light: '#FD8E52',
    },
    [Color.Yellow]: {
        dark: '#715B14',
        light: '#FDE075',
    },
    [Color.Green]: {
        dark: '#3B7114',
        light: '#8AD058',
    },
    [Color.Teal]: {
        dark: '#147153',
        light: '#61B399',
    },
    [Color.Blue]: {
        dark: '#143471',
        light: '#4C86F0',
    },
    [Color.Purple]: {
        dark: '#481471',
        light: '#8F11F0',
    },
    [Color.Pink]: {
        dark: '#71143C',
        light: '#E93181',
    },
};

const numberOfColors = Object.values(pairs).length;
const numberOfColorCombinations = numberOfColors * 2;
const numberOfMarkerTypes = Object.values(CoordinateMarkerType).length;

export function getCoordinateMarkerStyle(index: number) {
    const markerTypeIndex = Math.floor(index / numberOfColorCombinations) % numberOfMarkerTypes;
    const colorCombinationIndex = index % numberOfColorCombinations;
    const colorIndex = index % numberOfColors;

    const markerType = Object.values(CoordinateMarkerType)[markerTypeIndex];
    const color = Object.values(pairs)[colorIndex];

    if (colorCombinationIndex < numberOfColors) {
        return {
            fillColor: color.dark,
            borderColor: color.light,
            type: markerType,
        };
    } else {
        return {
            fillColor: color.light,
            borderColor: color.dark,
            type: markerType,
        };
    }
}
