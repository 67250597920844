import { MutableRefObject, useMemo } from 'react';

import { useRefState } from '../../components/ref-state/use-ref-state';
import { MessageName, OptionMessage } from '../../constants/message';

type GameOptions = {
    themeSong: boolean;
    clientSideAudio: boolean;
    buzzerTimerEnabled: boolean;
    buzzerTimerSeconds: number;
};

type UseGameOptionsReturn = [
    MutableRefObject<GameOptions>,
    <T extends keyof GameOptions>(key: T, value: GameOptions[T]) => void,
];

const LOCALSTORAGE_KEY = 'ModifiedGameOptions';

const defaultGameOptions: GameOptions = {
    themeSong: true,
    clientSideAudio: false,
    buzzerTimerEnabled: true,
    buzzerTimerSeconds: 5,
};

const saveableGameOptions: Array<keyof GameOptions> = ['themeSong', 'buzzerTimerEnabled', 'buzzerTimerSeconds'];

export function useGameOptions(sendMessage: (message: OptionMessage) => void): UseGameOptionsReturn {
    const storedDefaults = useMemo(() => {
        const storedDefaultsString = localStorage.getItem(LOCALSTORAGE_KEY);
        if (storedDefaultsString) {
            try {
                const defaults = JSON.parse(storedDefaultsString);
                return defaults;
            } catch (e) {
                localStorage.removeItem(LOCALSTORAGE_KEY);
            }
        }

        return {};
    }, []);

    const [_options, setOptions, options] = useRefState<GameOptions>({ ...defaultGameOptions, ...storedDefaults });

    function setOption<T extends keyof GameOptions>(key: T, value: GameOptions[T]) {
        if (saveableGameOptions.includes(key)) {
            storedDefaults[key] = value;
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(storedDefaults));
        }

        const newOptions = { ...options.current };
        newOptions[key] = value;

        setOptions(newOptions);
        sendMessage({ name: MessageName.option, data: { option: key, value } as OptionMessage['data'] });
    }

    return [options, setOption];
}
