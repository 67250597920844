import './tooltip.scss';

import classNames from 'classnames';
import React, { useRef, useState } from 'react';

type TooltipProps = {
    className?: string;
    children: React.ReactNode;
    text: string;
    position?: 'top' | 'left' | 'right' | 'bottom';
    delayMs?: number;
};

const positionMap = {
    top: { bottom: '100%', left: '50%', transform: 'translateX(-50%)' },
    bottom: { top: '100%', left: '50%', transform: 'translateX(-50%)' },
    left: { right: '100%', top: '50%', transform: 'translateY(-50%)' },
    right: { left: '100%', top: '50%', transform: 'translateY(-50%)' },
};

export function Tooltip({ className, children, text, position = 'bottom', delayMs }: TooltipProps) {
    const [isShowingTooltip, setIsShowingTooltip] = useState(false);
    const tooltipTimeoutRef = useRef<NodeJS.Timeout>();

    const containerClasses = classNames('tooltip__container', {
        [className]: Boolean(className),
    });

    const tooltipClasses = classNames('tooltip', {
        'tooltip--visible': isShowingTooltip,
    });

    function handleMouseEnter() {
        tooltipTimeoutRef.current = setTimeout(() => {
            setIsShowingTooltip(true);
        }, delayMs ?? 1000);
    }

    function handleMouseLeave() {
        clearTimeout(tooltipTimeoutRef.current);
        setIsShowingTooltip(false);
    }

    return (
        <div className={containerClasses} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {text && (
                <div className={tooltipClasses} style={positionMap[position]}>
                    {text}
                </div>
            )}
        </div>
    );
}
