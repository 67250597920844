import { RoundType } from '../types/round';
import { Field, FieldType, imageDefinition, validateField } from './round-validators';

type GameStateValidator = (gameState: unknown) => void;

const defaultGameStateDefinition: Field = {
    type: FieldType.object,
    of: {
        roundType: {
            oneOf: [...Object.values(RoundType), undefined],
        },
    },
};

const whenTheySingGameStateDefinition: Field = {
    type: FieldType.object,
    of: {
        roundType: {
            oneOf: [RoundType.WhenTheySing],
        },
        startTime: {
            type: FieldType.number,
            length: 16,
        },
        clientSideAudio: {
            type: FieldType.boolean,
        },
        fileUrl: {
            type: FieldType.string,
            length: 700,
        },
        fadeOut: {
            maxValue: 30,
            length: 5,
            type: FieldType.number,
        },
    },
};

const fingerOnItGameStateDefinition: Field = {
    type: FieldType.object,
    of: {
        roundType: {
            oneOf: [...Object.values(RoundType), undefined],
        },
        image: imageDefinition,
    },
};

export const gameStateDefinitions: { [key in RoundType]?: Field } = {
    [RoundType.FingerOnIt]: fingerOnItGameStateDefinition,
    [RoundType.WhereIsKazakhstan]: fingerOnItGameStateDefinition,
    [RoundType.WhenTheySing]: whenTheySingGameStateDefinition,
};

function createValidator(roundType: RoundType | undefined): GameStateValidator {
    const key = roundType || 'Title Slide';
    const definition = getGameStateDefinition(roundType);
    return (gameState) => validateField(key, definition, gameState);
}

const gameStateValidators: { [key in RoundType]?: GameStateValidator } = {
    [RoundType.FingerOnIt]: createValidator(RoundType.FingerOnIt),
    [RoundType.WhereIsKazakhstan]: createValidator(RoundType.WhereIsKazakhstan),
};

export function getGameStateDefinition(roundType: RoundType | undefined): Field {
    return gameStateDefinitions[roundType!] || defaultGameStateDefinition;
}

export function getGameStateValidator(roundType: RoundType | undefined): GameStateValidator {
    return gameStateValidators[roundType!] || createValidator(roundType);
}
