type LockProps = {
    scale?: number;
    isUnlocked?: boolean;
};

const lockedPath = (
    <path
        d="M15,15c0,0.7-0.4,1.4-1,1.7V19h-2v-2.3c-0.6-0.3-1-1-1-1.7c0-1.1,0.9-2,2-2S15,13.9,15,15z M21,16
c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-2.5,1.2-4.8,3-6.2V5c0-2.8,2.2-5,5-5s5,2.2,5,5v4.8C19.8,11.2,21,13.5,21,16z M10,8.6
C10.9,8.2,11.9,8,13,8c1.1,0,2.1,0.2,3,0.6V5c0-1.7-1.3-3-3-3s-3,1.3-3,3V8.6z M19,16c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,3.3,2.7,6,6,6
S19,19.3,19,16z"
    />
);

const unlockedPath = (
    <path
        d="M15,15c0,0.7-0.4,1.4-1,1.7V19h-2v-2.3c-0.6-0.3-1-1-1-1.7c0-1.1,0.9-2,2-2S15,13.9,15,15z M21,16c0,4.4-3.6,8-8,8
s-8-3.6-8-8c0-2.5,1.2-4.8,3-6.2V5c0-1.7-0.3-3-2-3S4,3.3,4,5v5.2H2V5c0-2.8,1.2-5,4-5s4,2.2,4,5v3.6C10.9,8.2,11.9,8,13,8
C17.4,8,21,11.6,21,16z M19,16c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,3.3,2.7,6,6,6S19,19.3,19,16z"
    />
);

export function Lock({ scale = 1, isUnlocked }: LockProps) {
    const roundedScale = Math.round(24 * scale);
    let path = lockedPath;
    let classes = 'icon__lock icon__lock--locked';
    if (isUnlocked) {
        path = unlockedPath;
        classes = 'icon__lock icon__lock--unlocked';
    }

    return (
        <svg className={classes} width={`${roundedScale}`} height={`${roundedScale}`} viewBox="0 0 24 24">
            {path}
        </svg>
    );
}
