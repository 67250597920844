import { calculateNewVoteCount, CFRequestTypes, GameTypes } from '@house-of-games/common';
import { RateState } from '@house-of-games/common/lib/types/cloud-functions-request';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { ConfirmationModal } from '../../common/components/confrimation-modal/confirmation-modal';
import { Check } from '../../common/components/icons/check';
import { Download } from '../../common/components/icons/download';
import { Vote } from '../../common/components/icons/vote';
import { LoadingSpinner } from '../../common/components/loading-error/loading-spinner';
import { TextFlip } from '../../common/components/text-flip/text-flip';
import { FirebaseHelper } from '../../utils/firebase';
import { requestHandler } from '../../utils/request-handler';
import { useProfileContext } from '../auth-router/auth-context';

type SearchItemProps = {
    game: GameTypes.PrivateGame;
};

export function SearchItem({ game }: SearchItemProps) {
    const [gameExistsModal, setGameExistsModal] = useState<GameTypes.PrivateGame>(null);
    const [isNotEnoughStorage, setIsNotEnoughStorage] = useState(false);
    const [storageResponse, setStorageResponse] = useState<CFRequestTypes.VerifyCopyStorageResponse>();
    const profile = useProfileContext();
    const initialIsUpvoted = useRef(game.rating?.like?.includes(profile.uid));
    const initialIsDownvoted = useRef(game.rating?.dislike?.includes(profile.uid));
    const [isUpvoted, setIsUpvoted] = useState(initialIsUpvoted.current);
    const [isDownvoted, setIsDownvoted] = useState(initialIsDownvoted.current);
    const [count, setCount] = useState(game.rating.count || 0);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingString, setLoadingString] = useState<string>();

    const rounds = Object.values(game.rounds);
    const numberOfRounds = rounds.length;
    const numberOfQuestions = rounds.reduce((count, round) => {
        return count + round.questions.length;
    }, 0);

    const [isDownloaded, setIsDownloaded] = useState(false);
    const [downloadComponent, setDownloadComponent] = useState(
        <div onClick={handleDownloadClick}>
            <Download scale={1.3} />
        </div>,
    );

    useEffect(() => {
        if (isDownloaded) {
            setDownloadComponent(<Check scale={1.3} />);
        }
    }, [isDownloaded]);

    function handleDownloadClick() {
        const matchedGame = profile.games.find((g) => g.original?.publicUid === game.publicUid);
        if (matchedGame) {
            setGameExistsModal(matchedGame);
        } else {
            triggerDownload();
        }
    }

    async function triggerDownload(isStorageCheckOverride?: boolean) {
        try {
            setIsLoading(true);
            setStorageResponse(undefined);

            if (!isStorageCheckOverride) {
                setLoadingString('Checking Storage Requirements');
                const storageData = await requestHandler.verifyCopyStorage(game.id);
                setStorageResponse(storageData);
                if (storageData.requiredStorageBytes > storageData.availableStorageBytes) {
                    setIsLoading(false);
                    setIsNotEnoughStorage(true);
                    return;
                }
            }

            setLoadingString('Downloading');
            await requestHandler.createGame(game.name, game.id);
            setIsLoading(false);
            setIsDownloaded(true);
            setStorageResponse(undefined);
        } catch (e) {
            setIsLoading(false);
            setStorageResponse(undefined);
        }
    }

    function handleDownvote() {
        const rateState = isDownvoted ? RateState.Neutral : RateState.Disliked;
        setIsDownvoted(!isDownvoted);
        setIsUpvoted(false);

        handleVote(rateState);
    }

    function handleUpvote() {
        const rateState = isUpvoted ? RateState.Neutral : RateState.Liked;
        setIsUpvoted(!isUpvoted);
        setIsDownvoted(false);

        handleVote(rateState);
    }

    function renderGameExistsModal() {
        const message = (
            <>
                {`It looks like you already have a copy of this game saved as: ${gameExistsModal.name} (${gameExistsModal.id})`}
                <br />
                Would you like to add another copy to your collection?
            </>
        );
        return (
            <ConfirmationModal
                textElement={message}
                labels={{
                    decline: 'Cancel',
                    accept: 'Add',
                }}
                onAccept={() => {
                    setGameExistsModal(null);
                    triggerDownload();
                }}
                onDecline={() => setGameExistsModal(null)}
            />
        );
    }

    function renderNotEnoughStorageModal() {
        const { availableStorageString, requiredStorageString } = FirebaseHelper.createStorageStrings(storageResponse);
        const message = (
            <>
                {`This game requires ${requiredStorageString} of storage but you only have ${availableStorageString} left. You can still download this game but some questions will not work properly.`}
                <br />
                <br />
                Would you like to download this game anyway?
            </>
        );
        return (
            <ConfirmationModal
                textElement={message}
                labels={{
                    decline: 'Cancel',
                    accept: 'Download',
                }}
                onAccept={() => {
                    setIsNotEnoughStorage(false);
                    triggerDownload(true);
                }}
                onDecline={() => setIsNotEnoughStorage(false)}
            />
        );
    }

    function renderLoading() {
        return (
            <div className="loading-overlay__container">
                <LoadingSpinner />
                <div className="loading-text">{loadingString}</div>
            </div>
        );
    }

    async function handleVote(state: CFRequestTypes.RateState) {
        const newCount = calculateNewVoteCount(isUpvoted, isDownvoted, count, state);
        setCount(newCount);

        try {
            await requestHandler.rate(game.publicUid, state);
        } catch {
            setIsDownvoted(isDownvoted);
            setIsUpvoted(isUpvoted);
            setCount(count);
        }
    }

    return (
        <div className="search-item">
            {gameExistsModal && renderGameExistsModal()}
            {isNotEnoughStorage && renderNotEnoughStorageModal()}
            {isLoading && renderLoading()}
            <div className="search-item__name">{game.name}</div>
            <div className="search-item__info">
                {numberOfRounds} Rounds
                <br />
                {numberOfQuestions} Questions
            </div>
            <div className="search-item__rating">
                <div className={isDownvoted ? 'search-item__rate-down--voted' : ''} onClick={handleDownvote}>
                    <Vote isDownvote scale={0.5} />
                </div>
                <span className="search-item__rating-count">{count}</span>
                <div className={isUpvoted ? 'search-item__rate-up--voted' : ''} onClick={handleUpvote}>
                    <Vote scale={0.5} />
                </div>
            </div>
            <div className="search-item__download">
                <TextFlip text={downloadComponent} />
            </div>
            <Link draggable={false} to={`/search/${game.owner}`}>
                <div className="search-item__profile">More From This Creator</div>
            </Link>
        </div>
    );
}
