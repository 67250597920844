type BinProps = {
    scale?: number;
    isOpen?: boolean;
};

export function Bin({ scale = 1, isOpen }: BinProps) {
    return (
        <svg className="icon__bin" width={`${24 * scale}`} height={`${24 * scale}`} viewBox="0 0 24 24">
            {isOpen ? (
                <path d="M17.19,11.5H18v9c0,1.1-2.69,2-6,2s-6-0.9-6-2v-9h0.81c1.04,0.6,2.97,1,5.19,1S16.15,12.1,17.19,11.5z M18.19,8.5l-0.39,0.92c-0.37,0.89-3.15,0.57-6.21-0.71S6.37,5.67,6.74,4.78l0.39-0.92c0.24-0.57,1.46-0.64,3.1-0.29l1.17-2.79l1.15,0.48l0.23,0.1l3.23,1.35l0.23,0.1l1.15,0.48l-1.17,2.79C17.63,7.01,18.43,7.93,18.19,8.5z M15.44,4.09l-3.23-1.35L11.7,3.96c0.53,0.17,1.08,0.37,1.64,0.61c0.56,0.24,1.09,0.49,1.58,0.75L15.44,4.09z" />
            ) : (
                <path d="M17.19,11.5H18v9c0,1.1-2.69,2-6,2s-6-0.9-6-2v-9h0.81c1.04,0.6,2.97,1,5.19,1S16.15,12.1,17.19,11.5z M18,8.25v1c0,0.97-2.69,1.75-6,1.75s-6-0.78-6-1.75v-1c0-0.62,1.1-1.16,2.75-1.47V3.75H10h0.25h3.5H14h1.25v3.03C16.9,7.09,18,7.63,18,8.25zM13.75,5.25h-3.5v1.33C10.8,6.53,11.39,6.5,12,6.5s1.2,0.03,1.75,0.08V5.25z" />
            )}
        </svg>
    );
}
