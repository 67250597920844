import './play-broken-karaoke.scss';

import { RoundTypes } from '@house-of-games/common';
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { useRefState } from '../../../common/components/ref-state/use-ref-state';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const popSound = require('./pop.mp3');

type Question = SlideProps<RoundTypes.BrokenKaraokeRound>['question'];
function generateSingleCharacterArray(characters: Question['lines']) {
    const characterArray: Array<RoundTypes.BrokenKaraokeCharacter> = [];
    Object.values(characters).forEach((line) => {
        const isEmptyLine = line.length === 0;
        const isSingleEmptyCharacter = line.length === 1 && !line[0]?.character;
        if (isEmptyLine || isSingleEmptyCharacter) {
            characterArray.push({ character: 'newline', delayMs: 0 });
            return;
        }

        characterArray.push(...line);
        characterArray.push({ character: 'newline', delayMs: 0 });
    });
    return characterArray;
}

type PlayBrokenKaraokeProps = SlideProps<RoundTypes.BrokenKaraokeRound> & {
    startOverride?: boolean;
};

type BrokenKaraokeCharactersProps = {
    lines: RoundTypes.BrokenKaraokeRound['questions'][number]['lines'];
    isPlaying: boolean;
    audioElementRef: MutableRefObject<HTMLAudioElement>;
    onComplete?: () => void;
};

const noop = () => {
    // noop
};

export function BrokenKaraokeCharacters({
    audioElementRef,
    lines,
    isPlaying,
    onComplete = noop,
}: BrokenKaraokeCharactersProps) {
    const flatCharacters = useMemo(() => {
        return generateSingleCharacterArray(lines);
    }, []);

    const isDone = useRef(false);
    const currentLetter = useRef(0);
    const letterTimeout = useRef<number>(null);
    const [renderingLetters, setRenderingLetters, renderingLettersRef] = useRefState([]);

    useEffect(() => {
        audioElementRef.current.src = popSound;

        return () => {
            clearTimeout(letterTimeout.current);
        };
    }, []);

    useEffect(() => {
        if (isDone.current) {
            return;
        } else if (isPlaying) {
            recursiveLoop();
        } else if (!isPlaying) {
            clearTimeout(letterTimeout.current);
        }
    }, [isPlaying]);

    function pop() {
        audioElementRef.current.currentTime = 0;
        audioElementRef.current.play();
    }

    function recursiveLoop() {
        if (currentLetter.current >= flatCharacters.length) {
            isDone.current = true;
            onComplete();
            return;
        }

        const { character, delayMs } = flatCharacters[currentLetter.current];
        letterTimeout.current = window.setTimeout(() => {
            if (character !== 'newline') {
                pop();
            }
            currentLetter.current += 1;
            setRenderingLetters([...renderingLettersRef.current, character]);
            recursiveLoop();
        }, delayMs);
    }

    function renderCharacters() {
        const lines: Array<Array<React.ReactElement>> = [[]];
        flatCharacters.forEach(({ character }, i) => {
            if (character === 'newline') {
                lines.push([]);
                return;
            }
            const lastIndex = lines.length - 1;
            lines[lastIndex].push(
                <div key={i} className="play-broken-karaoke__character">
                    {renderingLetters[i] && character}
                </div>,
            );
        });

        return lines.map((line, i) => {
            return (
                <div key={i} className="play-broken-karaoke__line">
                    {line}
                </div>
            );
        });
    }

    return <div className="play-broken-karaoke__characters">{renderCharacters()}</div>;
}

export function PlayBrokenKaraoke({
    musicQuestionHelpers,
    question,
    startOverride,
    onPlayFinished,
}: PlayBrokenKaraokeProps) {
    const { audioElementRef, setMessageHandler } = musicQuestionHelpers;
    const isStarted = useRef(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isRevealed, setIsRevealed] = useState(false);
    const isDone = useRef(false);

    useEffect(() => {
        setMessageHandler((msg: Message) => handleMessageReceived(msg));
    }, []);

    useEffect(() => {
        setIsPlaying(startOverride);
    }, [startOverride]);

    function handleMessageReceived(message: Message) {
        switch (message.name) {
            case MessageName.play:
                isStarted.current = true;
                setIsPlaying(true);
                break;
            case MessageName.pause:
            case MessageName.buzzed:
                setIsPlaying(false);
                break;
            case MessageName.reveal:
                setIsRevealed(true);
                break;
            case MessageName.clear:
                if (!isDone.current && isStarted.current) {
                    setIsPlaying(true);
                }
                break;
        }
    }

    function handleCharactersDone() {
        isDone.current = true;
        setIsPlaying(false);
        onPlayFinished();
    }

    return (
        <div className="play-broken-karaoke slide page">
            <div className="play-broken-karaoke__year">{question.year}</div>
            <BrokenKaraokeCharacters
                audioElementRef={audioElementRef}
                isPlaying={isPlaying}
                lines={question.lines}
                onComplete={handleCharactersDone}
            />
            <div className="play-broken-karaoke__answer">
                <TextFlip text={isRevealed ? `${question.song} - ${question.artist}` : null} />
            </div>
        </div>
    );
}
