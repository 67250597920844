import { RoundTypes } from '@house-of-games/common';
import { useEffect, useState } from 'react';

import { MessageName } from '../../../common/constants/message';
import { processWithoutTrailingPunctuation } from '../../../utils/process-without-trailing-punctuation';
import { SlideProps } from '../../game/game';
import { QuestionAnswerComponent } from '../common/question-answer';

export function PlayGamesHouseOf({
    question,
    message,
    musicQuestionHelpers,
}: SlideProps<RoundTypes.GamesHouseOfRound>) {
    const [isRevealed, setIsRevealed] = useState(false);
    const [isAlphabetical, setIsAlphabetical] = useState(true);

    useEffect(() => {
        switch (message.name) {
            case MessageName.reveal:
                setIsRevealed(true);
                setTimeout(() => {
                    setIsAlphabetical(false);
                }, 3000);
        }
    }, [message]);

    function alphabetize() {
        return processWithoutTrailingPunctuation(question.answer, (str) => {
            return str.toLowerCase().split(' ').sort().join(' ');
        });
    }

    function getAnswerText() {
        if (!isRevealed) {
            return null;
        } else if (isAlphabetical) {
            return alphabetize();
        } else {
            return question.answer;
        }
    }
    return (
        <QuestionAnswerComponent
            question={question.question}
            answer={getAnswerText()}
            musicQuestionHelpers={musicQuestionHelpers}
        />
    );
}
