import './create-rhyme-time.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';

import { Button } from '../../../common/components/buttons/buttons';
import { QuestionArea } from '../common/question-area';
import { RoundProps } from '../create-round-component-map';

type Question = RoundTypes.RhymeTimeRound['questions'][0];

type AssociatedRounds =
    | RoundProps<RoundTypes.RhymeTimeRound>
    | RoundProps<RoundTypes.RoonerspismsRound>
    | RoundProps<RoundTypes.KingJumbleRound>;

export function CreateRhymeTime({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: AssociatedRounds) {
    const { questions } = round;
    const definition = roundDefinitions[round.type].of;

    function handleNewQuestion() {
        createNewQuestion({ 0: {}, 1: {} });
    }

    function handleQuestionChanged(i: number, j: 0 | 1, q: Question[0]) {
        const newQuestion = { ...questions[i] };
        newQuestion[j] = q;
        updateQuestion(i, newQuestion);
    }

    return (
        <div className="create-rhyme-time">
            {questions.map((qs, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-rhyme-time-question__container">
                        {Object.values(qs).map((q, j) => {
                            const isArrowsEnabled = j === 0;
                            const onRemove = isArrowsEnabled ? undefined : removeQuestion;
                            return (
                                <div key={`${uniqueKey}-${i}-${j}`} className="create-rhyme-time-question-area">
                                    <QuestionArea
                                        index={i}
                                        {...q}
                                        maxPromptLength={definition.of.prompt.length}
                                        maxQuestionLength={definition.of.question.length}
                                        maxAnswerLength={definition.of.answer.length}
                                        isArrowsEnabled={isArrowsEnabled}
                                        allowedQuestionTypes={[
                                            RoundTypes.QuestionType.Text,
                                            RoundTypes.QuestionType.Audio,
                                            RoundTypes.QuestionType.Image,
                                        ]}
                                        numberOfQuestions={questions.length}
                                        onChange={(i, q) => handleQuestionChanged(i, j as 0 | 1, q)}
                                        onClickUpArrow={shiftUp}
                                        onClickDownArrow={shiftDown}
                                        onRemove={onRemove}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
