import { GameTypes } from '@house-of-games/common';
import React, { useEffect, useRef, useState } from 'react';

import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { without } from '../../utils/array';
import { Slide } from '../control/slide-control';

type Players = GameTypes.PublicGame['players'];

export function useBuzzer(
    players: Players,
    currentSlide: Slide,
    audioRef: React.MutableRefObject<HTMLAudioElement>,
): [Array<string>, GameTypes.Player, string, boolean, (playersToUnBuzz: Array<string> | 'all') => void, () => void] {
    const previousPlayers = useRef<Players>(players);
    // const buzzedThisSlide = useRef<Array<GameTypes.UserId>>([]);
    const [buzzedThisSlide, setBuzzedThisSlide, buzzedThisSlideRef] = useRefState<Array<GameTypes.UserId>>([]);
    const [activeBuzzer, setActiveBuzzer] = useState<GameTypes.Player>();
    const [activeBuzzerTextAnswer, setActiveBuzzerTextAnswer] = useState<string>();
    const isBuzzerEnabled = currentSlide && 'isBuzzerEnabled' in currentSlide && currentSlide?.isBuzzerEnabled;

    function getNewBuzzerIds() {
        // Finds player IDs where their "buzzer" value has changed since last "players" update
        // and they haven't buzzed already this slide
        return Object.keys(players).filter(
            (uid) =>
                players[uid]?.buzzer &&
                players[uid]?.buzzer !== previousPlayers.current[uid]?.buzzer &&
                !buzzedThisSlide.includes(uid),
        );
    }

    function setActiveBuzzerStates(newBuzzedIds: Array<string>) {
        const sortedByFastest = newBuzzedIds.sort((a, b) => players[a].buzzer - players[b].buzzer);
        const playerId = sortedByFastest[0];
        const player = players[playerId];
        const isEarlierThanExisting = activeBuzzer?.buzzer > player.buzzer;
        let newBuzzedThisSlide = [...buzzedThisSlideRef.current];
        if (isEarlierThanExisting) {
            newBuzzedThisSlide = without<GameTypes.UserId>(buzzedThisSlide, activeBuzzer.id);
        }

        const shouldOverwrite = !activeBuzzer || isEarlierThanExisting;
        if (shouldOverwrite) {
            setBuzzedThisSlide([...newBuzzedThisSlide, playerId]);
            setActiveBuzzerTextAnswer(undefined);
            setActiveBuzzer(player);
            audioRef.current.volume = 0.4;
            audioRef.current.play();
        }
    }

    function populateBuzzerData() {
        const newBuzzedIds = getNewBuzzerIds();

        const textAnswer = players[activeBuzzer?.id]?.answer;
        if (textAnswer && textAnswer !== activeBuzzerTextAnswer) {
            setActiveBuzzerTextAnswer(textAnswer.toString());
        }

        if (newBuzzedIds.length === 0) {
            return;
        }

        // "Active Buzzer" scenario is when the buzzer will show up in game.
        // In this scenario we must make sure to only add IDs to `buzzedThisSlide`
        // if they became the "Active Buzzer". Any buzzes WHILE there is an active buzzer
        // will be ignored (unless there is server lag and they somehow buzzed before, in which
        // case the active buzzer will be overwritten and removed from `buzzedThisSlide`)
        if (isBuzzerEnabled) {
            setActiveBuzzerStates(newBuzzedIds);
            return;
        }

        setBuzzedThisSlide([...buzzedThisSlideRef.current, ...newBuzzedIds]);
    }

    function unBuzz(playerIds: Array<string> | 'all') {
        let shouldClearActiveBuzzer: boolean;

        if (playerIds === 'all') {
            shouldClearActiveBuzzer = true;
            setBuzzedThisSlide([]);
        }

        shouldClearActiveBuzzer = shouldClearActiveBuzzer || playerIds.includes(activeBuzzer?.id);
        setBuzzedThisSlide(without(buzzedThisSlideRef.current, playerIds));

        if (shouldClearActiveBuzzer) {
            clearActiveBuzzer();
        }
    }

    function clearActiveBuzzer() {
        setActiveBuzzer(undefined);
        setActiveBuzzerTextAnswer(undefined);
    }

    useEffect(() => {
        populateBuzzerData();
        previousPlayers.current = players;
    }, [players]);

    useEffect(() => {
        unBuzz('all');
    }, [currentSlide]);

    return [buzzedThisSlide, activeBuzzer, activeBuzzerTextAnswer, isBuzzerEnabled, unBuzz, clearActiveBuzzer];
}
