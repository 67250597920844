import { RoundTypes } from '@house-of-games/common';
import { useEffect, useState } from 'react';

import { MessageName } from '../../../common/constants/message';
import { processWithoutTrailingPunctuation } from '../../../utils/process-without-trailing-punctuation';
import { SlideProps } from '../../game/game';
import { QuestionAnswerComponent } from '../common/question-answer';

export function PlayBackwards({ question, message, musicQuestionHelpers }: SlideProps<RoundTypes.BackwardsRound>) {
    const [isRevealed, setIsRevealed] = useState(false);
    const [isBackwards, setIsBackwards] = useState(true);

    useEffect(() => {
        switch (message.name) {
            case MessageName.reveal:
                setIsRevealed(true);
                setTimeout(() => {
                    setIsBackwards(false);
                }, 3000);
        }
    }, [message]);

    function reverse(str: string) {
        return processWithoutTrailingPunctuation(str, (res) => {
            return res.toLowerCase().split(' ').reverse().join(' ');
        });
    }

    function getAnswerText() {
        if (!isRevealed) {
            return null;
        } else if (isBackwards) {
            return reverse(question.answer);
        } else {
            return question.answer;
        }
    }
    return (
        <QuestionAnswerComponent
            question={reverse(question.question)}
            answer={getAnswerText()}
            musicQuestionHelpers={musicQuestionHelpers}
        />
    );
}
