const endsWithPunctuation = /[^0-9\p{L}]$/u;

export function processWithoutTrailingPunctuation(str: string, processor: (str: string) => string) {
    if (endsWithPunctuation.test(str)) {
        const endingPunctuation = str.slice(-1);
        const stringToProcess = str.slice(0, -1);
        return `${processor(stringToProcess)}${endingPunctuation}`;
    }

    return processor(str);
}
