import './question-type-selector.scss';

import { RoundTypes } from '@house-of-games/common';
import classNames from 'classnames';

import { ImageNoBorder } from '../../../common/components/icons/image-no-border';
import { Music } from '../../../common/components/icons/music';
import { Pencil } from '../../../common/components/icons/pencil';

type QuestionTypeSelectorProps = {
    selectedQuestionType: RoundTypes.QuestionType;
    allowedTypes: Array<RoundTypes.QuestionType>;
    isMusicAllowed?: boolean;
    isImageAllowed?: boolean;
    onSelectImage?: () => void;
    onSelectMusic?: () => void;
    onSelectText?: () => void;
};

export function QuestionTypeSelector({
    allowedTypes,
    onSelectImage,
    onSelectMusic,
    onSelectText,
    selectedQuestionType,
}: QuestionTypeSelectorProps) {
    function getClassNames(questionType: RoundTypes.QuestionType) {
        const baseClass = 'question-type-selector__type';
        const classes = classNames(baseClass, {
            [`${baseClass}--selected`]: selectedQuestionType === questionType,
            [`${baseClass}--disabled`]: !allowedTypes.includes(questionType),
        });
        return classes;
    }

    return (
        <div className="question-type-selector__container">
            <div className={getClassNames(RoundTypes.QuestionType.Text)} onClick={onSelectText}>
                <Pencil />
            </div>
            <div className={getClassNames(RoundTypes.QuestionType.Audio)} onClick={onSelectMusic}>
                <Music />
            </div>
            <div className={getClassNames(RoundTypes.QuestionType.Image)} onClick={onSelectImage}>
                <ImageNoBorder />
            </div>
        </div>
    );
}
