import { RoundTypes } from '@house-of-games/common';
import { useEffect, useRef, useState } from 'react';

import { Button } from '../../../../common/components/buttons/buttons';
import { BrokenKaraokeCharacters } from '../play-broken-karaoke';

type Question = RoundTypes.BrokenKaraokeRound['questions'][0];

type KaraokePreviewProps = {
    lines: Question['lines'];
    onBack: () => void;
    onDone: () => void;
    isPreviewOnly: boolean;
};

export function KaraokePreview({ lines, onBack, onDone, isPreviewOnly }: KaraokePreviewProps) {
    const audioElementRef = useRef<HTMLAudioElement>();
    const playingTimeout = useRef<number>();
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        playingTimeout.current = window.setTimeout(() => {
            setIsPlaying(true);
        }, 1200);

        return () => {
            clearTimeout(playingTimeout.current);
        };
    }, []);

    return (
        <>
            <div className="karaoke-capture-modal__page-preview">
                <audio ref={audioElementRef} />
                <BrokenKaraokeCharacters audioElementRef={audioElementRef} lines={lines} isPlaying={isPlaying} />
            </div>
            <div className="karaoke-capture-modal__buttons">
                {isPreviewOnly ? (
                    <Button
                        className="karaoke-capture-modal__button karaoke-capture-modal__button--secondary"
                        label="Done"
                        onClick={onDone}
                    />
                ) : (
                    <>
                        <Button
                            className="karaoke-capture-modal__button karaoke-capture-modal__button--secondary"
                            label="Back"
                            onClick={onBack}
                        />
                        <Button
                            className="karaoke-capture__button karaoke-capture__button--primary"
                            label="Save"
                            onClick={onDone}
                        />
                    </>
                )}
            </div>
        </>
    );
}
