import './control-buttons.scss';

import { RoundTypes } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { ClearBuzzer } from '../../../common/components/icons/clear-buzzer';
import { PlayPause } from '../../../common/components/icons/play-pause';
import { Message, MessageName } from '../../../common/constants/message';
import { Slide, SlideType } from '../slide-control';

type ControlButtonsProps = {
    slide: Slide;
    message: Message;
    isMusicQuestion: boolean;
    sendMessage: (message: Message) => void;
    onClearBuzzer: () => void;
    onPlay: () => void;
    onPause: () => void;
};

function checkIsInteractiveRound(roundType: RoundTypes.RoundType) {
    return [RoundTypes.RoundType.BrokenKaraoke, RoundTypes.RoundType.ZToA, RoundTypes.RoundType.WhenTheySing].includes(
        roundType,
    );
}

export function ControlButtons({
    slide,
    message,
    isMusicQuestion,
    sendMessage,
    onClearBuzzer,
    onPlay,
    onPause,
}: ControlButtonsProps) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isBuzzed, setIsBuzzed] = useState(false);
    const [isInteractiveRound, setIsInteractiveRound] = useState(false);
    const isPlayTriggered = useRef(false);
    const isPlayFinished = useRef(false);

    function handlePlay() {
        isPlayTriggered.current = true;
        setIsPlaying(true);
        sendMessage({ name: MessageName.play });
        onPlay();
    }

    function handlePause() {
        setIsPlaying(false);
        sendMessage({ name: MessageName.pause });
        onPause();
    }

    function handlePlayPause() {
        if (isPlaying) {
            handlePause();
        } else {
            handlePlay();
        }
    }

    function handleClearBuzzer() {
        if (isBuzzed) {
            onClearBuzzer();

            if (isPlayTriggered.current && !isPlayFinished.current) {
                setIsPlaying(true);
            }

            setIsBuzzed(false);
            sendMessage({ name: MessageName.clear });
        }
    }

    useEffect(() => {
        setIsPlaying(false);
        setIsBuzzed(false);
        isPlayTriggered.current = false;
        isPlayFinished.current = false;

        if (slide.slideType === SlideType.Question && checkIsInteractiveRound(slide.roundType)) {
            setIsInteractiveRound(true);
        } else {
            setIsInteractiveRound(false);
        }
    }, [slide]);

    useEffect(() => {
        if (!message) {
            return;
        }

        switch (message.name) {
            case MessageName.buzzed:
                if (message.data.length > 0 && slide.slideType === SlideType.Question && slide.isBuzzerEnabled) {
                    setIsBuzzed(true);
                    setIsPlaying(false);
                }
                break;
            case MessageName.paused:
                setIsPlaying(false);
                break;
            case MessageName.finished:
                setIsPlaying(false);
                isPlayFinished.current = true;
                break;
        }
    }, [message]);

    function getPrimaryButton() {
        const primaryButtonClassName = 'control-button control-button__primary';
        // return <div className={primaryButtonClassName}><PlayPause isPlaying={isPlaying} scale={1} /></div>;
        if ((!isMusicQuestion && !isInteractiveRound) || isBuzzed) {
            const classes = classNames(primaryButtonClassName, {
                'control-button--disabled': !isBuzzed,
            });
            return (
                <div className={classes} onClick={handleClearBuzzer}>
                    <ClearBuzzer scale={1.3} />
                </div>
            );
        }

        return (
            <div className={primaryButtonClassName} onClick={handlePlayPause}>
                <PlayPause isPlaying={isPlaying} />
            </div>
        );
    }

    return <div className="control-buttons-container">{getPrimaryButton()}</div>;
}
