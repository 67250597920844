import { CFRequestTypes, GameState, GameTypes, PlayerAnswer, RoundTypes } from '@house-of-games/common';

import { FirebaseHelper } from './firebase';
import { retry } from './promise';

const prodUrl = `https://${window.location.host}/api`;
const localUrl = 'http://localhost:5000/api';
const cloudFunctionUrl = process.env.NODE_ENV === 'production' ? prodUrl : localUrl;

class RequestHandler {
    private authToken: string;

    public getToken = async () => {
        this.authToken = await FirebaseHelper.getAuth().currentUser.getIdToken();
    };

    public signUp = async (email: string, name: string, password: string): Promise<void> => {
        const payload: CFRequestTypes.SignUpPayload = {
            email,
            name,
            password,
        };

        await this.postFetch(`${cloudFunctionUrl}/sign-up`, payload, true);
    };

    public getPrivateProfile = async (): Promise<GameTypes.PrivateProfile> => {
        const profile = await this.postFetch<GameTypes.PrivateProfile>(`${cloudFunctionUrl}/my-profile`, {});
        return profile;
    };

    public createGame = async (name: string, cpId?: string): Promise<GameTypes.GameId> => {
        const payload: CFRequestTypes.CreateGamePayload = {
            name,
            cpId,
        };

        const gameId = await this.postFetch<GameTypes.GameId>(`${cloudFunctionUrl}/create-game`, payload);
        // const gameId = await this.responseWrapper<GameTypes.GameId>(res);
        return gameId;
    };

    public requestSession = async (gameId: GameTypes.GameId, preferredSessionId?: GameTypes.SessionId) => {
        const payload: CFRequestTypes.RequestSessionIdPayload = {
            gameId,
            preferredSessionId,
        };

        const { sessionId } = await this.postFetch<CFRequestTypes.RequestSessionIdResponse>(
            `${cloudFunctionUrl}/request-session`,
            payload,
        );
        // const gameId = await this.responseWrapper<GameTypes.GameId>(res);
        return sessionId;
    };

    public renameGame = async (gameId: GameTypes.GameId, name: string): Promise<void> => {
        const payload: CFRequestTypes.RenameGamePayload = {
            gameId,
            name,
        };

        await this.postFetch<GameTypes.GameId>(`${cloudFunctionUrl}/rename-game`, payload);
    };

    public deleteGames = async (gameIds: Array<GameTypes.GameId>): Promise<void> => {
        const payload: CFRequestTypes.DeleteGamesPayload = {
            gameIds,
        };

        await this.postFetch(`${cloudFunctionUrl}/delete-games`, payload);
    };

    public clearPlayer = async (gameId: GameTypes.GameId, playerId: string): Promise<void> => {
        const payload: CFRequestTypes.ClearPlayerPayload = {
            gameId,
            playerId,
        };

        await this.postFetch(`${cloudFunctionUrl}/clear-player`, payload);
    };

    public clearPlayers = async (gameId: GameTypes.GameId): Promise<void> => {
        const payload: CFRequestTypes.ClearPlayersPayload = {
            gameId,
        };

        await this.postFetch(`${cloudFunctionUrl}/clear-players`, payload);
    };

    public joinGame = async (sessionId: GameTypes.GameId, uid: string, name: string): Promise<GameTypes.GameId> => {
        const payload: CFRequestTypes.JoinGamePayload = {
            sessionId,
            uid,
            name,
        };

        const gameId = await this.postFetch<GameTypes.GameId>(`${cloudFunctionUrl}/join`, payload, true);
        return gameId;
    };

    public buzz = async (gameId: GameTypes.GameId, uid: string, timeStamp?: number): Promise<void> => {
        const payload: CFRequestTypes.BuzzPayload = {
            gameId,
            uid,
            timeStamp,
        };

        await this.postFetch(`${cloudFunctionUrl}/buzz`, payload, true);
    };

    public answer = async (
        gameId: GameTypes.GameId,
        uid: string,
        roundType: RoundTypes.RoundType,
        answer: PlayerAnswer,
    ): Promise<void> => {
        const payload: CFRequestTypes.AnswerPayload = {
            gameId,
            uid,
            roundType,
            answer,
        };

        await this.postFetch(`${cloudFunctionUrl}/answer`, payload, true);
    };

    public setGameState = async <T extends RoundTypes.RoundType = RoundTypes.RoundType>(
        gameId: GameTypes.GameId,
        gameState: GameState<T>,
    ): Promise<GameState<T>> => {
        const payload: CFRequestTypes.GameStatePayload = {
            gameId,
            gameState,
        };

        return await this.postFetch<GameState<T>>(`${cloudFunctionUrl}/set-game-state`, payload);
    };

    public submitRounds = async (gameId: GameTypes.GameId, rounds: Array<RoundTypes.Round>): Promise<void> => {
        const payload: CFRequestTypes.SubmitRoundsPayload = {
            gameId,
            rounds,
        };

        await this.postFetch(`${cloudFunctionUrl}/submit-rounds`, payload);
    };

    public moveRounds = async (gameId: GameTypes.GameId, rounds: GameTypes.PrivateGame['rounds']): Promise<void> => {
        const payload: CFRequestTypes.MoveRoundsPayload = {
            gameId,
            rounds,
        };

        await this.postFetch(`${cloudFunctionUrl}/move-rounds`, payload);
    };

    public deleteRounds = async (gameId: GameTypes.GameId, roundTypes: Array<RoundTypes.RoundType>): Promise<void> => {
        const payload: CFRequestTypes.DeleteRoundsPayload = {
            gameId,
            roundTypes,
        };

        await this.postFetch(`${cloudFunctionUrl}/delete-rounds`, payload);
    };

    public search = async (searchTerm: string): Promise<Array<GameTypes.PrivateGame>> => {
        const payload: CFRequestTypes.SearchPayload = {
            searchTerm,
        };

        const searchResults = await this.postFetch<Array<GameTypes.PrivateGame>>(`${cloudFunctionUrl}/search`, payload);
        return searchResults;
    };

    public verifyCopyStorage = async (gameId: GameTypes.GameId): Promise<CFRequestTypes.VerifyCopyStorageResponse> => {
        const payload: CFRequestTypes.VerifyCopyStorageRequest = {
            cpId: gameId,
        };

        const storageResponse = await this.postFetch<CFRequestTypes.VerifyCopyStorageResponse>(
            `${cloudFunctionUrl}/verify-copy-storage`,
            payload,
        );
        return storageResponse;
    };

    public rate = async (publicUid: string, state: CFRequestTypes.RateState): Promise<void> => {
        const payload: CFRequestTypes.RatePayload = {
            publicUid,
            state,
        };

        await this.postFetch(`${cloudFunctionUrl}/rate`, payload);
    };

    public makePublic = async (gameId: GameTypes.GameId, isPublic: boolean): Promise<void> => {
        const payload: CFRequestTypes.MakePublicPayload = {
            gameId,
            isPublic,
        };

        await this.postFetch(`${cloudFunctionUrl}/make-public`, payload);
    };

    public createAudioFileUrls = async (gameId: GameTypes.GameId): Promise<CFRequestTypes.CreateFileUrlsResponse> => {
        const createAudioFileUrlsResponse = await this.postFetch<CFRequestTypes.CreateFileUrlsResponse>(
            `${cloudFunctionUrl}/create-audio-file-urls`,
            {
                gameId,
            },
        );
        return createAudioFileUrlsResponse;
    };

    public createImageFileUrls = async (gameId: GameTypes.GameId): Promise<CFRequestTypes.CreateFileUrlsResponse> => {
        const createImageFileUrlsResponse = await this.postFetch<CFRequestTypes.CreateFileUrlsResponse>(
            `${cloudFunctionUrl}/create-image-file-urls`,
            {
                gameId,
            },
        );
        return createImageFileUrlsResponse;
    };

    public requestUploadToken = async (
        fileType: CFRequestTypes.UploadFileType,
    ): Promise<CFRequestTypes.UploadTokenResponse> => {
        const payload: CFRequestTypes.UploadTokenRequest = {
            type: fileType,
        };

        const uploadTokenResponse = await this.postFetch<CFRequestTypes.UploadTokenResponse>(
            `${cloudFunctionUrl}/request-upload-token`,
            payload,
        );
        return uploadTokenResponse;
    };

    private async postFetch<T>(
        url: string,
        payload: { [key: string]: unknown },
        isUnauthenticatedRequest?: boolean,
    ): Promise<T> {
        return retry(
            async () => {
                if (!this.authToken && !isUnauthenticatedRequest) {
                    await this.getToken();
                }

                const headers: HeadersInit = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                };

                if (!isUnauthenticatedRequest && this.authToken) {
                    headers.Authorization = `Bearer ${this.authToken}`;
                }

                const res = await fetch(url, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(payload),
                });
                return await this.responseWrapper<T>(res);
            },
            2,
            (res) => res.customErrorCode === 'AUTH_EXPIRED',
        );
    }

    private async responseWrapper<T>(res: Response): Promise<T> {
        const contentType = res.headers.get('content-type');

        let responseData;
        switch (true) {
            case contentType.includes('text/html'):
                responseData = await res.text();
                break;
            case contentType.includes('application/json'):
                responseData = await res.json();
                break;
            default:
                responseData = res.statusText;
        }

        if (responseData.customErrorCode === 'AUTH_EXPIRED') {
            await this.getToken();
            throw responseData;
        }

        if (res.status >= 300) {
            throw { ...responseData, httpStatusCode: res.status };
        }

        return responseData;
    }
}

export const requestHandler = new RequestHandler();
