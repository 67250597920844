import './play-you-complete-me.scss';

import { RoundTypes, UserStorage } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { useDynamicTextSize } from '../../game/use-dynamic-text-size';
import { MusicQuestion } from '../common/music-question';

export function PlayYouCompleteMe({
    roundType,
    question,
    message,
    musicQuestionHelpers,
}: SlideProps<RoundTypes.YouCompleteMeRound> | SlideProps<RoundTypes.ICompleteYouRound>) {
    const textSizeClass = useDynamicTextSize([question.question]);
    const [numberOfRevealed, setNumberOfRevealed] = useState(0);

    useEffect(() => {
        musicQuestionHelpers.preloadQuestions([question.question]).then(() => {
            musicQuestionHelpers.questionChanged(question.question);
        });
    }, []);

    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    const answerArray = question.answer.split(' ');
    const revealedArray = useMemo(() => {
        if (roundType === RoundTypes.RoundType.YouCompleteMe) {
            return answerArray.slice(0, numberOfRevealed);
        } else if (roundType === RoundTypes.RoundType.ICompleteYou) {
            return numberOfRevealed === 0 ? [] : answerArray.slice(-numberOfRevealed);
        }
    }, [numberOfRevealed]);

    function handleMessageReceived(msg: Message) {
        switch (msg.name) {
            case MessageName.reveal:
                setNumberOfRevealed(numberOfRevealed + 1);
                break;
        }
    }

    function renderQuestion() {
        if (UserStorage.checkIsValidUri(question.question)) {
            return <MusicQuestion />;
        }

        return (
            <AnimateExpandContainer className={`play-you-complete-me__question ${textSizeClass}`}>
                <TextFlip text={question.question} offset={300} />
            </AnimateExpandContainer>
        );
    }

    return (
        <div className="play-you-complete-me slide page">
            {renderQuestion()}
            <div className="play-you-complete-me__answers">
                {answerArray.map((word, i) => {
                    const classes = classNames('play-you-complete-me__answer-text', {
                        'play-you-complete-me__answer-text--visible': revealedArray.includes(word),
                    });

                    return (
                        <AnimateExpandContainer key={i} className="play-you-complete-me__answer">
                            <div className={classes}>{word}</div>
                        </AnimateExpandContainer>
                    );
                })}
            </div>
        </div>
    );
}
