import './play-totes-emoji.scss';

import { RoundTypes } from '@house-of-games/common';
import { useEffect, useMemo, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';

type AssociatedRounds = SlideProps<RoundTypes.TotesEmojiRound>;

export function PlayTotesEmoji({ question, message }: AssociatedRounds) {
    const [isRevealed, setIsRevealed] = useState(false);
    const category = useMemo(() => {
        if (question && 'category' in question) {
            return question.category;
        }
    }, []);

    useEffect(() => {
        switch (message.name) {
            case MessageName.reveal:
                setIsRevealed(true);
        }
    }, [message]);

    return (
        <div className="play-totes-emoji">
            {category && (
                <div className="play-totes-emoji__category">
                    <div className="box-header">{category}</div>
                </div>
            )}
            {question.author && (
                <TextFlip className="play-totes-emoji__author" text={`Clue Written By ${question.author}`} offset={0} />
            )}
            <AnimateExpandContainer className="play-totes-emoji__question">
                <TextFlip className="play-totes-emoji__question-text" text={question.question} offset={300} />
            </AnimateExpandContainer>
            <div className="play-totes-emoji__answer-container">
                {isRevealed && (
                    <AnimateExpandContainer className="play-totes-emoji__answer">
                        <TextFlip className="play-totes-emoji__answer-text" text={question.answer} offset={300} />
                    </AnimateExpandContainer>
                )}
            </div>
        </div>
    );
}
