import './confirmation-modal.scss';

import classNames from 'classnames';
import React from 'react';

import { Button } from '../buttons/buttons';
import { Close } from '../icons/close';

type ConfirmationModalProps = {
    className?: string;
    textElement: React.ReactNode;
    labels?: {
        accept: string;
        decline: string;
    };
    onAccept?: () => void;
    onDecline?: () => void;
    onClose?: () => void;
};

const defaultLabels = { accept: 'Accept', decline: 'Decline' };

export function ConfirmationModal({
    className,
    labels = defaultLabels,
    onAccept,
    onClose,
    onDecline,
    textElement,
}: ConfirmationModalProps) {
    const containerClasses = classNames('confirmation-modal__container', {
        [className]: className,
    });

    return (
        <div className={containerClasses}>
            <div className="confirmation-modal readable">
                {onClose && (
                    <div className="modal-close__container" onClick={onClose}>
                        <Close scale={0.6} />
                    </div>
                )}
                <div className="modal-text">{textElement}</div>
                <div className="modal-buttons__container">
                    {onDecline && (
                        <Button className="modal-button__decline" label={labels.decline} onClick={onDecline} />
                    )}
                    {onAccept && <Button className="modal-button__accept" label={labels.accept} onClick={onAccept} />}
                </div>
            </div>
        </div>
    );
}
