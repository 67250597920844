import './coordinate-marker.scss';

import classNames from 'classnames';
import React from 'react';

export enum CoordinateMarkerType {
    'Solid' = 'Solid',
    'Striped' = 'Striped',
    'Dashed' = 'Dashed',
}

type CoordinateSvgProps = {
    fillColor: string;
    borderColor: string;
};

function CoordinateMarkerSolid({ fillColor, borderColor }: CoordinateSvgProps) {
    return (
        <>
            <g>
                <path
                    className="coordinate-marker__fill"
                    fill={fillColor}
                    d="M10,19.5L10,19.5c-5.25,0-9.5-4.25-9.5-9.5V0.5H10c5.25,0,9.5,4.25,9.5,9.5v0C19.5,15.25,15.25,19.5,10,19.5z"
                />
            </g>
            <g>
                <path
                    className="coordinate-marker__path"
                    fill={borderColor}
                    d="M10,3c3.86,0,7,3.14,7,7c0,3.86-3.14,7-7,7c-3.86,0-7-3.14-7-7V3H10 M10,0H0v10c0,5.52,4.48,10,10,10h0
		c5.52,0,10-4.48,10-10v0C20,4.48,15.52,0,10,0L10,0z"
                />
            </g>
        </>
    );
}

function CoordinateMarkerDashed({ fillColor, borderColor }: CoordinateSvgProps) {
    return (
        <>
            <g>
                <path
                    className="coordinate-marker__fill"
                    fill={fillColor}
                    d="M10,20L10,20C4.48,20,0,15.52,0,10V0h10c5.52,0,10,4.48,10,10v0C20,15.52,15.52,20,10,20z"
                />
            </g>
            <g>
                <polygon fill={borderColor} points="3,4 0,4 0,0 4,0 4,3 3,3 		" />
                <path
                    className="coordinate-marker__path"
                    fill={borderColor}
                    d="M10.08,20l-0.02-3c1.42-0.01,2.78-0.44,3.94-1.25l1.71,2.46C14.05,19.37,12.1,19.98,10.08,20z M6.55,19.39
			c-1.9-0.7-3.52-1.94-4.7-3.59l2.44-1.74c0.82,1.16,1.96,2.03,3.29,2.51L6.55,19.39z M18.23,15.68l-2.47-1.71
			c0.8-1.16,1.23-2.53,1.23-3.95l3,0.01C19.99,12.06,19.38,14.01,18.23,15.68z M0.33,12.56C0.11,11.73,0,10.86,0,10V7.1h3V10
			c0,0.61,0.08,1.21,0.23,1.79L0.33,12.56z M16.56,7.56c-0.49-1.32-1.37-2.46-2.53-3.28l1.73-2.45c1.65,1.17,2.9,2.79,3.61,4.68
			L16.56,7.56z M11.76,3.22C11.19,3.08,10.6,3,10,3H7.06V0H10c0.85,0,1.7,0.11,2.51,0.32L11.76,3.22z"
                />
            </g>
        </>
    );
}

function CoordinateMarkerStriped({ fillColor, borderColor }: CoordinateSvgProps) {
    return (
        <>
            <g>
                <path
                    className="coordinate-marker__fill"
                    fill={fillColor}
                    d="M10,20L10,20C4.48,20,0,15.52,0,10V0h10c5.52,0,10,4.48,10,10v0C20,15.52,15.52,20,10,20z"
                />
            </g>
            <g>
                <path
                    className="coordinate-marker__path"
                    fill={borderColor}
                    d="M10,2c4.41,0,8,3.59,8,8c0,4.41-3.59,8-8,8c-4.41,0-8-3.59-8-8V2H10 M10,1H1v9c0,4.97,4.03,9,9,9h0c4.97,0,9-4.03,9-9v0
			C19,5.03,14.97,1,10,1L10,1z"
                />
            </g>
            <g>
                <path
                    className="coordinate-marker__path"
                    fill={borderColor}
                    d="M10,4c3.31,0,6,2.69,6,6c0,3.31-2.69,6-6,6c-3.31,0-6-2.69-6-6V4H10 M10,3H3v7c0,3.87,3.13,7,7,7h0c3.87,0,7-3.13,7-7v0
			C17,6.13,13.87,3,10,3L10,3z"
                />
            </g>
        </>
    );
}

export type CoordinateMarkerProps = CoordinateSvgProps & {
    type?: CoordinateMarkerType;
    scale?: number;
    hoverText?: string;
    style?: React.CSSProperties;
    className?: string;
};

const CoordinateMarkerTypeMap = {
    [CoordinateMarkerType.Dashed]: CoordinateMarkerDashed,
    [CoordinateMarkerType.Solid]: CoordinateMarkerSolid,
    [CoordinateMarkerType.Striped]: CoordinateMarkerStriped,
};

export function CoordinateMarker({
    className,
    type = CoordinateMarkerType.Solid,
    scale = 1,
    style,
    hoverText,
    fillColor,
    borderColor,
}: CoordinateMarkerProps) {
    const roundedScale = Math.round(20 * scale);
    const svgProps = { fillColor, borderColor };
    const Marker = CoordinateMarkerTypeMap[type];

    const classes = classNames('coordinate-marker', {
        [className]: className,
    });

    return (
        <div className={classes} style={style}>
            <svg
                className="coordinate-marker__svg"
                width={`${roundedScale}`}
                height={`${roundedScale}`}
                viewBox="0 0 20 20"
            >
                {<Marker {...svgProps} />}
            </svg>
            {hoverText && (
                <span
                    className="coordinate-marker__text"
                    style={{ border: `3px solid ${borderColor}`, color: borderColor, background: fillColor }}
                >
                    {hoverText}
                </span>
            )}
        </div>
    );
}
