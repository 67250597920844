import './styles/base.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthRouter } from './modules/auth-router/auth-router';
import { RecommendedBrowserBanner } from './modules/recommended-browser-banner/recommended-browser-banner';
import * as serviceWorker from './serviceWorker';

if (window.BroadcastChannel === undefined) {
    require('./broadcast-channel-polyfill');
}

ReactDOM.render(
    <React.StrictMode>
        <RecommendedBrowserBanner />
        <Router>
            <div className="app">
                {/* XMAS_THEME */}
                {/* {!window.location.pathname.includes('control') && (
                    <div className="winter-is-coming">
                        <div className="snow snow--near"></div>
                        <div className="snow snow--near snow--alt"></div>

                        <div className="snow snow--mid"></div>
                        <div className="snow snow--mid snow--alt"></div>

                        <div className="snow snow--far"></div>
                        <div className="snow snow--far snow--alt"></div>
                    </div>
                )} */}
                <AuthRouter />
            </div>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
