export function retry<T>(asyncFn: () => Promise<T>, times: number, condition?: (error: any) => boolean): Promise<T> {
    return new Promise(async (resolve, reject) => {
        let error;
        for (let index = 0; index < times; index++) {
            try {
                const res = await asyncFn();
                return resolve(res);
            } catch (e) {
                if (condition && !condition(e)) {
                    return reject(e);
                } else {
                    console.error('[PROMISE:RETRY] Retrying promise due to error: ', e);
                    error = e;
                }
            }
        }

        console.error('[PROMISE:RETRY] Ran out of retries with error: ', error);
        reject(error);
    });
}
