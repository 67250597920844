import './create-games-house-of.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';

import { Button } from '../../../common/components/buttons/buttons';
import { QuestionArea } from '../common/question-area';
import { RoundProps } from '../create-round-component-map';

type AssociatedRounds =
    | RoundProps<RoundTypes.GamesHouseOfRound>
    | RoundProps<RoundTypes.BackwardsRound>
    | RoundProps<RoundTypes.PastTenseRound>
    | RoundProps<RoundTypes.MouseOfGamesRound>
    | RoundProps<RoundTypes.HouseOfGamersRound>
    | RoundProps<RoundTypes.HoseOfGamesRound>
    | RoundProps<RoundTypes.DistinctlyAverageRound>
    | RoundProps<RoundTypes.YouCompleteMeRound>
    | RoundProps<RoundTypes.YouSpellTerribleRound>
    | RoundProps<RoundTypes.ICompleteYouRound>
    | RoundProps<RoundTypes.TerribleDatingRound>;

const musicRounds: ReadonlyArray<RoundTypes.RoundType> = [
    RoundTypes.RoundType.GamesHouseOf,
    RoundTypes.RoundType.PastTense,
    RoundTypes.RoundType.YouCompleteMe,
    RoundTypes.RoundType.YouSpellTerrible,
    RoundTypes.RoundType.ICompleteYou,
    RoundTypes.RoundType.TerribleDating,
];

const imageRounds: ReadonlyArray<RoundTypes.RoundType> = [...musicRounds, RoundTypes.RoundType.Backwards];

export function CreateGamesHouseOf({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: AssociatedRounds) {
    const { questions } = round;
    const definition = roundDefinitions[round.type].of;

    function handleNewQuestion() {
        createNewQuestion({});
    }

    const allowedQuestionTypes = [RoundTypes.QuestionType.Text];
    if (musicRounds.includes(round.type)) {
        allowedQuestionTypes.push(RoundTypes.QuestionType.Audio);
    }

    if (imageRounds.includes(round.type)) {
        allowedQuestionTypes.push(RoundTypes.QuestionType.Image);
    }

    return (
        <div className="create-games-house-of">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-games-house-of-question__container">
                        <QuestionArea
                            index={i}
                            {...q}
                            maxPromptLength={definition.prompt.length}
                            maxQuestionLength={definition.question.length}
                            maxAnswerLength={definition.answer.length}
                            isArrowsEnabled
                            allowedQuestionTypes={allowedQuestionTypes}
                            numberOfQuestions={questions.length}
                            onChange={updateQuestion}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                            onRemove={removeQuestion}
                        />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
