import './play-finger-on-it.scss';

import { GameTypes, RoundTypes } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { getCoordinateMarkerStyle } from './coordinate-colors';
import { CoordinateMarker } from './coordinate-marker';
import { ImageWithCoordinates, ImageWithCoordinatesProps } from './image-with-coordinates';

type CoordinatePosition = { x: number; y: number };

type AssociatedRounds = SlideProps<RoundTypes.FingerOnItRound> | SlideProps<RoundTypes.WhereIsKazakhstanRound>;

function checkIsValidCoordinate(answer: unknown): answer is CoordinatePosition {
    const isCorrectShape = typeof answer === 'object' && 'x' in answer && 'y' in answer;
    const answerObject = answer as CoordinatePosition;
    return isCorrectShape && answerObject.x <= 100 && answerObject.y <= 100;
}

export function PlayFingerOnIt({ question, message, players, roundType, setGameState, imageUrls }: AssociatedRounds) {
    const [isPlayersRevealed, setIsPlayersRevealed] = useState(false);
    const [isRevealed, setIsRevealed] = useState(false);
    const [coordinates, setCoordinates] = useState<Array<ImageWithCoordinatesProps['coordinates'][0]>>([]);
    const finalAnswers = useRef<GameTypes.PrivateGame['players']>(null);
    const imageSrc = imageUrls?.[question.image] || question.image;

    function generateCoordinates() {
        const newCoordinates = Object.keys(finalAnswers.current)
            .sort()
            .reduce((arr, pId, index) => {
                const player = finalAnswers.current[pId];
                if (checkIsValidCoordinate(player.answer)) {
                    const coordinateMarkerStyle = getCoordinateMarkerStyle(index);
                    const coordinate: ImageWithCoordinatesProps['coordinates'][0] = {
                        key: player.id,
                        hoverText: player.name,
                        x: player.answer.x,
                        y: player.answer.y,
                        ...coordinateMarkerStyle,
                        scale: 1.6,
                    };
                    return [...arr, coordinate];
                } else {
                    return arr;
                }
            }, []);
        if (isRevealed && question.answer) {
            newCoordinates.push({
                key: 'answer',
                hoverText: 'Correct Answer',
                x: question.answer.x,
                y: question.answer.y,
                fillColor: 'var(--hog-secondary-dark)',
                borderColor: 'var(--off-white)',
                scale: 2,
                className: 'coordinate-marker__answer',
            });
        }

        return newCoordinates;
    }

    useEffect(() => {
        setGameState({ roundType, image: imageSrc });
    }, [imageUrls]);

    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    useEffect(() => {
        if (isPlayersRevealed || isRevealed) {
            setCoordinates(generateCoordinates());
        }
    }, [players, isPlayersRevealed, isRevealed]);

    function handleMessageReceived(msg: Message) {
        switch (msg.name) {
            case MessageName.reveal:
                if (isPlayersRevealed) {
                    setIsRevealed(true);
                } else {
                    finalAnswers.current = players;
                    setIsPlayersRevealed(true);
                }
        }
    }

    function calculateDistance(p1: CoordinatePosition, p2: CoordinatePosition) {
        const dX = Math.abs(p1.x - p2.x);
        const dY = Math.abs(p1.y - p2.y);
        return Math.sqrt(Math.pow(dX, 2) * Math.pow(dY, 2));
    }

    const coordinatesForKeys = coordinates;
    if (isRevealed && question.answer) {
        coordinatesForKeys.sort((c1, c2) => {
            if (c1.key === 'answer') {
                return -1;
            } else {
                const d1 = calculateDistance(c1, question.answer);
                const d2 = calculateDistance(c2, question.answer);
                return d1 - d2;
            }
        });
    }

    return (
        <div className="play-finger-on-it slide page">
            <div className="image-and-key">
                {isPlayersRevealed && (
                    <div className="coordinate-key scrollable">
                        {coordinates.map((c, i) => {
                            const coordinateClasses = classNames('coordinate-key__item', {
                                'coordinate-key__item--winner':
                                    roundType === RoundTypes.RoundType.WhereIsKazakhstan && isRevealed && i === 1,
                            });
                            return (
                                <div key={c.key} className={coordinateClasses}>
                                    <CoordinateMarker
                                        fillColor={c.fillColor}
                                        borderColor={c.borderColor}
                                        type={c.type}
                                    />
                                    <span className="coordinate-key__name">{c.hoverText}</span>
                                </div>
                            );
                        })}
                    </div>
                )}
                <ImageWithCoordinates coordinates={coordinates} imageQuestion={imageSrc} />
            </div>
            <AnimateExpandContainer className="play-finger-on-it__answer">
                <TextFlip text={question.question} offset={300} />
            </AnimateExpandContainer>
        </div>
    );
}
