import './create-highbrow-lowbrow.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';

import { Button } from '../../../common/components/buttons/buttons';
import { TextField } from '../../../common/components/form-fields/text-field';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { TextArea } from '../common/text-area';
import { RoundProps } from '../create-round-component-map';

type AssociatedRounds = RoundProps<RoundTypes.HighbrowLowbrowRound>;

const definition = roundDefinitions[RoundTypes.RoundType.HighbrowLowbrow].of;

export function CreateHighbrowLowbrow({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: AssociatedRounds) {
    const { questions } = round;

    function handleNewQuestion() {
        createNewQuestion({});
    }

    function handleChangeHighbrow(questionIndex: number, highbrow: string) {
        updateQuestion(questionIndex, { highbrow });
    }

    function handleChangeLowbrow(questionIndex: number, lowbrow: string) {
        updateQuestion(questionIndex, { lowbrow });
    }

    function handleChangeAnswer(questionIndex: number, answer: string) {
        updateQuestion(questionIndex, { answer });
    }

    return (
        <div className="create-highbrow-lowbrow">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-highbrow-lowbrow-question__container">
                        <div className="create-highbrow-lowbrow-question__text-row">
                            <QuestionArrows
                                index={i}
                                numberOfQuestions={questions.length}
                                onClickUpArrow={shiftUp}
                                onClickDownArrow={shiftDown}
                            />
                            <div className="create-highbrow-lowbrow-question__text-container">
                                <TextArea
                                    className="create-highbrow-lowbrow-question__text"
                                    defaultValue={q.highbrow}
                                    maxLength={definition.highbrow.length}
                                    placeholder={'Enter your highbrow question...'}
                                    onChange={(str) => handleChangeHighbrow(i, str)}
                                />
                            </div>
                            <div className="create-highbrow-lowbrow-question__text-container">
                                <TextArea
                                    className="create-highbrow-lowbrow-question__text"
                                    defaultValue={q.lowbrow}
                                    maxLength={definition.lowbrow.length}
                                    placeholder={'Enter your lowbrow question...'}
                                    onChange={(str) => handleChangeLowbrow(i, str)}
                                />
                            </div>
                        </div>
                        <TextField
                            className="create-highbrow-lowbrow-question__answer"
                            label="Answer"
                            type="text"
                            defaultValue={q.answer}
                            maxLength={definition.answer.length}
                            onChange={(a) => handleChangeAnswer(i, a)}
                        />
                        <QuestionErase index={i} onRemove={removeQuestion} />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
