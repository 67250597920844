type ClearBuzzerProps = {
    scale?: number;
};

export function ClearBuzzer({ scale = 1 }: ClearBuzzerProps) {
    const roundedScale = Math.round(24 * scale);
    return (
        <svg
            className="icon icon__clear-buzzer"
            width={`${roundedScale}`}
            height={`${roundedScale}`}
            viewBox="0 0 24 24"
        >
            <path
                d="M12,4.4c-5.4,0-9.7,4.3-9.7,9.7c0,0.2,0,0.3,0,0.5C3,14.8,3.7,15,4.4,15.2v3.5l0.5,0.1c2.3,0.5,4.6,0.8,7.1,0.8
                s4.8-0.3,7.1-0.8l0.5-0.1v-3.5c0.7-0.2,1.4-0.4,2.1-0.7c0-0.2,0-0.3,0-0.5C21.7,8.7,17.4,4.4,12,4.4z M16.5,15.1l-1.4,1.4L12,13.4
                l-3.1,3.1l-1.4-1.4l3.1-3.1L7.5,8.9l1.4-1.4l3.1,3.1l3.1-3.1l1.4,1.4L13.4,12L16.5,15.1z"
            />
        </svg>
    );
}
