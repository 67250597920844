import './game.scss';

import { CFRequestTypes, GameCrawler, GameState, GameTypes, RoundTypes, UserStorage } from '@house-of-games/common';
import classNames from 'classnames';
import QRCode from 'qrcode-svg';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { AnimateExpandContainer } from '../../common/components/animate-expand-container/animate-expand-container';
import { Close } from '../../common/components/icons/close';
import { LoadingSpinner } from '../../common/components/loading-error/loading-spinner';
import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { TextFlip } from '../../common/components/text-flip/text-flip';
import { Toast } from '../../common/components/toast/toast';
import { useStorageCache } from '../../common/components/user-storage/use-storage-cache';
import { Message, MessageName } from '../../common/constants/message';
import { AppRoute } from '../../common/constants/routes';
import { useGameOptions } from '../../common/effects/game-options/use-game-options';
import { roundLabels } from '../../common/round-maps/round-labels';
import { requestHandler } from '../../utils/request-handler';
// import { checkStorageIsThemeEnabled, setChristmasTheme } from '../../utils/set-christmas-theme'; // XMAS_THEME
import { useProfileContext } from '../auth-router/auth-context';
import { Scores } from '../control/control';
import { Slide, SlideType } from '../control/slide-control';
import { AudioNotification } from '../rounds/broken-karaoke/audio-notification';
import { getPlayRoundComponent } from '../rounds/play-round-component-map';
import { useAnimate } from '../rounds/useAnimate';
import { AudioConfigurationModal } from './audio-configuration-modal';
import { useBuzzer } from './buzzer';
import { BuzzerTest } from './buzzer-test';
import { useMusicQuestionBuzzer, UseMusicQuestionBuzzerReturnType } from './use-music-question-buzzer';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const buzzSound = require('./buzz.mp3');

// eslint-disable-next-line @typescript-eslint/no-var-requires
const newRoundTheme = require('./new-round-theme.mp3');

const buzzerEnterExitAnimationTime = 300;
const tenMinutesMs = 600000;

type Options = {
    clientSideAudio: {
        enabled?: boolean;
        files?: Record<string, string>;
    };
    themeSongEnabled: boolean;
};

type NonStaticSlideProps = {
    message: Message;
    players: { [key: string]: GameTypes.Player };
    options: Options;
    showQrCode?: boolean;
};

export type SlideProps<T extends RoundTypes.Round> = NonStaticSlideProps & {
    roundType: T['type'];
    question: T['questions'][0];
    setGameState<T extends RoundTypes.RoundType>(gameState: GameState<T>): Promise<GameState<T>>;
    musicQuestionHelpers: UseMusicQuestionBuzzerReturnType;
    onPlayPaused: () => void;
    onPlayFinished: () => void;
    imageUrls: { [key: string]: string };
};

function getScale() {
    const widthRatio = window.outerWidth / 1920;
    const heightRatio = window.outerHeight / 1080;
    return Math.min(widthRatio, heightRatio);
}

type NewCategoryProps = { category: string };
function NewCategory({ category }: NewCategoryProps) {
    return (
        <div className="new-category slide page">
            <AnimateExpandContainer className="new-category__text">
                <TextFlip text={category} offset={300} />
            </AnimateExpandContainer>
        </div>
    );
}

type NewRoundProps = {
    themeSongEnabled: boolean;
    title: string;
    url?: string;
    shouldShowQrCode?: boolean;
    setGameState<T extends RoundTypes.RoundType>(gameState: GameState<T>): Promise<GameState<T>>;
};
function NewRound({ title, url, setGameState, themeSongEnabled, shouldShowQrCode }: NewRoundProps) {
    const classes = useAnimate('new-round', 'slide page');
    const containerClasses = useAnimate('new-round__container', 'slide page');

    function getTextLines(text: string) {
        const arr = text.split(' ');
        const newText: Array<any> = [''];
        arr.forEach((word) => {
            const maxLineLength = 14;
            const lastLine = newText[newText.length - 1];
            if (lastLine.length + word.length < maxLineLength) {
                newText[newText.length - 1] = `${lastLine} ${word}`.trim();
            } else {
                newText.push(<br key={`br-${word}`} />);
                newText.push(word);
            }
        });
        return newText;
    }

    function copyToClipboard() {
        navigator.clipboard.writeText(url);
        alert('Copied To Clipboard!');
    }

    function createQrCode(str: string) {
        const svgString = new QRCode({
            content: str,
            padding: 4,
            width: 200,
            height: 200,
            color: '#FFD246',
            background: '#D41521',
            ecl: 'M',
            join: true,
        }).svg();

        return <div className="qr-code" dangerouslySetInnerHTML={{ __html: svgString }} />;
    }

    useEffect(() => {
        setGameState({ roundType: undefined });
    }, []);

    const qrCodeContainerClasses = classNames('qr-code__container', {
        'qr-code__container--hidden': !shouldShowQrCode,
    });

    return (
        <div className={containerClasses}>
            <div className={classes}>
                <div className="new-round__background" />
            </div>
            <div className="new-round__round-name">{getTextLines(title)}</div>
            {url && (
                <>
                    <div className="new-round__url" onClick={copyToClipboard}>
                        <div className="new-round__url-text">Join in at: {url}</div>
                        <div className={qrCodeContainerClasses}>{createQrCode(url)}</div>
                    </div>
                </>
            )}
            {themeSongEnabled && <audio src={newRoundTheme} autoPlay />}
            {title === roundLabels[RoundTypes.RoundType.BrokenKaraoke] && <AudioNotification />}
        </div>
    );
}

type PopupNotificationProps = { handleClose: () => void };
function PopupNotification({ handleClose }: PopupNotificationProps) {
    return (
        <div className="popup-notification">
            <div className="popup-notification__text">
                {`It looks like your browser is blocking popups from this site. If you don't see a new window with game
                controls then please allow popups for this site and refresh the page.`}
            </div>
            <div className="popup-notification__close" onClick={handleClose}>
                <Close scale={0.6} />
            </div>
        </div>
    );
}

function ScoresComponent({ scores, players }: { scores: Scores; players: GameTypes.PublicGame['players'] }) {
    return (
        <div className="game-scores">
            {Object.values(players)
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort((a, b) => {
                    const scoreA = scores[a.id] || 0;
                    const scoreB = scores[b.id] || 0;
                    return scoreB - scoreA;
                })
                .map((player) => {
                    const score = scores[player.id] || 0;
                    return (
                        <AnimateExpandContainer key={player.id} className="game-scores__player">
                            <TextFlip className="game-scores__name" text={player.name} offset={300} />
                            <TextFlip className="game-scores__score" text={`${score}`} offset={300} />
                        </AnimateExpandContainer>
                    );
                })}
        </div>
    );
}

type NonStaticProps = {
    options: Options;
    message: Message;
    players: { [key: string]: GameTypes.Player };
    shouldShowQrCode: boolean;
};

type SlideComponentProps = NonStaticProps & {
    displayName: string;
    sessionId: GameTypes.SessionId;
    slide: Slide;
    setGameState<T extends RoundTypes.RoundType>(gameState: GameState<T>): Promise<GameState<T>>;
    musicQuestionHelpers: UseMusicQuestionBuzzerReturnType;
    onPlayPaused: () => void;
    onPlayFinished: () => void;
    imageUrls: { [key: string]: string };
};

function SlideComponent({
    displayName,
    sessionId,
    slide,
    options: optionsProp,
    players: playersProp,
    message: messageProp,
    setGameState,
    musicQuestionHelpers,
    onPlayPaused,
    onPlayFinished,
    imageUrls,
    shouldShowQrCode: shouldShowQrCodeProp,
}: SlideComponentProps) {
    function buildCurrentSlide() {
        // Must return callback so that component is re-mounted between slides
        if (!slide) {
            return () => <LoadingSpinner />;
        }

        switch (slide.slideType) {
            case SlideType.Question:
                const Component = getPlayRoundComponent(slide.roundType);
                return ({ players, message, options }: NonStaticProps) => (
                    <Component
                        options={options}
                        roundType={slide.roundType}
                        question={slide.question}
                        message={message}
                        players={players}
                        setGameState={setGameState}
                        musicQuestionHelpers={musicQuestionHelpers}
                        onPlayPaused={onPlayPaused}
                        onPlayFinished={onPlayFinished}
                        imageUrls={imageUrls}
                    />
                );
            case SlideType.Scores:
                return ({ players }: NonStaticProps) => <ScoresComponent players={players} scores={slide.scores} />;
            case SlideType.NewCategory:
                return () => <NewCategory category={slide.category} />;
            case SlideType.NewRound:
                const roundLabel = roundLabels[slide.roundType];
                return () => (
                    <NewRound
                        title={roundLabel}
                        setGameState={setGameState}
                        themeSongEnabled={optionsProp.themeSongEnabled}
                    />
                );
            case SlideType.Title:
                const title = `${displayName}'s House Of Games`;
                return ({ shouldShowQrCode }: NonStaticProps) => (
                    <NewRound
                        themeSongEnabled={optionsProp.themeSongEnabled}
                        title={title}
                        url={sessionId && `${window.location.host}${AppRoute.Play}/${sessionId}`}
                        setGameState={setGameState}
                        shouldShowQrCode={shouldShowQrCode}
                    />
                );
            case SlideType.BuzzerTest:
                return ({ players }: NonStaticProps) => <BuzzerTest players={players} />;
        }
    }

    const CurrentSlide: any = useMemo<(nonStaticProps: NonStaticProps) => React.ReactElement>(buildCurrentSlide, [
        slide,
    ]);
    return (
        <CurrentSlide
            message={messageProp}
            players={playersProp}
            options={optionsProp}
            shouldShowQrCode={shouldShowQrCodeProp}
        />
    );
}

type RouteParams = { gameId: GameTypes.GameId };

export function Game() {
    const [defaultGameOptions] = useGameOptions(() => {
        // noop (not using set options)
    });
    // const isThemeEnabledRef = useRef(checkStorageIsThemeEnabled()); // XMAS_THEME
    const storageCache = useStorageCache();
    const resizeTimeoutRef = useRef<NodeJS.Timeout>();
    const [scale, setScale] = useState(getScale());
    const bcRef = useRef<BroadcastChannel>();
    const audioBuzzRef = useRef<HTMLAudioElement>();
    const profile = useProfileContext();
    const { gameId } = useParams<RouteParams>();
    const [sessionId, setSessionId] = useState<GameTypes.SessionId>();
    const navigate = useNavigate();
    const game = profile.games.find((g) => g.id === gameId);
    const [isAudioConfigurationModalRequired, setAudioConfigurationModalRequired] = useState(false);
    const [isPopupNotificationRequired, setPopupNotificationRequired] = useState(false);
    const [toastString, setToastString] = useState(null);
    const [currentSlide, setCurrentSlide] = useState<Slide>();
    const [message, setMessage] = useState<Message>({ name: null });
    const [slideComponentKey, setSlideComponentKey, slideComponentKeyRef] = useRefState(0);
    const isPlayFinished = useRef(false);
    const [buzzedThisSlide, activeBuzzer, activeBuzzerTextAnswer, isBuzzerEnabled, unBuzz, clearActiveBuzzer] =
        useBuzzer(game?.players, currentSlide, audioBuzzRef);

    // Need to delay the buzzer text changing to allow for the animation
    const [delayedBuzzerText, setDelayedBuzzerText] = useState({ name: undefined, answerText: undefined });
    const delayedBuzzerTimeout = useRef<number>();

    const audioElementRef = useRef<HTMLAudioElement>();
    const musicQuestionHelpers = useMusicQuestionBuzzer(message, handleQuestionChanged, audioElementRef);
    const [isClientSideAudioEnabled, setIsClientSideAudioEnabled, isClientSideAudioEnabledRef] = useRefState<boolean>(
        defaultGameOptions.current.clientSideAudio,
    );
    const [imageUrls, setImageUrls] = useState<CFRequestTypes.CreateFileUrlsResponse>();
    const [audioUrls, setAudioUrls] = useState<CFRequestTypes.CreateFileUrlsResponse>();
    const audioUrlsTimeout = useRef<number>();
    const isAudioUrlsExpired = useRef<boolean>(true);

    const isThemeSongEnabledRef = useRef<boolean>(defaultGameOptions.current.themeSong);
    const [isBuzzerTimerEnabled, setIsBuzzerTimerEnabled] = useState(defaultGameOptions.current.buzzerTimerEnabled);
    const [buzzerTimerSeconds, setBuzzerTimerSeconds] = useState(defaultGameOptions.current.buzzerTimerSeconds);

    const [shouldShowQrCode, setShouldShowQrCode, shouldShowQrCodeRef] = useRefState(false);
    const options = useMemo<Options>(() => {
        return {
            clientSideAudio: {
                enabled: isClientSideAudioEnabled,
                files: audioUrls,
            },
            themeSongEnabled: isThemeSongEnabledRef.current,
        };
    }, [isThemeSongEnabledRef.current, isClientSideAudioEnabled, audioUrls]);

    function setGameState<T extends RoundTypes.RoundType>(gameState: GameState<T>) {
        return requestHandler.setGameState<T>(gameId, gameState);
    }

    const prefetch = useMemo(() => {
        function preloadImages(imageQuestions: Array<string>) {
            const images = new Set<string>(imageQuestions);
            const urlsToPreload = Array.from(images)
                .map((i) => imageUrls[i] || i)
                .filter((i) => UserStorage.checkIsHttpUrl(i));
            return urlsToPreload.map((image) => {
                return <link key={image} rel="preload" as="image" href={image} />;
            });
        }

        function preloadFiles(files: Array<string>) {
            files.forEach((fileKey) => {
                return storageCache.load(fileKey);
            });
        }

        if (currentSlide?.slideType === SlideType.NewRound) {
            if (
                [
                    RoundTypes.RoundType.AnswerSmash,
                    RoundTypes.RoundType.FingerOnIt,
                    RoundTypes.RoundType.WhereIsKazakhstan,
                ].includes(currentSlide.roundType)
            ) {
                type ImageRound =
                    | RoundTypes.AnswerSmashRound
                    | RoundTypes.FingerOnItRound
                    | RoundTypes.WhereIsKazakhstanRound;
                const imageUrls = (game?.rounds?.[currentSlide.roundType] as ImageRound).questions.map((q) => q.image);
                return preloadImages(imageUrls);
            } else {
                const roundType = currentSlide.roundType;
                const partialGame = {
                    id: gameId,
                    rounds: { [roundType]: { type: roundType, questions: game?.rounds?.[roundType].questions } },
                };
                const fileMatches = GameCrawler.gameCrawler(partialGame, UserStorage.userStorageMatcher);
                const fileUrls = Object.keys(fileMatches);
                return preloadFiles(fileUrls);
            }
        }
    }, [currentSlide]);

    function onMessageReceived(message: Message) {
        setMessage(message);
        switch (message.name) {
            case MessageName.slide:
                musicQuestionHelpers.handleSlideChanged();
                setCurrentSlide(message.data);
                break;
            case MessageName.unbuzzed:
                unBuzz(message.data);
                break;
            case MessageName.clear:
                clearActiveBuzzer();
                break;
            case MessageName.toggleQrCode:
                setShouldShowQrCode(!shouldShowQrCodeRef.current);
                break;
            case MessageName.play:
                if (isPlayFinished.current) {
                    isPlayFinished.current = false;
                    setSlideComponentKey(slideComponentKeyRef.current + 1);
                    setMessage({ ...message });
                }
                break;
            case MessageName.option:
                if (message.data.option === 'clientSideAudio') {
                    handleAudioOptionChanged(message.data.value);
                } else if (message.data.option === 'buzzerTimerEnabled') {
                    setIsBuzzerTimerEnabled(message.data.value);
                } else if (message.data.option === 'buzzerTimerSeconds') {
                    setBuzzerTimerSeconds(message.data.value);
                } else if (message.data.option === 'themeSong') {
                    isThemeSongEnabledRef.current = message.data.value;
                }
                break;
        }
    }

    function handleQuestionChanged(isMusicQuestion: boolean) {
        sendMessage({ name: MessageName.questionChanged, data: { isMusicQuestion } });
    }

    async function handleAudioOptionChanged(newIsClientSideAudioEnabled: boolean) {
        if (newIsClientSideAudioEnabled === isClientSideAudioEnabledRef.current) {
            return;
        }

        if (!newIsClientSideAudioEnabled || !isAudioUrlsExpired.current) {
            setIsClientSideAudioEnabled(newIsClientSideAudioEnabled);
            return;
        }

        const fileUrls = await requestHandler.createAudioFileUrls(gameId);
        setAudioUrls(fileUrls);
        setIsClientSideAudioEnabled(newIsClientSideAudioEnabled);
        isAudioUrlsExpired.current = false;
        audioUrlsTimeout.current = window.setTimeout(() => {
            isAudioUrlsExpired.current = true;
        }, tenMinutesMs);
    }

    function handlePlayPaused() {
        sendMessage({ name: MessageName.paused });
    }

    function handlePlayFinished() {
        isPlayFinished.current = true;
        sendMessage({ name: MessageName.finished });
    }

    function handleExitGame() {
        navigate(`/create/${gameId}`);
    }

    function handleKeyDown(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            handleExitGame();
        }
    }

    async function triggerAudioPlaybackTest() {
        audioBuzzRef.current.muted = true;
        try {
            await audioBuzzRef.current.play();
            await audioBuzzRef.current.pause();
        } catch {
            setAudioConfigurationModalRequired(true);
        } finally {
            await audioBuzzRef.current.pause();
            audioBuzzRef.current.muted = false;
        }
    }

    // XMAS_THEME
    // function handleStorageChanged() {
    //     const newThemeState = checkStorageIsThemeEnabled();
    //     if (newThemeState !== isThemeEnabledRef.current) {
    //         isThemeEnabledRef.current = newThemeState;
    //         setChristmasTheme(newThemeState);
    //     }
    // }

    function handleResize() {
        clearTimeout(resizeTimeoutRef.current);
        resizeTimeoutRef.current = setTimeout(() => {
            setScale(getScale());
        }, 400);
    }

    function sendMessage(msg: Message) {
        bcRef.current.postMessage(msg);
    }

    useEffect(() => {
        if (!game) {
            return;
        }

        requestHandler.requestSession(gameId).then((sessionIdResponse) => {
            setSessionId(sessionIdResponse);
        });

        requestHandler.createImageFileUrls(gameId).then((urls) => {
            setImageUrls(urls);
        });

        window.addEventListener('keydown', handleKeyDown);
        // window.addEventListener('storage', handleStorageChanged); // XMAS_THEME
        window.addEventListener('resize', handleResize);
        bcRef.current = new BroadcastChannel(gameId);

        const controllerExistsTimeout = setTimeout(() => {
            const { outerWidth, outerHeight } = window;
            const width = Math.max(0.8 * outerWidth, 640);
            const height = Math.max(0.8 * outerHeight, 360);
            const popup = window.open(
                `/control/${gameId}`,
                '_blank',
                `menubar=no, toolbar=no, width=${width}, height=${height}`,
            );
            setTimeout(() => {
                if (!popup) {
                    setPopupNotificationRequired(true);
                }
            }, 2000);
        }, 1000);

        setTimeout(() => {
            setToastString('Press Esc To Exit Game');
        }, 500);
        setTimeout(() => {
            setToastString(null);
        }, 3500);

        bcRef.current.onmessage = (msg) => {
            const message = msg.data as Message;
            if (!message.name) {
                return;
            } else if (message.name === MessageName.pong) {
                clearTimeout(controllerExistsTimeout);
            } else {
                onMessageReceived(message);
            }
        };

        sendMessage({ name: MessageName.ping });
        triggerAudioPlaybackTest();

        return () => {
            clearTimeout(audioUrlsTimeout.current);
            window.removeEventListener('keydown', handleKeyDown);
            // window.removeEventListener('storage', handleStorageChanged); // XMAS_THEME
            window.removeEventListener('resize', handleResize);
            sendMessage({ name: MessageName.closing });
            bcRef.current.close();
        };
    }, []);

    useEffect(() => {
        if (activeBuzzer) {
            clearTimeout(delayedBuzzerTimeout.current);
            setDelayedBuzzerText({ name: activeBuzzer.name, answerText: undefined });
        } else {
            delayedBuzzerTimeout.current = window.setTimeout(() => {
                setDelayedBuzzerText({ name: undefined, answerText: undefined });
            }, buzzerEnterExitAnimationTime);
        }
    }, [activeBuzzer]);

    useEffect(() => {
        if (activeBuzzerTextAnswer) {
            setDelayedBuzzerText({ name: delayedBuzzerText.name, answerText: activeBuzzerTextAnswer });
        }
    }, [activeBuzzerTextAnswer]);

    useEffect(() => {
        if (buzzedThisSlide) {
            setMessage({ name: MessageName.buzzed, data: buzzedThisSlide });
            sendMessage({ name: MessageName.buzzed, data: buzzedThisSlide });
        }
    }, [buzzedThisSlide]);

    if (!game) {
        return <Navigate to={AppRoute.Home} replace />;
    }

    const buzzerClasses = classNames('game__buzzer-container', {
        'game__buzzer-container--visible': Boolean(activeBuzzer),
    });

    return (
        <div className="game page" style={{ zoom: scale, paddingTop: isBuzzerEnabled ? '120px' : '20px' }}>
            {isPopupNotificationRequired && (
                <PopupNotification handleClose={() => setPopupNotificationRequired(false)} />
            )}
            {prefetch}
            {isAudioConfigurationModalRequired && (
                <AudioConfigurationModal
                    audioElementRefs={[audioElementRef, audioBuzzRef]}
                    onClose={() => setAudioConfigurationModalRequired(false)}
                />
            )}
            <audio ref={audioElementRef} onEnded={handlePlayPaused} />
            <audio ref={audioBuzzRef} src={buzzSound} />
            {
                <div className={buzzerClasses}>
                    {isBuzzerTimerEnabled && (
                        <div className="game__buzzer-timer-container">
                            <div
                                className="game__buzzer-timer"
                                style={{ animationDuration: `${buzzerTimerSeconds}s` }}
                            />
                        </div>
                    )}
                    <div className="game__buzzer">{delayedBuzzerText.name}</div>
                    {delayedBuzzerText.answerText && (
                        <AnimateExpandContainer className="game__buzzer-answer">
                            <TextFlip text={delayedBuzzerText.answerText} offset={300} />
                        </AnimateExpandContainer>
                    )}
                </div>
            }
            {sessionId ? (
                <SlideComponent
                    key={slideComponentKey}
                    options={options}
                    displayName={profile.displayName}
                    sessionId={sessionId}
                    slide={currentSlide}
                    players={game.players}
                    message={message}
                    setGameState={setGameState}
                    onPlayPaused={handlePlayPaused}
                    onPlayFinished={handlePlayFinished}
                    musicQuestionHelpers={musicQuestionHelpers}
                    imageUrls={imageUrls}
                    shouldShowQrCode={shouldShowQrCode}
                />
            ) : (
                <LoadingSpinner />
            )}
            {<Toast s={toastString} />}
        </div>
    );
}
