import './create-finger-on-it.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';
import { useState } from 'react';

import { Button } from '../../../common/components/buttons/buttons';
import { TextField } from '../../../common/components/form-fields/text-field';
import { ImageSelectModal } from '../../../common/components/user-storage/image-select-modal';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { RoundProps } from '../create-round-component-map';
import { ImageWithCoordinates } from './image-with-coordinates';

type AssociatedRounds = RoundProps<RoundTypes.FingerOnItRound> | RoundProps<RoundTypes.WhereIsKazakhstanRound>;

type ImageWithInputProps = {
    imageQuestion: string;
    answer: { x: number; y: number };
    onChangeImage: () => void;
    onImageClicked: (x: number, y: number) => void;
};

function ImageWithInput({ imageQuestion, answer, onChangeImage, onImageClicked }: ImageWithInputProps) {
    const shouldShowTip = imageQuestion && !answer;

    return (
        <div className="image-with-input">
            <div className="image-with-input__input-container">
                <Button label={imageQuestion ? 'Change Image' : 'Select Image'} onClick={onChangeImage} />
            </div>
            <ImageWithCoordinates
                coordinates={
                    answer
                        ? [
                              {
                                  x: answer.x,
                                  y: answer.y,
                                  fillColor: 'var(--hog-red-dark)',
                                  borderColor: 'var(--hog-primary)',
                                  scale: 1.4,
                              },
                          ]
                        : []
                }
                imageQuestion={imageQuestion}
                onClick={onImageClicked}
            />
            <div className="image-with-input__tip" style={{ visibility: shouldShowTip ? 'visible' : 'hidden' }}>
                Click On The Image To Set An Answer
            </div>
        </div>
    );
}

export function CreateFingerOnIt({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: AssociatedRounds) {
    const { questions } = round;
    const definition = roundDefinitions[round.type].of;
    const [selectingImage, setSelectingImage] = useState<number>();

    function handleNewQuestion() {
        createNewQuestion({});
    }

    function handleQuestionChanged(quesitonIndex: number, question: string) {
        updateQuestion(quesitonIndex, { question });
    }

    function handleImageChanged(questionIndex: number, image: string) {
        let answer = questions[questionIndex].answer;
        if (image === '') {
            answer = undefined;
        }
        updateQuestion(questionIndex, { image, answer });
        setSelectingImage(undefined);
    }

    function handleAnswerChanged(quesitonIndex: number, answer: { x: number; y: number }) {
        updateQuestion(quesitonIndex, { answer });
    }

    function showImageSelectModal(questionIndex: number) {
        setSelectingImage(questionIndex);
    }

    return (
        <div className="create-finger-on-it">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-finger-on-it-question__container">
                        <QuestionArrows
                            index={i}
                            numberOfQuestions={questions.length}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                        />
                        <QuestionErase index={i} onRemove={removeQuestion} />
                        <div className="create-finger-on-it__image-container">
                            <ImageWithInput
                                answer={q.answer}
                                imageQuestion={q.image}
                                onChangeImage={() => showImageSelectModal(i)}
                                onImageClicked={(x, y) => handleAnswerChanged(i, { x, y })}
                            />
                        </div>
                        <div className="create-finger-on-it-question__question-container">
                            <TextField
                                label="Question"
                                type="text"
                                maxLength={definition.question.length}
                                onChange={(question) => handleQuestionChanged(i, question)}
                                isOutline
                                defaultValue={q.question}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
            {selectingImage !== undefined && (
                <ImageSelectModal
                    onCloseModal={() => setSelectingImage(undefined)}
                    onFileSelected={(image: string) => handleImageChanged(selectingImage, image)}
                />
            )}
        </div>
    );
}
