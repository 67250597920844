import './search.scss';

import { checkLooksLikeUid, dataValidators, GameTypes } from '@house-of-games/common';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { TextField } from '../../common/components/form-fields/text-field';
import { useLoadingError } from '../../common/components/loading-error/loading-error';
import { LoadingSpinner } from '../../common/components/loading-error/loading-spinner';
import { AppRoute } from '../../common/constants/routes';
import { requestHandler } from '../../utils/request-handler';
import { SearchItem } from './search-item';

type RouteParams = { profileId: string };

export function Search() {
    const { profileId } = useParams<RouteParams>();
    const currentSearch = useRef<string>(null);
    const navigate = useNavigate();
    const [isLoading, , wrapper] = useLoadingError(false);
    const [results, setResults] = useState<Array<GameTypes.PrivateGame>>([]);

    useEffect(() => {
        handleProfileIdScenario();
    }, [profileId]);

    function handleProfileIdScenario() {
        const isValidProfileId = checkLooksLikeUid(profileId);
        if (!isValidProfileId) {
            navigate(AppRoute.Search, { replace: true });
        }

        const searchTerm = isValidProfileId ? profileId : '';
        handleSearch(searchTerm);
    }

    function handleSearch(s: string) {
        const isValid = dataValidators.searchTerm.validate(s);
        if (!isValid || s === currentSearch.current) {
            return;
        }

        wrapper(async () => {
            const newResults = await requestHandler.search(s);
            currentSearch.current = s;
            setResults(newResults);
        })();
    }

    function handleEnterPress(s: string) {
        handleSearch(s);
        if (profileId) {
            navigate(AppRoute.Search);
        }
    }

    return (
        <div className="search page">
            <div className="header">
                <div className="box-header__container">
                    <Link draggable={false} to={AppRoute.Home}>
                        <div className="box-header__back-button-container">
                            <div className="box-header__back-button" />
                        </div>
                    </Link>
                    <TextField
                        className="search-input"
                        type="text"
                        label="Search"
                        isOutline={true}
                        onEnterPress={handleEnterPress}
                        maxLength={dataValidators.searchTerm.max}
                    />
                    <div className="box-subheader">Search for public games to add them to your collection</div>
                </div>
            </div>
            <div className="search-results__container">
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div className="search-results">
                        {results.map((game) => {
                            return <SearchItem key={game.publicUid} game={game} />;
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
