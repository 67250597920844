import './toast.scss';

import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

type ToastProps = {
    s: string;
    className?: string;
};

const shortTextDuration = 3000;
const longTextDuration = 6000;
const longTextCharacters = 50;

export function Toast({ s, className }: ToastProps) {
    const [isVisible, setIsVisible] = useState(false);
    const stringToShow = useRef<string>();

    useEffect(() => {
        if (s) {
            stringToShow.current = s;
            setIsVisible(true);
            const duration = s.length > longTextCharacters ? longTextDuration : shortTextDuration;
            setTimeout(() => {
                setIsVisible(false);
            }, duration);
        }
    }, [s]);

    const classes = classNames('toast', {
        [className]: className,
        'toast--hidden': !isVisible,
        'toast--visible': isVisible,
    });

    return <div className={classes}>{stringToShow.current}</div>;
}
