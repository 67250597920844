import './text-flip.scss';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

type TextFlipProps = {
    className?: string;
    offset?: number;
    offsetFlag?: boolean;
    text: React.ReactNode;
    entranceOnly?: boolean;
};

const FLIP_TIME = 300;

export function TextFlip({ text, className, offset, offsetFlag, entranceOnly }: TextFlipProps) {
    const offsetExists = typeof offset === 'number';
    const [isRendered, setIsRendered] = useState(false);
    const [textToShow, setTextToShow] = useState(text);
    const [flip, setFlip] = useState(offsetExists || offsetFlag);

    function completeFlip() {
        return window.setTimeout(() => {
            setFlip(false);
            setTextToShow(text);
        }, FLIP_TIME + 300);
    }

    useEffect(() => {
        let offsetTimeout: number;
        let flipTimeout: number;

        setIsRendered(true);
        if (offsetExists) {
            offsetTimeout = window.setTimeout(() => {
                flipTimeout = completeFlip();
            }, offset);
        }

        return () => {
            clearTimeout(offsetTimeout);
            clearTimeout(flipTimeout);
        };
    }, []);

    useEffect(() => {
        if (text === textToShow) {
            return;
        }

        if (entranceOnly) {
            setTextToShow(text);
            return;
        }

        let flipTimeout: number;

        if (isRendered) {
            setFlip(true);
            flipTimeout = completeFlip();
        }

        return () => {
            clearTimeout(flipTimeout);
        };
    }, [text]);

    useEffect(() => {
        let flipTimeout: number;
        if (offsetFlag !== undefined && !offsetFlag) {
            flipTimeout = completeFlip();
        }

        return () => {
            clearTimeout(flipTimeout);
        };
    }, [offsetFlag]);

    const classes = classNames('text-flip', {
        'text-flip--flipping': flip,
        [className]: className,
    });
    return (
        <div className={classes} style={{ transition: `transform ${FLIP_TIME}ms linear` }}>
            {textToShow}
        </div>
    );
}
