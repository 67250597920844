import classNames from 'classnames';

type VoteProps = {
    isDownvote?: boolean;
    className?: string;
    scale?: number;
};

export function Vote({ isDownvote, className, scale = 1 }: VoteProps) {
    const classes = classNames('icon__vote', {
        'icon__vote--up': !isDownvote,
        'icon__vote--down': isDownvote,
        [className]: className,
    });
    const style = {
        transformOrigin: 'center',
        transform: `scale(${scale})`,
    };

    if (isDownvote) {
        style.transform = `scale(${-1 * scale})`;
    }

    return (
        <svg className={classes} width="24" height="24" viewBox="0 0 24 24">
            <path style={style} d="M24 22h-24l12-20z" />
        </svg>
    );
}
