import { Slide } from '../../modules/control/slide-control';

export enum MessageName {
    'ping' = 'ping',
    'pong' = 'pong',
    'slide' = 'slide',
    'play' = 'play',
    'pause' = 'pause',
    'paused' = 'paused',
    'finished' = 'finished',
    'reveal' = 'reveal',
    'clear' = 'clear',
    'buzzed' = 'buzzed',
    'unbuzzed' = 'unbuzzed',
    'closing' = 'closing',
    'spawned' = 'spawned',
    'option' = 'option',
    'questionChanged' = 'questionChanged',
    'toggleQrCode' = 'toggleQrCode',
}

type ThemeSongOption = {
    option: 'themeSong';
    value: boolean;
};

type ClientSideAudioOption = {
    option: 'clientSideAudio';
    value: boolean;
};

type BuzzerTimerEnabledOption = {
    option: 'buzzerTimerEnabled';
    value: boolean;
};

type BuzzerTimerSecondsOption = {
    option: 'buzzerTimerSeconds';
    value: number;
};

interface CommonMessage {
    name: MessageName;
}

export interface SimpleMessage extends CommonMessage {
    name: Exclude<
        MessageName,
        MessageName.slide | MessageName.buzzed | MessageName.unbuzzed | MessageName.option | MessageName.questionChanged
    >;
}

export interface QuestionChangedMessage extends CommonMessage {
    name: MessageName.questionChanged;
    data: {
        isMusicQuestion: boolean;
    };
}

export interface OptionMessage extends CommonMessage {
    name: MessageName.option;
    data: ClientSideAudioOption | BuzzerTimerEnabledOption | BuzzerTimerSecondsOption | ThemeSongOption;
}

export interface BuzzedMessage extends CommonMessage {
    name: MessageName.buzzed | MessageName.unbuzzed;
    data: Array<string>;
}

export interface SlideMessage extends CommonMessage {
    name: MessageName.slide;
    data: Slide;
}

export type Message = SimpleMessage | QuestionChangedMessage | OptionMessage | BuzzedMessage | SlideMessage;
