import './play-answer-smash.scss';

import { RoundTypes, UserStorage } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { useStorageCache } from '../../../common/components/user-storage/use-storage-cache';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';

type AutoFitImageProps = {
    imageSrc: string;
    fitTo?: {
        maxWidth: number;
        maxHeight: number;
    };
};

function AutoFitImage({ imageSrc, fitTo }: AutoFitImageProps) {
    const imageRef = useRef<HTMLImageElement>();
    const [isVisible, setIsVisible] = useState(false);
    const visibilityTimeout = useRef<number>();

    useEffect(() => {
        visibilityTimeout.current = window.setTimeout(() => {
            if (imageRef.current) {
                setIsVisible(true);
            }
        }, 800);

        return () => {
            clearTimeout(visibilityTimeout.current);
        };
    }, []);

    function onImageLoad() {
        if (!fitTo) {
            return;
        }

        const currentWidth = imageRef.current.width;
        const currentHeight = imageRef.current.height;

        const currentAspect = currentWidth / currentHeight;
        const maxAspect = fitTo.maxWidth / fitTo.maxHeight;

        const toPx = (n: number) => `${n}px`;

        if (currentAspect > maxAspect) {
            imageRef.current.style.width = toPx(fitTo.maxWidth);
        } else {
            imageRef.current.style.height = toPx(fitTo.maxHeight);
        }
    }

    const classes = classNames('play-answer-smash__image', {
        'play-answer-smash__image--visible': isVisible,
    });

    return (
        <AnimateExpandContainer>
            <img ref={imageRef} className={classes} src={imageSrc} onLoad={onImageLoad} />
        </AnimateExpandContainer>
    );
}

export function PlayAnswerSmash({ question, message }: SlideProps<RoundTypes.AnswerSmashRound>) {
    const storageCache = useStorageCache();
    const [isRevealed, setIsRevealed] = useState(false);
    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    function handleMessageReceived(msg: Message) {
        switch (msg.name) {
            case MessageName.reveal:
                setIsRevealed(true);
        }
    }

    const imageSrc = UserStorage.checkIsValidUri(question.image)
        ? storageCache.cache.current[question.image]
        : question.image;
    return (
        <div className="play-answer-smash slide page">
            <div className="play-answer-smash__category">
                <div className="box-header">{question.category}</div>
            </div>
            <div className="play-answer-smash__content">
                <div className="play-answer-smash__image-container">
                    <AutoFitImage imageSrc={imageSrc} fitTo={{ maxWidth: window.innerWidth * 0.8, maxHeight: 380 }} />
                </div>
                <AnimateExpandContainer className="play-answer-smash__question">
                    <TextFlip text={question.question} offset={300} />
                </AnimateExpandContainer>
                <div className="play-answer-smash__answer-container">
                    {isRevealed && (
                        <AnimateExpandContainer className="play-answer-smash__answer">
                            <TextFlip text={question.answer} offset={300} />
                        </AnimateExpandContainer>
                    )}
                </div>
            </div>
        </div>
    );
}
