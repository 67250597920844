import './app-router.scss';

import { signOut } from 'firebase/auth';
import { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
// import { ChristmasTree } from '../../common/components/icons/christmas-tree'; // XMAS_THEME
import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { StorageCacheContext } from '../../common/components/user-storage/use-storage-cache';
import { AppRoute } from '../../common/constants/routes';
import { FirebaseHelper } from '../../utils/firebase';
// import { useChristmasTheme } from '../../utils/set-christmas-theme'; // XMAS_THEME
import { Control } from '../control/control';
import { Create } from '../create/create-game';
import { Faq } from '../faq/faq';
import { Game } from '../game/game';
import { Home } from '../home/home';
import { Play } from '../play/play';
import { Profile } from '../profile/profile';
import { Search } from '../search/search';
import { Storage } from '../storage/storage';
import { ClipboardContext } from './clipboard-context';

type AppRouterProps = {
    onChangeName: (name: string) => Promise<void>;
};

export function AppRouter({ onChangeName }: AppRouterProps) {
    const [_storageCache, setStorageCache, storageCacheRef] = useRefState<Record<string, string>>({});
    const location = useLocation();
    const shouldRenderLogout = !location.pathname.match(/\/(control|play|game)/);
    const [clipboard, setClipboard] = useState(null);
    // const [isChristmasThemeEnabled, toggleChristmasTheme] = useChristmasTheme(); // XMAS_THEME

    function handleSetStorageCache(key: string, value: string) {
        setStorageCache({ ...storageCacheRef.current, [key]: value });
    }

    async function handleLoadStorageItem(key: string) {
        if (storageCacheRef.current[key]) {
            return storageCacheRef.current[key];
        }

        const file = await FirebaseHelper.downloadFile(key);
        const objectUrl = URL.createObjectURL(file);
        handleSetStorageCache(key, objectUrl);
        return objectUrl;
    }

    function handleRemoveStorageCache(keyToRemove: string) {
        const newCache = { ...storageCacheRef.current };
        delete newCache[keyToRemove];
        setStorageCache(newCache);
    }

    function buildRoutes() {
        return (
            <StorageCacheContext.Provider
                value={{
                    cache: storageCacheRef,
                    set: handleSetStorageCache,
                    delete: handleRemoveStorageCache,
                    load: handleLoadStorageItem,
                }}
            >
                <ClipboardContext.Provider value={clipboard}>
                    <Routes>
                        <Route key={AppRoute.Home} path={AppRoute.Home} element={<Home />} />
                        <Route key={AppRoute.Faq} path={AppRoute.Faq} element={<Faq />} />
                        <Route
                            key={AppRoute.Profile}
                            path={AppRoute.Profile}
                            element={<Profile onChangeName={onChangeName} />}
                        />
                        <Route
                            key={AppRoute.Create}
                            path={`${AppRoute.Create}/:gameId`}
                            element={<Create setClipboard={setClipboard} />}
                        >
                            <Route path=":roundType" element={<Create setClipboard={setClipboard} />} />
                        </Route>
                        <Route key={AppRoute.Control} path={`${AppRoute.Control}/:gameId`} element={<Control />} />
                        <Route key={AppRoute.Game} path={`${AppRoute.Game}/:gameId`} element={<Game />} />
                        <Route key={AppRoute.Play} path={AppRoute.Play} element={<Play isLoggedIn={true} />}>
                            <Route path=":sessionId" element={<Play isLoggedIn={true} />} />
                        </Route>
                        <Route key={AppRoute.Storage} path={AppRoute.Storage} element={<Storage />} />
                        <Route key={AppRoute.Search} path={AppRoute.Search} element={<Search />}>
                            <Route path=":profileId" element={<Search />} />
                        </Route>
                        <Route key="*" path="*" element={<Navigate to={AppRoute.Home} replace />} />
                    </Routes>
                </ClipboardContext.Provider>
            </StorageCacheContext.Provider>
        );
    }

    return (
        <>
            {buildRoutes()};
            {shouldRenderLogout && (
                <div className="top-right-container">
                    {/* XMAS_THEME */}
                    {/* <ChristmasTree
                        filled={isChristmasThemeEnabled}
                        scale={1.3}
                        onClick={() => toggleChristmasTheme()}
                    /> */}
                    <Button className="logout" onClick={() => signOut(FirebaseHelper.getAuth())} label="Logout" />
                </div>
            )}
        </>
    );
}
