import './create-answer-smash.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';
import { useState } from 'react';

import { Button } from '../../../common/components/buttons/buttons';
import { TextField } from '../../../common/components/form-fields/text-field';
import { ImageSelectModal } from '../../../common/components/user-storage/image-select-modal';
import { ImageSelectComponent } from '../common/image-question-input';
import { QuestionArea } from '../common/question-area';
import { RoundProps } from '../create-round-component-map';

const definition = roundDefinitions[RoundTypes.RoundType.AnswerSmash].of;

export function CreateAnswerSmash({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: RoundProps<RoundTypes.AnswerSmashRound>) {
    const { questions } = round;
    const [selectingImage, setSelectingImage] = useState<number>();

    function handleNewQuestion() {
        createNewQuestion({});
    }

    function handleCategoryChanged(questionIndex: number, category: string) {
        const newQuestion = { ...questions[questionIndex], category };
        updateQuestion(questionIndex, newQuestion);
    }

    function handleImageChanged(questionIndex: number, image: string) {
        const newQuestion = { ...questions[questionIndex], image };
        updateQuestion(questionIndex, newQuestion);
        setSelectingImage(undefined);
    }

    return (
        <div className="create-answer-smash">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-answer-smash-question__container">
                        <div className="create-answer-smash__category">
                            <TextField
                                maxLength={definition.category.length}
                                label="Category"
                                type="text"
                                isOutline
                                defaultValue={q.category}
                                onChange={(v) => handleCategoryChanged(i, v)}
                            />
                        </div>
                        <ImageSelectComponent imageQuestion={q.image} onClick={() => setSelectingImage(i)} />
                        <QuestionArea
                            index={i}
                            {...q}
                            allowedQuestionTypes={[RoundTypes.QuestionType.Text]}
                            maxQuestionLength={definition.question.length}
                            maxAnswerLength={definition.answer.length}
                            isArrowsEnabled={true}
                            numberOfQuestions={questions.length}
                            onChange={updateQuestion}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                            onRemove={removeQuestion}
                        />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
            {selectingImage !== undefined && (
                <ImageSelectModal
                    onCloseModal={() => setSelectingImage(undefined)}
                    onFileSelected={(image: string) => handleImageChanged(selectingImage, image)}
                />
            )}
        </div>
    );
}
