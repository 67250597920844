import './control.scss';

import { GameCrawler, GameTypes, RoundTypes, UserStorage } from '@house-of-games/common';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
import { ConfirmationModal } from '../../common/components/confrimation-modal/confirmation-modal';
// import { ChristmasTree } from '../../common/components/icons/christmas-tree'; // XMAS_THEME
import { ClearBuzzer } from '../../common/components/icons/clear-buzzer';
import { DeleteTag } from '../../common/components/icons/delete-tag';
import { ImageNoBorder } from '../../common/components/icons/image-no-border';
import { Music } from '../../common/components/icons/music';
import { NotificationBell } from '../../common/components/icons/notification-bell';
import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { Message, MessageName } from '../../common/constants/message';
import { AppRoute } from '../../common/constants/routes';
import { useGameOptions } from '../../common/effects/game-options/use-game-options';
import { answerStringMap } from '../../common/round-maps/answer-string';
import { PreviewDefinition } from '../../common/round-maps/question-preview-string';
import { roundDescriptions } from '../../common/round-maps/round-descriptions';
import { roundLabels } from '../../common/round-maps/round-labels';
import { without } from '../../utils/array';
import { requestHandler } from '../../utils/request-handler';
// import { useChristmasTheme } from '../../utils/set-christmas-theme'; // XMAS_THEME
import { useProfileContext } from '../auth-router/auth-context';
import { ControlButtons } from './control-buttons/control-buttons';
import { AddIconBold } from './scoreboard/icon-add-bold';
import { SubtractIconBold } from './scoreboard/icon-subtract-bold';
import { ScoreboardAnswer } from './scoreboard/scoreboard-answer';
import { generateSlides, Slide, SlideType } from './slide-control';

export type Scores = { [key in GameTypes.UserId]: number };

type SelectedAnswers = { [key in GameTypes.UserId]: number };

type RouteParams = { gameId: GameTypes.GameId };

function getContextualShowAnswer(slide: Slide): Array<string> {
    if (slide.slideType !== SlideType.Question) {
        return [];
    }

    if ([RoundTypes.RoundType.YouCompleteMe, RoundTypes.RoundType.ICompleteYou].includes(slide.roundType)) {
        const answerArrayLength = (slide.question as RoundTypes.YouCompleteMeRound['questions'][number]).answer?.split(
            ' ',
        ).length;
        return Array(answerArrayLength - 1).fill('Show Word');
    }

    const twoQuestionRounds = [
        RoundTypes.RoundType.HighbrowLowbrow,
        RoundTypes.RoundType.KingJumble,
        RoundTypes.RoundType.RhymeTime,
        RoundTypes.RoundType.Roonerspisms,
    ];
    if (twoQuestionRounds.includes(slide.roundType)) {
        return ['Show Next Question'];
    }

    const playerAnswerRounds = [
        RoundTypes.RoundType.WhereIsKazakhstan,
        RoundTypes.RoundType.FingerOnIt,
        RoundTypes.RoundType.RichList,
        RoundTypes.RoundType.SizeMatters,
        RoundTypes.RoundType.ZList,
        RoundTypes.RoundType.WhenTheySing,
    ];
    if (playerAnswerRounds.includes(slide.roundType)) {
        return ['Show Player Guesses'];
    }

    return [];
}

function AverageComponent({ selectedAnswers }: { selectedAnswers: SelectedAnswers }) {
    function getAverageText() {
        const numberOfAnswers = Object.keys(selectedAnswers).length;
        if (numberOfAnswers < 2) {
            return '...';
        }
        const sum = Object.values(selectedAnswers).reduce((a, b) => Number(a) + Number(b), 0);
        return `${sum / numberOfAnswers}`;
    }

    return (
        <div className="average__component">
            <div className="average__box">
                <div className="average__label">Average</div>
                {getAverageText()}
            </div>
            <div className="average__tip">Shift + Click to select multiple answers and find the average</div>
        </div>
    );
}

export function Control() {
    const { gameId } = useParams<RouteParams>();
    const profile = useProfileContext();
    const game = profile.games.find((g) => g.id === gameId);
    const [currentSlideIndex, setCurrentSlideIndex, currentSlideIndexRef] = useRefState(0);
    const slides = useRef(generateSlides(game?.rounds));
    const isFirstSlide = currentSlideIndex === 0;
    const isLastSlide = currentSlideIndex === slides.current.length - 1;
    const [currentlyBuzzed, setCurrentlyBuzzed] = useState<string>();
    const [alreadyBuzzed, setAlreadyBuzzed] = useState<Array<string>>([]);
    const [currentQuestionString, setCurrentQuestionString] = useState<string>('Title Screen');
    const [currentRoundString, setCurrentRoundString] = useState<string>('Title Screen');
    const [contextualShowAnswer, setContextualShowAnswer] = useState<Array<string>>([]);
    const [isDeletePlayersModalOpen, setIsDeletePlayersModalOpen] = useState(false);

    const [gameOptions, setGameOption] = useGameOptions(sendMessage);
    const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswers>({});
    const [stopWatch, setStopWatch, stopWatchRef] = useRefState<number>(0);
    const stopWatchInterval = useRef<number>();
    const [isMusicQuestion, setIsMusicQuestion] = useState(false);
    // const [isChristmasThemeEnabled, toggleChristmasTheme] = useChristmasTheme(); // XMAS_THEME

    const [message, setMessage] = useState<Message>();
    const fileMatches = useMemo<GameCrawler.CrawlResult<string>>(() => {
        const matches = GameCrawler.gameCrawler(game, UserStorage.userStorageMatcher);
        if (Object.keys(matches).length > 0) {
            return matches;
        }

        return;
    }, []);

    const [scores, setScores, scoresRef] = useRefState<Scores>({});
    const [isShowingScores, setIsShowingScores] = useState(false);
    const scoresAtStartOfSlide = useRef<Scores>(undefined);
    const [isConfirmNextSlideModalOpen, setIsConfirmNextSlideModalOpen] = useState(false);
    const [isScoreComparisonEnabled, setIsScoreComparisonEnabled] = useState(true);

    const bcRef = useRef<BroadcastChannel>();
    const currentSlide = slides.current[currentSlideIndex];
    const [isShowAnswerDisabled, setIsShowAnswerDisabled] = useState<boolean>(
        currentSlide.slideType !== SlideType.Question,
    );

    function saveScores() {
        window.localStorage.setItem(`${gameId}_scores`, JSON.stringify(scoresRef.current));
    }

    function loadScores() {
        const savedScores = window.localStorage.getItem(`${gameId}_scores`);
        if (savedScores) {
            try {
                const parsedScores = JSON.parse(savedScores);
                setScores(parsedScores);
            } catch (e) {
                window.localStorage.removeItem(`${gameId}_scores`);
            }
        }
    }

    function clearScores() {
        window.localStorage.removeItem(`${gameId}_scores`);
        requestHandler.clearPlayers(gameId);
    }

    function deletePlayer(playerId: string) {
        const players = Object.keys(game.players);
        const isLastPlayer = players.length === 1 && players[0] === playerId;
        if (isLastPlayer) {
            window.localStorage.removeItem(`${gameId}_scores`);
        }
        requestHandler.clearPlayer(gameId, playerId);
    }

    function addScore(playerId: GameTypes.UserId) {
        const currentScore = scores[playerId] || 0;
        setScores({ ...scores, [playerId]: currentScore + 1 });
    }

    function subtractScore(playerId: GameTypes.UserId) {
        const currentScore = scores[playerId] || 0;
        setScores({ ...scores, [playerId]: Math.max(currentScore - 1, 0) });
    }

    function addBuzzerTime() {
        const nextBuzzerTime = gameOptions.current.buzzerTimerSeconds + 1;
        setGameOption('buzzerTimerSeconds', nextBuzzerTime);
    }

    function subtractBuzzerTime() {
        const nextBuzzerTime = Math.max(1, gameOptions.current.buzzerTimerSeconds - 1);
        setGameOption('buzzerTimerSeconds', nextBuzzerTime);
    }

    function toggleBuzzerTimer() {
        const nextBuzzerTimerEnabled = !gameOptions.current.buzzerTimerEnabled;
        setGameOption('buzzerTimerEnabled', nextBuzzerTimerEnabled);
    }

    function showScores() {
        setIsShowingScores(true);
        sendMessage({
            name: MessageName.slide,
            data: {
                slideType: SlideType.Scores,
                scores,
            },
        });
    }

    function hideScores() {
        setIsShowingScores(false);
        sendMessage({
            name: MessageName.slide,
            data: currentSlide,
        });
    }

    function handleClearBuzzer() {
        if (currentlyBuzzed) {
            setAlreadyBuzzed([...alreadyBuzzed, currentlyBuzzed]);
            setCurrentlyBuzzed(undefined);
        }
    }

    function toggleScores() {
        if (isShowingScores) {
            hideScores();
        } else {
            showScores();
        }
    }

    function sendMessage(message: Message) {
        bcRef.current.postMessage(message);
    }

    function toggleThemeSongOption() {
        const nextSelected = !gameOptions.current.themeSong;
        setGameOption('themeSong', nextSelected);
    }

    function toggleClientSideAudio() {
        const nextSelected = !gameOptions.current.clientSideAudio;
        setGameOption('clientSideAudio', nextSelected);
    }

    function startStopWatch() {
        stopWatchRef.current = 0;
        clearInterval(stopWatchInterval.current);
        stopWatchInterval.current = window.setInterval(() => {
            setStopWatch(stopWatchRef.current + 57);
        }, 57);
    }

    function pauseStopWatch() {
        clearInterval(stopWatchInterval.current);
    }

    function stopStopWatch() {
        clearInterval(stopWatchInterval.current);
        setStopWatch(0);
    }

    function handlePlay() {
        if (checkIsStopWatchEnabled()) {
            startStopWatch();
        }
    }

    function handlePause() {
        pauseStopWatch();
    }

    function handleChangeSlide(newSlideIndex: number) {
        stopStopWatch();
        setAlreadyBuzzed([]);
        setCurrentlyBuzzed(undefined);
        setSelectedAnswers({});
        setIsShowingScores(false);
        setIsMusicQuestion(false);

        const newSlide = slides.current[newSlideIndex];
        setIsShowAnswerDisabled(newSlide.slideType !== SlideType.Question);

        if (newSlide.slideType === SlideType.NewRound) {
            setCurrentRoundString(`Round ${newSlide.roundNumber}/${newSlide.totalRounds}`);
            setCurrentQuestionString(`${newSlide.totalQuestions} Questions`);
        } else if (newSlide.slideType === SlideType.Question) {
            scoresAtStartOfSlide.current = scoresRef.current;
            setCurrentRoundString(`Round ${newSlide.roundNumber}/${newSlide.totalRounds}`);
            setCurrentQuestionString(`Question ${newSlide.questionNumber}/${newSlide.totalQuestions}`);
        } else if (newSlide.slideType === SlideType.Title) {
            setCurrentRoundString('Title Screen');
            setCurrentQuestionString('Title Screen');
        }

        setContextualShowAnswer(getContextualShowAnswer(newSlide));
        sendMessage({
            name: MessageName.slide,
            data: newSlide,
        });
        setCurrentSlideIndex(newSlideIndex);
    }

    function checkHaveScoresChanged() {
        return Object.keys(scoresRef.current).some((key) => {
            const currentScore = scoresRef.current[key];
            const initialScore = scoresAtStartOfSlide.current[key];
            if (initialScore === undefined) {
                // If the player wasn't joined at the start, there score would be 0 if unchanged
                return currentScore !== 0;
            }

            return currentScore !== initialScore;
        });
    }

    function handleContinueWhenScoresUnchanged() {
        nextSlide(true);
        setIsConfirmNextSlideModalOpen(false);
    }

    function nextSlide(ignoreScores?: boolean) {
        if (isLastSlide) {
            return;
        }

        if (
            isScoreComparisonEnabled &&
            !ignoreScores &&
            currentSlide.slideType === SlideType.Question &&
            scoresAtStartOfSlide.current
        ) {
            const isScoreboardUpdated = checkHaveScoresChanged();
            if (!isScoreboardUpdated) {
                setIsConfirmNextSlideModalOpen(true);
                return;
            }
        }

        handleChangeSlide(currentSlideIndex + 1);
    }

    function previousSlide() {
        if (isFirstSlide) {
            return;
        }

        handleChangeSlide(currentSlideIndex - 1);
    }

    function showAnswer() {
        stopStopWatch();

        if (contextualShowAnswer.length > 0) {
            const [, ...rest] = contextualShowAnswer;
            setContextualShowAnswer(rest);
        } else {
            setIsShowAnswerDisabled(true);
        }

        sendMessage({ name: MessageName.reveal });
    }

    function checkIsDistinctlyAverage() {
        return (
            currentSlide.slideType === SlideType.Question &&
            currentSlide.roundType === RoundTypes.RoundType.DistinctlyAverage
        );
    }

    function toggleSelectedAnswer(e: React.MouseEvent, playerId: string) {
        if (!checkIsDistinctlyAverage()) {
            return;
        }

        let newSelectedAnswers = { ...selectedAnswers };

        const playerAnswer = game.players[playerId].answer as any;
        const isNonNumericalAnswer = isNaN(Number(playerAnswer));
        if (isNonNumericalAnswer) {
            return;
        }

        if (!e.shiftKey) {
            newSelectedAnswers = {};
        }

        if (Object.keys(newSelectedAnswers).includes(playerId)) {
            delete newSelectedAnswers[playerId];
        } else {
            newSelectedAnswers[playerId] = playerAnswer;
        }

        setSelectedAnswers(newSelectedAnswers);
    }

    useEffect(() => {
        window.addEventListener('beforeunload', saveScores);
        loadScores();

        bcRef.current = new BroadcastChannel(gameId);

        bcRef.current.onmessage = (msg) => {
            const currentSlideByRef = slides.current[currentSlideIndexRef.current];
            const message = msg.data as Message;
            setMessage(message);
            switch (message.name) {
                case MessageName.closing:
                case MessageName.spawned:
                    window.close();
                    break;
                case MessageName.ping:
                    sendMessage({ name: MessageName.pong });
                    sendMessage({ name: MessageName.slide, data: currentSlideByRef });
                    break;
                case MessageName.questionChanged:
                    setIsMusicQuestion(message.data.isMusicQuestion);
                    break;
                case MessageName.buzzed:
                    if (currentSlideByRef.slideType === SlideType.Question && currentSlideByRef.isBuzzerEnabled) {
                        setCurrentlyBuzzed(message.data[0]);
                    } else {
                        setAlreadyBuzzed(message.data);
                    }
                    break;
            }
        };

        sendMessage({ name: MessageName.spawned });
        sendMessage({ name: MessageName.slide, data: slides.current[currentSlideIndexRef.current] });

        return () => {
            window.removeEventListener('beforeunload', saveScores);
            sendMessage({ name: MessageName.closing });
            bcRef.current.close();
        };
    }, []);

    useEffect(() => {
        const modifiedAnswers = Object.entries(selectedAnswers).filter(([playerId, answer]) => {
            return game.players[playerId]?.answer === answer;
        });

        if (modifiedAnswers) {
            setSelectedAnswers({});
        }
    }, [game?.players]);

    function checkIsStopWatchEnabled() {
        return (
            currentSlide.slideType === SlideType.Question &&
            currentSlide.roundType === RoundTypes.RoundType.WhenTheySing
        );
    }

    function getPreviewElements(previewDefinitions: Array<PreviewDefinition>) {
        return (
            <>
                {previewDefinitions?.map((def, i) => {
                    const { prompt, questionType = RoundTypes.QuestionType.Text } = def;

                    let backgroundElement: React.ReactNode;
                    if (questionType === RoundTypes.QuestionType.Audio) {
                        backgroundElement = <Music />;
                    } else if (questionType === RoundTypes.QuestionType.Image) {
                        backgroundElement = <ImageNoBorder />;
                    }

                    const iconClassNames = classNames(`album-item__preview-icon`, {
                        'album-item__preview-icon--background': Boolean(prompt),
                    });

                    return (
                        <div
                            key={`${prompt}-${i}`}
                            className={`album-item__preview-item album-item__preview-item--${questionType.toLowerCase()}`}
                        >
                            {backgroundElement && <div className={iconClassNames}>{backgroundElement}</div>}
                            {prompt}
                        </div>
                    );
                })}
            </>
        );
    }

    function generateAlbumView() {
        const slideComponents = slides.current.map((slide, i) => {
            const transform = (i - currentSlideIndex) * 100 + (31 / 38) * 100;
            let text: React.ReactElement;
            switch (slide.slideType) {
                case SlideType.Question:
                    const previewElements = getPreviewElements(slide.previewString);
                    if (i === currentSlideIndex && checkIsStopWatchEnabled()) {
                        text = (
                            <div className="album-item__preview">
                                {previewElements}
                                <br />
                                <br />
                                <div className="stop-watch">{(stopWatch / 1000).toFixed(2)}</div>
                            </div>
                        );
                    } else {
                        text = <div className="album-item__preview">{previewElements}</div>;
                    }
                    break;
                case SlideType.NewCategory:
                    text = (
                        <div className="album-item__category">
                            New Category
                            <br />
                            <br />
                            {slide.category}
                        </div>
                    );
                    break;
                case SlideType.NewRound:
                    text = (
                        <div className="album-item__round">
                            {`Round ${slide.roundNumber}`}
                            <br />
                            <br />
                            {roundLabels[slide.roundType]}
                        </div>
                    );
                    break;
                case SlideType.Title:
                    text = (
                        <div>
                            Title Slide
                            <br />
                            <br />
                            <Button
                                label="Show QR Code To Join"
                                onClick={() => sendMessage({ name: MessageName.toggleQrCode })}
                            />
                        </div>
                    );
                    // text = <Button label='Show QR Code To Join' onClick={() => sendMessage({ name: MessageName.toggleQrCode })} />
                    break;
                case SlideType.BuzzerTest:
                    text = <div>Buzz To Ready Up</div>;
                    break;
            }

            const className = currentSlideIndex === i ? 'album-item album-item--selected' : 'album-item';
            return (
                <div key={i} className="album-item-container" style={{ transform: `translateX(${transform}%)` }}>
                    <div className={className}>{text}</div>
                </div>
            );
        });

        return (
            <div className="album-container">
                <div className="album">{slideComponents}</div>
                <div className="album-overlay" />
            </div>
        );
    }

    const leftArrowClasses = classNames('album-view-arrow album-view-arrow__left', {
        'album-view-arrow--disabled': isFirstSlide,
    });

    const rightArrowClasses = classNames('album-view-arrow album-view-arrow__right', {
        'album-view-arrow--disabled': isLastSlide,
    });

    const playerKeys = Object.keys(game?.players || {});
    const isScoreboardEmpty = playerKeys.length === 0;

    function getInfoComponent() {
        switch (currentSlide.slideType) {
            case SlideType.Question:
                const answerString = answerStringMap[currentSlide.roundType](currentSlide.question as any);
                if (answerString) {
                    return (
                        <div className="answer-container">
                            <div className="answer-label">Answer</div>
                            <div className="answer-string">{answerString}</div>
                        </div>
                    );
                }
                break;
            case SlideType.NewRound:
                return <div className="info-label readable">{roundDescriptions[currentSlide.roundType]}</div>;
        }
    }

    function removeAlreadyBuzzed(player: string) {
        sendMessage({ name: MessageName.unbuzzed, data: [player] });
        setAlreadyBuzzed(without(alreadyBuzzed, player));
    }

    const positionInfoClassNames = classNames('position-info-container', {
        'position-info-container--hidden': currentQuestionString === 'Title Screen',
    });

    if (!game) {
        return <Navigate to={AppRoute.Home} replace />;
    }

    const audioCheckboxClasses = classNames('option__checkbox', {
        'option__checkbox--selected': gameOptions.current.clientSideAudio,
    });

    const themeSongCheckboxClasses = classNames('option__checkbox', {
        'option__checkbox--selected': gameOptions.current.themeSong,
    });

    const buzzerTimerCheckboxClasses = classNames('option__checkbox', {
        'option__checkbox--selected': gameOptions.current.buzzerTimerEnabled,
    });

    const buzzerConfigurationClasses = classNames('option__configuration', {
        'option__configuration--disabled': !gameOptions.current.buzzerTimerEnabled,
    });

    return (
        <div className="control page">
            {/* XMAS_THEME */}
            {/* <ChristmasTree filled={isChristmasThemeEnabled} scale={1.3} onClick={() => toggleChristmasTheme()} /> */}
            <div className="control-left">
                <div className={positionInfoClassNames}>
                    <div className="position-info-round">{currentRoundString}</div>
                    <div className="position-info-question">{currentQuestionString}</div>
                </div>
                <div className="album-view-container">
                    <div className={leftArrowClasses} onClick={previousSlide} />
                    {generateAlbumView()}
                    <div className={rightArrowClasses} onClick={() => nextSlide()} />
                </div>
                <ControlButtons
                    slide={currentSlide}
                    message={message}
                    isMusicQuestion={isMusicQuestion}
                    sendMessage={sendMessage}
                    onClearBuzzer={handleClearBuzzer}
                    onPlay={handlePlay}
                    onPause={handlePause}
                />
                <div className="show-answer-container">
                    <Button
                        disabled={isShowAnswerDisabled}
                        label={contextualShowAnswer[0] || 'Show Answer'}
                        onClick={showAnswer}
                    />
                </div>
                <div className="info-container">{getInfoComponent()}</div>
                <div className="notepad__container">
                    <div className="notepad_label">Notes</div>
                    <textarea className="notepad" />
                </div>
            </div>
            <div className="control-right">
                <div className="scoreboard">
                    <div className="scoreboard__header-container">
                        <div className="scoreboard__header">Scoreboard</div>
                        <NotificationBell
                            filled={isScoreComparisonEnabled}
                            onClick={() => setIsScoreComparisonEnabled(!isScoreComparisonEnabled)}
                        />
                    </div>
                    {isScoreboardEmpty ? (
                        <div className="scoreboard__empty-text">Waiting for players to join the game</div>
                    ) : (
                        playerKeys
                            .sort((a, b) => game.players[a].name.localeCompare(game.players[b].name))
                            .map((id, index) => {
                                const isBuzzerEnabled =
                                    currentSlide.slideType === SlideType.Question && currentSlide.isBuzzerEnabled;
                                const playerAlreadyBuzzed = alreadyBuzzed.includes(id);
                                const answer = game.players[id].answer;
                                const itemClassName = classNames('scoreboard__item', {
                                    'scoreboard__item--current-buzzed': currentlyBuzzed === id,
                                    'scoreboard__item--already-buzzed': playerAlreadyBuzzed,
                                });

                                const isAnswerSelected = Object.keys(selectedAnswers).includes(id);
                                const isAnswerDefined = Boolean(answer?.toString()); // This will return false for '' and undefined but true for 0 and '0'
                                let answerString: string;
                                if (isAnswerDefined) {
                                    answerString = 'Submitted!';

                                    if (['string', 'number'].includes(typeof answer)) {
                                        answerString = `${answer}`;
                                    }
                                } else if (playerAlreadyBuzzed && !isBuzzerEnabled) {
                                    answerString = 'Submitted!';
                                }

                                return (
                                    <div
                                        key={id}
                                        className={itemClassName}
                                        style={{ zIndex: playerKeys.length - index }}
                                    >
                                        <div className="scoreboard__player">
                                            <DeleteTag onClick={() => deletePlayer(id)} />
                                            <div className="scoreboard__player-name">{game.players[id].name}</div>
                                            <div
                                                className="scoreboard__player-buzzer"
                                                onClick={() => removeAlreadyBuzzed(id)}
                                            >
                                                <ClearBuzzer />
                                            </div>
                                            <div className="scoreboard__player-score">
                                                <div
                                                    className="scoreboard__player-score-minus"
                                                    onClick={() => subtractScore(id)}
                                                >
                                                    <SubtractIconBold scale={0.4} />
                                                </div>
                                                <div className="scoreboard__player-score-number">{scores[id] || 0}</div>
                                                <div
                                                    className="scoreboard__player-score-plus"
                                                    onClick={() => addScore(id)}
                                                >
                                                    <AddIconBold scale={0.4} />
                                                </div>
                                            </div>
                                        </div>
                                        <ScoreboardAnswer
                                            answer={answerString}
                                            isAnswerSelected={isAnswerSelected}
                                            onClick={(e) => toggleSelectedAnswer(e, id)}
                                        />
                                    </div>
                                );
                            })
                    )}
                </div>
                <Button
                    label={isShowingScores ? 'Hide Scoreboard' : 'Show Scoreboard'}
                    className={classNames('scoreboard__toggle', { 'scoreboard__toggle--showing': isShowingScores })}
                    disabled={isScoreboardEmpty}
                    onClick={toggleScores}
                />
                <Button
                    label="Clear All Players"
                    className="scoreboard__clear"
                    disabled={isScoreboardEmpty}
                    onClick={() => setIsDeletePlayersModalOpen(true)}
                />
                {checkIsDistinctlyAverage() && (
                    <>
                        <div className="spacer" />
                        <AverageComponent selectedAnswers={selectedAnswers} />
                    </>
                )}
                <div className="spacer" />
                <div className="options__container">
                    <div className="options">
                        <div className="options__header">Options</div>
                        <div className="option">
                            <div className="option__label">Show Buzzer Timer</div>
                            <div className="option__body">
                                <div className="option__description">
                                    {`Shows a small bar above the player name when someone buzzes in that indicates
                                    how much time they have left to answer the question.`}
                                </div>
                                <div className={buzzerConfigurationClasses}>
                                    <div className="option__configuration-title">Time(s)</div>
                                    <div className="option__configuration-field">
                                        <div className="scoreboard__player-score">
                                            <div
                                                className="scoreboard__player-score-minus"
                                                onClick={() => subtractBuzzerTime()}
                                            >
                                                <SubtractIconBold scale={0.4} />
                                            </div>
                                            <div className="scoreboard__player-score-number">
                                                {gameOptions.current.buzzerTimerSeconds}
                                            </div>
                                            <div
                                                className="scoreboard__player-score-plus"
                                                onClick={() => addBuzzerTime()}
                                            >
                                                <AddIconBold scale={0.4} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={buzzerTimerCheckboxClasses} onClick={toggleBuzzerTimer} />
                        </div>
                        <div className="option">
                            <div className="option__label">Play Theme Song For New Rounds</div>
                            <div className="option__body">
                                <div className="option__description">
                                    {`Plays the "new round" jingle from the show for each new round.`}
                                </div>
                            </div>
                            <div className={themeSongCheckboxClasses} onClick={toggleThemeSongOption} />
                        </div>
                        {fileMatches && game.rounds.WhenTheySing?.questions?.length > 0 && (
                            <div className="option">
                                <div className="option__label">{`"Win When They're Singing" Audio On Buzzer Devices`}</div>
                                <div className="option__description">
                                    {`This will make the music questions from "Win When They're Singing" play sound on the
                                players devices instead of the host PC. This can be useful for screen-share
                                scenarios as it will improve audio quality and accuracy of buzzer time.`}
                                </div>
                                <div className={audioCheckboxClasses} onClick={toggleClientSideAudio} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isDeletePlayersModalOpen && (
                <ConfirmationModal
                    textElement="This will delete all players from the scoreboard and cannot be undone. Would you like to continue?"
                    onAccept={clearScores}
                    onDecline={() => setIsDeletePlayersModalOpen(false)}
                    labels={{
                        accept: 'Delete',
                        decline: 'Cancel',
                    }}
                />
            )}
            {isConfirmNextSlideModalOpen && (
                <ConfirmationModal
                    className="confirm-next-slide-modal"
                    textElement={
                        <>
                            {`It looks like the scores haven't been updated for this question!`}
                            <br />
                            Would you like to continue anyway?
                            <br />
                            <br />
                            (This reminder can be disabled by clicking the <NotificationBell filled={true} /> icon by
                            the scoreboard)
                        </>
                    }
                    onAccept={() => handleContinueWhenScoresUnchanged()}
                    onDecline={() => setIsConfirmNextSlideModalOpen(false)}
                    labels={{
                        accept: 'Continue To Next Slide',
                        decline: 'Cancel',
                    }}
                />
            )}
        </div>
    );
}
