import './verify-email.scss';

import { getAuth, sendEmailVerification } from 'firebase/auth';
import PropTypes from 'prop-types';

import { Button } from '../../common/components/buttons/buttons';

type VerifyProps = {
    email: string;
    handleRefreshVerified: () => void;
};

export const VerifyEmail = ({ email, handleRefreshVerified }: VerifyProps) => {
    return (
        <div className="verify-container">
            <div className="verify-content readable">
                <div className="text--off-white size--1.2 text--shadow">
                    Please verify your email address and refresh this page.
                </div>
                <br />
                <div className="text--off-white size--0.9 text--shadow">({email})</div>
                <br />
                <br />
                <div className="button__container">
                    <Button onClick={handleRefreshVerified} label="Refresh" />
                </div>
                <br />
                <br />
                <div className="text--off-white size--0.9 text--shadow">
                    {"Haven't received a verification email? Remember to check your spam folder."}
                    <br />
                    <span className="link" onClick={() => sendEmailVerification(getAuth().currentUser)}>
                        Click here to re-send.
                    </span>
                </div>
            </div>
        </div>
    );
};

VerifyEmail.propTypes = {
    email: PropTypes.string.isRequired,
    handleRefreshVerified: PropTypes.func.isRequired,
};
