import './create-totes-emoji.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';

import { Button } from '../../../common/components/buttons/buttons';
import { TextField } from '../../../common/components/form-fields/text-field';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { RoundProps } from '../create-round-component-map';

export function CreateTotesEmoji({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: RoundProps<RoundTypes.TotesEmojiRound>) {
    const { questions } = round;
    const definition = roundDefinitions[round.type].of;

    function handleNewQuestion() {
        createNewQuestion({});
    }

    function handleCategoryChanged(questionIndex: number, category: string) {
        const newQuestion = { ...questions[questionIndex], category };
        updateQuestion(questionIndex, newQuestion);
    }

    function handleQuestionChanged(questionIndex: number, question: string) {
        const newQuestion = { ...questions[questionIndex], question };
        updateQuestion(questionIndex, newQuestion);
    }

    function handleAuthorChanged(questionIndex: number, author: string) {
        const newQuestion = { ...questions[questionIndex], author };
        updateQuestion(questionIndex, newQuestion);
    }

    function handleAnswerChanged(questionIndex: number, answer: string) {
        const newQuestion = { ...questions[questionIndex], answer };
        updateQuestion(questionIndex, newQuestion);
    }

    return (
        <div className="create-totes-emoji">
            {questions.map((q, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-totes-emoji-question__container">
                        <QuestionArrows
                            index={i}
                            numberOfQuestions={questions.length}
                            onClickUpArrow={shiftUp}
                            onClickDownArrow={shiftDown}
                        />
                        <div className="create-totes-emoji-question__category">
                            <TextField
                                label="Category"
                                type="text"
                                maxLength={definition.category.length}
                                isOutline
                                defaultValue={q.category}
                                onChange={(v) => handleCategoryChanged(i, v)}
                            />
                        </div>
                        <div className="create-totes-emoji-question__author">
                            <TextField
                                label="Author"
                                type="text"
                                maxLength={definition.author.length}
                                isOutline
                                defaultValue={q.author}
                                onChange={(v) => handleAuthorChanged(i, v)}
                            />
                        </div>
                        <div className="create-totes-emoji-question__question">
                            <TextField
                                label="Emoji Clue"
                                type="text"
                                maxLength={definition.question.length}
                                isOutline
                                theme={{ border: 'var(--hog-gold)', text: 'var(--off-white)' }}
                                defaultValue={q.question}
                                onChange={(v) => handleQuestionChanged(i, v)}
                                isEmoji
                            />
                        </div>
                        <div className="create-totes-emoji-question__answer">
                            <TextField
                                label="Answer"
                                type="text"
                                maxLength={definition.answer.length}
                                defaultValue={q.answer}
                                onChange={(v) => handleAnswerChanged(i, v)}
                            />
                        </div>
                        <QuestionErase index={i} onRemove={removeQuestion} />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
