import './create-cine-nyms.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';

import { Button } from '../../../common/components/buttons/buttons';
import { QuestionArea } from '../common/question-area';
import { QuestionErase } from '../common/question-erase';
import { TextArea } from '../common/text-area';
import { RoundProps } from '../create-round-component-map';

type Question = RoundTypes.CineNymsRound['questions'][0];

type AssociatedRounds = RoundProps<RoundTypes.CineNymsRound>;

const definition = roundDefinitions[RoundTypes.RoundType.CineNyms].of;

export function CreateCineNyms({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: AssociatedRounds) {
    const { questions } = round;

    function handleNewQuestion() {
        createNewQuestion({});
    }

    function handleQuestionChanged(questionIndex: number, question: Partial<Question>) {
        const newQuestion = { ...question };
        updateQuestion(questionIndex, newQuestion);
    }

    function handleOriginalQuoteChanged(questionIndex: number, originalQuote: string) {
        updateQuestion(questionIndex, { originalQuote });
    }

    return (
        <div className="create-cine-nyms">
            {questions.map((question, i) => {
                return (
                    <div key={`${uniqueKey}-${i}`} className="create-cine-nyms__question-container readable">
                        <div className="create-cine-nyms__text-area">
                            <TextArea
                                maxLength={definition.originalQuote.length}
                                defaultValue={question.originalQuote}
                                placeholder="Enter the original unaltered quote..."
                                onChange={(quote) => handleOriginalQuoteChanged(i, quote)}
                            />
                        </div>
                        <div className="create-cine-nyms__question-area">
                            <QuestionArea
                                index={i}
                                {...question}
                                allowedQuestionTypes={[RoundTypes.QuestionType.Text]}
                                maxQuestionLength={definition.question.length}
                                maxAnswerLength={definition.answer.length}
                                answerPlaceholder="Quote Origin"
                                questionPlaceholder="Enter the altered synonymous quote..."
                                isArrowsEnabled={true}
                                numberOfQuestions={questions.length}
                                onChange={handleQuestionChanged}
                                onClickUpArrow={shiftUp}
                                onClickDownArrow={shiftDown}
                            />
                        </div>
                        <QuestionErase index={i} onRemove={removeQuestion} />
                    </div>
                );
            })}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
        </div>
    );
}
