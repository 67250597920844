import './create-game.scss';

import {
    CFRequestTypes,
    dataValidators,
    GameCrawler,
    GameTypes,
    RoundTypes,
    UserStorage,
} from '@house-of-games/common';
import { PrivateProfile } from '@house-of-games/common/lib/types/game';
import deepEqual from 'deep-equal';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
import { ConfirmationModal } from '../../common/components/confrimation-modal/confirmation-modal';
import { Dropdown, DropdownOption } from '../../common/components/form-fields/dropdown';
import { DraggableGrid } from '../../common/components/grid/draggable-grid';
import { Lock } from '../../common/components/icons/lock';
import { useLoadingError } from '../../common/components/loading-error/loading-error';
import { LoadingSpinner } from '../../common/components/loading-error/loading-spinner';
import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { Toast } from '../../common/components/toast/toast';
import { Tooltip } from '../../common/components/tooltip/tooltip';
import { TransitionalModal, useTransitionalModal } from '../../common/components/transitional-modal/transitional-modal';
import { useStorageCache } from '../../common/components/user-storage/use-storage-cache';
import { AppRoute } from '../../common/constants/routes';
import { roundDescriptions } from '../../common/round-maps/round-descriptions';
import { roundLabels } from '../../common/round-maps/round-labels';
import { FirebaseHelper } from '../../utils/firebase';
import { requestHandler } from '../../utils/request-handler';
import { ClipboardState, useClipboardContext } from '../app-router/clipboard-context';
import { useProfileContext } from '../auth-router/auth-context';
import { CreateRound } from '../rounds/create-round';
import { EditableHeader } from './editable-header';

function getNumberOfQuestionsString(n: number) {
    if (n === 1) {
        return '1 Question';
    } else {
        return `${n} Questions`;
    }
}

function CopiedGamePrompt({ game, profile }: { game: GameTypes.PrivateGame; profile: PrivateProfile }) {
    const isAlreadyLiked = useRef(
        profile.like?.includes(game.original.publicUid) || profile.dislike?.includes(game.original.publicUid),
    );
    const [isJustLiked, setIsJustLiked] = useState(false);
    let bottomLineText = null;

    function handleRate(state: CFRequestTypes.RateState) {
        isAlreadyLiked.current = true;
        setIsJustLiked(true);
        setTimeout(() => {
            setIsJustLiked(false);
        }, 2000);

        requestHandler.rate(game.original.publicUid, state);
    }

    if (!isAlreadyLiked.current && !isJustLiked) {
        bottomLineText = (
            <>
                {"Don't forget to leave a "}
                <span className="copied-game-prompt__like" onClick={() => handleRate(CFRequestTypes.RateState.Liked)}>
                    like
                </span>
                {' or '}
                <span
                    className="copied-game-prompt__dislike"
                    onClick={() => handleRate(CFRequestTypes.RateState.Disliked)}
                >
                    dislike
                </span>
                {' to help others find great games!'}
            </>
        );
    } else if (isJustLiked) {
        bottomLineText = <>Thanks!</>;
    }

    return (
        <div className="create-game__copied-game-prompt">
            It looks like this game was downloaded from the search page -&nbsp;
            <Link draggable={false} className="copied-game-prompt__creator-link" to={`/search/${game.original.owner}`}>
                click here to view more games from this creator
            </Link>
            {bottomLineText && (
                <>
                    <br />
                    {bottomLineText}
                </>
            )}
        </div>
    );
}

function GameIdWithPublicToggle({ game }: { game: GameTypes.PrivateGame }) {
    const [localPublic, setLocalPublic] = useState(game.public);
    const [isChanging, setIsChanging] = useState(false);

    async function togglePublic() {
        if (isChanging) {
            return;
        }

        const newValue = !localPublic;
        setLocalPublic(newValue);
        setIsChanging(true);
        try {
            await requestHandler.makePublic(game.id, newValue);
        } catch (e) {
            setLocalPublic(!newValue);
        }
        setIsChanging(false);
    }

    return (
        <div className="create-game__subheader">
            <div className="subheader__items">
                <Tooltip
                    className="subheader__lock-container"
                    text="Making your game public will allow other people to download a copy from the search page!"
                >
                    <span onClick={togglePublic}>
                        <Lock isUnlocked={localPublic} />
                    </span>
                </Tooltip>
            </div>
        </div>
    );
}

type RouteParams = { gameId: GameTypes.GameId; roundType: RoundTypes.RoundType };

type CreateProps = {
    setClipboard: (clip: ClipboardState) => void;
};

export function Create({ setClipboard }: CreateProps) {
    const isWindows = window.navigator.platform.includes('Win');
    const modifierKey = isWindows ? 'Ctrl' : 'Cmd';
    const profile = useProfileContext();
    const profileRef = useRef(profile);
    profileRef.current = profile;

    const clipboard = useClipboardContext();
    const clipboardRef = useRef(clipboard);
    clipboardRef.current = clipboard;

    const { gameId, roundType: creatingRoundType } = useParams<RouteParams>();
    const game = profile.games.find((g) => g.id === gameId);

    const { name, rounds: profileRounds = {} } = game || {};
    const [rounds, setRounds, roundsRef] = useRefState(profileRounds);
    const [, setSelectedRounds, selectedRoundsRef] = useRefState<Array<RoundTypes.RoundType>>([]);
    const [isLoading, error, wrapper] = useLoadingError(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isShowingConfirmationDialog, setIsShowingConfirmationDialog] = useState(false);
    const draggableGridRef = useRef<DraggableGrid>(null);
    const storageCache = useStorageCache();

    useEffect(() => {
        window.addEventListener('copy', handleCopy);
        window.addEventListener('paste', handlePaste);
        FirebaseHelper.listAllFiles();
        return () => {
            window.removeEventListener('copy', handleCopy);
            window.removeEventListener('paste', handlePaste);
        };
    }, []);

    useEffect(() => {
        setRounds(profileRounds);
    }, [profileRounds]);

    function checkIsCopyable() {
        return selectedRoundsRef.current?.length > 0;
    }

    function checkIsPastable() {
        const currentClip = clipboardRef.current;
        const isDifferentGameId = currentClip?.gameId && currentClip.gameId !== gameId;
        const roundsExist = currentClip?.rounds?.length > 0;
        return isDifferentGameId && roundsExist;
    }

    function handleCopy() {
        if (checkIsCopyable()) {
            setClipboard({ gameId: gameId, rounds: selectedRoundsRef.current });
            draggableGridRef.current?.clearSelected?.();
            setToastMessage('Copied To Clipboard!');
            setToastMessage('');
        }
    }

    function handlePaste() {
        const currentClip = clipboardRef.current;
        const currentRounds = roundsRef.current;
        if (!checkIsPastable()) {
            return;
        }

        const isDuplicateRounds = Object.keys(currentRounds).some((k) =>
            currentClip.rounds.includes(k as RoundTypes.RoundType),
        );
        if (isDuplicateRounds) {
            setIsShowingConfirmationDialog(true);
        } else {
            mergeRoundsFromClipboard();
        }
    }

    function mergeRoundsFromClipboard() {
        const currentClip = clipboardRef.current;
        const currentRounds = roundsRef.current;
        const clipboardGame = profileRef.current.games.find((g) => g.id === clipboard.gameId);
        const newRounds = cloneDeep(currentRounds);
        currentClip.rounds?.forEach((roundType) => {
            const clipRound = clipboardGame?.rounds[roundType];
            if (newRounds[roundType] && clipRound) {
                const existingQuestions = newRounds[roundType].questions || [];
                const newQuestions = clipRound.questions || [];
                newRounds[roundType].questions = [...existingQuestions, ...newQuestions] as any;
            } else if (clipRound) {
                const position = Object.keys(newRounds).length;
                newRounds[clipRound.type] = { ...clipRound, position } as any;
            }
        });
        requestHandler.submitRounds(gameId, Object.values(newRounds));
    }

    function overwriteRoundsFromClipboard() {
        const clipboardGame = profileRef.current.games.find((g) => g.id === clipboard.gameId);
        const newRounds = cloneDeep(roundsRef.current);
        clipboardRef.current.rounds?.forEach((roundType) => {
            const clipRound = clipboardGame?.rounds[roundType];
            if (clipRound) {
                const position = newRounds[clipRound.type]?.position ?? Object.keys(newRounds).length;
                newRounds[clipRound.type] = { ...clipRound, position } as any;
            }
        });
        requestHandler.submitRounds(gameId, Object.values(newRounds));
    }

    function handleConfirmationMerge() {
        mergeRoundsFromClipboard();
        setIsShowingConfirmationDialog(false);
    }

    function handleConfirmationOverwrite() {
        overwriteRoundsFromClipboard();
        setIsShowingConfirmationDialog(false);
    }

    function createNewRoundOptions() {
        return Object.values(RoundTypes.RoundType)
            .sort((a, b) => roundLabels[a].localeCompare(roundLabels[b]))
            .map((r) => {
                return {
                    value: r,
                    label: roundLabels[r],
                    disabled: Object.keys(rounds).includes(r),
                };
            });
    }

    const newRoundOptions = createNewRoundOptions();
    const firstEnabledNewRound = newRoundOptions.find((o) => !o.disabled);

    const [selectedRound, setSelectedRound] = useState<RoundTypes.RoundType>(firstEnabledNewRound?.value);
    const [contentClassName, modalClassName, openModal, closeModal] = useTransitionalModal();

    if (!game) {
        return <Navigate to={AppRoute.Home} replace />;
    }

    function handleNewRound() {
        openModal();
    }

    function handleCloseModal() {
        closeModal();
    }

    function recalculatePositions(keys: Array<string>) {
        const newRounds = cloneDeep(rounds);
        keys.forEach((key, i) => {
            const round = newRounds[key as RoundTypes.RoundType];
            if (round) {
                round.position = i;
            }
        });
        return newRounds;
    }

    function handleRoundOrderChanged(keys: Array<string>) {
        const newRounds = recalculatePositions(keys);

        if (!deepEqual(newRounds, rounds)) {
            setRounds(newRounds);
            requestHandler.moveRounds(gameId, newRounds);
        }
    }

    async function handleRoundRemoved(keys: Array<string>) {
        const newKeys = Object.keys(rounds)
            .filter((k) => !keys.includes(k))
            .sort((a, b) => {
                return rounds[a as RoundTypes.RoundType].position - rounds[b as RoundTypes.RoundType].position;
            });
        const newRounds = recalculatePositions(newKeys);

        (keys as Array<RoundTypes.RoundType>).forEach((key) => {
            delete newRounds[key];
        });

        if (!deepEqual(newRounds, rounds)) {
            setRounds(newRounds);
        }

        const roundsToDelete: GameTypes.PrivateGame['rounds'] = {};
        (keys as Array<RoundTypes.RoundType>).forEach((key) => {
            if (rounds[key]) {
                const round = rounds[key];
                (roundsToDelete[key] as any) = round;
            }
        });

        const fileMatchesInRounds = GameCrawler.gameCrawler(
            { rounds: roundsToDelete, id: gameId },
            UserStorage.userStorageMatcher,
        );
        const fileUris = Object.values(fileMatchesInRounds).map((m) => m.value);
        const pathsToDelete = keys.map((key) => `${gameId}.rounds.${key}`);
        const deletedFiles = await FirebaseHelper.checkAndDeleteFiles(fileUris, pathsToDelete);
        deletedFiles.forEach((f) => {
            storageCache.delete(f);
        });
        requestHandler.deleteRounds(gameId, keys as Array<RoundTypes.RoundType>);
    }

    async function handleCreateRound() {
        await requestHandler.submitRounds(gameId, [
            {
                type: selectedRound,
                position: Object.values(rounds).length,
                isBuzzerRound: true,
                questions: [],
            },
        ] as any);
        const newSelectedRound = newRoundOptions.find((o) => !o.disabled && o.value !== selectedRound);
        setSelectedRound(newSelectedRound?.value);
        handleCloseModal();
    }

    function onSelectRoundChange(r: DropdownOption<RoundTypes.RoundType>) {
        setSelectedRound(r.value);
    }

    function validateNewRound() {
        return (
            Object.values(RoundTypes.RoundType).includes(selectedRound) && !Object.keys(rounds).includes(selectedRound)
        );
    }

    function buildRoundPath(roundType: RoundTypes.RoundType) {
        return `${roundType.toLowerCase()}`;
    }

    async function handleRenameGame(newName: string) {
        try {
            await requestHandler.renameGame(gameId, newName);
            return newName;
        } catch (e) {
            return name;
        }
    }

    const confirmationText = (
        <>
            Some of the rounds in your clipboard already exist in this game.
            <br />
            Would you like to overwrite or merge with the existing questions?
        </>
    );

    let tipText =
        'Enter selection mode by long clicking on a round. You can select multiple rounds by clicking them while in selection mode and drag rounds to re-order or delete them';
    if (checkIsCopyable()) {
        tipText = `You can use ${modifierKey} + C to copy the selected rounds to another game`;
    } else if (checkIsPastable()) {
        tipText = `It looks like you have some rounds stored on the clipboard. You can use ${modifierKey} + V to paste them here`;
    }

    const orderedRounds = Object.values(rounds).sort((a, b) => a.position - b.position);
    return creatingRoundType ? (
        <CreateRound rounds={rounds} />
    ) : (
        <div className="create-game page">
            <TransitionalModal className={modalClassName} handleClose={handleCloseModal}>
                <>
                    <div className="modal__title">New Round</div>
                    <div className="modal__text">Select a type of round from the dropdown</div>
                    <Dropdown<RoundTypes.RoundType>
                        defaultOption={firstEnabledNewRound}
                        options={newRoundOptions}
                        className="new-round"
                        onChange={onSelectRoundChange}
                    />
                    <br />
                    <div className="modal__description">{roundDescriptions[selectedRound]}</div>
                    <br />
                    <Button label="Confirm" onClick={wrapper(handleCreateRound)} disabled={!validateNewRound()} />
                </>
            </TransitionalModal>
            {isShowingConfirmationDialog && (
                <ConfirmationModal
                    labels={{
                        accept: 'Merge',
                        decline: 'Overwrite',
                    }}
                    textElement={confirmationText}
                    onAccept={handleConfirmationMerge}
                    onDecline={handleConfirmationOverwrite}
                    onClose={() => setIsShowingConfirmationDialog(false)}
                />
            )}
            <div className="header">
                {game.original && <CopiedGamePrompt game={game} profile={profile} />}
                <div className="box-header__container">
                    <Link draggable={false} to={AppRoute.Home}>
                        <div className="box-header__back-button-container">
                            <div className="box-header__back-button" />
                        </div>
                    </Link>
                    <EditableHeader
                        initialValue={name}
                        validator={dataValidators.gameName}
                        onSubmit={handleRenameGame}
                    />
                    <br />
                    {/* <div className="box-subheader">{gameId}</div> */}
                    <div className="box-subheader">
                        <GameIdWithPublicToggle game={game} />
                    </div>
                    <div className="create-round-save-button__container">
                        <Link draggable={false} to={`${AppRoute.Game}/${gameId}`}>
                            <Button label="Play" />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="box-header__tip-container">{tipText}</div>
            <div className={`content scrollable ${contentClassName}`}>
                <br />
                <DraggableGrid
                    ref={draggableGridRef}
                    onChange={handleRoundOrderChanged}
                    onSelect={(keys) => setSelectedRounds(keys as Array<RoundTypes.RoundType>)}
                    onRemove={handleRoundRemoved}
                    items={[
                        ...orderedRounds.map((round) => {
                            return {
                                key: round.type,
                                isSelectable: true,
                                component: () => (
                                    <Link
                                        onDragStart={(e) => {
                                            e.preventDefault();
                                        }}
                                        draggable={false}
                                        key={round.type}
                                        className="games-grid-item__container"
                                        to={buildRoundPath(round.type)}
                                    >
                                        <div className="games-grid-item__label">
                                            <div className="games-grid-item__number">{round.position + 1}</div>
                                        </div>
                                        <div className="games-grid-item__text-container">
                                            <div className="games-grid-item__text">{roundLabels[round.type]}</div>
                                            <div className="games-grid-item__subtext">
                                                {getNumberOfQuestionsString(round.questions.length)}
                                            </div>
                                        </div>
                                    </Link>
                                ),
                            };
                        }),
                        {
                            key: 'new-round',
                            isFixed: true,
                            component: () => (
                                <div
                                    className="games-grid-item__container games-grid-item__container--new"
                                    onClick={handleNewRound}
                                >
                                    New
                                    <br />
                                    Round
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
            {isLoading && <LoadingSpinner />}
            <Toast s={error?.message} />
            <Toast s={toastMessage} className="copy-success-toast" />
        </div>
    );
}
