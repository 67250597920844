import './login.scss';

import { dataValidators } from '@house-of-games/common';
import classNames from 'classnames';
import { sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '../../common/components/buttons/buttons';
import { TextField, TextFieldProps } from '../../common/components/form-fields/form-fields';
import { useLoadingError } from '../../common/components/loading-error/loading-error';
import { LoadingSpinner } from '../../common/components/loading-error/loading-spinner';
import { Logo } from '../../common/components/logo/logo';
import { errors as firebaseErrors } from '../../common/constants/firebase-errors';
import { FirebaseHelper } from '../../utils/firebase';
import { requestHandler } from '../../utils/request-handler';

enum FormType {
    'SignUp' = 'SignUp',
    'LogIn' = 'LogIn',
    'ForgottenPassword' = 'ForgottenPassword',
}

enum FieldTypes {
    'Email' = 'Email',
    'DisplayName' = 'DisplayName',
    'Password' = 'Password',
    'ConfirmPassword' = 'ConfirmPassword',
}

type FormTemplate = {
    fields: Array<FieldTypes>;
    cta: {
        action: () => void;
        label: string;
    };
    subtext: Array<{
        action: () => void;
        key: string;
        link: string;
        label?: string;
    }>;
};

export function Login() {
    const shouldUseSignUpForm = useMemo(() => {
        const url = new URL(window.location.href);
        const signUpSearchParam = url.searchParams.get('sign-up');
        return typeof signUpSearchParam === 'string';
    }, []);

    const [formType, setFormType] = useState(shouldUseSignUpForm ? FormType.SignUp : FormType.LogIn);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, error, wrapper] = useLoadingError(false);

    const fields: { [k in FieldTypes]: TextFieldProps } = {
        [FieldTypes.Email]: {
            label: 'Email',
            type: 'email',
            validator: dataValidators.email.validate,
            errorMessage: dataValidators.email.errorMessage,
            ref: useRef<TextField>(),
        },
        [FieldTypes.DisplayName]: {
            label: 'Name',
            type: 'text',
            maxLength: dataValidators.displayName.max,
            validator: dataValidators.displayName.validate,
            errorMessage: dataValidators.displayName.errorMessage,
            ref: useRef<TextField>(),
        },
        [FieldTypes.Password]: {
            label: 'Password',
            type: 'password',
            validator: dataValidators.password.validate,
            errorMessage: dataValidators.password.errorMessage,
            ref: useRef<TextField>(),
        },
        [FieldTypes.ConfirmPassword]: {
            label: 'Confirm Password',
            type: 'password',
            validator: (password) => password === fields.Password.ref.current?.state?.value,
            errorMessage: 'Passwords do not match',
            ref: useRef<TextField>(),
        },
    };

    const forms: { [key in FormType]: FormTemplate } = {
        [FormType.SignUp]: {
            fields: [FieldTypes.Email, FieldTypes.DisplayName, FieldTypes.Password, FieldTypes.ConfirmPassword],
            cta: {
                action: () => handleSignUp(),
                label: 'Sign Up',
            },
            subtext: [
                {
                    action: () => setFormType(FormType.LogIn),
                    key: 'goToLogIn',
                    label: 'Already have an account?',
                    link: 'Log In',
                },
            ],
        },
        [FormType.LogIn]: {
            fields: [FieldTypes.Email, FieldTypes.Password],
            cta: {
                action: () => handleLogIn(),
                label: 'Log In',
            },
            subtext: [
                {
                    action: () => setFormType(FormType.SignUp),
                    key: 'goToSignUp',
                    label: "Don't have an account?",
                    link: 'Sign Up Free',
                },
                {
                    action: () => setFormType(FormType.ForgottenPassword),
                    key: 'goToForgottenPassword',
                    label: 'Forgotten your password?',
                    link: 'Reset',
                },
            ],
        },
        [FormType.ForgottenPassword]: {
            fields: [FieldTypes.Email],
            cta: {
                action: () => handleForgottenPassword(),
                label: 'Send Recovery Email',
            },
            subtext: [
                {
                    action: () => setFormType(FormType.LogIn),
                    key: 'goToLogIn',
                    link: 'Return To Log In',
                },
            ],
        },
    };

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [formType]);

    const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            const {
                cta: { action },
            } = forms[formType];
            handleCta(action);
        }
    };

    const handleLogIn = () => {
        const { Email, Password } = fields;
        const auth = FirebaseHelper.getAuth();
        return signInWithEmailAndPassword(auth, Email.ref.current.state.value, Password.ref.current.state.value);
    };

    const handleSignUp = async () => {
        const { Email, DisplayName, Password } = fields;
        const email = Email.ref.current.state.value;
        const name = DisplayName.ref.current.state.value;
        const password = Password.ref.current.state.value;
        await requestHandler.signUp(email, name, password);
        const auth = FirebaseHelper.getAuth();
        await signInWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(FirebaseHelper.getAuth().currentUser);
    };

    const handleForgottenPassword = () => {
        const { Email } = fields;
        return sendPasswordResetEmail(FirebaseHelper.getAuth(), Email.ref.current.state.value);
    };

    const handleCta = (action: () => void) => {
        if (!isLoading && checkIsFormValid()) {
            wrapper(action)();
        }
    };

    useEffect(() => {
        checkIsFormValid();
    }, [formType]);

    const revalidate = () => {
        const { fields: currentFieldTypes } = forms[formType];
        currentFieldTypes.forEach((field) => {
            const { ref } = fields[field];
            ref.current.validate();
        });
    };

    const checkIsFormValid = () => {
        const { fields: currentFieldTypes } = forms[formType];

        const isFormCurrentlyValid = currentFieldTypes.every((field) => {
            const { ref } = fields[field];
            return ref && ref.current.state.isValid;
        });

        setIsFormValid(isFormCurrentlyValid);
        return isFormCurrentlyValid;
    };

    const onFieldChanged = () => {
        revalidate();
        checkIsFormValid();
    };

    const buildFields = () => {
        return forms[formType].fields.map((field) => {
            const { label, type, errorMessage, validator, ref, maxLength } = fields[field];
            return (
                <TextField
                    key={field}
                    label={label}
                    type={type}
                    errorMessage={errorMessage}
                    maxLength={maxLength}
                    validator={validator}
                    ref={ref}
                    onChange={onFieldChanged}
                />
            );
        });
    };

    const {
        cta: { action, label },
        subtext = [],
    } = forms[formType];
    const errorClassNames = classNames('error-text', {
        'error-text--show': Boolean(error),
    });

    return (
        <div className="login scrollable">
            <div className="login__logo">
                <Logo />
            </div>
            <div className="form__container">
                <div className="form">
                    <br />
                    {buildFields()}
                    <div className={errorClassNames}>
                        {(firebaseErrors as any)[error && error.code] || 'Something went wrong, please try again.'}
                    </div>
                    <br />
                    <Button onClick={() => handleCta(action)} disabled={!isFormValid} label={label} />
                    <br />
                    {subtext.map(({ action: linkAction, key, label: subtextLabel, link }) => (
                        <Fragment key={key}>
                            <div className="form__subtext text--off-white size--0.9">
                                {subtextLabel}{' '}
                                <span className="link" onClick={linkAction}>
                                    {link}
                                </span>
                            </div>
                            <br />
                        </Fragment>
                    ))}
                </div>
                {isLoading && <LoadingSpinner />}
            </div>
        </div>
    );
}
