import classNames from 'classnames';

type UploadProps = {
    scale?: number;
    isInvalid?: boolean;
};

const invalidIconPath = (
    <path
        d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2s10,4.5,10,10
    S17.5,22,12,22z M17.8,7.9L13.8,12l4.1,4.1l-1.8,1.8L12,13.8l-4.1,4.1l-1.8-1.8l4.1-4.1L6.2,7.9l1.8-1.8l4.1,4.1l4.1-4.1L17.8,7.9z"
    />
);

const validIconPath = (
    <path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm0-22c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm2 14h-4v-1h4v1zm0 1v1h-4v-1h4zm-4-6h-4l6-6 6 6h-4v3h-4v-3z" />
);

export function Upload({ scale = 1, isInvalid }: UploadProps) {
    const roundedScale = Math.round(24 * scale);
    const classes = classNames('icon__upload', {
        'icon__upload--valid': !isInvalid,
        'icon__upload--invalid': isInvalid,
    });

    return (
        <svg className={classes} width={`${roundedScale}`} height={`${roundedScale}`} viewBox="0 0 24 24">
            {isInvalid ? invalidIconPath : validIconPath}
        </svg>
    );
}
