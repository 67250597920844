import './play-when-they-sing.scss';

import { GameState, RoundTypes } from '@house-of-games/common';
import { Player } from '@house-of-games/common/lib/types/game';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { useRefState } from '../../../common/components/ref-state/use-ref-state';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { MusicQuestion } from '../common/music-question';

enum State {
    'Guessing' = 'Guessing',
    'PlayerAnswers' = 'PlayerAnswers',
    'Answer' = 'Answer',
    'Finished' = 'Finished',
}

export function PlayWhenTheySing({
    question,
    players,
    options,
    musicQuestionHelpers,
    setGameState,
    onPlayPaused,
}: SlideProps<RoundTypes.WhenTheySingRound>) {
    const { audioElementRef, preloadQuestions, questionChanged, setMessageHandler } = musicQuestionHelpers;
    const [_isPlayersRevealed, setIsPlayersRevealed, isPlayersRevealedRef] = useRefState(false);
    const [_isAnswerRevealed, setIsAnswerRevealed, isAnswerRevealedRef] = useRefState(false);
    const [_isAnswerFinished, setIsAnswerFinished, isAnswerFinishedRef] = useRefState(false);
    const [currentPosition, setCurrentPosition] = useState<number>();
    const startTimeRef = useRef<Array<number>>([]);
    const positionIntervalRef = useRef<number>();
    const volumeIntervalRef = useRef<number>();

    function getCurrentState() {
        if (isAnswerFinishedRef.current) {
            return State.Finished;
        }

        if (isAnswerRevealedRef.current) {
            return State.Answer;
        }

        if (isPlayersRevealedRef.current) {
            return State.PlayerAnswers;
        }

        return State.Guessing;
    }

    useEffect(() => {
        setMessageHandler(handleMessageReceived);
        preloadQuestions([question.question]).then(() => {
            questionChanged(question.question);
        });
    }, []);

    useEffect(() => {
        handleSetGameState();
    }, [options]);

    function handlePlay() {
        audioElementRef.current.currentTime = 0;
        audioElementRef.current.volume = 1;
        audioElementRef.current.play();
        if (!positionIntervalRef.current) {
            triggerPositionInterval();
        }

        if (getCurrentState() === State.Guessing) {
            handleSetGameState(true);
        }
    }

    function handlePause() {
        audioElementRef.current.pause();
        clearInterval(positionIntervalRef.current);
        positionIntervalRef.current = undefined;
    }

    async function handleSetGameState(start?: boolean) {
        const gameState: GameState<RoundTypes.RoundType.WhenTheySing> = {
            roundType: RoundTypes.RoundType.WhenTheySing,
            startTime: start ? -1 : startTimeRef.current[startTimeRef.current.length - 1],
        };

        const {
            clientSideAudio: { enabled, files = {} },
        } = options;
        const fileUrl = files[question.question];
        if (enabled && fileUrl) {
            gameState.clientSideAudio = true;
            gameState.fileUrl = fileUrl;
            gameState.fadeOut = question.fadeOut;
        }

        const { startTime } = await setGameState<RoundTypes.RoundType.WhenTheySing>(gameState);
        startTimeRef.current.push(startTime);
    }

    function handleMessageReceived(msg: Message) {
        const currentState = getCurrentState();
        switch (msg.name) {
            case MessageName.reveal:
                if (currentState === State.Guessing) {
                    setIsPlayersRevealed(true);
                } else if (currentState === State.PlayerAnswers) {
                    setIsAnswerRevealed(true);
                    setTimeout(() => {
                        handlePlay();
                    }, 700);
                }
                break;
            case MessageName.play: {
                if (currentState === State.Guessing) {
                    handlePlay();
                }
                break;
            }
            case MessageName.pause: {
                if (currentState === State.Guessing) {
                    handlePause();
                }
                break;
            }
        }
    }

    function triggerPositionInterval() {
        clearInterval(positionIntervalRef.current);
        positionIntervalRef.current = window.setInterval(() => {
            if (!audioElementRef.current) {
                clearInterval(positionIntervalRef.current);
                positionIntervalRef.current = undefined;
            }

            const position = audioElementRef.current?.currentTime;
            if (position && position > question.answer) {
                clearInterval(positionIntervalRef.current);
                positionIntervalRef.current = undefined;
                onPlayPaused();
                setCurrentPosition(question.answer);
                setIsAnswerFinished(true);
                return;
            }

            setCurrentPosition(position);

            if (!volumeIntervalRef.current && getCurrentState() === State.Guessing && position >= question.fadeOut) {
                triggerVolumeInterval();
            }
        }, 57);
    }

    function triggerVolumeInterval() {
        clearInterval(volumeIntervalRef.current);
        volumeIntervalRef.current = window.setInterval(() => {
            if (!audioElementRef.current) {
                clearInterval(volumeIntervalRef.current);
                volumeIntervalRef.current = undefined;
                return;
            }

            const easeValue = Math.floor((audioElementRef.current.volume * 100) / 40) / 100;
            const newVolume = Math.max(audioElementRef.current.volume - (0.05 + easeValue), 0);
            if (newVolume === 0) {
                audioElementRef.current.pause();
                audioElementRef.current.currentTime = 0;
                onPlayPaused();
                clearInterval(volumeIntervalRef.current);
                volumeIntervalRef.current = undefined;
            }
            audioElementRef.current.volume = newVolume;
        }, 120);
    }

    const adaptedPlayerBuzzers: Record<string, number> = Object.values(players).reduce((acc, player: Player) => {
        const buzzTime = player.buzzer;
        const startTime = startTimeRef.current.find(
            (t, i) =>
                buzzTime > t && (startTimeRef.current[i + 1] === undefined || buzzTime < startTimeRef.current[i + 1]),
        );
        if (!startTime) {
            return acc;
        }

        return {
            ...acc,
            [player.id]: buzzTime - startTime,
        };
    }, {});

    function createSecondsString(adaptedTime: number) {
        const seconds = adaptedTime / 1000;
        return seconds.toFixed(2);
    }

    function sortByEarliest(a: Player, b: Player) {
        return adaptedPlayerBuzzers[a.id] - adaptedPlayerBuzzers[b.id];
    }

    function sortByClosestToAnswer(a: Player, b: Player) {
        const aTimeSeconds = parseFloat(createSecondsString(adaptedPlayerBuzzers[a.id]));
        const bTimeSeconds = parseFloat(createSecondsString(adaptedPlayerBuzzers[b.id]));
        return Math.abs(aTimeSeconds - question.answer) - Math.abs(bTimeSeconds - question.answer);
    }

    function buildAnswerStageComponents() {
        const currentState = getCurrentState();
        let titleText = question.title;
        if ([State.Answer, State.Finished].includes(currentState)) {
            titleText = (currentPosition || 0).toFixed(2);
        }

        const header = (
            <AnimateExpandContainer key="header" className="play-when-they-sing__header">
                <TextFlip
                    key="title"
                    className="play-when-they-sing__title"
                    text={titleText}
                    offset={300}
                    entranceOnly
                />
            </AnimateExpandContainer>
        );

        const sortFunction = currentState === State.Finished ? sortByClosestToAnswer : sortByEarliest;
        const sortedPlayers = Object.values(players)
            .filter((p) => Boolean(adaptedPlayerBuzzers[p.id]))
            .sort(sortFunction);
        return (
            <>
                {header}
                <div className="play-when-they-sing__players">
                    {sortedPlayers.map((player, i) => {
                        const playerClassNames = classNames('play-when-they-sing__player', {
                            'play-when-they-sing__player--winner': currentState === State.Finished && i === 0,
                        });
                        return (
                            <AnimateExpandContainer key={player.id} className={playerClassNames}>
                                <TextFlip
                                    key={player.id}
                                    text={
                                        <>
                                            <span className="play-when-they-sing__player-name">{player.name}</span>
                                            <span className="play-when-they-sing__player-time">
                                                {createSecondsString(adaptedPlayerBuzzers[player.id])}s
                                            </span>
                                        </>
                                    }
                                    offset={300}
                                    entranceOnly
                                />
                            </AnimateExpandContainer>
                        );
                    })}
                </div>
            </>
        );
    }

    function renderCurrentStateComponent() {
        switch (getCurrentState()) {
            case State.Guessing:
                return <MusicQuestion />;
            case State.PlayerAnswers:
            case State.Answer:
            case State.Finished:
                return buildAnswerStageComponents();
        }
    }

    audioElementRef.current.muted = options?.clientSideAudio?.enabled && getCurrentState() === State.Guessing;
    return <div className="play-when-they-sing slide page">{renderCurrentStateComponent()}</div>;
}
