import { RoundTypes, UserStorage } from '@house-of-games/common';
import { DimSumsRound } from '@house-of-games/common/lib/types/round';

const questionPreivew = (q: { question?: string; prompt?: string }) => [buildPromptFromQuestion(q.question, q.prompt)];
const questionArrayPreivew = (q: { 0: { question?: string; prompt?: string } }) =>
    Object.values(q).map((singleQ) => buildPromptFromQuestion(singleQ.question, singleQ.prompt));
const answerPreivew = (q: { answer?: string }) => [{ prompt: q.answer }];
const dimSumsPreview = (q: DimSumsRound['questions'][number]) => {
    const numberToLetterMap = {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
    } as const;
    return Object.values(q.questions).map((singleQ, i) =>
        buildPromptFromQuestion(singleQ.question, `${numberToLetterMap[i as 0 | 1 | 2 | 3]}) ${singleQ.question}`),
    );
};

export type PreviewDefinition = {
    questionType?: RoundTypes.QuestionType;
    prompt: string;
};

function buildPromptFromQuestion(q: string, prompt?: string) {
    const questionType = UserStorage.getQuestionType(q);
    return {
        questionType,
        prompt: questionType === RoundTypes.QuestionType.Text ? prompt || q : prompt,
    };
}

export const previewStringMap: {
    [key in RoundTypes.RoundType]: (question: RoundTypes.Round<key>['questions'][number]) => Array<PreviewDefinition>;
} = {
    [RoundTypes.RoundType.RhymeTime]: questionArrayPreivew,
    [RoundTypes.RoundType.BrokenKaraoke]: (q) => [{ prompt: `${q.song} - ${q.artist}` }],
    [RoundTypes.RoundType.InTheName]: questionPreivew,
    [RoundTypes.RoundType.InCode]: answerPreivew,
    [RoundTypes.RoundType.ZToA]: answerPreivew,
    [RoundTypes.RoundType.DimSums]: dimSumsPreview,
    [RoundTypes.RoundType.GamesHouseOf]: questionPreivew,
    [RoundTypes.RoundType.Elephant]: questionPreivew,
    [RoundTypes.RoundType.Roonerspisms]: questionArrayPreivew,
    [RoundTypes.RoundType.Backwards]: questionPreivew,
    [RoundTypes.RoundType.OppositesAttract]: questionPreivew,
    [RoundTypes.RoundType.KingJumble]: questionArrayPreivew,
    [RoundTypes.RoundType.TwoInOne]: questionPreivew,
    [RoundTypes.RoundType.PastTense]: questionPreivew,
    [RoundTypes.RoundType.CorrectionCentre]: questionPreivew,
    [RoundTypes.RoundType.MouseOfGames]: questionPreivew,
    [RoundTypes.RoundType.TheAnswerIsnt]: questionPreivew,
    [RoundTypes.RoundType.CineNyms]: questionPreivew,
    [RoundTypes.RoundType.HighbrowLowbrow]: (q) => [
        buildPromptFromQuestion(q.highbrow),
        buildPromptFromQuestion(q.lowbrow),
    ],
    [RoundTypes.RoundType.HouseOfGamers]: questionPreivew,
    [RoundTypes.RoundType.HoseOfGames]: questionPreivew,
    [RoundTypes.RoundType.DistinctlyAverage]: questionPreivew,
    [RoundTypes.RoundType.YouCompleteMe]: questionPreivew,
    [RoundTypes.RoundType.YouSpellTerrible]: questionPreivew,
    [RoundTypes.RoundType.TotesEmoji]: questionPreivew,
    [RoundTypes.RoundType.ICompleteYou]: questionPreivew,
    [RoundTypes.RoundType.FingerOnIt]: questionPreivew,
    [RoundTypes.RoundType.WhereIsKazakhstan]: questionPreivew,
    [RoundTypes.RoundType.TerribleDating]: questionPreivew,
    [RoundTypes.RoundType.AnswerSmash]: questionPreivew,
    [RoundTypes.RoundType.RichList]: questionPreivew,
    [RoundTypes.RoundType.SizeMatters]: questionPreivew,
    [RoundTypes.RoundType.ZList]: questionPreivew,
    [RoundTypes.RoundType.WhenTheySing]: (q) => [{ prompt: q.title }],
};
