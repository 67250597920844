import './play-rhyme-time.scss';

import { RoundTypes, UserStorage } from '@house-of-games/common';
import { useEffect, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { useDynamicTextSize } from '../../game/use-dynamic-text-size';
import { ImageQuestion } from '../common/image-question';
import { MusicQuestion } from '../common/music-question';

type AssociatedRounds =
    | SlideProps<RoundTypes.RhymeTimeRound>
    | SlideProps<RoundTypes.RoonerspismsRound>
    | SlideProps<RoundTypes.KingJumbleRound>;

export function PlayRhymeTime({ question, message, musicQuestionHelpers, onPlayPaused }: AssociatedRounds) {
    const textSizeClass = useDynamicTextSize([question[0]?.question, question[1]?.question]);
    const [isSecondQuestionVisible, setIsSecondQuestionVisible] = useState(false);
    const [isRevealed, setIsRevealed] = useState(false);

    const { 0: q1, 1: q2 } = question;

    useEffect(() => {
        const questionStrings = Object.values(question).map((q) => q.question);
        musicQuestionHelpers.preloadQuestions(questionStrings).then(() => {
            musicQuestionHelpers.questionChanged(q1.question);
        });
    }, []);

    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    function handleMessageReceived(message: Message) {
        switch (message.name) {
            case MessageName.reveal:
                if (isSecondQuestionVisible) {
                    setIsRevealed(true);
                } else {
                    setIsSecondQuestionVisible(true);
                    if (UserStorage.checkIsValidUri(q2.question)) {
                        onPlayPaused();
                        musicQuestionHelpers.questionChanged(q2.question);
                    }
                }
                break;
        }
    }

    function renderQuestion(q: string, answer: string) {
        const questionType = UserStorage.getQuestionType(q);

        if (questionType === RoundTypes.QuestionType.Audio) {
            return <MusicQuestion answer={isRevealed && answer} />;
        }

        if (questionType === RoundTypes.QuestionType.Image) {
            return <ImageQuestion question={q} answer={isRevealed && answer} />;
        }

        return (
            <AnimateExpandContainer className="play-rhyme-time__question">
                <TextFlip text={isRevealed ? answer : q} offset={0} />
            </AnimateExpandContainer>
        );
    }

    return (
        <div className={`play-rhyme-time slide page ${textSizeClass}`}>
            <div className="play-rhyme-time__question-container">{renderQuestion(q1.question, q1.answer)}</div>
            <div className="play-rhyme-time__question-container">
                {isSecondQuestionVisible && renderQuestion(q2.question, q2.answer)}
            </div>
        </div>
    );
}
