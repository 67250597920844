import './play-in-code.scss';

import { RoundTypes } from '@house-of-games/common';
import { useEffect, useState } from 'react';

import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { Message, MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { getCode } from './shared-in-code';

export function PlayInCode({ question, message }: SlideProps<RoundTypes.InCodeRound>) {
    const [isRevealed, setIsRevealed] = useState(false);
    useEffect(() => {
        handleMessageReceived(message);
    }, [message]);

    function handleMessageReceived(msg: Message) {
        switch (msg.name) {
            case MessageName.reveal:
                setIsRevealed(true);
        }
    }

    const arrayToShow = isRevealed ? question.answer.split('').map((c) => c.toUpperCase()) : getCode(question.answer);

    return (
        <div className="play-in-code slide page">
            <div className="play-in-code__category">{question.question || question.category}</div>
            <div className="play-in-code__code">
                {arrayToShow.map((c, i) => {
                    if (c === ' ') {
                        return <div key={i} className="play-in-code__space" />;
                    } else {
                        return (
                            <div key={i} className="play-in-code__character">
                                <TextFlip text={`${c}`} offset={0} />
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
}
