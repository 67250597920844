import { RoundTypes } from '@house-of-games/common';
import { MathOperator } from '@house-of-games/common/lib/types/round';
import { diffWords } from 'diff';

import { processWithoutTrailingPunctuation } from '../../utils/process-without-trailing-punctuation';

const defaultAnswer = (q: { answer?: string }) => q.answer;
const twoQuestions = (q: { 0: { answer?: string }; 1: { answer?: string } }) => `${q[0].answer}, ${q[1].answer}`;
// const answerPreivew = (q: { answer?: string }) => q.answer;

function alphabetize(question: RoundTypes.Question) {
    return processWithoutTrailingPunctuation(question.answer, (str) => {
        return str.toLowerCase().split(' ').sort().join(' ');
    });
}

function reverse(question: RoundTypes.Question) {
    return processWithoutTrailingPunctuation(question.answer, (res) => {
        return res.toLowerCase().split(' ').reverse().join(' ');
    });
}

function diff(question: RoundTypes.Question) {
    const diff = diffWords(question.question, question.answer);
    let incorrectDiff = '';
    let correctDiff = '';

    function joinString(originalString: string, stringToJoin: string) {
        const trimmed = stringToJoin.trim();
        if (originalString) {
            return `${originalString} ${trimmed}`;
        } else {
            return trimmed;
        }
    }

    diff.forEach((diffWord) => {
        if (diffWord.removed) {
            incorrectDiff = joinString(incorrectDiff, diffWord.value);
        } else if (diffWord.added) {
            correctDiff = joinString(correctDiff, diffWord.value);
        }
    });

    return `"${incorrectDiff}" becomes "${correctDiff}"`;
}

function dimSumsAnswerString(question: RoundTypes.DimSumsRound['questions'][number]): string {
    const numberToLetterMap = {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
    };

    const operatorMap: { [key in RoundTypes.MathOperator]: string } = {
        [MathOperator.Add]: '+',
        [MathOperator.Subtract]: '−',
        [MathOperator.Multiply]: '×',
        [MathOperator.Divide]: '÷',
    };

    const { firstTerm, secondTerm, operator } = question.equation;
    return `${numberToLetterMap[firstTerm]} ${operatorMap[operator]} ${numberToLetterMap[secondTerm]}`;
}

export const answerStringMap: {
    [key in RoundTypes.RoundType]: (question: RoundTypes.Round<key>['questions'][number]) => string;
} = {
    [RoundTypes.RoundType.RhymeTime]: twoQuestions,
    [RoundTypes.RoundType.BrokenKaraoke]: (q) => `${q.song} - ${q.artist}`,
    [RoundTypes.RoundType.InTheName]: defaultAnswer,
    [RoundTypes.RoundType.InCode]: defaultAnswer,
    [RoundTypes.RoundType.ZToA]: defaultAnswer,
    [RoundTypes.RoundType.DimSums]: dimSumsAnswerString,
    [RoundTypes.RoundType.GamesHouseOf]: alphabetize,
    [RoundTypes.RoundType.Elephant]: (q) =>
        q.category ? q.answer?.toLowerCase().split(q.category.toLowerCase()).join('') : q.answer,
    [RoundTypes.RoundType.Roonerspisms]: twoQuestions,
    [RoundTypes.RoundType.Backwards]: reverse,
    [RoundTypes.RoundType.OppositesAttract]: defaultAnswer,
    [RoundTypes.RoundType.KingJumble]: twoQuestions,
    [RoundTypes.RoundType.TwoInOne]: defaultAnswer,
    [RoundTypes.RoundType.PastTense]: defaultAnswer,
    [RoundTypes.RoundType.CorrectionCentre]: diff,
    [RoundTypes.RoundType.MouseOfGames]: defaultAnswer,
    [RoundTypes.RoundType.TheAnswerIsnt]: defaultAnswer,
    [RoundTypes.RoundType.CineNyms]: defaultAnswer,
    [RoundTypes.RoundType.HighbrowLowbrow]: defaultAnswer,
    [RoundTypes.RoundType.HouseOfGamers]: defaultAnswer,
    [RoundTypes.RoundType.HoseOfGames]: defaultAnswer,
    [RoundTypes.RoundType.DistinctlyAverage]: defaultAnswer,
    [RoundTypes.RoundType.YouCompleteMe]: defaultAnswer,
    [RoundTypes.RoundType.YouSpellTerrible]: defaultAnswer,
    [RoundTypes.RoundType.TotesEmoji]: defaultAnswer,
    [RoundTypes.RoundType.ICompleteYou]: defaultAnswer,
    [RoundTypes.RoundType.FingerOnIt]: () => undefined,
    [RoundTypes.RoundType.WhereIsKazakhstan]: () => undefined,
    [RoundTypes.RoundType.TerribleDating]: defaultAnswer,
    [RoundTypes.RoundType.AnswerSmash]: defaultAnswer,
    [RoundTypes.RoundType.RichList]: () => undefined,
    [RoundTypes.RoundType.SizeMatters]: () => undefined,
    [RoundTypes.RoundType.ZList]: () => undefined,
    [RoundTypes.RoundType.WhenTheySing]: (q) => `${q.answer}s`,
};
