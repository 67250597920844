import './audio-configuration-modal.scss';

import { MutableRefObject, useEffect, useRef } from 'react';

import { Button } from '../../common/components/buttons/buttons';
import { Close } from '../../common/components/icons/close';

type AudioConfigurationModalProps = {
    audioElementRefs: Array<MutableRefObject<HTMLAudioElement>>;
    onClose: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const popSound = require('../rounds/broken-karaoke/pop.mp3');

export function AudioConfigurationModal({ audioElementRefs, onClose }: AudioConfigurationModalProps) {
    const audioElementRef = useRef<HTMLAudioElement>();

    useEffect(() => {
        const originalSources: Array<string> = [];
        audioElementRefs.forEach((ref) => {
            originalSources.push(ref.current.src);
            ref.current.src = popSound;
            ref.current.muted = true;
        });

        return () => {
            audioElementRefs.forEach((ref, i) => {
                ref.current.src = originalSources[i];
                ref.current.muted = false;
            });
        };
    }, []);

    async function triggerAudioPlayback() {
        try {
            const refs = [audioElementRef, ...audioElementRefs];
            const promises = refs.map((ref) => {
                return ref.current.play();
            });
            await Promise.all(promises);
            setTimeout(() => {
                onClose();
            }, 300);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="audio-configuration-modal__container">
            <audio ref={audioElementRef} src={popSound} />
            <div className="audio-configuration-modal">
                <div className="audio-configuration-modal__close" onClick={onClose}>
                    <Close scale={0.6} />
                </div>
                <h1 className="audio-configuration-modal__header">Audio Issue Detected</h1>
                <div className="audio-configuration-modal__info">
                    {`There was a problem playing audio. This is most likely due to your web browser
                    blocking autoplay.`}
                    <br />
                    <br />
                    {`Click the button below to test audio and if you don't hear anything
                    you might need to allow audio playback from this website. This is normally done by
                    clicking a small volume icon in the address bar.`}
                </div>
                <div className="audio-configuration-modal__button">
                    <Button label="Play Test Sound" onClick={triggerAudioPlayback} />
                </div>
            </div>
        </div>
    );
}
