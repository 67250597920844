import { Validator } from '@house-of-games/common';
import classNames from 'classnames';
import { FormEvent, KeyboardEvent, useRef, useState } from 'react';

type EditableHeaderProps = {
    initialValue: string;
    onSubmit: (value: string) => Promise<string>;
    validator: Validator;
};

export function EditableHeader({ initialValue, validator, onSubmit }: EditableHeaderProps) {
    const [value, setValue] = useState(initialValue);
    const [isValid, setIsValid] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    function handleInputChanged(e: FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;

        if (newValue.length < validator.min) {
            setIsValid(false);
            setValue(newValue);
            return;
        } else if (newValue.length > validator.max) {
            setIsValid(true);
            setValue(value.substr(0, validator.max));
            return;
        }

        setIsValid(true);
        setValue(newValue);
    }

    function handleKeyPress(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            inputRef.current.blur();
        }
    }

    async function handleFinishedEditing() {
        if (!isValid) {
            setValue(initialValue);
            setIsValid(true);
            return;
        }

        if (value === initialValue) {
            return;
        }

        const newName = await onSubmit(value);
        setValue(newName);
    }

    const inputClass = 'box-header box-header-input';
    const inputClasses = classNames(inputClass, {
        [`${inputClass}--invalid`]: !isValid,
    });

    return (
        <input
            ref={inputRef}
            className={inputClasses}
            type="text"
            value={value}
            max={validator.max}
            onChange={handleInputChanged}
            onBlur={handleFinishedEditing}
            onKeyPress={handleKeyPress}
        />
    );
}
