import './karaoke-capture-modal.scss';

import { RoundTypes } from '@house-of-games/common';
import { useRef, useState } from 'react';

import { KaraokeCapture } from './karaoke-capture';
import { KaraokeLyrics } from './karaoke-lyrics-input';
import { KaraokePreview } from './karaoke-preview';

type Question = RoundTypes.BrokenKaraokeRound['questions'][0];

type RecordInputDialogProps = {
    title: string;
    saveAndClose: (lines: Question['lines']) => void;
    closeModal: () => void;
    isPreviewOnly?: boolean;
    lines?: Question['lines'];
};

enum Page {
    'Lyrics',
    'Capture',
    'Preview',
}

export function KaraokeCaptureModal({
    title,
    saveAndClose,
    closeModal,
    isPreviewOnly,
    lines: providedLines,
}: RecordInputDialogProps) {
    const [page, setPage] = useState<Page>(isPreviewOnly ? Page.Preview : Page.Lyrics);

    // Lyrics Input
    const lyrics = useRef('');

    // Recording Page
    const wordLines = useRef<Array<Array<string>>>([]);

    // Preview Page
    const questionLines = useRef<Question['lines']>(providedLines || { 0: [] });

    function handleLyricsDone(newLyrics: string) {
        lyrics.current = newLyrics;
        const stringLines = lyrics.current.trim().split('\n');
        wordLines.current = stringLines.map((l) => {
            const whiteSpaceRegex = new RegExp('[ ]{1,}', 'g');
            return l.split(whiteSpaceRegex).filter((s) => s.length > 0);
        });

        setPage(Page.Capture);
    }

    function handleCaptureEnd(generatedLines: Question['lines']) {
        questionLines.current = generatedLines;
        setPage(Page.Preview);
    }

    function handlePreviewDone() {
        if (isPreviewOnly) {
            closeModal();
        } else {
            saveAndClose(questionLines.current);
        }
    }

    function onBack() {
        if (page === Page.Preview) {
            setPage(Page.Capture);
        } else {
            setPage(Page.Lyrics);
        }
    }

    function buildInstructions() {
        switch (page) {
            case Page.Lyrics:
                return (
                    <>
                        <div className="karaoke-capture-modal__instructions-subheader">Lyrics</div>
                        <div className="karaoke-capture-modal__instructions-body">
                            {`Copy and paste the song lyrics into the text box below.
                            Remove all punctuation and make sure that there are no more than
                            14 words per line.`}
                        </div>
                    </>
                );
            case Page.Capture:
                return (
                    <>
                        <div className="karaoke-capture-modal__instructions-subheader">Record</div>
                        <div className="karaoke-capture-modal__instructions-body">
                            {`Tap Enter/Space to record the time between each word.
                            The time between key presses will be measured and the recording
                            will finish once you reach the end of the lyrics.`}
                        </div>
                    </>
                );
            case Page.Preview:
                return (
                    <>
                        <div className="karaoke-capture-modal__instructions-subheader">Preview</div>
                        <div className="karaoke-capture-modal__instructions-body">
                            {`Check to see if the letters are correct
                            and the timing is accurate.`}
                        </div>
                    </>
                );
        }
    }

    function buildKaraokeLyrics() {
        return <KaraokeLyrics initialLyrics={lyrics.current} onCancel={closeModal} onDone={handleLyricsDone} />;
    }

    function buildKaraokeCapture() {
        return <KaraokeCapture wordLines={wordLines.current} onBack={onBack} onCaptureEnd={handleCaptureEnd} />;
    }

    function buildKaraokePreview() {
        return (
            <KaraokePreview
                lines={questionLines.current}
                onBack={onBack}
                onDone={handlePreviewDone}
                isPreviewOnly={isPreviewOnly}
            />
        );
    }

    function buildContent() {
        switch (page) {
            case Page.Lyrics:
                return buildKaraokeLyrics();
            case Page.Capture:
                return buildKaraokeCapture();
            case Page.Preview:
                return buildKaraokePreview();
        }
    }

    return (
        <div className="karaoke-capture-modal__container">
            <div className="karaoke-capture-modal">
                <div className="karaoke-capture-modal__instructions">
                    <h3>{title}</h3>
                    <br />
                    <br />
                    {buildInstructions()}
                </div>
                {buildContent()}
            </div>
        </div>
    );
}
