import { useState } from 'react';

import { Close } from '../../common/components/icons/close';

export function RecommendedBrowserBanner() {
    const isBannerPreviouslyDismissed = Boolean(window.localStorage.getItem('RECOMMENDED_BROWSER_BANNER_DISMISSED'));
    const [isBannerDismissed, setIsBannerDismissed] = useState<boolean>(isBannerPreviouslyDismissed);
    const isChromium = Boolean((window as any).chrome);

    if (isBannerDismissed) {
        return <></>;
    }

    if (isChromium) {
        return <></>;
    }

    function getReadableBrowserName() {
        let browserName: string;
        const userAgent = navigator.userAgent;

        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = 'Chrome';
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = 'Firefox';
        } else if (userAgent.match(/safari/i)) {
            browserName = 'Safari';
        }
        return browserName;
    }

    const readableBrowserName = getReadableBrowserName();

    if (readableBrowserName === 'Chrome') {
        // Should never get here!
        return <></>;
    }

    function handleDismissBanner() {
        window.localStorage.setItem('RECOMMENDED_BROWSER_BANNER_DISMISSED', 'true');
        setIsBannerDismissed(true);
    }

    return (
        <div className="recommended-browser-banner">
            {readableBrowserName ? `It looks like you're using ${readableBrowserName}! ` : ''}For smooth animations and
            fewest issues, it is recommended to use a Chromium based browser like Google Chrome or Microsoft Edge.
            <div onClick={handleDismissBanner} className="recommended-browser-banner__close-container">
                <Close scale={0.6} />
            </div>
        </div>
    );
}
