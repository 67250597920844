import { RoundTypes } from '@house-of-games/common';

export const roundDescriptions: { [key in RoundTypes.RoundType]: string } = {
    // Buzzer Rounds
    [RoundTypes.RoundType.RhymeTime]: 'The players are given two questions at a time, the answers rhyme.',
    [RoundTypes.RoundType.BrokenKaraoke]:
        'The first letter of the song lyrics will appear in time with the beat of a song. Players must name the song.',
    [RoundTypes.RoundType.InTheName]:
        'The players are given questions to which the answer can be made using the letters from the full name of one player. If a player gets the correct answer for their own name, they get two points instead of one.',
    [RoundTypes.RoundType.InCode]:
        'The players are given a category. The answer will be shown in alpha-numeric code (A=1, B=2, e.t.c). The first player to state the answer gets the point.',
    [RoundTypes.RoundType.ZToA]:
        'The players are given three blanked out words which are related in some way. The words will begin to fill in from Z down to A, and players must guess the category that links the words.',
    [RoundTypes.RoundType.GamesHouseOf]:
        'Players must give answers to the questions in alphabetical order. For example, if the answer is "House Of Games", players must say "Games House Of".',
    [RoundTypes.RoundType.Elephant]:
        'The players are given a word. Answers must be provided with this word removed. For example, if the word is "Art" and the answer is "Heart", players must simply say "He".',
    [RoundTypes.RoundType.Roonerspisms]:
        'The players are given two questions and the answers are spoonerisms of each other.',
    [RoundTypes.RoundType.Backwards]:
        'The players are given questions in reverse order, the players must also answer these questions in reverse. For example, the question "Series which with confused often is Wars Star?" would have the answer "Trek Star".',
    [RoundTypes.RoundType.OppositesAttract]:
        'The players are given a category and a clue that is the opposite of the answer. For example, if the category is "Game Shows" and the clue is "Caravan Of Torture", the answer would be "House Of Games".',
    [RoundTypes.RoundType.KingJumble]:
        'The players are given two questions at a time and the answers are anagrams of each other.',
    [RoundTypes.RoundType.TwoInOne]:
        'The players are given a category and a clue to the answer. The clue has the same initials as the answer. For example, if the category was "Wachowski Films", the clue might be "Terrible Machines" and the answer (with the initials "T.M") would be "The Matrix"',
    [RoundTypes.RoundType.PastTense]:
        'The players are given a question and must give their answer in the past tense. For example, if the answer was "Take Five", the players would say "Took Five"',
    // [RoundTypes.RoundType.InternetHistory]: 'InternetHistory',
    // [RoundTypes.RoundType.PopArt]: 'PopArt',                     Fun but longer
    // [RoundTypes.RoundType.FeelIt]: 'FeelIt',                     Not Doable Online
    // [RoundTypes.RoundType.TooComplicated]: 'TooComplicated',

    // Individual Games
    [RoundTypes.RoundType.CorrectionCentre]:
        'The players are given a statement but one word has been altered to make the statement incorrect. Players must identify the word and give the corrected version.',
    [RoundTypes.RoundType.MouseOfGames]:
        'The title of something (e.g. book, tv show, film) will have had one letter replaced to give it a whole new meaning. A description of this new thing is provided to the players, who must identify the original title.',
    // [RoundTypes.RoundType.InTheQuestion]:
    //     'The players are given a category and a statement describing something from that category. Part of the statement is in capitals, and is an anagram for the answer.',
    [RoundTypes.RoundType.TheAnswerIsnt]:
        'Each player is given a question with four possible answers. One is correct, but the other three are false answers created by the other contestants who are not answering this question. They need to find the correct answer for a point, but if they choose a false answer, the player who created that answer gets a point.',
    [RoundTypes.RoundType.CineNyms]:
        'The players are given a famous quote, but its words have been replaced with synonyms. The players must answer with the origin of the quote.',
    [RoundTypes.RoundType.HighbrowLowbrow]:
        'The players are given two questions with the same answer, one of which is significantly harder than the other. They are shown the hard question first, follwed by the easier question. If they get the correct answer having seen only the hard question they are awarded 2 points, but if they change their answer after seeing the easier question they will only be awarded one point.',
    // [RoundTypes.RoundType.PenUltimate]: 'PenUltimate',
    // [RoundTypes.RoundType.WritersDayOff]: 'WritersDayOff',
    [RoundTypes.RoundType.HouseOfGamers]:
        'The title of something (e.g. book, tv show, film) will have had one letter added to give it a whole new meaning. A description of this new thing is provided to the players, who must identify the original title.',
    [RoundTypes.RoundType.HoseOfGames]:
        'The title of something (e.g. book, tv show, film) will have had one letter removed to give it a whole new meaning. A description of this new thing is provided to the players, who must identify the original title.',

    // Pairs Games
    [RoundTypes.RoundType.DistinctlyAverage]:
        'The players are in pairs for this round. The pairs are given a question which has a numerical answer. Without discussing, each player must give their answer. The average between each pair is used, and the closest pair get a point each.',
    // [RoundTypes.RoundType.BuildYourOwn]: 'BuildYourOwn',         Tricky for this scenario
    [RoundTypes.RoundType.DimSums]:
        'The players are in pairs for this round (although this round also works well as individuals). The players are given four questions, each of which has a numerical answer. The players are also given a sum (e.g. ? x ? = 18). The players must choose which two questions should be used to fill in the sum correctly.',
    // [RoundTypes.RoundType.ChronIllogical]: 'ChronIllogical',
    [RoundTypes.RoundType.YouCompleteMe]:
        'The players are in pairs for this round. The pairs are given a question which has a two word answer. One player in the pair must buzz in and give the first word of the answer. The second player in the pair must then give the second word of the answer.',
    // [RoundTypes.RoundType.AllInTheDetails]: 'AllInTheDetails',
    // [RoundTypes.RoundType.VennFamous]: 'VennFamous',
    [RoundTypes.RoundType.YouSpellTerrible]:
        'The players are in pairs for this round. One player in the pair must buzz in and give the first word of the answer. The second player in the pair must then spell the answer.',
    [RoundTypes.RoundType.TotesEmoji]:
        'Players are asked to identify an answer based on a category, where one of the other players has provided a clue written using only emojis.',
    // [RoundTypes.RoundType.TheTwoWrongies]: 'TheTwoWrongies',
    [RoundTypes.RoundType.ICompleteYou]:
        'The players are in pairs for this round. The pairs are given a question which has a two word answer. One player in the pair must buzz in and give the second word of the answer. The second player in the pair must then give the first word of the answer.',
    // [RoundTypes.RoundType.HausDerSpiele]: 'HausDerSpiele',       Fun but tricky
    [RoundTypes.RoundType.ZList]:
        'The players are given a question, statement, or category that has multiple answers. Players must write down a correct answer furthest down the alphabet (i.e. nearest to "Z"). The correct answer that is last in alphabetical order gets a point, but a bonus point is awarded if it is the last possible answer alphabetically.',

    // "Random" Games
    // [RoundTypes.RoundType.IsItMe]: 'IsItMe',
    // [RoundTypes.RoundType.NiceRound]: 'NiceRound',               Tricky
    // [RoundTypes.RoundType.WhatsTheQuestion]: 'WhatsTheQuestion', Tricky
    // [RoundTypes.RoundType.LuckyDip]: 'LuckyDip',
    [RoundTypes.RoundType.WhenTheySing]:
        'The players hear the introduction to a song which is faded out after a few seconds. They must press their buzzer when they think the first word is sung.',
    // [RoundTypes.RoundType.NotSoNiceRound]: 'NotSoNiceRound',
    // [RoundTypes.RoundType.WhoGoesFirst]: 'WhoGoesFirst',

    // Tablet Games
    [RoundTypes.RoundType.FingerOnIt]:
        'The players are given a picture question and have to point out who or what they think is the correct answer.',
    [RoundTypes.RoundType.WhereIsKazakhstan]:
        'The players are given a map of a certain part of the world. They are given a question and have to choose on their map where they think it is. The closest to the correct place wins a point.',
    [RoundTypes.RoundType.RichList]:
        'The players are given a question, statement, or category that has multiple answers. Players must write down a correct answer, but will only receive a point if the same answer was not given by any other player.',
    [RoundTypes.RoundType.SizeMatters]:
        'The players are given a question, statement, or category that has multiple answers. Players must write down a correct answer with the most letters. The correct answer with the most letters gets a point, but a bonus point is awarded if it is the longest possible answer.',
    [RoundTypes.RoundType.TerribleDating]:
        'The players are given a question to which the answer is a year. The player who is closest to the correct year gets a point. A bonus point is awarded if the exact year is given.',
    // [RoundTypes.RoundType.OutplayOsman]: 'OutplayOsman',         Not Possible Online

    // Final Round
    [RoundTypes.RoundType.AnswerSmash]:
        'Typically the final round of the game. The players are given a category, and a picture related to that category. Below the picture is a general knowledge question, and the players have to "smash" the answers together. For example, if the category is "Superheroes", the picture is Captain America, and the answer to the question is "Carpool Karaoke", the players would say "Captain Americarpool Karaoke".',
};
