import './play-elephant.scss';

import { RoundTypes } from '@house-of-games/common';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { QuestionAnswerComponent } from '../common/question-answer';

const answerRevealDelay = 2500;

type AssociatedRounds = SlideProps<RoundTypes.ElephantRound>;

type AnswerSegment = {
    type: 'segment' | 'segment-match';
    value: string;
    index: number;
};

export function PlayElephant({ question, message, musicQuestionHelpers }: AssociatedRounds) {
    const [answerNodes, setAnswerNodes] = useState<React.ReactNode>();
    const answerTimeout = useRef<number>();

    const category = useMemo(() => {
        if (question && 'category' in question) {
            return question.category;
        }
    }, []);

    useEffect(() => {
        return () => {
            clearTimeout(answerTimeout.current);
        };
    }, []);

    useEffect(() => {
        switch (message.name) {
            case MessageName.reveal: {
                const answerSegments = createAnswerSegments();
                setAnswerNodes(createAnswerNodes(answerSegments));
                if (answerSegments.length > 1) {
                    answerTimeout.current = window.setTimeout(() => {
                        setAnswerNodes(createAnswerNodes(answerSegments, true));
                    }, answerRevealDelay);
                }
            }
        }
    }, [message]);

    function createAnswerSegments() {
        let newString = '';
        const answerSegments: Array<AnswerSegment> = [];
        const answer = question.answer;
        const lowerCaseAnswer = question.answer?.toLowerCase();
        const lowerCategory = category?.toLowerCase();
        const categoryLength = category.length;

        const regEx = new RegExp(`(${lowerCategory})`, 'g');
        const matches = Array.from(lowerCaseAnswer.matchAll(regEx));
        let segmentNumber = 0;

        matches.forEach((match) => {
            const startIndex = match.index;
            if (startIndex > newString.length) {
                const newStringSegment = answer.substring(newString.length, startIndex);
                newString += newStringSegment;
                answerSegments.push({
                    type: 'segment',
                    value: newStringSegment,
                    index: segmentNumber++,
                });
            }

            const newStringSegment = answer.substring(newString.length, newString.length + categoryLength);
            newString += newStringSegment;
            answerSegments.push({
                type: 'segment-match',
                value: newStringSegment,
                index: segmentNumber++,
            });
        });

        if (newString.length < answer.length) {
            const newStringSegment = answer.substring(newString.length, answer.length);
            newString += newStringSegment;
            answerSegments.push({
                type: 'segment',
                value: newStringSegment,
                index: segmentNumber++,
            });
        }

        return answerSegments;
    }

    function createAnswerNodes(
        answerSegments: Array<AnswerSegment>,
        isShowingMatches?: boolean,
    ): Array<React.ReactNode> {
        let filteredSegments = answerSegments;
        if (!isShowingMatches) {
            filteredSegments = filteredSegments.filter((s) => s.type === 'segment');
        }

        return filteredSegments.map((segment) => {
            const className = classNames('answer-segment', {
                'answer-segment__match': segment.type === 'segment-match',
            });

            return (
                <span key={segment.index} className={className}>
                    {segment.value}
                </span>
            );
        });
    }

    return (
        <QuestionAnswerComponent
            category={category}
            question={question.question}
            answer={answerNodes && <div className="elephant-answer">{answerNodes}</div>}
            musicQuestionHelpers={musicQuestionHelpers}
            className="elephant"
        />
    );
}
