import { RoundTypes } from '@house-of-games/common';

export const roundTips: { [key in RoundTypes.RoundType]?: string } = {
    // Buzzer Rounds
    // [RoundTypes.RoundType.RhymeTime]: 'Rhyme Time',
    // [RoundTypes.RoundType.BrokenKaraoke]: 'Broken Karaoke',
    // [RoundTypes.RoundType.InTheName]: "It's All In The Name",
    [RoundTypes.RoundType.InCode]: 'Just type the correct answer and the code will automatically be generated',
    // [RoundTypes.RoundType.ZToA]: 'Z To A',
    [RoundTypes.RoundType.GamesHouseOf]:
        'Type the answer normally and it will automatically be re-ordered alphabetically in-game',
    [RoundTypes.RoundType.Elephant]: 'Type the full answer and the "Elephant" will be omitted automatically in-game',
    // [RoundTypes.RoundType.Roonerspisms]: 'Roonerspisms',
    [RoundTypes.RoundType.Backwards]: 'Type the answer normally and it will automatically be reversed in-game',
    // [RoundTypes.RoundType.OppositesAttract]: 'Opposites Attract',
    // [RoundTypes.RoundType.KingJumble]: 'King Of The Jumble',
    // [RoundTypes.RoundType.TwoInOne]: 'Two Clues In One',
    // [RoundTypes.RoundType.PastTense]: 'A Blast From The Past Tense',
    // [RoundTypes.RoundType.InternetHistory]: 'InternetHistory',
    // [RoundTypes.RoundType.PopArt]: 'PopArt',                     Fun but longer
    // [RoundTypes.RoundType.FeelIt]: 'FeelIt',                     Not Doable Online
    // [RoundTypes.RoundType.TooComplicated]: 'TooComplicated',

    // Individual Games
    [RoundTypes.RoundType.CorrectionCentre]:
        'Type the full incorrect statement on the left, and then the full correct statement on the right. The difference will automatically be calculated in-game',
    // [RoundTypes.RoundType.MouseOfGames]: 'Mouse Of Games',
    // [RoundTypes.RoundType.InTheQuestion]: '',
    // [RoundTypes.RoundType.TheAnswerIsnt]: "And The Answer Isn't",
    // [RoundTypes.RoundType.CineNyms]: 'Cine-Nyms',
    // [RoundTypes.RoundType.HighbrowLowbrow]: 'Highbrow Lowbrow',
    // [RoundTypes.RoundType.PenUltimate]: 'PenUltimate',
    // [RoundTypes.RoundType.WritersDayOff]: 'WritersDayOff',
    // [RoundTypes.RoundType.HouseOfGamers]: 'House Of Gamers',
    // [RoundTypes.RoundType.HoseOfGames]: 'Hose Of Games',

    // Pairs Games
    // [RoundTypes.RoundType.DistinctlyAverage]: 'Distinctly Average',
    // [RoundTypes.RoundType.BuildYourOwn]: 'BuildYourOwn',         Tricky for this scenario
    // [RoundTypes.RoundType.DimSums]: '',                   // ADD THIS ASAP
    // [RoundTypes.RoundType.ChronIllogical]: 'ChronIllogical',
    [RoundTypes.RoundType.YouCompleteMe]:
        'The answers will be revealed one word at a time, so answers with more than two words can be used to make questions even harder!',
    // [RoundTypes.RoundType.AllInTheDetails]: 'AllInTheDetails',
    // [RoundTypes.RoundType.VennFamous]: 'VennFamous',
    // [RoundTypes.RoundType.YouSpellTerrible]: 'You Spell Terrible',
    [RoundTypes.RoundType.TotesEmoji]:
        "It's worth mentioning that emojis can look very different if you are using a Mac or Windows PC 😬 ",
    // [RoundTypes.RoundType.TheTwoWrongies]: 'TheTwoWrongies',
    [RoundTypes.RoundType.ICompleteYou]:
        'The answers will be revealed one word at a time, so answers with more than two words can be used to make questions even harder!',
    // [RoundTypes.RoundType.HausDerSpiele]: 'HausDerSpiele',       Fun but tricky
    [RoundTypes.RoundType.ZList]:
        'Enter a comma-separated list of answers in any order. (e.g. "Answer 1, Answer 2, Answer 3")',

    // "Random" Games
    // [RoundTypes.RoundType.IsItMe]: 'IsItMe',
    // [RoundTypes.RoundType.NiceRound]: 'NiceRound',               Tricky
    // [RoundTypes.RoundType.WhatsTheQuestion]: 'WhatsTheQuestion', Tricky
    // [RoundTypes.RoundType.LuckyDip]: 'LuckyDip',
    [RoundTypes.RoundType.WhenTheySing]:
        'Drag and drop an mp3 file into the music box. Audio clips can be a maximum of 30 seconds and you will have the option to trim them if they are longer. \n It is recommended to use the "Audio Buzzer On Devices" option when playing via video chat since these apps will often add significant delay.',
    // [RoundTypes.RoundType.NotSoNiceRound]: 'NotSoNiceRound',
    // [RoundTypes.RoundType.WhoGoesFirst]: 'WhoGoesFirst',

    // Tablet Games
    [RoundTypes.RoundType.FingerOnIt]: "Once you've set an image, click on it to set a marker for the correct answer",
    [RoundTypes.RoundType.WhereIsKazakhstan]:
        "Once you've set an image, click on it to set a marker for the correct answer",
    [RoundTypes.RoundType.RichList]: 'Enter a comma-separated list of answers. (e.g. "Answer 1, Answer 2, Answer 3")',
    [RoundTypes.RoundType.SizeMatters]:
        'Enter a comma-separated list of answers in any order. (e.g. "Answer 1, Answer 2, Answer 3")',
    // [RoundTypes.RoundType.TerribleDating]: "I'm Terrible At Dating",
    // [RoundTypes.RoundType.OutplayOsman]: 'OutplayOsman',         Not Possible Online

    // Final Round
    // [RoundTypes.RoundType.AnswerSmash]: 'Answer Smash',
};
