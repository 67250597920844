import './home.scss';

import { dataValidators, GameCrawler, GameTypes, UserStorage } from '@house-of-games/common';
import deepmerge from 'deepmerge';
import { Component, createRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
import { TextField } from '../../common/components/form-fields/form-fields';
import { DraggableGrid } from '../../common/components/grid/draggable-grid';
import { Vote } from '../../common/components/icons/vote';
import { useLoadingError } from '../../common/components/loading-error/loading-error';
import { LoadingSpinner } from '../../common/components/loading-error/loading-spinner';
import { Toast } from '../../common/components/toast/toast';
import { TransitionalModal, useTransitionalModal } from '../../common/components/transitional-modal/transitional-modal';
import { AppRoute } from '../../common/constants/routes';
import { FirebaseHelper } from '../../utils/firebase';
import { requestHandler } from '../../utils/request-handler';
import { useProfileContext } from '../auth-router/auth-context';
import { NoticeBoard } from './noticeboard';

type CreateProps = {
    handleCreateGame: (id: GameTypes.GameId) => void;
};

type CreateState = {
    isValid: boolean;
};

enum ModalType {
    'Create' = 'Create',
    'About' = 'About',
    'Search' = 'Search',
}

class CreateModal extends Component<CreateProps, CreateState> {
    inputRef = createRef<TextField>();
    state: CreateState = {
        isValid: false,
    };

    handleTextChanged = () => {
        const { isValid } = this.inputRef.current.state;
        this.setState({ isValid });
    };

    handleConfirm = () => {
        const { value: gameId } = this.inputRef.current.state;
        this.props.handleCreateGame(gameId);
    };

    render() {
        return (
            <>
                <h2 className="modal__title">Create Game</h2>
                <h3 className="modal__text">Enter a name for your game</h3>
                <TextField
                    label="Name"
                    type="text"
                    errorMessage={dataValidators.gameName.errorMessage}
                    maxLength={dataValidators.gameName.max}
                    validator={dataValidators.gameName.validate}
                    ref={this.inputRef}
                    onChange={this.handleTextChanged}
                />
                <Button label="Confirm" onClick={this.handleConfirm} disabled={!this.state.isValid} />
            </>
        );
    }
}

function AboutModal() {
    return (
        <>
            <div className="about__subheader">What is House Of Games?</div>
            <div className="about__text">
                {`As I'm sure most of you know already, House Of Games is a popular BBC TV show hosted by Richard Osman.
                Celebrities on the show participate in a number of small quiz-like rounds to score points. The questions
                are typically based on general knowledge, wordplay, or a combination of the two.`}
            </div>
            <br />
            <div className="about__subheader">What is this website?</div>
            <div className="about__text">
                {`This is a fan made website with the goal of letting people at home create their own "House Of Games" to
                play with family & friends, whether you're screen sharing over zoom or plugged into a TV in the living
                room.`}
            </div>
            <br />
            <div className="about__subheader">Who Am I?</div>
            <div className="about__text">
                {`I am a software developer from London with a full time job who happens to be a fan of the show. I am in
                no way affiliated with the official House Of Games TV show, but I've tried my best to create this in a
                way that feels faithful to its inspiration.`}
            </div>
            <br />
            <div className="about__subheader">How can I get in touch?</div>
            <div className="about__text">
                {`If you have a feature request, bug report, general questions or just want to share your appreciation for
                my work, please drop me an email at `}
                <span className="email">houseofgamesapp@gmail.com</span>.
            </div>
            <div className="about__subheader">Donations</div>
            <div className="about__text">
                Nobody should feel obligated to donate, but if you want to chip in to running costs and development time
                please use the official PayPal link below.
            </div>
            <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="JKSBRJATBA6TL" />
                <input
                    type="image"
                    src="https://houseofgames.app/donate.png"
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                />
                <img alt="" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
            </form>
        </>
    );
}

export function Home() {
    const profile = useProfileContext();
    const [isLoading, error, wrapper] = useLoadingError(false);
    const [contentClassName, modalClassName, openModal, closeModal] = useTransitionalModal();
    const navigate = useNavigate();
    const [modalType, setModalType] = useState<ModalType>(null);

    const selectModal = (type: ModalType) => {
        setModalType(type);
        openModal();
    };

    const handleCreateGame = async (name: string) => {
        const gameId = await requestHandler.createGame(name);
        handleSelectGame(gameId);
    };

    const handleSelectGame = (gameId: GameTypes.GameId) => {
        navigate(`${AppRoute.Create}/${gameId}`);
    };

    const handleDeleteGame = async (keys: Array<string>) => {
        let fileMatches: GameCrawler.CrawlResult<string> = {};
        const gamesToDelete: Array<string> = [];

        keys.forEach((key) => {
            window.localStorage.removeItem(`${key}_scores`);
            const game = profile.games.find((game) => game.id === key);
            if (game) {
                gamesToDelete.push(key);
                fileMatches = deepmerge(fileMatches, GameCrawler.gameCrawler(game, UserStorage.userStorageMatcher));
            }
        });

        const fileUris = Object.values(fileMatches).map((m) => m.value);
        FirebaseHelper.checkAndDeleteFiles(fileUris, gamesToDelete);
        await requestHandler.deleteGames(keys);
    };

    const renderModal = () => {
        switch (modalType) {
            case ModalType.Create:
                return <CreateModal handleCreateGame={wrapper(handleCreateGame)} />;
            case ModalType.About:
                return <AboutModal />;
            default:
                return;
        }
    };

    return (
        <div className="home page">
            <NoticeBoard />
            <div className="top-left">
                <Link draggable={false} to={AppRoute.Profile} className="top-left__link-profile">
                    <Button label="Profile" secondary className="top-left__button-profile" />
                </Link>
                <Button className="top-left__about" onClick={() => selectModal(ModalType.About)} label="About" />
                <Link draggable={false} to={AppRoute.Faq} className="top-left__link-faq">
                    <Button label="FAQs" secondary className="top-left__button-faq" />
                </Link>
                <Link draggable={false} to={AppRoute.Play} className="top-left__link-join">
                    <Button label="Join Game" className="top-left__button-join" />
                </Link>
            </div>
            <TransitionalModal className={modalClassName} handleClose={() => closeModal()}>
                {renderModal()}
            </TransitionalModal>
            <div className="header">
                <div className="box-header__container">
                    <h1 className="box-header">{`${profile.displayName}'s House Of Games`}</h1>
                </div>
            </div>
            <div className={`content scrollable ${contentClassName}`}>
                <div className="home__buttons-container">
                    <Link draggable={false} to={AppRoute.Storage} className="home__link-storage">
                        <Button label="My Files" className="home__buttons-storage" />
                    </Link>
                    <Button
                        label="New Game"
                        className="home__buttons-new-game"
                        onClick={() => selectModal(ModalType.Create)}
                    />
                    <Link draggable={false} to={AppRoute.Search} className="home__link-search">
                        <Button label="Search Games" className="home__buttons-search" />
                    </Link>
                </div>
                <h3 className="games-grid-header">My Games</h3>
                <DraggableGrid
                    isFixedOrder
                    onRemove={handleDeleteGame}
                    items={profile.games.map((game) => {
                        const rounds = Object.values(game.rounds);
                        const numberOfRounds = rounds.length;
                        const numberOfQuestions = rounds.reduce((count, round) => {
                            return count + round.questions.length;
                        }, 0);
                        return {
                            key: game.id,
                            isSelectable: true,
                            component: () => (
                                <Link
                                    onDragStart={(e) => {
                                        e.preventDefault();
                                    }}
                                    draggable={false}
                                    key={game.id}
                                    className="games-grid-item__container"
                                    to={`${AppRoute.Create}/${game.id}`}
                                >
                                    <div className="games-grid-item__text">{game.name}</div>
                                    <div className="games-grid-item__subtext">{numberOfRounds} Rounds</div>
                                    <div className="games-grid-item__subtext">{numberOfQuestions} Questions</div>
                                    {game.public && (
                                        <div className="games-grid-item__rating">
                                            <div className="game-item__rate-down">
                                                <Vote isDownvote scale={0.5} />
                                            </div>
                                            <span className="game-item__rating-count">{game.rating?.count || 0}</span>
                                            <div className="game-item__rate-up">
                                                <Vote scale={0.5} />
                                            </div>
                                        </div>
                                    )}
                                </Link>
                            ),
                        };
                    })}
                />
            </div>
            {isLoading && <LoadingSpinner />}
            <Toast s={error?.message} />
        </div>
    );
}
