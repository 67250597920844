import './mp3-trim.scss';
import 'firebase/storage';

import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AppError } from '../../../utils/app-error';
import { FirebaseHelper } from '../../../utils/firebase';
import { trim } from '../../../utils/mp3-trim';
import { Button } from '../buttons/buttons';
import { TextField } from '../form-fields/text-field';
import { PlayPause } from '../icons/play-pause';
import { Trim } from '../icons/trim';
import { LoadingSpinner } from '../loading-error/loading-spinner';
import { useRefState } from '../ref-state/use-ref-state';
import { useStorageCache } from './use-storage-cache';

type Mp3TrimProps = {
    file: File;
    audioBuffer: AudioBuffer;
    audioContext: AudioContext;
    onCancel: () => void;
    onFileSaved: (fileKey: string) => void;
};

const MIN_AUDIO_LENGTH_SECONDS = 5;
const MAX_AUDIO_LENGTH_SECONDS = 60;
const DEFAULT_AUDIO_LENGTH_SECONDS = 30;
const MAX_FILE_NAME_LENGTH = 30;

enum ScrubbingState {
    'Start' = 'Start',
    'End' = 'End',
}

enum SavingState {
    'Trimming' = 'Trimming',
    'Uploading' = 'Uploading',
}

const clamp = (min: number, max: number, val: number): number => Math.min(Math.max(min, val), max);

export function Mp3Trim({ audioBuffer, audioContext, file, onCancel, onFileSaved }: Mp3TrimProps) {
    const storageCache = useStorageCache();
    const audioSrc = useMemo(() => URL.createObjectURL(file), [file]);
    const audioRef = useRef<HTMLAudioElement>();
    const trackRef = useRef<HTMLDivElement>();
    const [start, setStart, startRef] = useRefState(0);
    const [end, setEnd, endRef] = useRefState(Math.min(DEFAULT_AUDIO_LENGTH_SECONDS, audioBuffer.duration));
    const [scrubbingState, setScrubbingState, scrubbingStateRef] = useRefState<ScrubbingState>(undefined);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [savingState, setSavingState] = useState<SavingState>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isPlaying, setIsPlaying] = useState(false);
    const [fileName, setFileName] = useState<string>();
    const isValid = Math.floor(end) - Math.floor(start) <= MAX_AUDIO_LENGTH_SECONDS;

    function handleSave() {
        setErrorMessage(undefined);
        setSavingState(SavingState.Trimming);
        window.requestIdleCallback(
            async () => {
                const trimmedFile = trim(audioBuffer, audioContext, start, end);
                setSavingState(SavingState.Uploading);

                try {
                    const fileKey = await FirebaseHelper.uploadFile(trimmedFile, {
                        customMetadata: {
                            originalFileName: file.name,
                            nickName: fileName?.substring(0, MAX_FILE_NAME_LENGTH),
                        },
                    });

                    storageCache.set(fileKey, URL.createObjectURL(trimmedFile));
                    onFileSaved(fileKey);
                } catch (e) {
                    if (e instanceof AppError) {
                        setErrorMessage(e.message);
                    } else {
                        setErrorMessage('There was an error trying to upload your file');
                    }
                }

                setSavingState(undefined);
            },
            { timeout: 200 },
        );
    }

    function setScrubbingStateStart() {
        setScrubbingState(ScrubbingState.Start);
    }

    function setScrubbingStateEnd() {
        setScrubbingState(ScrubbingState.End);
    }

    function clearScrubbingState() {
        if (scrubbingStateRef.current === ScrubbingState.Start) {
            audioRef.current.currentTime = startRef.current;
        }

        setScrubbingState(undefined);
    }

    function handlePositionChanged() {
        setCurrentPosition(audioRef.current.currentTime);
        if (audioRef.current.currentTime >= end) {
            audioRef.current.pause();
            audioRef.current.currentTime = start;
            setIsPlaying(false);
        }
    }

    function canSetStartPoint() {
        return currentPosition > start && currentPosition <= end - MIN_AUDIO_LENGTH_SECONDS;
    }

    function canSetEndPoint() {
        return currentPosition < end && currentPosition >= start + MIN_AUDIO_LENGTH_SECONDS;
    }

    function handleSetStartPoint() {
        if (canSetStartPoint()) {
            setStart(currentPosition);
        }
    }

    function handleSetEndPoint() {
        if (canSetEndPoint()) {
            setEnd(currentPosition);
        }
    }

    function handlePlayPause() {
        if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            audioRef.current.currentTime = start;
            audioRef.current.play();
            setIsPlaying(true);
        }
    }

    function getMinMax(): [number, number] {
        if (scrubbingStateRef.current === ScrubbingState.Start) {
            return [0, Math.max(endRef.current - MIN_AUDIO_LENGTH_SECONDS, 0)];
        } else {
            return [Math.min(startRef.current + MIN_AUDIO_LENGTH_SECONDS, audioBuffer.duration), audioBuffer.duration];
        }
    }

    function getPositionFromMouseX(mouseX: number): number {
        const trackNode = trackRef.current;
        const max = audioBuffer.duration;
        const { left, width } = trackNode.getBoundingClientRect();
        const clamped = clamp(left, left + width, mouseX);
        const percentageDecimal = (clamped - left) / width;
        const relativeValue = max * percentageDecimal;
        return relativeValue;
    }

    function handleMouseMove(e: MouseEvent) {
        if (scrubbingStateRef.current) {
            const position = getPositionFromMouseX(e.pageX);
            const [min, max] = getMinMax();
            const clamped = clamp(min, max, position);
            if (scrubbingStateRef.current === ScrubbingState.Start) {
                setStart(clamped);
            } else if (scrubbingStateRef.current === ScrubbingState.End) {
                setEnd(clamped);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('mouseup', clearScrubbingState);
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mouseup', clearScrubbingState);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    function calculateThumbStart() {
        const duration = audioBuffer.duration;
        const percent = start === 0 ? 0 : 100 / (duration / start);
        return percent;
    }

    function calculateThumbEnd() {
        const duration = audioBuffer.duration;
        const percent = end === 0 ? 0 : 100 / (duration / end);
        return percent;
    }

    function calculatePosition() {
        const duration = audioBuffer.duration;
        const percent = currentPosition === 0 ? 0 : 100 / (duration / currentPosition);
        return percent;
    }

    function renderTrackComponents() {
        const thumbStart = calculateThumbStart();
        const thumbEnd = calculateThumbEnd();
        const thumbStyle: React.CSSProperties = { position: 'absolute', top: '50%' };
        const positionPercent = calculatePosition();
        const isWithinSelection = positionPercent >= thumbStart && positionPercent <= thumbEnd;

        const thumbStartClassNames = classNames('mp3-thumb mp3-thumb__start', {
            'mp3-thumb--scrubbing': scrubbingState === ScrubbingState.Start,
            'mp3-thumb--playing': isPlaying,
        });
        const thumbEndClassNames = classNames('mp3-thumb mp3-thumb__end', {
            'mp3-thumb--scrubbing': scrubbingState === ScrubbingState.End,
            'mp3-thumb--playing': isPlaying,
        });
        const positionClassNames = classNames('mp3-track__position', {
            'mp3-track__position--out-of-range': !isWithinSelection,
        });

        return (
            <>
                <div
                    className={thumbStartClassNames}
                    style={{ ...thumbStyle, left: `${thumbStart}%` }}
                    onMouseDown={setScrubbingStateStart}
                />
                <div
                    className={thumbEndClassNames}
                    style={{ ...thumbStyle, left: `${thumbEnd}%` }}
                    onMouseDown={setScrubbingStateEnd}
                />
                <div
                    className="mp3-track__selected"
                    style={{ position: 'absolute', left: `${thumbStart}%`, right: `${100 - thumbEnd}%` }}
                />
                <div className={positionClassNames} style={{ position: 'absolute', left: `${calculatePosition()}%` }} />
            </>
        );
    }

    function getTimeString(totalSeconds: number) {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        const secondsString = `0${seconds}`.substr(-2);

        return `${minutes}:${secondsString}`;
    }

    const trackClassNames = classNames('mp3-track', {
        'mp3-track--error': !isValid,
        'mp3-track--scrubbing': Boolean(scrubbingState),
    });

    const trimStartClassNames = classNames('mp3-trim-modal__trim-button mp3-trim-modal__trim-start', {
        'mp3-trim-modal__trim-button--disabled': !canSetStartPoint(),
    });

    const trimEndClassNames = classNames('mp3-trim-modal__trim-button mp3-trim-modal__trim-end', {
        'mp3-trim-modal__trim-button--disabled': !canSetEndPoint(),
    });

    const renderedErrorMessage = errorMessage || 'Something Went Wrong';
    const errorClassNames = classNames('mp3-trim-modal__error', {
        'mp3-trim-modal__error--visible': Boolean(errorMessage),
    });

    return (
        <div className="mp3-trim-modal__container">
            <div className="mp3-trim-modal">
                <audio ref={audioRef} src={audioSrc} onTimeUpdate={handlePositionChanged} />
                <div className="mp3-trim-modal__text">
                    <div className="mp3-trim-modal__title">Trim Your File</div>
                    <div className="mp3-trim-modal__description">
                        Audio clips can be a maximum of 30 seconds long. Use the sliders to select a segment of your
                        file.
                    </div>
                    <div className="mp3-trim-modal__times">
                        <div className="mp3-trim-modal__times-text">
                            <div>Start</div>
                            <div>{getTimeString(start)}</div>
                        </div>
                        <div className="mp3-trim-modal__times-text">
                            <div>Duration</div>
                            <div>{getTimeString(Math.floor(end) - Math.floor(start))}</div>
                        </div>
                        <div className="mp3-trim-modal__times-text">
                            <div>End</div>
                            <div>{getTimeString(end)}</div>
                        </div>
                    </div>
                </div>
                <div className="mp3-track__container">
                    <span className="mp3-track__label mp3-track__label-start">{getTimeString(currentPosition)}</span>
                    <div ref={trackRef} className={trackClassNames}>
                        {renderTrackComponents()}
                    </div>
                    <span className="mp3-track__label mp3-track__label-end">{getTimeString(audioBuffer.duration)}</span>
                </div>
                <div className="mp3-trim-modal__bottom">
                    <div className="mp3-trim-modal__play-pause">
                        <div className={trimStartClassNames} onClick={handleSetStartPoint}>
                            <Trim scale={1.2} />
                        </div>
                        <div className="mp3-trim-modal__play-pause-button" onClick={handlePlayPause}>
                            <PlayPause scale={1.2} isPlaying={isPlaying} />
                        </div>
                        <div className={trimEndClassNames} onClick={handleSetEndPoint}>
                            <Trim scale={1.2} />
                        </div>
                    </div>
                    <div className="mp3-trim-modal__buttons">
                        <div className="mp3-trim-modal__text-field">
                            <TextField
                                label="File Name"
                                type="text"
                                onChange={(name) => setFileName(name)}
                                maxLength={MAX_FILE_NAME_LENGTH}
                            />
                        </div>
                        <Button
                            className="mp3-trim-modal__button-cancel"
                            label="Cancel"
                            onClick={onCancel}
                            disabled={Boolean(savingState)}
                            secondary
                        />
                        <Button
                            className="mp3-trim-modal__button-save"
                            label={'Save & Upload'}
                            disabled={!isValid || Boolean(savingState)}
                            onClick={handleSave}
                        />
                    </div>
                </div>
                <div className={errorClassNames}>{renderedErrorMessage}</div>
            </div>
            {savingState && (
                <div className="mp3-trim-modal__loading">
                    <LoadingSpinner />
                    <div className="mp3-trim-modal__loading-text">
                        {savingState === SavingState.Trimming ? 'Creating Trimmed MP3 File' : 'Uploading'}
                    </div>
                </div>
            )}
        </div>
    );
}
