import { CFRequestTypes, GameCrawler, GameTypes, UserStorage } from '@house-of-games/common';
import deepmerge from 'deepmerge';
import { FirebaseApp, FirebaseError, initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, updateProfile } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import {
    deleteObject,
    getBlob,
    getStorage,
    listAll,
    ref,
    StorageReference,
    uploadBytes,
    UploadMetadata,
} from 'firebase/storage';

import { AppError } from './app-error';
import { requestHandler } from './request-handler';

const firebaseConfig = {
    apiKey: 'AIzaSyBRjyAHXho6-ldswqILsJABVxJOoMr1qeI',
    authDomain: 'house-of-games-nm.firebaseapp.com',
    databaseURL: 'https://house-of-games-nm.firebaseio.com',
    projectId: 'house-of-games-nm',
    storageBucket: 'house-of-games-nm.appspot.com',
    messagingSenderId: '523474449434',
    appId: '1:523474449434:web:f6e4877301b25f0233cda3',
    measurementId: 'G-R7K0RHTGKW',
};

const OneYearCache = 'max-age=31536000';

let firebaseApp: FirebaseApp;
let profile: GameTypes.PrivateProfile;

function toKb(sizeBytes: number) {
    return sizeBytes / 1024;
}

function toMb(sizeBytes: number) {
    return sizeBytes / 1024 / 1024;
}

export class FirebaseHelper {
    public static ErrorCodes = {
        SPACE_LIMIT_REACHED: 'SPACE_LIMIT_REACHED',
        UPLOAD_ERROR: 'UPLOAD_ERROR',
        INVALID_STORAGE_PATH: 'INVALID_STORAGE_PATH',
        FILE_NOT_FOUND: 'FILE_NOT_FOUND',
        INVALID_FILE_TYPE: 'INVALID_FILE_TYPE',
    };

    public static initializeApp = () => {
        firebaseApp = initializeApp(firebaseConfig);
        return firebaseApp;
    };

    public static setProfile = (newProfile: GameTypes.PrivateProfile) => {
        profile = newProfile;
    };

    public static getFirebase = () => {
        return firebaseApp;
    };

    public static getAuth = () => {
        return getAuth(firebaseApp);
    };

    public static getFirestore = () => {
        return getFirestore(firebaseApp);
    };

    public static getStorage = () => {
        return getStorage(firebaseApp);
    };

    public static async updateProfile(displayName: string) {
        const auth = FirebaseHelper.getAuth();
        await updateProfile(auth.currentUser, { displayName });
    }

    public static listAllFiles = async (): Promise<Array<StorageReference>> => {
        const uid = FirebaseHelper.getAuth().currentUser.uid;
        const storage = FirebaseHelper.getStorage();
        const [audioRoot, audioFolder, images] = await Promise.all([
            listAll(ref(storage, uid)),
            listAll(ref(storage, `${uid}/${UserStorage.filePathMap[CFRequestTypes.UploadFileType.Audio]}`)),
            listAll(ref(storage, `${uid}/${UserStorage.filePathMap[CFRequestTypes.UploadFileType.Image]}`)),
        ]);

        return [...audioRoot.items, ...audioFolder.items, ...images.items];
    };

    public static uploadFile = async (file: Blob, metadata?: UploadMetadata) => {
        let fileType: CFRequestTypes.UploadFileType;
        if (file.type.includes('image/')) {
            fileType = CFRequestTypes.UploadFileType.Image;
        } else if (file.type.includes('audio/')) {
            fileType = CFRequestTypes.UploadFileType.Audio;
        } else {
            throw new AppError({
                code: FirebaseHelper.ErrorCodes.INVALID_FILE_TYPE,
                message: `Invalid File Type. Expected Image or Audio but received ${file.type}.`,
            });
        }

        const res = await requestHandler.requestUploadToken(fileType);
        if (res.availableStorageBytes < file.size) {
            const { availableStorageString, requiredStorageString } = FirebaseHelper.createStorageStrings({
                availableStorageBytes: res.availableStorageBytes,
                requiredStorageBytes: file.size,
            });

            const errorMessage = `File is too large. You have ${availableStorageString} available but the file is ${requiredStorageString}`;
            throw new AppError({
                code: FirebaseHelper.ErrorCodes.SPACE_LIMIT_REACHED,
                message: errorMessage,
            }).log();
        }

        try {
            await signInWithCustomToken(FirebaseHelper.getAuth(), res.token);
            const storage = FirebaseHelper.getStorage();
            const storageRef = ref(storage, res.filePath);
            await uploadBytes(storageRef, file, { ...metadata, cacheControl: OneYearCache });
            const { fileType, fileName } = res.pathSegments;
            const fileTypeSegment = fileType ? `${fileType}/` : '/';
            return `${UserStorage.USER_STORAGE_PREFIX}${fileTypeSegment}${fileName}`;
        } catch (e) {
            throw new AppError({
                code: FirebaseHelper.ErrorCodes.UPLOAD_ERROR,
                message: 'There was an error trying to upload your file',
                nativeError: e,
            }).log();
        }
    };

    public static downloadFile = async (uri: string): Promise<Blob> => {
        const uid = FirebaseHelper.getAuth().currentUser.uid;
        let filePath = '';
        try {
            filePath = UserStorage.getFilePathFromUri(uri, uid);
        } catch (e) {
            throw e;
        }

        const storage = FirebaseHelper.getStorage();

        try {
            const file = await getBlob(ref(storage, filePath));
            return file;
        } catch (e) {
            if (e instanceof FirebaseError && e.code.includes('object-not-found')) {
                throw new AppError({
                    code: FirebaseHelper.ErrorCodes.FILE_NOT_FOUND,
                    message: 'File was not found at the requested path',
                    nativeError: e,
                }).log();
            }

            throw new AppError(AppError.convertUnknownError(e)).log();
        }
    };

    public static deleteFile = async (uri: string): Promise<void> => {
        const uid = FirebaseHelper.getAuth().currentUser.uid;
        const filePath = UserStorage.getFilePathFromUri(uri, uid);
        const storage = FirebaseHelper.getStorage();

        try {
            await deleteObject(ref(storage, filePath));
        } catch (e) {
            if (e instanceof FirebaseError && e.code.includes('object-not-found')) {
                throw new AppError({
                    code: FirebaseHelper.ErrorCodes.FILE_NOT_FOUND,
                    message: 'File was not found at the requested path',
                    nativeError: e,
                }).log();
            }

            throw new AppError(AppError.convertUnknownError(e)).log();
        }
    };

    public static checkAndDeleteFiles = async (
        uris: Array<string>,
        pathsToDelete: Array<string>,
    ): Promise<Array<string>> => {
        let allFiles: GameCrawler.CrawlResult<string> = {};
        profile?.games.forEach((game) => {
            const gameFiles = GameCrawler.gameCrawler(game, UserStorage.userStorageMatcher);
            allFiles = deepmerge(allFiles, gameFiles);
        });

        const fileUrisToDelete: Array<string> = [];
        uris.forEach((uri) => {
            const match = allFiles[uri];
            if (!match) {
                console.warn('This should never happen - something strange just happened.');
                return;
            }

            const otherPaths = match.paths.filter((p) => {
                return pathsToDelete.every((path) => {
                    // If no match paths start with the delete path then it muse exist elsewhere
                    return !p.absolute.startsWith(path);
                });
            });

            if (otherPaths.length !== 0) {
                return;
            }

            fileUrisToDelete.push(uri);
        });

        const filePromises: Array<Promise<string>> = [];
        fileUrisToDelete.forEach((fileUri) => {
            const uid = FirebaseHelper.getAuth().currentUser.uid;
            const filePath = UserStorage.getFilePathFromUri(fileUri, uid);
            const storage = FirebaseHelper.getStorage();
            const deletePromise = deleteObject(ref(storage, filePath)).then(() => fileUri);
            filePromises.push(deletePromise);
        });

        const results = await Promise.allSettled(filePromises);
        const deletedFiles: Array<string> = [];
        results.forEach((res) => {
            if (res.status === 'fulfilled') {
                deletedFiles.push(res.value);
                return;
            }

            if (res.reason instanceof FirebaseError && res.reason.code.includes('object-not-found')) {
                const err = new AppError({
                    code: FirebaseHelper.ErrorCodes.FILE_NOT_FOUND,
                    message: 'File was not found at the requested path',
                    nativeError: res.reason,
                });

                err.log();
            }

            const err = new AppError(AppError.convertUnknownError(res.reason));
            err.log();
        });

        return deletedFiles;
    };

    public static createStorageString(spaceBytes: number): string {
        let units = 'mb';
        let spaceString = toMb(spaceBytes).toFixed(2);
        if (Number(spaceString) < 1) {
            units = 'kb';
            spaceString = `${Math.floor(toKb(spaceBytes))}`;
        }
        return `${spaceString}${units}`;
    }

    public static createStorageStrings(storageResponse: CFRequestTypes.VerifyCopyStorageResponse) {
        let units = 'mb';
        let availableStorage = toMb(storageResponse.availableStorageBytes).toFixed(2);
        if (Number(availableStorage) < 1) {
            units = 'kb';
            availableStorage = `${Math.floor(toKb(storageResponse.availableStorageBytes))}`;
        }

        const requiredStorage =
            units === 'mb'
                ? toMb(storageResponse.requiredStorageBytes).toFixed(2)
                : `${Math.ceil(toKb(storageResponse.requiredStorageBytes))}`;
        return {
            availableStorageString: `${availableStorage}${units}`,
            requiredStorageString: `${requiredStorage}${units}`,
        };
    }
}
