import { RoundTypes } from '@house-of-games/common';

const addPath = <path d="M22,13.5h-8.5V22h-3v-8.5H2v-3h8.5V2h3v8.5H22V13.5z" />;
const subtractPath = <path d="M22,13.5H2v-3h20V13.5z" />;
const multiplyPath = (
    <path d="M14.12,12l6.94,6.94l-2.12,2.12L12,14.12l-6.94,6.94l-2.12-2.12L9.88,12L2.94,5.06l2.12-2.12L12,9.88l6.94-6.94l2.12,2.12L14.12,12z" />
);
const dividePath = (
    <path d="M22,10.5v3H2v-3H22z M12,7c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C10,6.1,10.9,7,12,7z M12,17c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2C14,17.9,13.1,17,12,17z" />
);
const equalsPath = <path d="M22,6.5v3H2v-3H22z M2,17.5h20v-3H2V17.5z" />;

const pathMap: { [key in RoundTypes.MathOperator | 'Equals']: React.ReactElement } = {
    [RoundTypes.MathOperator.Add]: addPath,
    [RoundTypes.MathOperator.Subtract]: subtractPath,
    [RoundTypes.MathOperator.Multiply]: multiplyPath,
    [RoundTypes.MathOperator.Divide]: dividePath,
    Equals: equalsPath,
};

type MathOperatorProps = {
    scale?: number;
    operator: RoundTypes.MathOperator | 'Equals';
};

export function MathOperator({ scale = 1, operator }: MathOperatorProps) {
    const path = pathMap[operator];
    return (
        <svg className="icon__math-operator" width={`${24 * scale}`} height={`${24 * scale}`} viewBox="0 0 24 24">
            {path}
        </svg>
    );
}
