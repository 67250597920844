type DownloadProps = {
    scale?: number;
};

export function Download({ scale = 1 }: DownloadProps) {
    const roundedScale = Math.round(24 * scale);
    return (
        <svg className="icon__download" width={`${roundedScale}`} height={`${roundedScale}`} viewBox="0 0 24 24">
            <path
                d="M6.25,11.25h4.25v-8.5h3v8.5h4.25l-5.75,6L6.25,11.25z M15.5,6.75v2.5h5v9.25h-17V9.25h5v-2.5H1v2.5V21h2.5h17H23V9.25v-2.5
	H15.5z"
            />
        </svg>
    );
}
