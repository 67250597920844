import './play-the-answer-isnt.scss';

import { RoundTypes } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { AnimateExpandContainer } from '../../../common/components/animate-expand-container/animate-expand-container';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';
import { useDynamicTextSize } from '../../game/use-dynamic-text-size';

type AssociatedRounds = SlideProps<RoundTypes.TheAnswerIsntRound>;

export function PlayTheAnswerIsnt({ question, message }: AssociatedRounds) {
    const textSizeClass = useDynamicTextSize([question.question]);
    const [isRevealed, setIsRevealed] = useState(false);

    useEffect(() => {
        switch (message.name) {
            case MessageName.reveal:
                setIsRevealed(true);
        }
    }, [message]);

    const answerIndex = useMemo(() => {
        return Math.floor(Math.random() * 4);
    }, [question]);

    const answers = useMemo(() => {
        const className = 'play-the-answer-isnt__answer';
        const arr = Object.values(question.fakes).map((fake, i) => {
            const classes = classNames(className, {
                [`${className}-fake`]: isRevealed,
            });
            return (
                <AnimateExpandContainer key={i} className={classes}>
                    <TextFlip className="play-the-answer-isnt__answer-text" text={fake} offset={300} />
                </AnimateExpandContainer>
            );
        });

        const realAnswerClasses = classNames(className, {
            [`${className}-real`]: isRevealed,
        });
        const realAnswerComponent = (
            <AnimateExpandContainer key="real" className={realAnswerClasses}>
                <TextFlip className="play-the-answer-isnt__answer-text" text={question.answer} offset={300} />
            </AnimateExpandContainer>
        );

        arr.splice(answerIndex, 0, realAnswerComponent);
        return arr;
    }, [question, isRevealed]);

    return (
        <div className="play-the-answer-isnt">
            <AnimateExpandContainer className={`play-the-answer-isnt__question ${textSizeClass}`}>
                <TextFlip className="play-the-answer-isnt__question-text" text={question.question} offset={300} />
            </AnimateExpandContainer>
            <div className="play-the-answer-isnt__answer-container">{answers}</div>
        </div>
    );
}
