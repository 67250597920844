import classNames from 'classnames';
import Picker, { IEmojiData } from 'emoji-picker-react';
import React, { Component, createRef, FormEvent, KeyboardEvent } from 'react';

export type TextFieldProps = {
    className?: string;
    defaultValue?: string;
    label: string;
    type: string;
    errorMessage?: string;
    disableTooltip?: boolean;
    isOutline?: boolean;
    isEmoji?: boolean;
    validator?: (s: string) => boolean;
    maxLength?: number;
    minValue?: number;
    maxValue?: number;
    beforeChange?: (value: string, previousValue: string) => string;
    onChange?: (s: string) => void;
    onEnterPress?: (s: string, isValid: boolean) => void;
    ref?: React.RefObject<TextField>;
    theme?: {
        text?: string;
        fill?: string;
        border?: string;
    };
    overwrite?: { value: string };
};

type TextFieldState = {
    isAccessed: boolean;
    isFocussed: boolean;
    isValid: boolean;
    isShowingEmoji: boolean;
    cursorPosition: number;
    value: string;
    minWidth: string;
};

export class TextField extends Component<TextFieldProps, TextFieldState> {
    componentRef = createRef<HTMLDivElement>();
    inputRef = createRef<HTMLInputElement>();
    labelRef = createRef<HTMLDivElement>();
    maxLabelWidth = 0;

    getDefaultValue = () => {
        if (!this.props.defaultValue) {
            return '';
        }

        if (!this.props.maxLength) {
            return this.props.defaultValue;
        }

        return this.props.defaultValue.slice(0, this.props.maxLength);
    };

    validator: TextFieldProps['validator'] =
        this.props.validator ||
        function () {
            return true;
        };

    initialState: TextFieldState = {
        isAccessed: false,
        isFocussed: false,
        isValid: this.validator(this.props.defaultValue || ''),
        isShowingEmoji: false,
        cursorPosition: undefined,
        value: this.getDefaultValue(),
        minWidth: '0',
    };

    state: TextFieldState = {
        ...this.initialState,
    };

    componentDidMount() {
        this.setMinWidth();
        this.calculateTheme();
        window.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClick);
    }

    componentDidUpdate(prevProps: Readonly<TextFieldProps>): void {
        if (prevProps.overwrite !== this.props.overwrite) {
            this.processChange(this.props.overwrite.value);
        }

        if (prevProps.isOutline !== this.props.isOutline) {
            this.calculateTheme();
        }

        if (!this.state.value) {
            this.setMinWidth();
        }
    }

    calculateTheme = () => {
        const { isOutline, theme = {} } = this.props;
        const modifiedTheme = { ...theme };

        if (isOutline) {
            modifiedTheme.border = theme.border || 'var(--hog-secondary)';
            modifiedTheme.text = theme.text || 'var(--off-white)';
        } else {
            modifiedTheme.border = theme.border || 'var(--hog-secondary-dark)';
            modifiedTheme.text = theme.text || 'var(--hog-secondary-dark)';
        }

        if (modifiedTheme.border) {
            this.componentRef.current.style.setProperty('--primary-outline', modifiedTheme.border);
        }

        if (modifiedTheme.text) {
            this.componentRef.current.style.setProperty('--primary-text', modifiedTheme.text);
        }

        if (modifiedTheme.fill) {
            this.componentRef.current.style.setProperty('--primary-fill', modifiedTheme.fill);
        }
    };

    reset = () => {
        this.setState(this.initialState);
    };

    blur = () => {
        this.inputRef.current.blur();
    };

    getValue = () => {
        return this.state.value;
    };

    handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (this.props.type === 'number') {
            const value = parseInt(e.target.value, 10);
            if (value > this.props.maxValue) {
                this.processChange(`${this.props.maxValue}`);
            } else if (value < this.props.minValue) {
                this.processChange(`${this.props.minValue}`);
            }
        }

        this.setState({ isFocussed: false, cursorPosition: e.currentTarget.selectionStart });
    };

    handleFocus = () => {
        this.setState({
            isFocussed: true,
            isAccessed: true,
        });
    };

    handleClick = (e: MouseEvent) => {
        if (this.componentRef.current.contains(e.target as Node)) {
            if (!this.state.isShowingEmoji) {
                this.setState({ isShowingEmoji: true });
            }
        } else {
            this.setState({ isShowingEmoji: false, cursorPosition: undefined });
        }
    };

    handleChange = (e: FormEvent<HTMLInputElement>) => {
        const text = e.currentTarget.value;
        this.processChange(text);
    };

    handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this.props.onEnterPress?.(this.state.value, this.state.isValid);
        }
    };

    handleClickEmoji = (event: React.MouseEvent, e: IEmojiData) => {
        const { cursorPosition, value } = this.state;
        let newValue = value;
        if (typeof cursorPosition === 'number') {
            newValue = value.slice(0, cursorPosition) + e.emoji + value.slice(cursorPosition);
        } else {
            newValue += e.emoji;
        }

        if (newValue.length > this.props.maxLength) {
            return;
        }

        this.setState({ cursorPosition: cursorPosition + e.emoji.length }, () => {
            this.processChange(newValue);
        });
    };

    processChange = (text: string) => {
        if (text.length > this.props.maxLength) {
            return;
        }

        if (this.props.beforeChange) {
            text = this.props.beforeChange(text, this.state.value);
        }

        this.setState({ value: text }, () => {
            const { onChange: customOnChange } = this.props;
            this.validate(customOnChange && (() => customOnChange(text)));
        });
    };

    validate = (cb?: () => void) => {
        const { value } = this.state;
        this.setState({ isValid: this.validator(value) }, cb);
    };

    setMinWidth = () => {
        const rect = this.labelRef.current?.getBoundingClientRect();
        if (!rect) {
            setTimeout(this.setMinWidth, 50);
            return;
        }

        const width = Math.ceil(rect.width);
        if (width > this.maxLabelWidth) {
            this.maxLabelWidth = width;
            const padding = this.props.type === 'number' ? '4rem' : '1.4rem';
            this.setState({ minWidth: `calc(${this.maxLabelWidth}px + ${padding})` });
        }
    };

    render() {
        const { className, disableTooltip, label, type, errorMessage, isOutline, isEmoji, maxValue, minValue } =
            this.props;

        const inputWidth = this.inputRef.current?.getBoundingClientRect().width;
        const inputScrollWidth = this.inputRef.current?.scrollWidth;
        const showToolTip = !disableTooltip && inputScrollWidth > inputWidth;

        const { isAccessed, isFocussed, isValid, isShowingEmoji, value, minWidth } = this.state;
        const additionalInputProps = type === 'number' ? { max: maxValue, min: minValue } : {};
        const classes = classNames('form__item', {
            'form__item--outline': isOutline,
            'form__item--focussed': isFocussed,
            'form__item--errored': !isValid && !isFocussed && isAccessed,
            'form__item--shifted': isFocussed || Boolean(value),
            [className]: Boolean(className),
        });
        return (
            <div className={classes} ref={this.componentRef}>
                <div className="form__label">{label}</div>
                <div ref={this.labelRef} className="form__label--hidden">
                    {label}
                </div>
                <input
                    ref={this.inputRef}
                    type={type}
                    className={`form__input form__${type}`}
                    value={value}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    style={{ minWidth }}
                    {...additionalInputProps}
                />
                <div className="form__error">{errorMessage}</div>
                {isShowingEmoji && isEmoji && (
                    <Picker onEmojiClick={this.handleClickEmoji} preload native disableAutoFocus />
                )}
                <div className={classNames('text-tooltip', { 'text-tooltip__visible': showToolTip })}>{value}</div>
            </div>
        );
    }
}
