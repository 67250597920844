type CheckProps = {
    scale?: number;
};

export function Check({ scale = 1 }: CheckProps) {
    const roundedScale = Math.round(24 * scale);
    return (
        <svg className="icon__check" width={`${roundedScale}`} height={`${roundedScale}`} viewBox="0 0 20 24">
            <path d="M11,12.88l10.5-10.5l2.12,2.12L11,17.12l-1.56-1.56L8.88,15l-3.44-3.44l2.12-2.12L11,12.88z M20.5,18.5h-17V7.25h10.13 l2.5-2.5H1V6v1.25V21h2.5h17H23V8.12l-2.5,2.5V18.5z" />
        </svg>
    );
}
