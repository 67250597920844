import './create-broken-karaoke.scss';

import { roundDefinitions, RoundTypes } from '@house-of-games/common';
import React, { useState } from 'react';

import { Button } from '../../../common/components/buttons/buttons';
import { TextField } from '../../../common/components/form-fields/text-field';
import { Add } from '../../../common/components/icons/add';
import { CarriageReturn } from '../../../common/components/icons/carriage-return';
import { Close } from '../../../common/components/icons/close';
import { QuestionArrows } from '../common/question-arrows';
import { QuestionErase } from '../common/question-erase';
import { RoundProps } from '../create-round-component-map';
import { KaraokeCaptureModal } from './broken-karaoke-modal/karaoke-capture-modal';

type Question = RoundTypes.BrokenKaraokeRound['questions'][0];
type Character = Question['lines'][0][0];

const definition = roundDefinitions[RoundTypes.RoundType.BrokenKaraoke].of;

type CharacterInputProps = {
    character: Character;
    onChangeCharacter: (value: Character) => void;
    onDelete: () => void;
};

function CharacterInput({ character, onChangeCharacter, onDelete }: CharacterInputProps) {
    function handleChangeDelay(e: React.ChangeEvent<HTMLInputElement>) {
        const value = parseInt(e.currentTarget.value);
        if (!value || value < 10000) {
            onChangeCharacter({ ...character, delayMs: value });
        }
    }

    function handleChangeLetter(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        const alphaNumericBracketsRegex = /^[\p{L}0-9()]?$/gu;
        if (alphaNumericBracketsRegex.test(value)) {
            onChangeCharacter({ ...character, character: value });
        }
    }

    return (
        <div className="create-broken-karaoke-question__character">
            <div className="create-broken-karaoke-question__x" onClick={onDelete}>
                <Close scale={0.4} />
            </div>
            <input
                type="text"
                max={definition.lines.of.of.of.character.length}
                value={(character.character || '').toUpperCase()}
                onChange={handleChangeLetter}
                maxLength={1}
                placeholder="Letter"
                className="create-broken-karaoke-question__letter"
            />
            <div className="create-broken-karaoke-question__separator" />
            <input
                type="number"
                max={definition.lines.of.of.of.character.length}
                value={character.delayMs ?? ''}
                onChange={handleChangeDelay}
                placeholder="Delay (ms)"
                className="create-broken-karaoke-question__delay"
            />
        </div>
    );
}

export function CreateBrokenKaraoke({
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    shiftUp,
    shiftDown,
    round,
    uniqueKey,
}: RoundProps<RoundTypes.BrokenKaraokeRound>) {
    const { questions } = round;
    const [recording, setRecording] = useState<number>(null);
    const [isPreviewMode, setIsPreviewMode] = useState(false);

    function handleNewQuestion() {
        createNewQuestion({ lines: { 0: [{}] } });
    }

    function handleChangeCharacter(
        character: Character,
        questionIndex: number,
        lineIndex: number,
        characterIndex: number,
    ) {
        const newQuestion: Question = { lines: { ...questions[questionIndex].lines } };
        newQuestion.lines[lineIndex][characterIndex] = character;
        updateQuestion(questionIndex, newQuestion);
    }

    function handleAddCharacter(questionIndex: number, lineIndex: number) {
        const newQuestion: Question = { lines: { ...questions[questionIndex].lines } };
        newQuestion.lines[lineIndex] = [...newQuestion.lines[lineIndex], {}];
        updateQuestion(questionIndex, newQuestion);
    }

    function handleDeleteCharacter(questionIndex: number, lineIndex: number, characterIndex: number) {
        const newQuestion: Question = { lines: { ...questions[questionIndex].lines } };
        const isLastElement = newQuestion.lines[lineIndex].length === 1;
        if (isLastElement) {
            const isLastRow = Object.keys(newQuestion.lines).length === 1;
            if (isLastRow) {
                return;
            }

            delete newQuestion.lines[lineIndex];
            updateQuestion(questionIndex, rebuildLineKeys(newQuestion));
        } else {
            newQuestion.lines[lineIndex].splice(characterIndex, 1);
            updateQuestion(questionIndex, newQuestion);
        }
    }

    function handleInsertLine(questionIndex: number, lineIndex: number) {
        const lines = questions[questionIndex].lines;
        const newQuestion: Question = { ...questions[questionIndex] };
        const newLines: Question['lines'] = {};
        Object.keys(lines).forEach((key) => {
            let newKey = parseInt(key);
            if (newKey > lineIndex) {
                newKey += 1;
            }
            newLines[newKey] = lines[parseInt(key)];
        });
        newLines[lineIndex + 1] = [{}];
        newQuestion.lines = newLines;
        updateQuestion(questionIndex, newQuestion);
    }

    function rebuildLineKeys(question: Question) {
        const newLines: Question['lines'] = {};
        Object.keys(question.lines).forEach((k, i) => {
            newLines[i] = question.lines[parseInt(k)];
        });
        return { ...question, lines: newLines };
    }

    function handleSongChanged(questionIndex: number, song: string) {
        const newQuestion: Question = { ...questions[questionIndex] };
        newQuestion.song = song;
        updateQuestion(questionIndex, newQuestion);
    }

    function handleArtistChanged(questionIndex: number, artist: string) {
        const newQuestion: Question = { ...questions[questionIndex] };
        newQuestion.artist = artist;
        updateQuestion(questionIndex, newQuestion);
    }

    function handleYearChanged(questionIndex: number, year: string) {
        const newQuestion: Question = { ...questions[questionIndex] };
        newQuestion.year = year;
        updateQuestion(questionIndex, newQuestion);
    }

    function handleOpenCaptureModal(questionIndex: number, previewMode?: boolean) {
        setIsPreviewMode(Boolean(previewMode));
        setRecording(questionIndex);
    }

    function handleCloseModal() {
        setRecording(null);
    }

    function handleSaveModal(lines: Question['lines']) {
        updateQuestion(recording, { lines });
        setRecording(null);
    }

    function renderQuestion(q: Question, questionIndex: number) {
        return (
            <div key={`${uniqueKey}-${questionIndex}`} className="create-broken-karaoke-question">
                <QuestionArrows
                    index={questionIndex}
                    numberOfQuestions={questions.length}
                    onClickUpArrow={shiftUp}
                    onClickDownArrow={shiftDown}
                />
                <div className="create-broken-karaoke-question__info">
                    <TextField
                        type="text"
                        label="Song"
                        maxLength={definition.song.length}
                        defaultValue={q.song}
                        onChange={(s: string) => handleSongChanged(questionIndex, s)}
                    />
                    <TextField
                        type="text"
                        label="Artist"
                        maxLength={definition.artist.length}
                        defaultValue={q.artist}
                        onChange={(a: string) => handleArtistChanged(questionIndex, a)}
                    />
                    <TextField
                        type="text"
                        label="Year"
                        maxLength={definition.year.length}
                        defaultValue={q.year}
                        onChange={(y: string) => handleYearChanged(questionIndex, y)}
                    />
                </div>
                <div className="create-broken-karaoke-question__lines">
                    {Object.keys(q.lines).map((lineKey) => {
                        const lineNumber = parseInt(lineKey);
                        const line = q.lines[lineNumber];
                        if (line.length === 0) {
                            line.push({});
                        }

                        return (
                            <div key={`${uniqueKey}-${lineKey}`} className="create-broken-karaoke-question__line">
                                {line.map((char, index) => {
                                    return (
                                        <CharacterInput
                                            key={`${uniqueKey}-${index}`}
                                            character={char}
                                            onChangeCharacter={(character: Character) =>
                                                handleChangeCharacter(character, questionIndex, lineNumber, index)
                                            }
                                            onDelete={() => handleDeleteCharacter(questionIndex, lineNumber, index)}
                                        />
                                    );
                                })}
                                {line.length < definition.lines.of.length && (
                                    <div
                                        className="create-broken-karaoke-question__add-character"
                                        onClick={() => handleAddCharacter(questionIndex, lineNumber)}
                                    >
                                        <Add />
                                    </div>
                                )}
                                {Object.keys(q.lines).length < definition.lines.length && (
                                    <div
                                        className="create-broken-karaoke-question__new-line"
                                        onClick={() => handleInsertLine(questionIndex, lineNumber)}
                                    >
                                        <CarriageReturn />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <div className="create-broken-karaoke-question__record-button-container">
                        <Button
                            className="create-broken-karaoke-question__record-button"
                            label="Record Input"
                            onClick={() => handleOpenCaptureModal(questionIndex)}
                        />
                        <Button
                            className="create-broken-karaoke-question__preview-button"
                            label="Preview"
                            onClick={() => handleOpenCaptureModal(questionIndex, true)}
                        />
                    </div>
                </div>
                <QuestionErase index={questionIndex} onRemove={removeQuestion} />
            </div>
        );
    }

    function getModalTitle(questionNumber: number) {
        const { song, artist } = questions[questionNumber];
        if (song && artist) {
            return `${song} - ${artist}`;
        } else if (song) {
            return song;
        } else {
            return `Question ${questionNumber + 1}`;
        }
    }

    return (
        <div className="create-broken-karaoke">
            {questions.map((q, i) => renderQuestion(q, i))}
            <div className="create-round-new-question__container">
                <Button
                    className="create-round-new-question__button"
                    label="New Question"
                    onClick={handleNewQuestion}
                />
            </div>
            {typeof recording === 'number' && (
                <KaraokeCaptureModal
                    title={getModalTitle(recording)}
                    closeModal={handleCloseModal}
                    saveAndClose={handleSaveModal}
                    isPreviewOnly={isPreviewMode}
                    lines={questions[recording].lines}
                />
            )}
        </div>
    );
}
