export type Validator = {
    min?: number;
    max?: number;
    validate: (v: string) => boolean;
    errorMessage: string;
};

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const sessionIdRegex = /^([a-z]|[A-Z]|[0-9]){4}$/;
const uidRegex = /^([a-z]|[A-Z]|[0-9]){20,36}$/;

function validatorsMap<T extends { [key: string]: Validator }>(t: T) {
    return t;
}

type MinMax = {
    min?: number;
    max?: number;
};

export function checkIsValidGameId(gameId: string) {
    const exactly20ValidCharsRegex = /^[a-zA-Z0-9]{20}$/;
    // Inaccurate test for now while transitioning to longer IDs
    return typeof gameId === 'string' && exactly20ValidCharsRegex.test(gameId);
}

export function checkIsValidSessionId(sessionId: string) {
    return sessionIdRegex.test(sessionId);
}

export function checkLooksLikeUid(str: string) {
    return uidRegex.test(str);
}

function stringLengthValidator(str = '', { min, max }: MinMax, shouldNotTrim?: boolean) {
    const defaultedMin = min || 1;
    const defaultedMax = max || Number.MAX_SAFE_INTEGER;
    const trimmed = shouldNotTrim ? str : str.trim();

    return Boolean(trimmed) && trimmed.length >= defaultedMin && trimmed.length <= defaultedMax;
}

export const dataValidators: { [key: string]: Validator } = validatorsMap({
    displayName: {
        min: 3,
        max: 14,
        validate: (name) => stringLengthValidator(name, dataValidators.displayName),
        errorMessage: 'Name must be between 3-14 characters',
    },
    gameName: {
        min: 3,
        max: 14,
        validate: (name) => stringLengthValidator(name, dataValidators.gameName),
        errorMessage: 'Name must be between 3-14 characters',
    },
    gameId: {
        min: 4,
        validate: (gameId) => checkIsValidGameId(gameId),
        errorMessage: 'Game ID must be valid',
    },
    email: {
        validate: (email) => emailRegex.test(email),
        errorMessage: 'Invalid email format',
    },
    password: {
        min: 6,
        validate: (v) => stringLengthValidator(v, dataValidators.password, false),
        errorMessage: 'Password must be at least 6 characters',
    },
    sessionId: {
        max: 4,
        validate: (v) => checkIsValidSessionId(v),
        errorMessage: 'Game Code must be a 4 digit alphanumeric code',
    },
    answer: {
        max: 40,
        validate: (answer) => stringLengthValidator(answer, dataValidators.answer),
        errorMessage: 'Answer must be between 1-40 characters',
    },
    searchTerm: {
        min: 3,
        max: 14,
        validate: (query) =>
            query === '' || checkLooksLikeUid(query) || stringLengthValidator(query, dataValidators.searchTerm),
        errorMessage: 'Search term must be between 3-14 characters',
    },
});
