import './play.scss';

import { GameState, GameTypes, PlayerAnswer, randomString } from '@house-of-games/common';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '../../common/components/buttons/buttons';
import { useRefState } from '../../common/components/ref-state/use-ref-state';
import { Toast } from '../../common/components/toast/toast';
import { FirebaseHelper } from '../../utils/firebase';
import { requestHandler } from '../../utils/request-handler';
import { getAnswerRoundComponent } from '../rounds/answer-round-component-map';
import { Join } from './join';

type AnswerComponentContainerProps = {
    gameId: GameTypes.GameId;
    uid: string;
    onLeave: () => void;
    audioElementRef: React.MutableRefObject<HTMLAudioElement>;
    changeAudioSource: (url: string) => Promise<string>;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const buzzSound: string = require('../game/buzz.mp3');

function AnswerComponentContainer({
    gameId,
    uid,
    onLeave,
    audioElementRef,
    changeAudioSource,
}: AnswerComponentContainerProps) {
    const [gameState, setGameState] = useState<GameState>(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [submittedMessage, setSubmittedMessage] = useState('');

    async function submit(answer: PlayerAnswer) {
        try {
            const assumedAnswer = answer;
            await requestHandler.answer(gameId, uid, gameState?.roundType, assumedAnswer);
            setSubmittedMessage('');
            setSubmittedMessage('Answer Submitted!');
        } catch (e) {
            handleRequestError(e);
        }
    }

    async function buzz(requestedTimeStamp?: number) {
        try {
            await requestHandler.buzz(gameId, uid, requestedTimeStamp);
        } catch (e) {
            handleRequestError(e);
        }
    }

    function handleRequestError(e: any) {
        if (e.customErrorCode === 'AUTH_INVALID') {
            setErrorMessage('');
            setErrorMessage('The game owner removed you from the game. Please refresh the page to re-join');
        } else if (e.message) {
            setErrorMessage('');
            setErrorMessage(e.message);
        }
    }

    useEffect(() => {
        const firestore = FirebaseHelper.getFirestore();
        const gameStateDocRef = doc(firestore, `games/${gameId}/public`, 'gameState');
        const gameStateSub = onSnapshot(gameStateDocRef, (doc) => {
            const newGameState = doc.data() as GameState;
            setGameState(newGameState);
        });
        return () => {
            gameStateSub();
        };
    }, []);

    const AnswerComponent = getAnswerRoundComponent(gameState?.roundType);
    return (
        <div className="answer-component-container">
            <div className="shelf shelf__top">
                <Button className="play__leave" label="Leave Game" onClick={onLeave} />
            </div>
            <AnswerComponent
                gameState={gameState}
                buzz={buzz}
                submit={submit}
                audioElementRef={audioElementRef}
                changeAudioSource={changeAudioSource}
            />
            <Toast s={errorMessage} />
            <Toast className="answer-submitted-toast" s={submittedMessage} />
        </div>
    );
}

type PlayProps = {
    isLoggedIn?: boolean;
};

type RouteParams = { sessionId: GameTypes.SessionId };

export function Play({ isLoggedIn }: PlayProps) {
    const { sessionId: routerSessionId } = useParams<RouteParams>();
    const [gameId, setGameId] = useRefState<string>(undefined);
    const audioElementRef = useRef<HTMLAudioElement>();
    const [audioSourceUrl, setAudioSourceUrl] = useState<string>(buzzSound);
    const audioLoadedResolver = useRef<() => void>();

    const uid = useMemo(() => {
        const existingPlayerId = localStorage.getItem('playerId');
        if (existingPlayerId) {
            return existingPlayerId;
        }

        const newPlayerId = randomString(32);
        localStorage.setItem('playerId', newPlayerId);
        return newPlayerId;
    }, []);

    async function triggerMutedPlayPause() {
        audioElementRef.current.muted = true;
        await audioElementRef.current.play();
        await audioElementRef.current.pause();
        audioElementRef.current.muted = false;
    }

    async function handleJoinGame(sessionId: GameTypes.SessionId, name: string) {
        triggerMutedPlayPause();
        const publicGame = await requestHandler.joinGame(sessionId, uid, name);
        setGameId(publicGame);
    }

    function changeAudioSource(url: string) {
        if (url === audioSourceUrl) {
            return Promise.resolve(url);
        }

        if (audioLoadedResolver.current) {
            audioLoadedResolver.current();
        }

        return new Promise<string>((resolve) => {
            setAudioSourceUrl(url);
            audioLoadedResolver.current = () => {
                audioLoadedResolver.current = undefined;
                resolve(url);
            };
        });
    }

    return (
        <div className="play page">
            <audio
                ref={audioElementRef}
                src={audioSourceUrl}
                crossOrigin="anonymous"
                onCanPlayThrough={audioLoadedResolver.current}
            />
            {gameId ? (
                <AnswerComponentContainer
                    gameId={gameId}
                    uid={uid}
                    onLeave={() => setGameId(undefined)}
                    audioElementRef={audioElementRef}
                    changeAudioSource={changeAudioSource}
                />
            ) : (
                <Join isLoggedIn={isLoggedIn} initialSessionId={routerSessionId} handleJoinGame={handleJoinGame} />
            )}
        </div>
    );
}
