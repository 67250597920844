type TrimProps = {
    scale?: number;
};

export function Trim({ scale = 1 }: TrimProps) {
    const roundedScale = Math.round(24 * scale);
    return (
        <svg className="icon__trim" width={`${roundedScale}`} height={`${roundedScale}`} viewBox="0 0 24 24">
            <path d="M8,7l5.4,5L8,17v-4H3v-2h5V7z M18,9c-1.66,0-3,1.34-3,3s1.34,3,3,3s3-1.34,3-3S19.66,9,18,9z" />
        </svg>
    );
}
