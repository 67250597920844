import './play-z-to-a.scss';

import { RoundTypes } from '@house-of-games/common';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { useRefState } from '../../../common/components/ref-state/use-ref-state';
import { TextFlip } from '../../../common/components/text-flip/text-flip';
import { MessageName } from '../../../common/constants/message';
import { SlideProps } from '../../game/game';

const alphabetArray = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
];

export function PlayZToA({ question, message, onPlayFinished }: SlideProps<RoundTypes.ZToARound>) {
    const [blanked, setBlanked, blankedRef] = useRefState(alphabetArray);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isRevealed, setIsRevealed] = useState(false);
    const isStarted = useRef(false);
    const isDone = useRef(false);
    const letterInterval = useRef<number>(null);

    useEffect(() => {
        switch (message.name) {
            case MessageName.play:
                isStarted.current = true;
                setIsPlaying(true);
                break;
            case MessageName.pause:
            case MessageName.buzzed:
                setIsPlaying(false);
                break;
            case MessageName.reveal:
                setIsRevealed(true);
                break;
            case MessageName.clear:
                if (!isDone.current && isStarted.current) {
                    setIsPlaying(true);
                }
                break;
        }
    }, [message]);

    useEffect(() => {
        if (isDone.current) {
            return;
        } else if (isPlaying) {
            startInterval();
        } else if (!isPlaying) {
            clearInterval(letterInterval.current);
        }

        return () => {
            clearInterval(letterInterval.current);
        };
    }, [isPlaying]);

    function startInterval() {
        letterInterval.current = window.setInterval(() => {
            if (blankedRef.current.length === 0) {
                clearInterval(letterInterval.current);
                isDone.current = true;
                onPlayFinished();
            }
            const newBlanked = [...blankedRef.current];
            newBlanked.pop();
            setBlanked(newBlanked);
        }, 700);
    }

    return (
        <div className="play-z-to-a slide page">
            <div className="play-z-to-a__letters-container">
                <div className="play-z-to-a__letters">
                    {alphabetArray.map((letter, i) => {
                        const letterClasses = classNames('play-z-to-a__letter', {
                            'play-z-to-a__letter--hidden': !blanked[i] || isRevealed,
                        });
                        return (
                            <span key={i} className={letterClasses}>
                                {letter}
                            </span>
                        );
                    })}
                </div>
                {isRevealed && <TextFlip className="play-z-to-a__answer" text={question.answer} offset={0} />}
            </div>
            <div className="play-z-to-a__clues-container">
                {Object.values(question.question).map((clue, i) => {
                    const clueArray = clue.split('').map((letter) => {
                        return !isRevealed && blanked.includes(letter.toLowerCase()) ? '-' : letter;
                    });
                    return (
                        <div key={i} className="play-z-to-a__clue-container">
                            {clueArray.map((letter, i) => {
                                return letter === ' ' ? (
                                    <div className="play-z-to-a__space" />
                                ) : (
                                    <div key={i} className="play-z-to-a__clue">
                                        <TextFlip text={letter} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
